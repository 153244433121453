import React, { useEffect, useRef, useState } from "react";
import CustomSkeleton from "../../../components/Skeleton";
import NewRateChangeReportTable from "../../newTable/newRateRangeRepotrTable";
import Calendar from "../../../components/calendar";
import { AiFillCalendar, AiOutlineWhatsApp } from "react-icons/ai";
import { format } from "date-fns";
import useIntelligenceDateRange from "../../../utils/dateRange";
import { DateRangePicker } from "react-date-range";

const CompsetReport = ({
  data,
  dateRange,
  setDateRange,
  isLoading,
  setIsWhatsApp,
  sendToWhatsappAdmin,
  monthEndWhatsAppAdmin,
  handleDownload,
}) => {

  const [showDateRange, setShowDateRange] = useState(false);
  const ref1 = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref1?.current && !ref1?.current.contains(event.target)) {
        setShowDateRange(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref1]);
  function getDate30DaysAfter() {
    const today = new Date();
    const thirtyDaysLater = new Date(today);
    thirtyDaysLater.setDate(today.getDate() + 90);
    return thirtyDaysLater;
  }

  const [expandedIndex, setExpandedIndex] = useState(0);
  const handleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  return (
    <>
      <div className="h-[80vh]">
        <div className="h-[10%] my-4 flex items-center text-white dark:text-black justify-between">
          <h1 className="text-[24px] font-bold"> Compset Rate Report</h1>
          <div className="flex flex-row items-center">
            <div className=" flex items-center gap-2 justify-center pr-2">
              <div className="w-fit font-semibold text-[17px]">Select date</div>
              {
                <div
                  className="relative mb-2 w-fit justify-start text-xs cursor-pointer select-none flex gap-4 items-center mx-1 px-2 py-3 text-white dark:text-black rounded-md border-solid border border-[#A0A4B0] z-20"
                  ref={ref1}
                >
                  <div
                    className="flex  text-white dark:text-black  gap-2 items-center"
                    onClick={() => setShowDateRange(!showDateRange)}
                  >
                    {" "}
                    <AiFillCalendar size={14} />{" "}
                    {format(new Date(dateRange[0].startDate), "dd MMM yyy")} -{" "}
                    {format(new Date(dateRange[0].endDate), "dd MMM yyy")}
                  </div>
                  {showDateRange && (
                    <div className="absolute z-50 top-[50px] right-[0px]">
                      <DateRangePicker
                        ranges={dateRange}
                        onChange={(range) => {
                          setDateRange([
                            {
                              startDate: range?.range1?.startDate,
                              endDate: range?.range1?.endDate,
                              range: "range 1",
                            },
                          ]);
                        }}
                        minDate={new Date()}
                        maxDate={getDate30DaysAfter()}
                      />
                    </div>
                  )}
                </div>
              }
            </div>

            <h1
              onClick={handleDownload}
              className="flex  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
            >
              Download
            </h1>

            <div
              onClick={setIsWhatsApp}
              className="flex  w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
            >
              <AiOutlineWhatsApp size={18} /> Send to WhatsApp
            </div>
          </div>
        </div>{" "}
        {/* -------------------------------------------------------------------------- */
        /*                                 Metric Data                                */
        /* -------------------------------------------------------------------------- */}
        <div className="flex flex-col gap-2 mt-2 overflow-y-scroll h-[90%]">
          {!isLoading ? (
            <div className="flex flex-col ">
              <div className="flex flex-row justify-between w-[100%]"></div>

              {/* Dynamic RateChange Report */}
              {data && data.map((reportData,id) => {
                return (
                  <NewRateChangeReportTable
                    data={reportData}
                    label={reportData?.header[0]}
                    fullHeight={true}
                    isExpanded={expandedIndex === id}
                    onExpand={() => handleExpand(id)}
                  />
                );
              })}
            </div>
          ) : (
            <div className="w-[100%] mt-[50px]">
              <CustomSkeleton height={400} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CompsetReport;
