import * as ExcelJS from "exceljs";
import { logoUrl } from "./retvensLogo.js";
import { formatDate } from "./formatDate.js";

async function transformData(data, currencySymbol) {
  const OTAs = [
    "Booking.com",
    "Goibibo",
    "Agoda",
    "MakeMyTrip",
    "Trip.com",
    "EaseMyTrip",
    "HappyEasyGo",
    "ClearTrip",
    "HRS",
  ];
  const defaultRate = "Sold out";

  function getUniqueOtaNames(data) {
    const uniqueOtas = new Set();
    try {
      for (const item of data) {
        for (const rateData of item.rateData) {
          uniqueOtas.add(rateData.otaName);
        }
      }
    } catch (error) {
      console.error("Error in getUniqueOtaNames:", error);
    }
    return Array.from(uniqueOtas);
  }

  let otasPresent = getUniqueOtaNames(data.ratesDate);

  let res = data.ratesDate.map((entry) => {
    let transformedEntry = {
      "Hotel Name": data.hotelName,
      Date: new Date(entry.date).toLocaleDateString("en-GB"), // Format date as DD/MM/YYYY
      Day: new Date(entry.date).toLocaleString("en-GB", { weekday: "short" }), // Get short day name
    };

    // Initialize all OTA fields to "Sold out"
    otasPresent.forEach((ota) => {
      transformedEntry[ota] = "Sold Out";
    });

    // Fill in the rates from the rateData
    entry.rateData.forEach((rateEntry) => {
      transformedEntry[rateEntry.otaName] = `${currencySymbol}${rateEntry.rate}`;
    });

    return transformedEntry;
  });

  return res;
}

export const ratesReportDownload = async (
  temp,
  hotelName,
  dayStart,
  endDate,
  currencySymbol = "₹"
) => {
  try {
    let transformedData = [];
    await Promise.all(
      temp.map(async (data, id) => {
        let res = await transformData(data, currencySymbol);
        transformedData.push(res);
      })
    );

    let maxColumnLength = 1; // Handle empty item
    transformedData.forEach((item) => {
      maxColumnLength = Math.max(
        maxColumnLength,
        item.length > 0 ? Object.keys(item[0]).length : 0
      );
    });

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data");

    const logoBase64 = logoUrl; // Add image at B1
    const logoId = workbook.addImage({
      base64: logoBase64,
      extension: "jpg",
    });

    // Merge cells for the image
    worksheet.mergeCells("A1", worksheet.getColumn(maxColumnLength).letter + "3");
    worksheet.addImage(logoId, "A1:A3");

    // Set column widths
    const columns = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
    const columnWidths = [15, 15, 15, 15, 15, 15, 15, 15, 15]; // Adjust widths as needed
    columns.forEach((column, index) => {
      worksheet.getColumn(column).width = columnWidths[index];
    });

    // Display today's date at the top
    const dateCell = worksheet.getCell(worksheet.getColumn(maxColumnLength).letter + "3");
    dateCell.value = new Date();
    dateCell.numFmt = "dd mmmm yyyy";

    // Add main heading and merge cells
    const mainHeading = worksheet.getCell("A4");
    mainHeading.value = `Rates Report ${hotelName} (${formatDate(dayStart)} - ${formatDate(endDate)})`;
    mainHeading.alignment = { horizontal: "center", vertical: "middle" };
    mainHeading.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "B7D166" },
    };

    worksheet.mergeCells("A4", worksheet.getColumn(maxColumnLength).letter + "4");
    worksheet.getRow(4).height = 40; // Increase row height
    worksheet.mergeCells("A5", "L5");

    // Loop through transformedData to add content
    transformedData.forEach((DATA3) => {
      if (!DATA3 || DATA3.length === 0) return;

      let currentRow = worksheet.rowCount;

      currentRow++;
      worksheet.mergeCells("A" + currentRow, "B" + currentRow);
      worksheet.addRow([DATA3[0]["Hotel Name"]]).eachCell((cell) => {
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });

      currentRow++;
      worksheet.mergeCells("A" + currentRow, "B" + currentRow);
      worksheet.addRow([]);

      // Add headers (keys from DATA3[0], excluding the first one)
      if (DATA3[0]) {
        worksheet.addRow(Object.keys(DATA3[0]).slice(1)).eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "B7D166" },
          };
          cell.border = {
            top: { style: "thin", color: { argb: "ffffff" } },
          };
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          };
        });
      }

      // Add data rows
      DATA3.forEach((source, index) => {
        worksheet.addRow(Object.values(source).slice(1)).eachCell((cell) => {
          if (cell.value === "Sold out") {
            cell.font = { color: { argb: "0000FF" } }; // Set font color to blue
          }
          if (index % 2 !== 0) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "ECEFCE" },
            };
          }
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          };
        });
      });

      worksheet.addRow([]); // Add empty row after each set of data
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the blob and trigger download
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Rates Report ${hotelName}.xlsx`; // Set filename
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error generating the report:", error);
  }
};
