import React, { useEffect, useRef, useState } from "react";
import MonthReportTable from "../../newTable/MonthReportTable";
import formatCurrency from "../../../utils/formatCurrency";
import Calendar from "../../../components/calendar";
import { DownloadMonthEndReport } from "../DownloadExcel/MonthEndReport";
import CustomSkeleton from "../../../components/Skeleton";
import { AiFillCalendar, AiOutlineWhatsApp } from "react-icons/ai";
import { formatDate } from "../DownloadExcel/formatDate";
import { DateRangePicker } from "react-date-range";
import { format, set } from "date-fns";
import Dropdown from "../../../components/dropdown";
import CustomCalander from "../../../components/CustomCalender/CustomCalander";
import NoDataFound from "../../../components/noData";
import useIntelligenceDateRange from "../../../utils/dateRange";
import { toast } from "react-toastify";
import whatsappBusiness from "../../../assets/img/whatsapp-business-icon.svg";

const MonthEndTable = ({
  data,
  dateRange,
  setDateRange,
  isLoading,
  setIsWhatsApp,
  sendToWhatsappAdmin,
  monthEndWhatsAppAdmin,
}) => {
  const dateLimit = useIntelligenceDateRange();
const [isDropdownOpen,setIsDropdownOpen]=useState();
  const [expandedIndex, setExpandedIndex] = useState(0);
  // console.log(dateRange,"date Range")
  // const[date,setDate]=useState([]);
  // const [selectedYear, setSelectedYear] = useState([
  //   format(
  //     new Date(new Date(2023, 0, 1).setFullYear(new Date().getFullYear() - 1)),
  //     "dd-MM-yyyy"
  //   ),
  //   format(
  //     new Date(
  //       new Date(2023, 11, 31).setFullYear(new Date().getFullYear() - 1)
  //     ),
  //     "dd-MM-yyyy"
  //   ),
  // ]);
  //  console.log(selectedYear,"selected Date")

  const [showDateRange, setShowDateRange] = useState(false);
  const ref1 = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref1?.current && !ref1?.current.contains(event.target)) {
        setShowDateRange(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref1]);
  const handleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  if (!data && !isLoading && !monthEndWhatsAppAdmin) {
    // Render a fallback UI or an error message when data is undefined
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center text-white dark:text-black justify-center p-2 font-semibold">
          <p>Month End Report </p>
        </div>
        <div className="m-3 w-full flex justify-end items-end">
          <div className="w-fit"></div>
          <h1
            onClick={() => {}}
            className="flex  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            Download
          </h1>
          <div
            onClick={setIsWhatsApp}
            className="flex  w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            <AiOutlineWhatsApp size={18} /> Send to WhatsApp
          </div>
        </div>
        <div className="h-full flex items-center justify-center w-[100%]">
          {/* <CustomSkeleton height={500} /> */}

          <NoDataFound />
        </div>
      </div>
    );
  }
  if (isLoading && !monthEndWhatsAppAdmin) {
    // Render a fallback UI or an error message when data is undefined
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center text-white dark:text-black justify-center p-2 font-semibold">
          <p>Month End Report</p>
        </div>

        <div className="w-[100%]">
          <CustomSkeleton
            height={500}
            title={"Month End Report"}
            animated={true}
            isTransparent={true} 
          />
          {/* No data found */}
        </div>
      </div>
    );
  }
  const { statistics, sourceData, roomData, twoMonthLOS, nextMonth } = data;
  const propertyName = window.localStorage.getItem("propertyName");
  const fromDate = format(
    new Date(dateLimit?.fromDate) || new Date(),
    "MM/dd/yyyy"
  );
  const endDate = format(
    new Date(dateLimit?.toDate) || new Date(),
    "MM/dd/yyyy"
  );

  return (
    <>
      <div className="flex top-0 sticky  items-center justify-center p-2 font-semibold">
        <p className="sticky text-white dark:text-black ">
          Month End Report - {propertyName} ({" "}
          {formatDate(new Date(dateRange[0])) ||
            formatDate(data.asOn?.startDate)}{" "}
          -{" "}
          {formatDate(new Date(dateRange[1])) || formatDate(data.asOn?.endDate)}{" "}
          )
        </p>
      </div>
      <div className="w-full sticky top-[40px] flex justify-end  ">
        <div className="m-3 w-auto items-center flex justify-end  ">
          <div className="w-fit">
            {/* <Calendar
              selectedDate={selected2Date}
              setSelectedDate={setSelected2Date}
              backgroundNone={true}
              right={"-left-0"}
              showIconBackground={true}
              onlyPastDaysSelectable={true}
            /> */}

            <div className=" flex items-center text-white dark:text-black gap-2 justify-center pr-2">
              <div className="w-fit text-sm">Select date range</div>
              <div className="border dark:border-black p-2 flex items-center rounded-md justify-center">
                <div>
                  {" "}
                  {formatDate(new Date(dateRange[0])) ||
                    formatDate(data.asOn?.startDate)}{" "}
                  -{" "}
                  {formatDate(new Date(dateRange[1])) ||
                    formatDate(data.asOn?.endDate)}{" "}
                </div>
                <CustomCalander
                  calendarType={"single"}
                  onlyMonth={true}
                  activeDateArray={"selected"}
                  selectedDateArray={dateRange}
                  setSelectedDateArray={setDateRange}
                  restrictionStart={fromDate}
                  restrictionEnd={endDate}
                />
              </div>
            </div>
          </div>

          <h1
            onClick={() => {
              DownloadMonthEndReport(data);
            }}
            className="flex m-auto   justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            Download
          </h1>
          {/* <div
            // onClick={() => {
            //   DownloadMonthEndReport(data);
            // }}
            onClick={setIsWhatsApp}
            className="flex m-auto w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            <AiOutlineWhatsApp size={18} /> Send to WhatsApp
          </div> */}
           <div
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="flex m-auto w-[180px] gap-2 justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-xs text-white  font-medium text-[17px]"
      >
        <AiOutlineWhatsApp size={18} /> Send via WhatsApp
      </div>

      {/* Dropdown Options */}
      {isDropdownOpen && (
        <div className="absolute mt-2 w-[180px] text-xs px-4 rounded-lg  shadow-lg dark:bg-[#1D3893] bg-[#3ec329]">
          <div
            onClick={() => {
              setIsDropdownOpen(false);
              setIsWhatsApp(); // Handle Send to Self
            }}
            className="flex gap-2 justify-start items-center p-2 cursor-pointer"
          >
            <AiOutlineWhatsApp size={18} /> Send to self
          </div>
          <div
            onClick={() => {
              setIsDropdownOpen(false);
              sendToWhatsappAdmin();
              // Handle Send to Personal Number
            }}
            className="flex gap-2 justify-start items-center p-2  cursor-pointer"
          >
            <AiOutlineWhatsApp size={18} /> Send to secondary 
          </div>
        </div>
      )}
          {/* <div
            onClick={sendToWhatsappAdmin}
            className="flex  w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
          <img src={whatsappBusiness} height={20} width={20} alt="" /> Send to Group
          </div> */}
        </div>
      </div>

      <div className=" overflow-y-auto h-[80%] ">
        <div className="flex flex-col gap-4">
          <MonthReportTable
            data={sourceData}
            arrival={true}
            isExpanded={expandedIndex === 0}
            onExpand={() => handleExpand(0)}
          />
          <MonthReportTable
            data={roomData}
            arrival={true}
            room={true}
            isExpanded={expandedIndex === 1}
            onExpand={() => handleExpand(1)}
          />
        </div>
        <div className="flex my-4 justify-between gap-4">
          <TableCards
            title={"Revenue"}
            mainData={formatCurrency(statistics[0]?.Revenue)}
          />
          <TableCards
            title={"Reservations"}
            mainData={statistics[0]?.Reservations}
          />
          <TableCards
            title={"ADR"}
            mainData={formatCurrency(statistics[0]?.ADR)}
          />
          <TableCards
            title={"Cancellations"}
            mainData={statistics[0]?.Cancellations}
          />
          {/* <TableCards
            title={"Occupancy"}
            mainData={statistics[0]?.Occupancy.toFixed(2)}
          /> */}
        </div>
        {twoMonthLOS.map((item, index) => (
          <>
            <div className="px-4 py-1 w-[100%] text-white dark:text-[#0D1321] font-semibold flex items-center">
              Average Length Of Stay
            </div>
            <div className="flex my-2 justify-between gap-4">
              <div
                key={index}
                className="px-4 py-2 w-[100%] rounded-lg border  text-white dark:bg-lightGradient bg-newGradient "
              >
                <p className="font-semibold ">Current Month</p>
                <p>{item["Current Month"]?.toFixed(2)}</p>
              </div>
              <div className="px-4 py-2 w-[100%] rounded-lg border  text-white dark:bg-lightGradient bg-newGradient ">
                <p className="font-semibold ">Next Month</p>
                <p>{item["Next Month"]?.toFixed(2)}</p>
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default MonthEndTable;

const TableCards = ({ mainData, title, height, width }) => {
  return (
    <div
      className={`px-4 py-2 w-[100%]  text-white dark:bg-lightGradient bg-newGradient  rounded-lg border `}
      style={{ height: `${height}px` }}
    >
      <div className=" font-semibold ">{title}</div>
      <div className=" mt-1 flex items-center gap-2 font-medium ">
        <div>
          {mainData}
          {title == "Occupancy" && "%"}
        </div>
      </div>
    </div>
  );
};
