import React from "react";
import Chart from "react-apexcharts";
import monthsArray from "../../utils/eNums/monthsArray";
import filter1 from "../../assets/icons/filter1.svg";
import filter2 from "../../assets/icons/filter2.svg";
import Dropdown from "../dropdown";
import ColumnChart from "./columnChart";
import formatCurrency from "../../utils/formatCurrency";
import ExcelDownloadButton from "../excelDownloadButton";
import { AiOutlineTable } from "react-icons/ai";
import NewTooltip from "../tooltip/NewTooltip";
import { format } from "date-fns";
import InfoToolTip from "../tooltip/InfoToolTip";

const AreaChart = ({
  height,
  showToolbar,
  xaxisShow,
  yaxisShow,
  dashed,
  showTick,
  title,
  showFilters,
  showTitle,
  currentSeriesName,
  currentSeriesData,
  pastSeriesData,
  showSingleSeries,
  onClick1,
  onClick2,
  filterState = false,
  filled = true,
  isCurrency,
  perviousLabel,
  currentLabel,
  downloadButtonData,
  fileName,
  setTableState,
  reversed,
  asPer,
  dateLabel,
  showToolTip = false,
  isPercentage,
  multiSeries = false,
  multiSeriesData,
  chartItem,
  isFormat = false,
  tooltipIsFormat=false,
  labelDivideValue,
  textWhite=false
}) => {

  // Sample data for the chart
  const colour = [
    "#305BEF",
    "#FA7070",
    "#F4A261",
    "#2A9D8F",
    "#E76F51",
    "#264653",
    "#E9C46A",
    "#8AB17D",
    "#F9844A",
    "#6A0572",
    "#FF6F61",
    "#F7C59F",
    "#5D2E8C",
  ];
  const chartData = {
    options: {
      chart: {
        id: "area-chart",
        toolbar: {
          show: showToolbar ? true : false,
        },
        sparkline: {
          enabled: false,
        },
      },
      xaxis: {
        show: xaxisShow,
        fill: 'white',
        axisTicks: {
          show: showTick,
        },
        categories: multiSeries
          ? multiSeriesData?.length > 0
            ? multiSeriesData.map((item, index) => {
                return item?.date[index]
                  ? format(new Date(item?.date[index]), "dd-MMM-yy")
                  : "N/A";
              })
            : []
          : currentSeriesName || monthsArray,
        labels: {
          show: xaxisShow,
          style: {
            colors: textWhite && '#FFFFFF', // Sets the text color to white
          },
        },
      },
      yaxis: {
        show: yaxisShow,
        reversed: reversed,
        labels: {
          formatter: function (value) {
            return isCurrency
              ? formatCurrency(value, isFormat && { isDecimal: false, isShortFormat: true })
              : isPercentage
              ? value?.toFixed(2)
              : typeof value === "number"
              ? value.toFixed(2)
              : value;
          },
          style: {
            colors: textWhite && '#FFFFFF', // Sets the text color to white
          },
        },
      },
      grid: {
        show: false, // Set to false to hide grid lines
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return multiSeries
            ? `<div class="max-h-max min-h-auto p-4 rounded-xl bg-white">
                <div class="w-[100%] text-center text-lg mb-2 font-semibold">${chartItem} for ${multiSeriesData[seriesIndex]?.date[dataPointIndex] ? format(new Date(multiSeriesData[seriesIndex]?.date[dataPointIndex]), "dd-MMM-yy") : "N/A"} </div>
                <div class="flex items-center gap-4 justify-between mt-4">
                  <div>
                    ${multiSeriesData?.map((item, index) => {
                      return `<div class=" flex items-center gap-2">
                                <div class=" w-2 h-2 rounded-full" style="background:${colour[index]}" ></div>
                                <div class="text-black flex text-xs min-w-[100px] truncate">${item?.hotelName || "Unnamed"}</div>
                              </div>`;
                    }).join('')}
                  </div>
                  <div>
                    ${series?.map((item) => {
                      return `<div class="text-black text-xs">${item[dataPointIndex] ? (chartItem === "Room Nights" ? item[dataPointIndex] : formatCurrency(item[dataPointIndex])) : 0}</div>`;
                    }).join('')}
                  </div>
                </div>
              </div>`
            : showSingleSeries
              ? `<div class="custom-tooltip">
                  <div class="custom-text-tooltip-second">
                    ${currentLabel} : ${isCurrency ? formatCurrency(series[0][dataPointIndex], tooltipIsFormat && { isDecimal: false, isShortFormat: true }) : series[0][dataPointIndex] || 0}
                  </div>
                  <div class="custom-text-tooltip-first">
                    For: ${currentSeriesName[dataPointIndex]}
                  </div>
                </div>`
              : `<div class="custom-tooltip">
                  <div class="custom-text-tooltip-first">
                    ${perviousLabel ? perviousLabel : "Previous"} : ${isCurrency ? formatCurrency(series[0][dataPointIndex], tooltipIsFormat && { isDecimal: false, isShortFormat: true }) : series[0][dataPointIndex] || 0}
                  </div>
                  <div class="custom-text-tooltip-second">
                    ${currentLabel ? currentLabel : "Current"} : ${isCurrency ? (formatCurrency(series[1][dataPointIndex]) === undefined ? "N/A" : formatCurrency(series[1][dataPointIndex], tooltipIsFormat && { isDecimal: false, isShortFormat: true })) : series[1][dataPointIndex] === undefined ? "N/A" : series[1][dataPointIndex]}
                  </div>
                </div>`;
        },
        x: {
          show: true,
        },
        y: {
          formatter: function (value) {
            return value; // You can customize the tooltip value formatting as needed
          },
        },
        marker: {
          show: false,
        },
        strokeDashArray: [], // Set to an empty array for a solid line
      },
      colors: [
        "#F6F5F2",
        "#FA7070",
        "#F4A261",
        "#2A9D8F",
        "#E76F51",
        "#264653",
        "#E9C46A",
        "#8AB17D",
        "#F9844A",
        "#6A0572",
        "#FF6F61",
        "#F7C59F",
        "#5D2E8C",
      ],
      stroke: {
        colors: [
          "#F6F5F2",
          "#FA7070",
          "#F4A261",
          "#2A9D8F",
          "#E76F51",
          "#264653",
          "#E9C46A",
          "#8AB17D",
          "#F9844A",
          "#6A0572",
          "#FF6F61",
          "#F7C59F",
          "#5D2E8C",
        ],
        width: [1, 1],
        dashArray: dashed && [4, 0],
      },
      dataLabels: {
        enabled: false, // Set to false to hide data labels on the lines
      },
      legend: {
        showForSeries: [], // Set to an empty array to hide the series number in the legend
        show: false, // Set to false to hide legend text
      },
      fill: {
        type: multiSeries
          ? [
              "gradient",
              "gradient",
              "gradient",
              "gradient",
              "gradient",
              "gradient",
              "gradient",
              "gradient",
              "gradient",
              "gradient",
              "gradient",
              "gradient",
              "gradient",
            ]
          : filled
            ? ["gradient", "gradient"]
            : ["solid"], // Set different fill types for each series
        colors: [
          "#F6F5F2",
          "#FA7070",
          "#F4A261",
          "#2A9D8F",
          "#E76F51",
          "#264653",
          "#E9C46A",
          "#8AB17D",
          "#F9844A",
          "#6A0572",
          "#FF6F61",
          "#F7C59F",
          "#5D2E8C",
        ],
        opacity: !multiSeries && !filled && 0,
        gradient: filled && {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0.5,
          gradientToColors: ["", ""],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 0,
          stops: multiSeries ? [100, 100] : [0, 100],
        },
      },
    },
  
    series: multiSeries
      ? multiSeriesData?.map((item) => {
          return {
            name: item?.hotelName || "Unnamed", // Fallback to "Unnamed" if hotelName is undefined
            data: item?.value || [], // Fallback to empty array
          };
        }) || [] // Fallback to empty array if multiSeriesData is undefined
      : showSingleSeries && !multiSeries
        ? [
            {
              name: "Series 2",
              data: Array.isArray(currentSeriesData) ? currentSeriesData : [],
            },
          ]
        : [
            {
              name: "Series 1",
              data: Array.isArray(pastSeriesData) ? pastSeriesData : [],
            },
            {
              name: "Series 2",
              data: Array.isArray(currentSeriesData) ? currentSeriesData : [],
            },
          ],
  };
  

  React.useEffect(() => {
    const div = document.getElementsByClassName(
      "apexcharts-canvas apexchartsarea-chart"
    );
    const div2 = document.getElementById("apexchartsarea-chart");
    if (div2) {
      div2.parentElement.style.minHeight = `0px !important`;
    }
    // if (div) {
    //   for (let i = 0; i < div.length; i++) {
    //     if (div[i].parentElement.attributes.type.value === "area") {
    //       div[i].parentElement.style["min-height"] = `${height}px`;
    //     }
    //   }
    // }
  }, []);

  return (
    <div className="relative">
      {/* This div controls the z-index to ensure the chart is below other elements */}
      <div
        style={{
          zIndex: 1, // Ensure this is lower than the other component
          position: "relative", // Make sure the chart has positioning context
        }}
      >
        <div className="flex p-4">
          {showTitle && (
            <p className="text-white">
              {title || "Chart Name"}
              <span className="text-[12px] ml-2 text-white">
                {asPer && `as per ${format(new Date(asPer), "dd MMM','yy")}`}
              </span>
            </p>
          )}
          <div className="flex items-center">
            {showFilters && (
              <div className="flex items-center gap-2">
                <div>
                  <img src={filter2} loading="lazy" onClick={onClick1} />
                </div>
                <div>
                  <img src={filter1} loading="lazy" onClick={onClick2} />
                </div>
                <Dropdown selectedItem={"Today's"} />
              </div>
            )}
            {setTableState && (
              <NewTooltip title="View Table View" color="#1c274c">
                <AiOutlineTable
                  style={{
                    marginRight: "10px",
                    color: "white",
                    cursor: "pointer",
                  }}
                  size={20}
                  onClick={() => setTableState(true)}
                />
              </NewTooltip>
            )}
            {downloadButtonData && (
              <ExcelDownloadButton
                data={downloadButtonData}
                filename={fileName}
              />
            )}
            {showToolTip && <InfoToolTip content="Enter Text" />}
          </div>
        </div>
        {!filterState && (
          <Chart
            options={chartData.options}
            series={chartData.series}
            width={"100%"}
            type={multiSeries ? "line" : "area"}
            height={height}
          />
        )}
        {filterState && <ColumnChart height={height} />}
      </div>
    </div>
  );
  
};

export default AreaChart;
