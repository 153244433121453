import React, { useEffect, useRef, useState } from "react";
import { useGetRateChangeReportQuery } from "../../../redux/slices/reports";
import RateChangeReportTable from "../../newTable/rateChangeReportTable";
import { CurrentProperty, UserId } from "../../../utils/impVars";
import { formatDate } from "../DownloadExcel/formatDate";
import { format } from "date-fns";
import { Calendar, DateRangePicker } from "react-date-range";
import { AiFillCalendar, AiOutlineWhatsApp } from "react-icons/ai";
import CustomSkeleton from "../../../components/Skeleton";
import useIntelligenceDateRange from "../../../utils/dateRange";
import { DownloadCancellationReport } from "../DownloadExcel/CancellationReport";

const CancellationReport = ({ data,dateRange,setDateRange ,isLoading,setIsWhatsApp,sendToWhatsappAdmin}) => {
    // const [showDateRange, setShowDateRange] = useState(false);
    const dateLimit=useIntelligenceDateRange();
  const [selectedDate, setSelectedDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
    },
  ]);
  const [showDateRange, setShowDateRange] = useState(false);
//   const [showDateRange, setshowDateRange] = useState(false);
  const newDate = new Date();

  const [parityDateRange, setParityDateRange] = useState([
    {
      startDate: newDate,
      endDate: new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate() + 10
      ), // Setting endDate to 10 days ago from today
      id: "range 1",
    },
  ]);


  // const {data} = useGetRateChangeReportQuery({})
  // const { dataCalcellationDataFinal ,sourceCancellationDataFinal,roomCancellationDataFinal} = data?.cancellationData
  // console.log(dataCalcellationDataFinal,"dataCalcellationDataFinal")
//   const startDate = parityDateRange[0].startDate;
  const [expandedIndex, setExpandedIndex] = useState(0);
  const fromDate = format( new Date(dateLimit?.fromDate), 'MM/dd/yyyy');
  const endDate = format( new Date(dateLimit?.toDate), 'MM/dd/yyyy');
  const ref1 = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref1?.current && !ref1?.current.contains(event.target)) {
        setShowDateRange(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref1]);
  const [isDropdownOpen,setIsDropdownOpen]=useState(false)
const dropdownRef = useRef(null);

useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);
  // // const { pickupToday ,pickupLastYear,pickupThisMonth,pickupNextMonth} = data;
  const handleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  return (
    <>
      <div className="my-4 flex items-center text-white dark:text-black justify-between">
      <h1>  Cancellations Report</h1>
        {/* <div>
          <div
            className="relative flex items-center gap-2 cursor-pointer text-sm"
            onClick={() => setshowDateRange(!showDateRange)}
          >
            <AiFillCalendar />
            Show Calendar
          </div>
          {showDateRange  && (
            <DateRangePicker
              className="absolute right-0"
              ranges={parityDateRange}
              onChange={(range) => {
                setParityDateRange([
                  {
                    startDate: range?.range1?.startDate,
                    endDate: range?.range1?.endDate,
                    range: "range 1",
                  },
                ]);
              }}
            />
          )}
        </div> */}
<div className="flex items-center">


<div className=" flex items-center gap-2 justify-center pr-2">
              <div className="w-fit text-sm">Select date range</div>
              {
                <div
                  className="relative mb-2 w-fit justify-start text-xs cursor-pointer select-none flex gap-4 items-center mx-1 px-2 py-3 text-gray-600 rounded-md border-solid border border-[#A0A4B0] z-20"
                  ref={ref1}
                >
                  <div
                    className="flex  gap-2 items-center"
                    onClick={() => setShowDateRange(!showDateRange)}
                  >
                    {" "}
                    <AiFillCalendar size={14} />{" "}
                    {format(new Date(dateRange[0].startDate), "dd MMM yyy") ||
                      " "}{" "}
                    -
                    {format(new Date(dateRange[0].endDate), "dd MMM yyy") ||
                      " "}
                  </div>
                  {showDateRange && (
                    <div className="absolute top-[50px] -left-[200px]">
                      <DateRangePicker
                        ranges={dateRange}
                        onChange={(range) => {
                          setDateRange([
                            {
                              startDate: range?.range1?.startDate,
                              endDate: range?.range1?.endDate,
                              range: "range 1",
                            },
                          ]);
                        }}
                        minDate={new Date(fromDate)}
                        maxDate={new Date(endDate)}
                      />
                    </div>
                  )}
                </div>
              }
            </div>
            <h1
            onClick={() => {
                DownloadCancellationReport(data?.rateChangeReport?.cancellationData);
            }}
            className="flex m-auto   justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            Download
          </h1>{" "}
          <div ref={dropdownRef} className="relative">
      {/* Main Button */}
      <div
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="flex m-auto w-[180px] gap-2 justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-xs text-white  font-medium text-[17px]"
      >
        <AiOutlineWhatsApp size={18} /> Send via WhatsApp
      </div>

     
        {isDropdownOpen && (
        <div className="absolute mt-2 w-[180px] text-white text-xs px-4 rounded-lg  shadow-lg dark:bg-[#1D3893] bg-[#3ec329]">
          <div
            onClick={() => {
              setIsDropdownOpen(false);
              setIsWhatsApp(); // Handle Send to Self
            }}
            className="flex gap-2 justify-start items-center p-2 cursor-pointer"
          >
            <AiOutlineWhatsApp size={18} /> Send to self
          </div>
          <div
            onClick={() => {
              setIsDropdownOpen(false);
              sendToWhatsappAdmin();
              // Handle Send to Personal Number
            }}
            className="flex gap-2 justify-start items-center p-2  cursor-pointer"
          >
            <AiOutlineWhatsApp size={18} /> Send to secondary 
          </div>
        </div>
      )}
    </div>
          </div>
      </div>{" "}
      {!isLoading ? (
        <div className="flex flex-col gap-2 mt-2">
          <RateChangeReportTable
            handleExpand={handleExpand}
            data={
              data?.rateChangeReport?.cancellationData
                ?.dateCancellationDataFinal
            }
            label={"Date Cancellation"}
            isExpanded={expandedIndex === 0}
            onExpand={() => handleExpand(0)}
          />
          <RateChangeReportTable
            data={
             data?.rateChangeReport?.cancellationData
                ?.sourceCancellationDataFinal
            }
            label={"Source Cancellation"}
            isExpanded={expandedIndex === 1}
            onExpand={() => handleExpand(1)}
            source={true}
          />
          <RateChangeReportTable
            data={
              data?.rateChangeReport?.cancellationData
                ?.roomCancellationDataFinal
            }
            label={"Room Cancellation"}
            isExpanded={expandedIndex === 2}
            onExpand={() => handleExpand(2)}
            room={true}
          />
        </div>
      ) : <div className="w-[100%] mt-[100px]"><CustomSkeleton height={400} /></div>}
    </>
  );
};

export default CancellationReport;
