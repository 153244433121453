import React, { useEffect, useRef, useState } from "react";
import {
  useGetDashboardChainGroupOverviewQuery,
  useGetDashboardChainGroupReputationQuery,
  useGetDashboardChainGroupVisibilityQuery,
  useGetDashboardChainQuery,
  useGetExportOTBDataForChainQuery,
  useGetOTBDataForChainQuery,
} from "../../redux/slices/dashboard";
import { CurrentProperty, UserId } from "../../utils/impVars";
import formatCurrency from "../../utils/formatCurrency";
import { Tooltip } from "react-tooltip";
import { RateParity } from "../newRatePulseDashboard";
import { Cards, SelectableChart } from "../IntelligenceNewDashboard";
import NewTable from "../newTable/NewTable";
import { calculatePercentage } from "../../utils/calculatePercentage";
import Skeleton from "react-loading-skeleton";
import TimeRange from "../../components/timeRange";
import OtaOptions from "../../utils/otaOptions";
import { format } from "date-fns";
import BackdropLoader from "../../components/newLoader";
import { useIsIpad } from "../../utils/isIpad";
import InfoToolTip from "../../components/tooltip/InfoToolTip";

const formatData = (data) => {
  const formattedData =
    data &&
    data.map((item) => ({
      "Property Name": item.propertyName,
      "Room Nights Current": item.roomNights.current || "",
      "Room Nights Previous": item.roomNights.previous || "",
      "Revenue Current": formatCurrency(item.revenue.current) || "",
      "Revenue Previous": formatCurrency(item.revenue.previous) || "",
      "ADR Current": formatCurrency(item.adr.current) || "",
      "ADR Previous": formatCurrency(item.adr.previous) || "",
    }));
  return formattedData;
};

const MultichainDashboard = () => {
  const [selectedItem, setSelectedItem] = useState([]);
  const [chartItem, setChartItem] = useState("Revenue");
  const [inputValue, setInputValue] = useState("Last 28 days");
  const [currentPage, setCurrentPage] = useState(1);

  const [FirstPeriod, setFirstPeriod] = useState([
    {
      startDate: new Date(new Date().getTime() - 28 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
      key: "range1",
    },
  ]);
  const [SecondPeriod, setSecondPeriod] = useState([
    {
      startDate: new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
      key: "range2",
    },
  ]);
  const [startEndPeriod1, setStartEndPeriod1] = useState([
    {
      startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
      key: "range1",
    },
  ]);
  const [startEndPeriod2, setStartEndPeriod2] = useState([
    {
      startDate: new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
      key: "range2",
    },
  ]);
  const [isSelectAll, setIsSelectAll] = useState(true);
  const [selectedTodaysRate, setSelectedTodaysRate] = useState([]);
  const [hoverIndex, setHoverIndex] = useState();
  const userId = UserId();
  const {
    data: GetOTBDataForChainQuery,
    isLoading: OTBIsLoading,
    isFetching: OTBIsFetching,
  } = useGetOTBDataForChainQuery({
    userId: userId,
    pageNumber: currentPage,
    startDate: format(new Date(FirstPeriod[0].startDate), "yyyy-MM-dd"),
    endDate: format(new Date(FirstPeriod[0].endDate), "yyyy-MM-dd"),
  });
  const {
    data: getExportOTBDataForChain,
    isLoading: exportOTBIsLoading,
    isFetching: exportOTBIsFetching,
  } = useGetExportOTBDataForChainQuery({
    userId: userId,
  });
  const { data: multiChainProp } = useGetDashboardChainQuery({
    userId: userId,
    hId: CurrentProperty(),
  });
  const {
    data: groupVisibility,
    isLoading,
    isFetching,
  } = useGetDashboardChainGroupVisibilityQuery(
    {
      userId: userId,
      hId: selectedTodaysRate && selectedTodaysRate?.join(","),
    },
    {
      refetchOnMountOrArgChange: true,
      skip: selectedTodaysRate?.length > 0 ? false : true,
    }
  );
  const { data: groupReputation } = useGetDashboardChainGroupReputationQuery(
    {
      userId: userId,
      hId: selectedTodaysRate && selectedTodaysRate?.join(","),
    },
    {
      refetchOnMountOrArgChange: true,
      skip: selectedTodaysRate?.length > 0 ? false : true,
    }
  );
  const {
    data: chainPropertyOverview,
    isFetching: overViewFetching,
    isLoading: overViewLoading,
  } = useGetDashboardChainGroupOverviewQuery(
    {
      hId: selectedTodaysRate && selectedTodaysRate?.join(","),
      userId: userId,
      chartData: chartItem?.toLowerCase(),
      startDate: format(new Date(FirstPeriod[0].startDate), "yyyy-MM-dd"),
      endDate: format(new Date(FirstPeriod[0].endDate), "yyyy-MM-dd"),
    },
    {
      skip: selectedTodaysRate?.length > 0 ? false : true,
      refetchOnMountOrArgChange: true,
    }
  );
  const [groupVisibilityData, setGroupVisibilityData] = useState();
  const [groupReputationData, setGroupReputationData] = useState();
  useEffect(() => {
    if (multiChainProp?.data?.rateMonitorOverview) {
      const hIds = multiChainProp?.data?.rateMonitorOverview
        ?.slice()
        ?.sort((a, b) => b.todayRate - a.todayRate)
        .map((item, index) => {
          return item?.hId;
        });
      setSelectedTodaysRate(isSelectAll ? hIds : [hIds[0]]);
    }
  }, [multiChainProp, isSelectAll]);

  useEffect(() => {
    if (groupVisibility) {
      setGroupVisibilityData(
        groupVisibility?.data?.visibility
          ?.filter((item) => item !== null && item !== undefined) // Filter out null or undefined items
          ?.slice() // Create a shallow copy of the array
          ?.sort((a, b) => {
            const rankA = a?.ranking;
            const rankB = b?.ranking;

            if (rankA === null || rankA === undefined) return 1; // Place 'a' after 'b'
            if (rankB === null || rankB === undefined) return -1; // Place 'b' after 'a'

            return rankA - rankB;
          })
      );
    }
  }, [groupVisibility]);
  useEffect(() => {
    if (groupReputation) {
      setGroupReputationData(
        groupReputation?.data?.groupReputation
          ?.filter((item) => item !== null && item !== undefined)
          ?.slice()
          ?.sort((a, b) => b.rating - a.rating)
      );
    }
  }, [groupVisibility]);

  const options = OtaOptions();

  const data = chainPropertyOverview && [
    {
      title: "Revenue",
      value: formatCurrency(
        chainPropertyOverview?.data?.thisYearData?.hotelMetrics1?.revenue
      ),
      percentage: calculatePercentage(
        chainPropertyOverview?.data?.previousYearData?.hotelMetrics1?.revenue,
        chainPropertyOverview?.data?.thisYearData?.hotelMetrics1?.revenue
      ),
    },
    {
      title: "ADR",
      value: formatCurrency(
        chainPropertyOverview?.data?.thisYearData?.hotelMetrics1?.ADR
      ),
      percentage: calculatePercentage(
        chainPropertyOverview?.data?.previousYearData?.hotelMetrics1?.ADR,
        chainPropertyOverview?.data?.thisYearData?.hotelMetrics1?.ADR
      ),
    },
    {
      title: "Room Nights",
      value:
        chainPropertyOverview?.data?.thisYearData?.hotelMetrics1?.roomNights,
      percentage: calculatePercentage(
        chainPropertyOverview?.data?.previousYearData?.hotelMetrics1
          ?.roomNights,
        chainPropertyOverview?.data?.thisYearData?.hotelMetrics1?.roomNights
      ),
    },
  ];

  const [periodOneCalender, setPeriodOneCalender] = useState(false);
  const [periodTwoCalender, setPeriodTwoCalender] = useState(false);
  const [saveSelectedItem, setSaveSelectedItem] = useState([]);
  const [period1ShowDropDown, setPeriod1ShowDropDown] = useState(false);
  const selectedItemDropDown =
    saveSelectedItem &&
    saveSelectedItem?.map((item) => {
      return item?.value;
    });
  const ref = useRef();
  const ref2 = useRef();

  const handleSave = () => {
    setFirstPeriod(startEndPeriod1);
    setSecondPeriod(startEndPeriod2);
    setSaveSelectedItem(selectedItem);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref?.current && !ref?.current.contains(event.target)) {
        setPeriodOneCalender(false);
        setPeriod1ShowDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref2?.current && !ref2?.current.contains(event.target)) {
        setPeriodTwoCalender(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref2]);

  const chartDataLabelOfMainChartThisYear =
    chainPropertyOverview &&
    chainPropertyOverview?.data?.thisYearData?.chartData[0]?.date.map((item) => {
      return format(new Date(item), "dd-MMM-yy");
    });
  const chartDataValueOfMainChartThisYear =
    chainPropertyOverview &&
    chainPropertyOverview?.data?.thisYearData?.chartData[0]?.value;

  const chartDataLabelOfMainChartLastYear =
    chainPropertyOverview &&
    chainPropertyOverview?.data?.previousYearData?.chartData[0]?.date?.map((item) => {
      return format(new Date(item), "dd-MMM-yy");
    });
  

  const chartDataValueOfMainChartLastYear =
    chainPropertyOverview &&
    chainPropertyOverview?.data?.previousYearData?.chartData[0]?.value;

  const isIpad = useIsIpad();
console.log(chainPropertyOverview?.data?.thisYearData?.chartData)
  return (
    <div className="m-4">
      <BackdropLoader
        loading={
          isFetching ||
          isLoading ||
          overViewFetching ||
          overViewLoading ||
          OTBIsLoading ||
          OTBIsFetching ||
          exportOTBIsFetching ||
          exportOTBIsLoading
        }
      />
      <div className="mb-2 text-white dark:text-black flex items-center gap-2 text-[20px] font-[500]">
        {/* <BackButton mainDashboard={true} /> */}
        Chain Property Dashboard
      </div>

      {/* Starting of Today's Rate */}

      <div>
        <div className="w-[100%] flex justify-between items-center">
          {" "}
          <div className="text-white dark:text-black">Today's Rate</div>
          <div
            className={`cursor-pointer ${isSelectAll ? "text-white bg-gray-500" : "text-white bg-blue-500"}  px-2 text-sm rounded-lg py-1`}
            onClick={() => setIsSelectAll(!isSelectAll)}
          >
            {isSelectAll ? "Reset" : "Select All"}
          </div>
        </div>
        <div className="my-2 min-w-[100%] max-w-[100%] overflow-x-scroll scrollHide flex gap-4">
          {multiChainProp?.data ? (
            multiChainProp?.data?.rateMonitorOverview
              ?.slice()
              ?.sort((a, b) => b.todayRate - a.todayRate)
              ?.map((item, index) => {
                return (
                  <label
                    key={item?.hId}
                    htmlFor={item?.hId}
                    className="cursor-pointer h-auto flex flex-col gap-1 p-2 min-w-[20%] bg-newGradient dark:bg-lightGradient  rounded-lg"
                  >
                    <div className="flex justify-between items-center">
                      <div
                        className={`text-white text-[14px] ${isIpad ? "min-w-[140px] max-w-[140px]" : "min-w-[200px] max-w-[200px]"}  truncate`}
                        data-tooltip-content={item?.hotelName}
                        data-tooltip-id={item?.hotelName}
                      >
                        {item?.hotelName}
                        <Tooltip place="bottom" id={item?.hotelName} />
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id={item?.hId}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedTodaysRate((prevState) => [
                                ...prevState,
                                item?.hId,
                              ]);
                            } else {
                              setSelectedTodaysRate((prevState) =>
                                prevState.filter((items) => items !== item?.hId)
                              );
                            }
                          }}
                          className="cursor-pointer"
                          checked={selectedTodaysRate.includes(item?.hId)}
                        />
                      </div>
                    </div>
                    <div className="text-white text-[22px] flex justify-between items-center">
                      <div>{formatCurrency(item?.todayRate)}</div>
                      <div
                        className={`${
                          item?.todayRate > item?.yesterdayRate ||
                          calculatePercentage(
                            item?.yesterdayRate,
                            item?.todayRate
                          ).toFixed(2) === "0.00"
                            ? "text-green-500 bg-green-50"
                            : "text-red-500 bg-red-50"
                        } px-2 rounded-md text-xs py-1`}
                      >
                        {calculatePercentage(
                          item?.yesterdayRate,
                          item?.todayRate
                        ).toFixed(2)}
                        %
                      </div>
                    </div>
                    <div className="text-white text-[12px]">
                      Yesterday’s Rate
                    </div>
                    <div className="text-white text-[12px]">
                      {formatCurrency(item?.yesterdayRate)}
                    </div>
                  </label>
                );
              })
          ) : (
            <div className="w-[100%]">
              {" "}
              <Skeleton height={"120px"} style={{ width: "100%" }} />{" "}
            </div>
          )}
        </div>
      </div>

      {/* Ending of Today's Rate */}

      <section>
        {/* Selectable Area Chart */}
        <div className=" rounded-lg p-4 bg-newGradient dark:bg-lightGradient my-4 flex justify-between items-center w-[100%]">
          <div className="w-[20%]"></div>
          <div className="flex bg gap-6 w-fit">
            <div className="flex justify-end items-center gap-2 w-[100%]">
              {/* <div className="h-5 w-5 bg-[#59647B] rounded-full"></div> */}
              <div className="text-sm text-white">Select Date Range</div>
              <div className="flex justify-end" ref={ref}>
                <TimeRange
                  period={startEndPeriod1}
                  setPeriod={setStartEndPeriod1}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  left={"-300px"}
                  setCalendarOpen={setPeriodOneCalender}
                  calendarOpen={periodOneCalender}
                  isFuture={false}
                  showDropDown={period1ShowDropDown}
                  setShowDropDown={setPeriod1ShowDropDown}
                />
              </div>
            </div>
            <div
              onClick={handleSave}
              className="bg-blue-50 outline outline-blue-500 rounded-lg px-4 flex justify-between items-center mt-1.5 h-[30px] text-blue-500 hover:bg-blue-500 hover:text-white duration-150 ease-in-out transition-all cursor-pointer"
            >
              Update
            </div>
          </div>
        </div>

        <div>
          {chainPropertyOverview ? (
            <SelectableChart
              chartHeight={450}
              data={data}
              chartItem={chartItem}
              setChartItem={setChartItem}
              thisYearLabel={chartDataLabelOfMainChartThisYear}
              thisYearData={chartDataValueOfMainChartThisYear}
              lastYearLabel={chartDataLabelOfMainChartLastYear}
              lastYearValue={chartDataValueOfMainChartLastYear}
              lastYearData={
                chainPropertyOverview?.data?.previousYearData?.hotelMetrics1
              }
              period1StartDate={
                chainPropertyOverview &&
                format(
                  new Date(
                    chainPropertyOverview?.data?.previousYearData?.startDate
                  ),
                  "dd MMM yy"
                )
              }
              period1EndDate={
                chainPropertyOverview &&
                format(
                  new Date(
                    chainPropertyOverview?.data?.previousYearData?.endDate
                  ),
                  "dd MMM yy"
                )
              }
              periodStartDate={format(
                new Date(FirstPeriod[0].startDate),
                "dd MMM yy"
              )}
              periodEndDate={format(
                new Date(FirstPeriod[0].endDate),
                "dd MMM yy"
              )}
              islastYear={true}
              width1={25}
              width2={75}
              multiSeries={true}
              multiSeriesData={
                chainPropertyOverview &&
                chainPropertyOverview?.data?.thisYearData?.chartData
              }
            />
          ) : (
            <div className="w-[100%] my-2">
              {" "}
              <Skeleton height={"420px"} style={{ width: "100%" }} />{" "}
            </div>
          )}
        </div>
      </section>

      {/* Starting of RatePulse Section */}

      <section className="my-4">
        <div className="flex gap-4">
          <div className="w-[25%]">
            {groupVisibilityData || !isFetching || !isLoading ? (
              <RateParity
                title={"Grouped Visibility"}
                // selectedDropdown={selectedVisibilityDropdown?.label}
                // setSelectedDropdown={setSelectedVisibilityDropdown}
                array={groupVisibilityData}
                isRank={true}
                showDropDown={false}
                // asPer={visibilityData?.data?.asPer}
                tooltip={
                  "This feature provides insights into both rate parity and visibility across different distribution channels and booking platforms."
                }
                multiChain={true}
              />
            ) : (
              <div className="w-[100%]">
                {" "}
                <Skeleton height={"320px"} style={{ width: "100%" }} />{" "}
              </div>
            )}
          </div>
          <div className="w-[25%]">
            {groupReputationData || !isLoading ? (
              <GroupedReputation
                isRank={false}
                title={"Rate-Parity Overview"}
                showDropDown={false}
                tooltip={
                  "Grouped Reputation: The collective online reputation of a group of properties or a brand, based on reviews, ratings, and feedback."
                }
                data={groupReputationData}
              />
            ) : (
              <div className="w-[100%]">
                {" "}
                <Skeleton height={"320px"} style={{ width: "100%" }} />{" "}
              </div>
            )}
          </div>

          <div className="w-[50%] gap-4 grid grid-cols-2 h-fit">
            <div className="w-[100%]">
              <Cards
                title={"RevPAR"}
                bottomData={formatCurrency(
                  chainPropertyOverview?.data?.previousYearData?.hotelMetrics1
                    ?.RevPAR
                )}
                mainData={formatCurrency(
                  chainPropertyOverview?.data?.thisYearData?.hotelMetrics1
                    ?.RevPAR
                )}
                percentageChange={calculatePercentage(
                  chainPropertyOverview?.data?.previousYearData?.hotelMetrics1
                    ?.RevPAR,
                  chainPropertyOverview?.data?.thisYearData?.hotelMetrics1
                    ?.RevPAR
                ).toFixed(2)}
                width={"100%"}
                tooltip={
                  "The total revenue generated per available room, calculated "
                }
              />
            </div>
            <div className="w-[100%]">
              <Cards
                title={"ALOS"}
                width={"100%"}
                bottomData={
                  chainPropertyOverview?.data?.previousYearData?.hotelMetrics1
                    ?.los
                }
                mainData={
                  chainPropertyOverview?.data?.thisYearData?.hotelMetrics1?.los
                }
                percentageChange={calculatePercentage(
                  chainPropertyOverview?.data?.previousYearData?.hotelMetrics1
                    ?.los,
                  chainPropertyOverview?.data?.thisYearData?.hotelMetrics1?.los
                ).toFixed(2)}
                tooltip={
                  "ALOS: Average Length of Stay, indicating the average duration of guest stays within a specific period."
                }
              />
            </div>
            <div className="w-[100%]">
              <Cards
                title={"Booking Pace"}
                width={"100%"}
                bottomData={chainPropertyOverview?.data?.previousYearData?.hotelMetrics2?.bookingPace.toFixed(
                  2
                )}
                mainData={chainPropertyOverview?.data?.thisYearData?.hotelMetrics2?.bookingPace.toFixed(
                  2
                )}
                percentageChange={calculatePercentage(
                  chainPropertyOverview?.data?.previousYearData?.hotelMetrics2
                    ?.bookingPace,
                  chainPropertyOverview?.data?.thisYearData?.hotelMetrics2
                    ?.bookingPace
                ).toFixed(2)}
                tooltip={
                  "The rate at which reservations are made over a specific period, used to track and forecast hotel occupancy."
                }
              />
            </div>
            <div className="w-[100%]">
              <Cards
                title={"BOB"}
                width={"100%"}
                bottomData={formatCurrency(
                  chainPropertyOverview?.data?.previousYearData?.hotelMetrics2?.BOB.toFixed(
                    2
                  )
                )}
                mainData={formatCurrency(
                  chainPropertyOverview?.data?.thisYearData?.hotelMetrics2?.BOB.toFixed(
                    2
                  )
                )}
                percentageChange={calculatePercentage(
                  chainPropertyOverview?.data?.previousYearData?.hotelMetrics2
                    ?.BOB,
                  chainPropertyOverview?.data?.thisYearData?.hotelMetrics2?.BOB
                ).toFixed(2)}
                tooltip={
                  "BOB: Business on the Books, representing current confirmed reservations for a future date or period."
                }
              />
            </div>
          </div>
        </div>
      </section>

      {/* Ending of RatePulse Section */}

      {/* Start of Table */}

      <div className="mb-4">
        {/* <IntelligenceTable data={sourceData} /> */}
        <NewTable
          data={GetOTBDataForChainQuery?.data?.result}
          filename={"Property Wise Comparison Breakdown"}
          defaultSortColumn={"source"}
          startDate={
            (chainPropertyOverview?.data?.previousYearData?.startDate &&
              new Date(
                chainPropertyOverview?.data?.previousYearData?.startDate
              )) ||
            format(new Date(FirstPeriod[0].startDate), "yyyy-MM-dd")
          }
          endDate={
            (chainPropertyOverview?.data?.thisYearData?.endDate &&
              new Date(
                chainPropertyOverview?.data?.thisYearData?.endDate
              )) ||
            format(new Date(FirstPeriod[0].endDate), "yyyy-MM-dd")
          }
          title={"Property Wise Comparison Breakdown"}
          isProperty={true}
          downloadData={
            getExportOTBDataForChain?.data &&
            formatData(getExportOTBDataForChain?.data)
          }
          totalpages={GetOTBDataForChainQuery?.data?.totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalData={GetOTBDataForChainQuery?.data?.total}
          chainPropertyDate={true}
        />
      </div>

      {/* End of Table */}

      {/* <div className="mb-6 flex items-center gap-2 mt-2">Pickup & OTB</div>
      <div className="">
        <div className="flex w-[100%] bg-gray-300 text-gray-800 p-2 text-sm rounded-t-lg">
          <div className="w-[20%] font-semibold">Hotel Name</div>
          <div className="w-[40%] flex items-center justify-center font-semibold">
            OTB
          </div>
          <div className="w-[40%] flex items-center justify-center font-semibold">
            OTB STLY
          </div>
        </div>
        <div className="flex w-[100%] gap-4 bg-gray-200 text-gray-800 text-sm">
          <div className="w-[20%]  p-2"></div>
          <div className="w-[40%] flex items-center justify-between">
            <div className="w-[20%] text-center p-2 text-gray-700">Occ</div>
            <div className="w-[20%] text-center p-2 text-gray-700">RNs</div>
            <div className="w-[20%] text-center p-2 text-gray-700">ADR</div>
            <div className="w-[20%] text-center p-2 text-gray-700">Rev</div>
          </div>
          <div className="w-[40%] flex items-center justify-between">
            <div className="w-[20%] text-center p-2 text-gray-700">Occ</div>
            <div className="w-[20%] text-center p-2 text-gray-700">RNs</div>
            <div className="w-[20%] text-center p-2 text-gray-700">ADR</div>
            <div className="w-[20%] text-center p-2 text-gray-700">Rev</div>
          </div>
        </div>
        {GetOTBDataForChainQuery?.data?.map((item, index) => {
          return (
            <div
              onMouseOver={() => setHoverIndex(index)}
              onMouseOut={() => setHoverIndex(null)}
              className={`flex w-[100%] gap-4 border-b ${index % 2 === 0 ? "bg-gray-50" : "bg-gray-50/40"} ${index === hoverIndex && "bg-white"}  transition-all ease-in-out duration-150 text-gray-800 text-sm ${GetOTBDataForChainQuery?.data?.length === index + 1 ? "rounded-b-lg" : ""}`}
            >
              <div
                className="max-w-[20%] min-w-[20%] truncate p-2 py-3"
                data-tooltip-content={item?.propertyName}
                data-tooltip-id={item?.propertyName}
              >
                {item?.propertyName}
                <Tooltip id={item?.propertyName} place="bottom" />
              </div>
              <div className="w-[40%] flex items-center justify-between -ml-2">
                <div className="w-[20%] text-center p-2 text-gray-700">
                  {item?.OTB?.occupancy}
                </div>
                <div className="w-[20%] text-center p-2 text-gray-700">
                  {item?.OTB?.roomNights}
                </div>
                <div className="w-[20%] text-center p-2 text-gray-700">
                  {formatCurrency(item?.OTB?.adr)}
                </div>
                <div className="w-[20%] text-center p-2 text-gray-700">
                  {formatCurrency(item?.OTB?.rev)}
                </div>
              </div>
              <div className="w-[40%] flex items-center justify-between">
                <div className="w-[20%] text-center p-2 text-gray-700">
                  {item?.OTBSTLY?.occupancy}
                </div>
                <div className="w-[20%] text-center p-2 text-gray-700">
                  {item?.OTBSTLY?.roomNights}
                </div>
                <div className="w-[20%] text-center p-2 text-gray-700">
                  {formatCurrency(item?.OTBSTLY?.adr)}
                </div>
                <div className="w-[20%] text-center p-2 text-gray-700">
                  {formatCurrency(item?.OTBSTLY?.rev)}
                </div>
              </div>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default MultichainDashboard;

const GroupedReputation = ({ data, tooltip }) => {
  return (
    <div className="p-4 bg-newGradient dark:bg-lightGradient rounded-lg">
      <div className="flex items-center justify-between">
        <div className="text-white">Grouped Reputation</div>
        <div>
          <InfoToolTip content={tooltip} />
        </div>
      </div>
      <div className="mt-[30px] flex flex-col gap-4 min-h-[245px] max-h-[245px] scrollHide overflow-y-scroll">
        {Array.isArray(data) &&
          data?.map((item) => {
            if (item !== null) {
              return (
                <div className="flex justify-between items-start">
                  <div
                    className="text-sm max-w-[70%] text-white truncate"
                    data-tooltip-id={item?.hotelName}
                    data-tooltip-content={item?.hotelName}
                  >
                    {item?.hotelName}
                    <Tooltip id={item?.hotelName} place="bottom" />
                  </div>
                  <div className="text-sm text-white">
                    {item?.rating}/{item?.totalRating}
                  </div>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};
