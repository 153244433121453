import React, { useEffect, useState } from "react";
import "./Global.css";
import Img from "../../assets/img/Branding.svg";
import SimpleButton from "../../components/button/SimpleButton";
import MultiInputPasscode from "../../components/passcode/OtpVerify";
import { useLocation, useNavigate } from "react-router";
import {
    useUserSignupMutation,
    useVerifyOtpMutation,
} from "../../redux/slices/onBoarding";
import { toast } from "react-toastify";
import { FormProvider, useForm } from 'react-hook-form';
import Backdrop from '../../components/backdrop'
import { SyncLoader } from 'react-spinners'
import { useId } from 'react';
import { UserId } from "../../utils/impVars";
const VerifyNumber = () => {
    const { state } = useLocation();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(null);
    const [token,setToken] = useState(null)

    useEffect(() => {
        // Check if location state is present and has email
        if (state) {
            setEmail(state.email);
            setToken(state?.token)
        }
    }, [state]);


    const [completedPasscode, setCompletedPasscode] = useState("");
    const methods = useForm({});
    const [verifyOtp] = useVerifyOtpMutation()
    const [userSignup] = useUserSignupMutation();
    const userId = UserId()

    const onSubmit = async (data) => {
        const userIdString = window.localStorage.getItem('userId');
        setLoading(true);
        const verifyOtpData = {
               userId : userId,
                OTP: completedPasscode
        };
        verifyOtp(verifyOtpData)
            .unwrap()
            .then((res) => {
                toast.success(res.message, { position: "bottom-right" });
                navigate('/property_details')
                setLoading(false);
            })
            .catch((err) => {
                toast.error("Please enter otp", { position: "bottom-right" });
                setLoading(false);
            });
    }
    const resendOtp = async () => {

        if (email) {
            let signUpData = {
                email: email,
                // other data if needed
            };
            userSignup(signUpData)
                .unwrap()
                .then((res) => {
                    toast.success(res.message, { position: "bottom-right" });
                })
                .catch((err) => {
                    toast.error(err.message, { position: "bottom-right" });
                    setLoading(false);
                });
        } else {
            console.error('Email is undefined');
        }
    };



    const [passError, setPassError] = useState(false);
    const navigate = useNavigate();
    const onComplete = (passcode) => {
        setCompletedPasscode(passcode)
    }

    // const handleNext = () => {
    //     if (completedPasscode.length < 6) {
    //         setPassError(true);
    //     } else {
    //         console.log("Next to property select")
    //         navigate('/login')
    //     }
    // }

    useEffect(() => {
        if (completedPasscode.length === 6) {
            setPassError(false);
        }
    }, [completedPasscode]);

    return (
        <>   {
            loading && <Backdrop>
                <SyncLoader color="white" />
            </Backdrop>

        }
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className='verify-email-container'>
                        <div className='verify-email-main'>
                            <div className='flex w-full justify-center'>
                                <img className='img-logo' src={Img} alt='' />
                            </div>
                            <div className='verify-email-content'>
                                <div className='flex items-center justify-center flex-col gap-2'>
                                    <h1 className='text-[1.3rem] text-[#0D1321] text-center font-sans font-semibold '>Verify your email</h1>
                                    <p className='text-[0.8rem] text-center text-[#485266]'>Enter the verification code sent on your email address</p>
                                    <p className='text-[0.8rem] text-center text-[#485266]'>{email}</p>
                                </div>
                                <div className='flex flex-col w-full gap-4'>
                                    <div className='w-full flex flex-row justify-center'>
                                        <div class="flex items-center justify-center">
                                            <MultiInputPasscode length={6}
                                                onComplete={onComplete}
                                                id={"otp"}
                                                name={"otp"}

                                                className={passError && "border-none outline outline-red-500"} />
                                        </div>
                                    </div>
                                    <SimpleButton text='Next' type='submit' />
                                    <div className='flex flex-col w-full gap-4 items-center'>
                                        <div className='text-sm font-[600] text-[#3F70ED] cursor-pointer' onClick={resendOtp}>Resend Code</div>
                                        <div>
                                            {/* <p className='text-sm font-[400] text-[#0D1321]'>Entered Wrong Number?<span className='text-sm font-[400] text-[#3F70ED] pl-2 cursor-pointer'>Edit Mobile</span></p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>

        </>

    );
};

export default VerifyNumber;
