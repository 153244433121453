import React, { useEffect, useRef, useState } from "react";
import ParityReportTable from "../../newTable/ParityReportTable";
import formatCurrency from "../../../utils/formatCurrency";
import Calendar from "../../../components/calendar";
import { DownloadParityReport } from "../DownloadExcel/ParityReport";
import { AiFillCalendar, AiOutlineWhatsApp } from "react-icons/ai";
import CustomSkeleton from "../../../components/Skeleton";
import { formatDate } from "../DownloadExcel/formatDate";
import { format } from "date-fns";
import { DateRangePicker } from "react-date-range";
import NoDataFound from "../../../components/noData";
import useIntelligenceDateRange from "../../../utils/dateRange";
import whatsappBusiness from '../../../assets/img/whatsapp-business-icon.svg'
import useRatePulseDateRange from "../../../utils/ratepulseDateRange";

const ParityReport = ({
  data,
  dateRange,
  setDateRange,
  setIsWhatsApp,
  isLoading,
  sendToWhatsappAdmin,
  parityWhatsAppAdmin,
  asOn
}) => {
  const [expandedIndex, setExpandedIndex] = useState(0);
  const dateLimit = useRatePulseDateRange();

const [isDropdownOpen,setIsDropdownOpen]=useState(false)
  const [showDateRange, setShowDateRange] = useState(false);
  const ref1 = useRef();
  const dropdownRef = useRef(null);

useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref1?.current && !ref1?.current.contains(event.target)) {
        setShowDateRange(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref1]);
  const handleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  if (!data && !isLoading) {
    // Render a fallback UI or an error message when data is undefined
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center justify-center p-2 font-semibold">
          <p>Parity Report - </p>
        </div>
        <div className="m-3 w-full flex justify-end items-end">
          <div className="w-fit"></div>
          <h1
            onClick={() => {}}
            className="flex  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            Download
          </h1>
          <div
            onClick={setIsWhatsApp}
            className="flex  w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            <AiOutlineWhatsApp size={18} /> Send to WhatsApp
          </div>
        </div>
        <div className="h-full flex items-center justify-center w-[100%]">
          {/* <CustomSkeleton height={500} /> */}

          <NoDataFound />
        </div>
      </div>
    );
  }
  if (isLoading) {
    // Render a fallback UI or an error message when data is undefined
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center justify-center p-2 font-semibold">
          <p>Parity Report - </p>
        </div>
        <div className="m-3 w-full flex justify-end items-end">
          <div className="w-fit"></div>
          <h1
            onClick={() => {}}
            className="flex  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            Download
          </h1>
          <div
            onClick={setIsWhatsApp}
            className="flex  w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            <AiOutlineWhatsApp size={18} /> Send to WhatsApp
          </div>
        </div>
        <div className="w-[100%]">
          <CustomSkeleton isTransparent={true} animated={true} height={500} title={'Parity Report'} />
          {/* No data found */}
        </div>
      </div>
    );
  }

  function ColoredRate({ parity, rateData, otaId }) {
    const rate = rateData.find((rate) => rate.otaId === otaId)?.rate;
    if (parity && rate) {
      return <span style={{ color: "green" }}>{formatCurrency(rate)}</span>;
    } else if (!parity && rate) {
      return <span style={{ color: "red" }}>{formatCurrency(rate)}</span>;
    } else {
      return <span style={{ color: "blue" }}>Sold out</span>;
    }
  }

  function transformData(data) {
    const ParityData = [];

    data.forEach((item) => {
   
      const { date, parity, rateData, averageRate, hotelName } = item;
      const day = new Date(date).toLocaleDateString("en-US", {
        weekday: "short",
      });
      const formattedDate = new Date(date).toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });

      const hotelRates = {
        Hotel: hotelName,
        Day: day,
        Date: formattedDate,
        AverageRate: averageRate,

        Goibibo: <ColoredRate parity={parity} rateData={rateData} otaId={2} />,
        Agoda: <ColoredRate parity={parity} rateData={rateData} otaId={4} />,
        MakeMyTrip: (
          <ColoredRate parity={parity} rateData={rateData} otaId={1} />
        ),
        EaseMyTrip: (
          <ColoredRate parity={parity} rateData={rateData} otaId={6} />
        ),
        HappyEasyGo:(
          <ColoredRate parity={parity} rateData={rateData} otaId={7} />
        ),
      };

      ParityData.push(hotelRates);
    });

    return ParityData;
  }

  if (!data) {
    return null; // or return a fallback UI
  }



  const transformedData = transformData(data);
  const fromDate = format(new Date(dateLimit?.fromDate), "MM/dd/yyyy");
   const newEndDate = new Date(dateLimit?.toDate);
  newEndDate.setDate(newEndDate.getDate() + 30);
  const propertyName = window.localStorage.getItem("propertyName");
  return (
    <>
    
      <div className={` flex top-0 sticky items-center justify-center p-2 font-semibold`}>
        <p className=" text-white dark:text-black">
          Parity Report - {propertyName}{" "}
          {`(  ${format(new Date(dateRange[0].startDate), "dd MMM yyy") || formatDate(asOn?.startDate)} - ${format(new Date(dateRange[0].endDate), "dd MMM yyy") || formatDate(asOn?.endDate)})`}{" "}
        </p>
      </div>
      <div className={`w-full sticky top-[40px] flex justify-end  items-center `}>
        <div className="m-3 w-full flex  text-white  dark:text-black justify-end gap-2 items-center">
          <div className="w-fit text-sm">Select date range</div>
          {
            <div
              className="relative mb-2 w-fit justify-start text-xs cursor-pointer select-none flex gap-4 items-center mx-1 px-2 py-3  rounded-md border-solid border border-[#A0A4B0]"
              ref={ref1}
            >
              <div
                className="flex gap-2 items-center"
                onClick={() => setShowDateRange(!showDateRange)}
              >
                {" "}
                <AiFillCalendar size={14} />{" "}
                {format(new Date(dateRange[0].startDate), "dd MMM yyy")} -{" "}
                {format(new Date(dateRange[0].endDate), "dd MMM yyy")}
              </div>
              {showDateRange && (
                <div className="absolute z-50 top-[50px] -left-[200px]">
                  <DateRangePicker
                    ranges={dateRange}
                    onChange={(range) => {
                      setDateRange([
                        {
                          startDate: range?.range1?.startDate,
                          endDate: range?.range1?.endDate,
                          range: "range 1",
                        },
                      ]);
                    }}
                    minDate={new Date(fromDate)}
                    maxDate={new Date(newEndDate)}
                  />
                </div>
              )}
            </div>
          }
          <h1
            onClick={() => {
              DownloadParityReport(data,asOn);
            }}
            className="flex  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            Download
          </h1>
          <div ref={dropdownRef} className="relative">
      {/* Main Button */}
      <div
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="flex m-auto w-[180px] gap-2 justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-xs text-white  font-medium text-[17px]"
      >
        <AiOutlineWhatsApp size={18} /> Send via WhatsApp
      </div>

     
      {isDropdownOpen && (
        <div className="absolute mt-2 w-[180px] text-xs px-4 rounded-lg  shadow-lg dark:bg-[#1D3893] bg-[#3ec329]">
          <div
            onClick={() => {
              setIsDropdownOpen(false);
              setIsWhatsApp(); // Handle Send to Self
            }}
            className="flex gap-2 justify-start items-center p-2 cursor-pointer"
          >
            <AiOutlineWhatsApp size={18} /> Send to self
          </div>
          <div
            onClick={() => {
              setIsDropdownOpen(false);
              sendToWhatsappAdmin();
              // Handle Send to Personal Number
            }}
            className="flex gap-2 justify-start items-center p-2  cursor-pointer"
          >
            <AiOutlineWhatsApp size={18} /> Send to secondary 
          </div>
        </div>
      )}
    </div>


          {/* <div
            onClick={sendToWhatsappAdmin}
            className="flex  w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
          <img src={whatsappBusiness} height={20} width={20} alt="" /> Send to Group
          </div> */}
    
        </div>
      </div>


        <div className={`flex flex-col gap-2 overflow-y-auto ${transformedData?.length === 0 && 'h-[500px]'} h-[80%] `}>
          <ParityReportTable
            arrival={true}
            data={transformedData}
            isExpanded={expandedIndex === 0}
            onExpand={() => handleExpand(0)}
          />
        </div>
    </>
  );
};

export default ParityReport;
