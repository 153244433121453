import React, { useState } from "react";
import arrow from "../../assets/icons/Vector (4).svg";
import arrowup from "../../assets/icons/Vector up.svg";
import arrow1 from "../../assets/icons/Vector (3).svg";
import profile from "../../assets/icons/3.svg";
import { CurrentProperty } from "../../utils/impVars";
import { AiTwotoneThunderbolt } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useGetChannelManagerFaultsQuery } from "../../redux/slices/dashboard";
import { useSelector } from "react-redux";

const ProfileSideBar = ({ name, designation, data, isSidebarOpen,setDropdownVisible,setDropdownOpen,isDropdownOpen }) => {
  const navigate = useNavigate();
  const propId = CurrentProperty();
  const selectedProperty =
    data &&
    data?.filter((prop) => {
      return parseInt(prop?.propertyId) === parseInt(propId);
    });
    const currentProp = window.localStorage.getItem('adminProp')
  const moreProperty =
    data &&
    data?.filter((prop) => {
      return parseInt(prop?.propertyId) != parseInt(propId);
    });
  const toggleDropdown = () => {
    setDropdownVisible(null)
    setDropdownOpen(!isDropdownOpen);
  };
  const propertyName = selectedProperty?.[0]?.propertyName || data?.[0]?.propertyName || currentProp;
  if (propertyName) {
    window.localStorage.setItem("propertyName", propertyName);
  }

  const handleSwitchProperty = (items) => {
    window.localStorage.setItem("hId", items?.propertyId);
    window.localStorage.setItem("adminHid", items?.propertyId);
    window.location.reload();
  };

  return (
    <div className="relative">
      {moreProperty && moreProperty?.length > 0 && (
        <div
          onClick={() => navigate("/chainProperty/dashboard")}
          className={` my-4 cursor-pointer flex ${isSidebarOpen ? "bg-white justify-start gap-4 px-4 hover:bg-blue-500 border-blue-500 border text-blue-500 hover:border-none" : "justify-center border-none bg-white "}  items-center    shadow-md drop-shadow-md hover:text-white  transition-transform ease-in-out duration-150 py-4 rounded-lg`}
        >
         { <AiTwotoneThunderbolt size={!isSidebarOpen && 30}  />}
          {isSidebarOpen && (
            <div className="text-[0.7em]  text-center">
              Chain Property Overview
            </div>
          )}
        </div>
      )}
      {isSidebarOpen && (
        <div
          className="w-[100%] max-h-[150px] overflow-y-scroll scrollHide flex flex-col justify-between items-center cursor-pointer h-fit bg-[#F6F8FB] rounded-xl p-2 pt-0"
          onClick={toggleDropdown}
        >
          <div className="flex justify-between w-[100%] p-2 sticky top-0 items-center bg-[#F6F8FB]">
            <div className="w-14 overflow-hidden rounded-xl">
              <img
                src={profile}
                alt="Profile"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="flex ml-2 w-[80%] cursor-pointer justify-between items-center">
              <div>
                <div className="text-[14px] line-clamp-2 text-[#0D1321]">
                  {propertyName}
                </div>
                <div className=" font-[400] text-[#8794AD]">{designation}</div>
              </div>
            </div>
            <div className="cursor-pointer w-auto p-2 flex items-center">
              <img
                className="w-4 hoverEffect"
                src={isDropdownOpen ? arrowup : arrow}
                alt=""
              />
            </div>
          </div>
          {isDropdownOpen && (
            <div className=" top-full left-0 mt-2 w-[100%]">
              {/* Additional profile sections go here */}
              {moreProperty &&
                moreProperty?.length > 0 &&
                moreProperty.map((item) => {
                  if (item?.propertyName) {
                    return (
                      <div
                        className="w-[100%] flex justify-between items-center cursor-pointer h-[56px] bg-[#F6F8FB] rounded-xl"
                        onClick={() => handleSwitchProperty(item)}
                      >
                        <div className="w-14 overflow-hidden rounded-xl">
                          <img
                            src={item?.profile || profile}
                            alt="Profile"
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div className="flex ml-2 w-[100%] cursor-pointer justify-between items-center">
                          <div>
                            <div className="text-[14px] line-clamp-2 text-[#0D1321]">
                              {item?.propertyName}
                            </div>
                          </div>
                        </div>
                        <div className="cursor-pointer px-2">
                          <img src={arrow1} alt="" />
                        </div>
                      </div>
                    );
                  }
                })}
              {/* Add more sections as needed */}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProfileSideBar;
