import React, { useEffect, useState } from "react";
import Dropdown from "../../components/dropdown";
import OtaCards from "../../components/otaCards/index";
import RateLeaderboard from "../rateLeaderboard";
import "../../components/calendar/Calendar.css";
import ParityCalender from "../../components/parityCalender";
import StatsCard from "../../components/cards/statsCard";
import RateTendencies from "../../components/rateendencies/index";
import GlobalModalComponent from "../../components/modal/newModal";
import SelectableTab from "../../components/selectableTab";
import calendar from "../../../src/assets/icons/calendar.svg";
import DynamicCounter from "../../components/dynamicCounter/DynamicCounter";
import { format, getDay, startOfMonth, subMonths } from "date-fns";
import { toast } from "react-toastify";
import AreaChart from "../../components/charts/areaChart";
import ColumnChart from "../../components/charts/columnChart";
import {
  useGetParityCalanderQuery,
  useGetRateMonitorDetailsQuery,
  useGetRateMonitorRateTrendDetailsQuery,
  useGetRateMonitorTodayRateDetailsQuery,
  useGetRateTendenciesQuery,
} from "../../redux/slices/rateMonitor";
import CustomSkeleton from "../../components/Skeleton";
import {
  CurrentProperty,
  PulseExtractionDate,
  UserId,
} from "../../utils/impVars";
import formatCurrency from "../../utils/formatCurrency";
import { useGetFiltersQuery } from "../../redux/slices/dashboard";
import BackdropLoader from "../../components/newLoader";
import ExcelDownloadButton from "../../components/excelDownloadButton";
import Modal from "../../components/modal";
import NewTooltip from "../../components/tooltip/NewTooltip";
import { AiOutlineCloseCircle, AiOutlineTable } from "react-icons/ai";
import { BackButton } from "../rateSuggestion/rateAnalysis";
import Calendar from "../../components/calendar";
import { ActiveOtaOptions } from "../../utils/otaOptions";
import InfoToolTip from "../../components/tooltip/InfoToolTip";
import useRatePulseDateRange from "../../utils/ratepulseDateRange";

const RateMonitor = () => {
  const otaOptions = ActiveOtaOptions();
  const [selectedPartityOtaDropdown, setSelectedPartityOtaDropdown] =
    useState();
  const [rateTendenciesDate, setRateTendencieDate] = useState(new Date());
  const hId = CurrentProperty();
  const extractionDate = PulseExtractionDate();
  const [selectedMonth, setSelectedMonth] = useState(new Date(extractionDate));
  const [selectedDate, setSelectedDate] = useState(new Date(extractionDate));
  const [selectedDropdown, setSelectedDropdown] = useState();
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [rateMoniitorFilterModal, setRateMonitorFilterModal] =
    React.useState(false);
  const [filterModalTabIndex, setFilterModalTabIndex] = React.useState(0);
  const [selectedFilter, setSelectedFilter] = useState();
  const [ratesTrend, setRatesTend] = useState();
  const [ratesTrend1, setRatesTend1] = useState();
  const ratePulseDate = useRatePulseDateRange();
  const ratePulseDateToDate = ratePulseDate.toDate;
  const { data: filterData } = useGetFiltersQuery({
    hId: CurrentProperty(),
    userId: UserId(),
  });
  const {
    data: rateLeaderborad,
    isLoading: rateLeaderboardIsLoading,
    isFetching,
  } = useGetRateMonitorDetailsQuery(
    {
      otaId: selectedIndex,
      hId: CurrentProperty(),
      userId: UserId(),
      startDate: format(new Date(rateTendenciesDate), "yyyy-MM-dd"),
      // type: selectedFilter?.value,
    },
    { refetchOnMountOrArgChange: true }
  );
  const {
    data: todaysRate,
    isLoading: todaysRateIsLoading,
    isFetching: todaysRateIsFetching,
  } = useGetRateMonitorTodayRateDetailsQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
      type: selectedFilter?.value,
    },
    { refetchOnMountOrArgChange: true }
  );
  const {
    data: rateTrend,
    isLoading: rateTrendIsLoading,
    isFetching: rateTrendIsFetching,
  } = useGetRateMonitorRateTrendDetailsQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
      type: selectedFilter?.value,
      startDate: format(new Date(rateTendenciesDate), "yyyy-MM-dd"),
      otaId: selectedIndex,
    },
    { refetchOnMountOrArgChange: true }
  );
  const { data: rateTendencies, isLoading: rateTendenciesIsLoading } =
    useGetRateTendenciesQuery(
      {
        hId: CurrentProperty(),
        userId: UserId(),
        type: selectedFilter?.value,
        startDate: format(new Date(rateTendenciesDate), "yyyy-MM-dd"),
        otaId: selectedIndex,
      },
      { refetchOnMountOrArgChange: true }
    );
  const {
    data: parityCalander,
    isLoading: parityCalendarIsLoading,
    isFetching: parityCalendarIsFetching,
  } = useGetParityCalanderQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
      type: selectedDropdown?.value || "amongCompset",
      startDate: format(new Date(rateTendenciesDate), "yyyy-MM-dd"),
    },
    { refetchOnMountOrArgChange: true }
  );
  const showTitle = true;

  // const otadata = [
  //   {
  //     otas: "",
  //     title: "MakeMyTrip",
  //     price: 3432,
  //   },
  //   {
  //     otas: "",
  //     title: "GoIbibo",
  //     price: 3432,
  //   },
  //   {
  //     otas: "",
  //     title: "Agoda",
  //     price: 3432,
  //   },
  //   {
  //     otas: "",
  //     title: "Booking.com",
  //     price: 3432,
  //   },
  //   {
  //     otas: "",
  //     title: "Expedia",
  //     price: 3432,
  //   },
  // ];
  const data = [
    {
      low: rateTendencies && rateTendencies?.data[0]?.lowestRate,
      avg: rateTendencies && rateTendencies?.data[0]?.averageRate,
      high: rateTendencies && rateTendencies?.data[0]?.highestRate,
      subtitle: "Compset Rate Range",
    },
  ];
  const typesArray = ["Room Inventory", "Plan Rates", "Restrictions"];
  const rateTendenciesGraph =
    rateTendencies && rateTendencies?.data?.thisYearRate?.rangeCounts;
  const rateTendenciesGraphHeader =
    rateTendenciesGraph &&
    Object.keys(rateTendenciesGraph).map((item) => {
      return `₹ ${item?.split("-")[0]} - ${item?.split("-")[1]}`;
    });
  const rateTendenciesGraphValue =
    rateTendenciesGraph && Object.values(rateTendenciesGraph);

  const rateTendGraph =
    rateTrend &&
    rateTrend?.data?.rateTrend.filter((item) => {
      return item?.hId === parseInt(hId);
    });
  const rateTendGraphHeader =
    rateTendGraph &&
    rateTendGraph[0]?.priceTrend?.map((item) => {
      return format(new Date(item?.date), "dd MMM',' yy");
    });
  const rateTendGraphValue =
    rateTendGraph &&
    rateTendGraph[0]?.priceTrend?.map((item) => {
      return item?.price;
    });

  const rangeData = rateTendencies?.data?.thisYearRate?.rangeCounts;

  const capitalizeFirstLetter = (string) => {
    const nameRemoved = string.replace("Name", "");
    return nameRemoved.charAt(0).toUpperCase() + nameRemoved.slice(1);
  };

  const HotelPriceData = ({ data }) => {
    const dates = data.rateTrend.map((item) => {
      return item.date.split("T")[0];
    });
    const columns = [
      {
        Header: capitalizeFirstLetter("HotelName"),
        accessor: "hotelName",
        Cell: ({ value }) => capitalizeFirstLetter(value),
      },
      ...dates.map((date) => ({
        Header: capitalizeFirstLetter(date),
        accessor: date,
      })),
    ];

    return columns;
  };

  const transformDataForTable = (data) => {
    const hotelsData = data.rateTrend.map((hotel) => {
      const { hotelName, priceTrend } = hotel;
      const hotelData = {
        hotelName: capitalizeFirstLetter(hotelName),
      };
      priceTrend.forEach((price) => {
        const formattedDate = price.date.split("T")[0];
        hotelData[formattedDate] = price.price;
      });
      return hotelData;
    });

    const columns = Object.keys(hotelsData[0]);

    const rows = hotelsData.map((hotel) => {
      return columns.map((column) => hotel[column]);
    });

    return { columns, rows };
  };

  const HotelTable = ({ data,isSticky }) => {
    function formatData(data) {
      const formattedData = {
        asPer: new Date(data.asPer).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }),
        rateTrend: data.rateTrend.map((hotel) => {
          return {
            hotelName: hotel.hotelName,
            hId: hotel.hId,
            priceTrend: hotel.priceTrend.map((price) => {
              return {
                price: formatCurrency(price.price),
                date: format(new Date(price.date), "dd MMM ',' yyyy"),
              };
            }),
          };
        }),
      };
      return formattedData;
    }
    const formattedData = formatData(data);
    const { columns, rows } = transformDataForTable(formattedData);
    return (
      <div  className=" bg-newGradient text-white dark:bg-lightGradient " style={{ width: "100%", overflowX: "auto", minWidth: "600px" }}>
        <table className="rounded-lg" style={{ minWidth: "100%" }}>
          <thead>
            <tr className="  bg-newGradient text-white dark:bg-lightGradient">
              {/* Render the header cells for the fixed column and the scrollable columns */}
              <th className="dark:bg-[#1d233f]  bg-[#4294e1] text-white"
                style={{
                  minWidth: "250px",
                  position: "sticky",
                  left: "0",
                //   background: "#fff",
                }}
              >
                Hotel
              </th>
              {columns.slice(1).map((column, index) => (
                <th className="bg-transparent text-white" key={index} style={{ minWidth: "180px" }}>
                  {capitalizeFirstLetter(column)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className=" bg-newGradient text-white dark:bg-lightGradient ">
            {/* Render rows for each hotel */}
            {rows.map((row, index) => (
              <tr className={`${index === 0 ? 'bg-transparent':''} `} key={index}>
             
                <td className={` text-white dark:bg-[#1d233f]  bg-[#4294e1]`}
                  style={{
                    minWidth: "180px",
                    position: "sticky",
                    left: "0",

                   
                  }}
                >
                  {row[0]}
                </td>
                {/* Render the rest of the cells for scrollable content */}
                {row.slice(1).map((cell, cellIndex) => (
                  <td className="  text-white bg-transparent" key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const createColumns = (rangeCounts) => {
    const columns = [];
    for (const range in rangeCounts) {
      if (rangeCounts.hasOwnProperty(range)) {
        columns.push({ title: range, dataIndex: range });
      }
    }
    return columns;
  };

  const modifyDataForTable = (data) => {
    if (!data) {
      return [];
    }

    const modifiedData = Object.entries(data).map(([range, count]) => {
      return { range, count };
    });

    return modifiedData;
  };

  const columns = createColumns(rangeData);
  const tableData = modifyDataForTable(rangeData);

  useEffect(() => {
    todaysRate?.data?.rateMonitorOverview &&
      setSelectedIndex(
        todaysRate?.data?.rateMonitorOverview
          ?.slice()
          .sort((a, b) => {
            // Check if a or b is null or undefined
            if (a == null) return 1; // Move null/undefined a to the end
            if (b == null) return -1; // Move null/undefined b to the end
          
            // Check if todayRate is null or undefined
            if (a.todayRate == null) return 1; // Move null/undefined todayRate in a to the end
            if (b.todayRate == null) return -1; // Move null/undefined todayRate in b to the end
            
            return b.todayRate - a.todayRate;
          })[0]?.otaId
      );
  }, [todaysRate?.data?.rateMonitorOverview]);

  return (
    <>
      {ratesTrend && (
        <Modal>
          <div className="lg:w-[80%] md:w-[90%] w-[95%]  bg-newGradient text-white dark:bg-lightGradient  h-fit [80%] relative sm:pt-5 sm:pb-5 sm:pl-5 sm:pr-5 px-5 pt-5 pb-10 rounded-md bg-white">
            <div className="mb-2 text-lg font-medium flex justify-between">
              <p>{"Rate Trend Last 10 Days"}</p>
              <p className="pr-6">
                <ExcelDownloadButton
                  filename={"Rate Trend Last 10 Days"}
                  data={rateTrend?.data?.rateTrend.flatMap((hotel) =>
                    hotel.priceTrend.map((priceData) => ({
                      hotelName: hotel.hotelName,
                      price: priceData.price,
                      date: priceData.date,
                    }))
                  )}
                />
              </p>
            </div>

            <div className="mt-5">
              <HotelTable isSticky={true} data={rateTrend.data} />
            </div>

            <div
              className="absolute h-fit w-fit right-2 sm:right-4 sm:top-4 bottom-[6px] sm:bottom-0  font-semibold cursor-pointer hover:text-red-500 flex items-center"
              onClick={() => {
                setRatesTend(false);
              }}
            >
              <AiOutlineCloseCircle size={24} />
            </div>
          </div>
        </Modal>
      )}
      {ratesTrend1 && (
        <Modal>
          <div className="lg:w-[80%] md:w-[90%] w-[95%] h-fit [80%] relative sm:pt-5 sm:pb-5 sm:pl-5 sm:pr-5 px-5 pt-5 pb-10 rounded-md bg-white">
            <div className="mb-2 text-lg font-medium flex justify-between">
              <p>{"Rate Tendencies Report"}</p>
              <p className="pr-6">
                <ExcelDownloadButton
                className="filter invert "
                  filename={"Rate Tendencies Report"}
                  data={Object.entries(
                    rateTendencies?.data?.thisYearRate?.rangeCounts || {}
                  ).map(([range, count]) => {
                    return {
                      Range: range,
                      Count: count,
                    };
                  })}
                />
              </p>
            </div>

            <div className="mt-5" onClick={(e) => e.stopPropagation()}>
              <DynamicTable isSticky={true} data={tableData} />
            </div>
            <div
              className="absolute  h-fit w-fit right-2 sm:right-4 sm:top-4 bottom-[6px] sm:bottom-0  font-semibold cursor-pointer hover:text-red-500 flex items-center"
              onClick={() => {
                setRatesTend1(false);
              }}
            >
              <AiOutlineCloseCircle size={24} />
            </div>
          </div>
        </Modal>
      )}
      <BackdropLoader
        loading={
          todaysRateIsLoading ||
          parityCalendarIsLoading ||
          rateTendenciesIsLoading ||
          rateLeaderboardIsLoading ||
          isFetching ||
          rateTrendIsFetching ||
          todaysRateIsFetching ||
          parityCalendarIsFetching
        }
      />
      {rateMoniitorFilterModal && (
        <BulkUpdateModalTwo
          filterModalTabIndex={filterModalTabIndex}
          setFilterModalTabIndex={setFilterModalTabIndex}
          setOverviewFilterModal={setRateMonitorFilterModal}
          typesArray={typesArray}
        />
      )}
      {!rateLeaderboardIsLoading &&
      !todaysRateIsLoading &&
      !rateTendenciesIsLoading &&
      !isFetching ? (
        <>
          <div className="flex  flex-col md:flex-row  md:justify-between md:items-center w-[100%]">
            <div className="text-[17px] flex text-white dark:text-black items-center p-4 ">
              {/* <BackButton dashboard={true} />  */}
              Overview{" "}
              <span className="text-[12px] ml-2 text-gray-100 dark:text-black">
                {todaysRate?.data?.asPer &&
                  `as per ${format(new Date(todaysRate?.data?.asPer), "dd MMM yyyy")}`}
              </span>
            </div>
            <div className="p-4 flex gap-4 ">
              <Calendar
                selectedDate={rateTendenciesDate}
                setSelectedDate={setRateTendencieDate}
              />
              <Dropdown
                selectedItem={
                  selectedFilter?.label || filterData?.data[3]?.types[0]?.label
                }
                options={filterData?.data[3]?.types}
                setSelectedItems={setSelectedFilter}
                right={"2px"}
              />
              {/* <div
                className="dark:bg-[#252C3B] bg-[#CCD6E5] cursor-pointer flex items-center h-[28] px-2 rounded-md"
                onClick={() => setRateMonitorFilterModal(true)}
              >
                <img src={filterIcon} />
              </div> */}
            </div>
          </div>
          <div
            className={
              " flex gap-4 mx-4 mr-6 overflow-scroll scrollHide p-4 w-[96%]"
            }
          >
            {todaysRate &&
              todaysRate?.data?.rateMonitorOverview
                ?.slice()
                .sort((a, b) => {
                  // Check if a or b is null or undefined
                  if (a == null) return 1; // Move null/undefined a to the end
                  if (b == null) return -1; // Move null/undefined b to the end
                
                  // Check if todayRate is null or undefined
                  if (a.todayRate == null) return 1; // Move null/undefined todayRate in a to the end
                  if (b.todayRate == null) return -1; // Move null/undefined todayRate in b to the end
                  
                  return b.todayRate - a.todayRate;
                })
                ?.map((ota, index) => {
                  if(ota?.otaName){
                    return (
                      <OtaCards
                        index={ota?.otaId}
                        onClick={() => setSelectedIndex(ota?.otaId)}
                        price={formatCurrency(ota?.todayRate)}
                        title={ota?.otaName}
                        selectedIndex={selectedIndex}
                        data={data}
                        percentageDifference={ota?.percentageDifference}
                        otaLogo={ota?.otaImage}
                        isPercentage={true}
                      />
                    );
                  }
                })}
          </div>
        </>
      ) : (
        <div className="m-4">
          <CustomSkeleton height={120} width={"100%"} />
        </div>
      )}
      <div className="flex gap-4 p-4 flex-col md:flex-row overflow-hidden ">
        {!rateLeaderboardIsLoading &&
        !todaysRateIsLoading &&
        !rateTendenciesIsLoading &&
        !isFetching ? (
          <div className="lg:w-[65%] md:w-[60%] w-[100%] bg-newGradient dark:bg-lightGradient drop-shadow-lg backdrop-blur-md rounded-2xl">
            {" "}
            <AreaChart
              height={"380px"}
              showTitle={showTitle}
              title={"Rate Trend"}
              xaxisShow={true}
              yaxisShow={true}
              currentSeriesData={rateTendGraphValue}
              currentSeriesName={rateTendGraphHeader}
              showSingleSeries={true}
              downloadButtonData={rateTrend?.data?.rateTrend.flatMap((hotel) =>
                hotel.priceTrend.map((priceData) => ({
                  hotelName: hotel.hotelName,
                  price: priceData.price,
                  date: priceData.date,
                }))
              )}
              fileName={"Rate Trend Report"}
              setTableState={() => {
                setRatesTend(true);
              }}
              currentLabel={"Rate"}
              isCurrency={true}
              asPer={rateTendencies?.data?.asPer}
              textWhite={true}
            />
          </div>
        ) : (
          <div className="w-full md:w-[60%]">
            <CustomSkeleton height={425} width={"100%"} />
          </div>
        )}
        <div className="w-full md:w-[40%]">
          {" "}
          {!rateLeaderboardIsLoading &&
          !todaysRateIsLoading &&
          !rateTendenciesIsLoading &&
          !isFetching ? (
            <RateLeaderboard
              data={rateLeaderborad?.data?.rateLeaderboad}
              asPer={rateLeaderborad?.data?.asPer}
            />
          ) : (
            <div className="">
              <CustomSkeleton height={425} width={"100%"} />
            </div>
          )}
        </div>
      </div>
      <div className=" lg:flex md:flex-none flex-none w-full gap-4 pt-2 px-4">
        {!rateLeaderboardIsLoading &&
        !todaysRateIsLoading &&
        !rateTendenciesIsLoading &&
        !isFetching ? (
          <div className=" w-full md:w-[55%] mb-4 bg-newGradient dark:bg-lightGradient drop-shadow-lg backdrop-blur-md rounded-2xl p-4">
            <div className="flex items-center justify-between">
              <div className="text-[14px] text-white font-[500]">
                Rate Tendencies{" "}
                <span className="text-[12px] text-gray-100 ml-1">
                  as per{" "}
                  {rateTendencies?.data?.asPer &&
                    format(
                      new Date(rateTendencies?.data?.asPer),
                      "dd MMM yyyy"
                    )}
                </span>
              </div>
              <div className="flex items-center">
                <div className="p-4 flex gap-4">
                  <NewTooltip
                    className=""
                    title="View Table View"
                    color="#1c274c"
                    download={true}
                  >
                    <AiOutlineTable
                      style={{
                        marginRight: "0px",
                        color: "white",
                        cursor: "pointer",
                      }}
                      size={20}
                      onClick={() => setRatesTend1(true)}
                    />
                  </NewTooltip>
                  <ExcelDownloadButton
                    className=""
                    data={Object.entries(
                      rateTendencies?.data?.thisYearRate?.rangeCounts || {}
                    ).map(([range, count]) => ({
                      Range: range,
                      Count: count,
                    }))}
                    filename={"Rate Tendencies Report"}
                  />
                </div>
                <div>
                  <InfoToolTip
                    content={
                      "This feature provides insights into the historical trends and patterns of room rates over a specified period."
                    }
                  />
                </div>
              </div>
            </div>
            <ColumnChart
              title={"Rate Tendencies"}
              currentSeriesData={rateTendenciesGraphValue}
              currenSeriesName={rateTendenciesGraphHeader}
              singleSeries={true}
              currentLabel={""}
              textWhite={true}
            />
          </div>
        ) : (
          <div className="w-[55%]">
            <CustomSkeleton height={425} width={"100%"} />
          </div>
        )}

        {!rateLeaderboardIsLoading &&
        !todaysRateIsLoading &&
        !rateTendenciesIsLoading &&
        !isFetching ? (
          <div className="flex flex-col w-full md:w-[45%] gap-4">
            <div className="flex flex-col md:flex-row gap-4 lg:mt-0 md:mt-4 mt-4 ">
              <div className="w-full">
                {" "}
                <StatsCard
                  title={"Average Rate"}
                  showCurrency={true}
                  previous={0}
                  current={
                    rateTendencies &&
                    rateTendencies?.data?.thisYearRate?.averageRate
                  }
                  isPermission={true}
                  tooltipText={
                    "This feature offers a summary of the average room rate (ARR) across a specified period"
                  }
                />
              </div>
              <div className="w-full">
                {" "}
                <StatsCard
                  title={"Most Frequent Rates"}
                  showCurrency={true}
                  previous={
                    rateTendencies &&
                    rateTendencies?.data?.thisYearRate?.mostFrequentRateLastYear
                  }
                  current={
                    rateTendencies &&
                    rateTendencies?.data?.thisYearRate?.mostFrequentRate
                  }
                  isPermission={true}
                  tooltipText={
                    "This feature highlights the most commonly observed room rates within a specified timeframe."
                  }
                />
              </div>
            </div>
            <div className="mt-3">
              {" "}
              <RateTendencies
                data={rateTendencies && rateTendencies?.data?.thisYearRate}
              />
            </div>
          </div>
        ) : (
          <div className="w-[45%]">
            <CustomSkeleton height={425} width={"100%"} />
          </div>
        )}
      </div>
      {/* <div className=" px-4 pt-2 overflow-hidden">
        <ParityCalender
          data={parityCalander?.data}
          setSelectedDropdown={setSelectedDropdown}
          selectedDropdown={selectedDropdown}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          maxHeight={300}
          asPer={parityCalander?.data?.asPer}
          otaOptions={otaOptions}
          selectedPartityOtaDropdown={selectedPartityOtaDropdown}
          setSelectedPartityOtaDropdown={setSelectedPartityOtaDropdown}
        />
      </div> */}
    </>
  );
};

export default RateMonitor;

const BulkUpdateModalTwo = ({
  setOverviewFilterModal,
  typesArray,
  filterModalTabIndex,
  setFilterModalTabIndex,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const toggleCalendar = () => {
    setShowDatePicker(!showDatePicker);
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const weekDays = [
    "All Days",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return (
    <GlobalModalComponent
      hideModal={() => setOverviewFilterModal(false)}
      saveBtnTitle={"Update"}
      title={"Bulk Update"}
      height={"true"}
    >
      <div className=" flex gap-6">
        <div className="flex flex-col  mb-2  w-[100%]">
          <div className="flex flex-col  mb-4  w-[100%]">
            <div className="w-[100%] text-[#59647B] text-[14px]">
              Time Period
            </div>
            <div className=" ">
              <Dropdown width={400} height={36} backgroundColor={"#F6F8FB"} />
            </div>
          </div>
          <div className="flex flex-col  mb-4  w-[100%]">
            <div className="w-[100%] text-[#59647B] text-[14px]">Room Type</div>
            <div className=" ">
              <Dropdown width={400} height={36} backgroundColor={"#F6F8FB"} />
            </div>
          </div>
          <div className="flex flex-col  mb-4  w-[100%]">
            <div className="w-[100%] text-[#59647B] text-[14px]">Rate Plan</div>
            <div className=" ">
              <Dropdown width={400} height={36} backgroundColor={"#F6F8FB"} />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="flex flex-col  mb-4  w-[100%]">
              <div className="w-[100%] text-[#59647B] text-[14px] relative">
                From
              </div>
              <div className=" h-[36px] w-full rounded-md bg-[#F6F8FB] px-3 py-1 flex justify-between items-center ">
                <div className="font-medium  text-sm ">Select Rate Plan</div>
                <div className="" onClick={toggleCalendar}>
                  <img src={calendar} />
                  {showDatePicker && (
                    <CalendarComp
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col  mb-4  w-[100%]">
              <div className="w-[100%] text-[#59647B] text-[14px]">To</div>
              <div className=" h-[36px] w-full rounded-md bg-[#F6F8FB] px-3 py-1 flex justify-between items-center">
                <div className=" font-medium  text-sm"> Select Rate Plan</div>
                <div>
                  <img src={calendar} />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mb-2">
            <div className="w-[100%] text-[#59647B] text-[14px] mb-2">
              Apply To
            </div>
            <div className=" grid grid-cols-4 gap-2 ">
              {weekDays.map((day, index) => (
                <WeekDaysCheckbox
                  key={index}
                  isChecked={isChecked}
                  handleCheckboxChange={handleCheckboxChange}
                  dayName={day}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-col w-[100%] ">
          <div>
            <SelectableTab
              typesArray={typesArray}
              selectedIndex={filterModalTabIndex}
              setSelectedIndex={setFilterModalTabIndex}
            />
          </div>
          <div className="flex w-full mt-4">
            <div className="flex h-34  flex-col w-full">
              <div className=" h-full text-[#59647B] text-[14px] w-full">
                Block Inventory
              </div>
              <div className=" h-full  text-[#59647B] text-[14px] w-full">
                Add Inventory
              </div>
            </div>
            <div className="flex flex-col w-full gap-2">
              <DynamicCounter />
              <DynamicCounter />
            </div>
          </div>
        </div>
      </div>
    </GlobalModalComponent>
  );
};
const WeekDaysCheckbox = ({ isChecked, handleCheckboxChange, dayName }) => (
  <div className="text-[13px] gap-1 font-medium rounded-md cursor-pointer flex items-center justify-center h-9 px-2">
    <label>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
    </label>
    <p>{dayName}</p>
  </div>
);

const CalendarComp = ({ setSelectedDate, selectedDate }) => {
  const [selectedMonth, setSelectedMonth] = useState(
    new Date(selectedDate)?.getMonth()
  );
  const [selectedYear, setSelectedYear] = useState(
    new Date(selectedDate)?.getFullYear()
  );
  const [viewMode, setViewMode] = useState("date"); // Default view mode is 'date'
  const [showCal, setShowCal] = useState(false);
  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return getDay(startOfMonth(new Date(year, month)));
  };

  const renderDays = () => {
    const daysInMonth = getDaysInMonth(selectedYear, selectedMonth);
    const firstDayOfMonth = getFirstDayOfMonth(selectedYear, selectedMonth);

    const calendar = [];
    const today = new Date();
    // Add empty slots for days before the 1st day of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
      const pastDate = subMonths(new Date(selectedYear, selectedMonth), 1);
      pastDate.setDate(pastDate.getDate() - (firstDayOfMonth - i));
      calendar.push(
        <div key={`empty${i}`} className="calendar-day past-day text-[12px]">
          {pastDate.getDate()}
        </div>
      );
    }
    // Render days of the month
    for (let i = 1; i <= daysInMonth; i++) {
      const currentDate = new Date(selectedYear, selectedMonth, i);
      const isPastDate = currentDate < today;
      const isSelected = i === new Date(selectedDate).getDate();
      const className = isSelected
        ? "calendar-day selected text-[12px]"
        : isPastDate
          ? "calendar-day past-day text-[12px] text-[#A8B1C4]"
          : "calendar-day text-black text-[12px]";
      calendar.push(
        <div
          className={className}
          key={i}
          onClick={() => {
            isPastDate
              ? toast.error("Past dates are disabled", {
                  position: "bottom-right",
                })
              : setSelectedDate(new Date(selectedYear, selectedMonth, i));
            setShowCal(!showCal);
          }}
        >
          {i}
        </div>
      );
    }

    return calendar;
  };

  const renderMonths = () => {
    const months = Array.from({ length: 12 }, (_, i) => i);
    return months.map((month) => (
      <div
        key={month}
        className={`month cursor-pointer  ${
          selectedMonth === month ? "selected" : ""
        }`}
        onClick={() => handleMonthClick(month)}
      >
        {new Date(2000, month, 1).toLocaleString("default", { month: "short" })}
      </div>
    ));
  };

  const renderYears = () => {
    const years = Array.from({ length: 12 }, (_, i) => selectedYear - 5 + i);
    return years.map((year) => (
      <div
        key={year}
        className={`year cursor-pointer ${selectedYear === year ? "selected" : ""}`}
        onClick={() => handleYearClick(year)}
      >
        {year}
      </div>
    ));
  };

  const handleMonthClick = (month) => {
    setSelectedMonth(month);
    setViewMode("date");
  };

  const handleYearClick = (year) => {
    setSelectedYear(year);
    setViewMode("date");
  };

  const handlePrevMonth = () => {
    const prevMonth = selectedMonth === 0 ? 11 : selectedMonth - 1;
    const prevYear = selectedMonth === 0 ? selectedYear - 1 : selectedYear;
    setSelectedMonth(prevMonth);
    setSelectedYear(prevYear);
  };

  const handleNextMonth = () => {
    const nextMonth = selectedMonth === 11 ? 0 : selectedMonth + 1;
    const nextYear = selectedMonth === 11 ? selectedYear + 1 : selectedYear;
    setSelectedMonth(nextMonth);
    setSelectedYear(nextYear);
  };
  const handlePrevious = () => {
    setSelectedDate(
      new Date(selectedDate).setDate(new Date(selectedDate).getDate() - 1)
    );
  };
  const handleNext = () => {
    setSelectedDate(
      new Date(selectedDate).setDate(new Date(selectedDate).getDate() + 1)
    );
  };
  return (
    <>
      <div className="calendar-div">
        <div className="header">
          <div className="arrow" onClick={handlePrevMonth}>
            {"<"}
          </div>
          <div className="flex">
            <div
              className="month-year cursor-pointer mr-2"
              onClick={() => setViewMode("month")}
            >
              {viewMode === "date"
                ? new Date(selectedYear, selectedMonth, 1).toLocaleString(
                    "default",
                    { month: "short" }
                  )
                : "Month"}
            </div>
            <div
              className="month-year cursor-pointer"
              onClick={() => setViewMode("year")}
            >
              {viewMode === "date" ? selectedYear : "Year"}
            </div>
          </div>
          <div className="arrow" onClick={handleNextMonth}>
            {">"}
          </div>
        </div>
        <div className="calendar">
          {viewMode === "date" && (
            <div className="weekdays text-[#59647B] text-[12px]">
              <div>S</div>
              <div>M</div>
              <div>T</div>
              <div>W</div>
              <div>T</div>
              <div>F</div>
              <div>S</div>
            </div>
          )}
          <div className="days">{viewMode === "date" && renderDays()}</div>
          <div className="monthyearview">
            {viewMode === "month" && renderMonths()}
            {viewMode === "year" && renderYears()}
          </div>
        </div>
      </div>
    </>
  );
};

const DynamicTable = ({ data,isSticky }) => {
  if (!data || data.length === 0) {
    return <div className="mt-4 text-gray-600 italic">No data available</div>;
  }

  return (
    <table className="w-full   bg-newGradient text-white dark:bg-lightGradient border-collapse border border-gray-200">
      <thead className="bg-transparent">
        <tr className="bg-transparent text-white">
          <th className="px-4 bg-transparent py-2">Range</th>
          <th className="px-4 bg-transparent  py-2">Count</th>
        </tr>
      </thead>
      <tbody className=" bg-transparent text-white ">
        {data.map((record, index) => (
          <tr className="bg-transparent" key={index}>
            <td className="px-4 py-2  bg-transparent border border-gray-200">
              ₹{record.range.split("-")[0]}- ₹{record.range.split("-")[1]}
            </td>

            <td className="px-4 py-2 bg-transparent border border-gray-200">{record.count}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
