import React, { useEffect, useRef, useState } from "react";
import MonthReportTable from "../../newTable/MonthReportTable";
import formatCurrency from "../../../utils/formatCurrency";
import YearReportTable from "../../newTable/YearEndReportTable";
import PickUpReport from "./PickUpReport";
import PickUpTable from "../../newTable/PickUpTable";
import ReviewTable from "../../newTable/ReviewTable";
import Calendar from "../../../components/calendar";
import { YearEndReportdownload } from "../DownloadExcel/YearEndReport";
import { AiFillCalendar, AiOutlineWhatsApp } from "react-icons/ai";
import CustomSkeleton from "../../../components/Skeleton";
import { formatDate } from "../DownloadExcel/formatDate";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import CustomCalander from "../../../components/CustomCalender/CustomCalander";
import NoDataFound from "../../../components/noData";
import useIntelligenceDateRange from "../../../utils/dateRange";
import { toast } from "react-toastify";
import whatsappBusiness from "../../../assets/img/whatsapp-business-icon.svg";

const YearEndReport = ({
  data,
  setDateRange,
  dateRange,
  setIsWhatsApp,
  isLoading,
  sendToWhatsappAdmin,
  yearEndWhatsAppAdmin,
}) => {
    const [isDropdownOpen,setIsDropdownOpen]=useState(false);
  const dateLimit = useIntelligenceDateRange();
  const fromDate = format(new Date(dateLimit.fromDate), "MM/dd/yyyy");
  const endDate = format(new Date(dateLimit.toDate), "MM/dd/yyyy");
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [showDateRange, setShowDateRange] = useState(false);
  const ref1 = useRef();
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref1?.current && !ref1?.current.contains(event.target)) {
        setShowDateRange(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref1]);
  const handleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  if (!data && !isLoading) {
    // Render a fallback UI or an error message when data is undefined
    return (
      <div className="flex flex-col drop-shadow-lg backdrop-blur-md items-center justify-center">
        <div className="flex items-center  text-white dark:text-black justify-center p-2 font-semibold">
          <p >Year End Report - </p>
        </div>
        <div className="m-3 w-full flex justify-end items-end">
          <div className="w-fit"></div>
          <h1
            onClick={() => {}}
            className="flex  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            Download
          </h1>
          <div className=" flex items-center text-white dark:text-black gap-2 justify-center pr-2">
            <div className="w-fit text-sm">Select date range</div>
            <CustomCalander
              calendarType={"single"}
              onlyYear={true}
              onlyMonth={false}
              activeDateArray={"selected"}
              selectedDateArray={dateRange}
              setSelectedDateArray={setDateRange}
              restrictionStart={fromDate}
              restrictionEnd={endDate}
            />
          </div>

          <div
            onClick={setIsWhatsApp}
            className="flex  w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            <AiOutlineWhatsApp size={18} /> Send to WhatsApp
          </div>
        </div>
        <div className="h-full flex items-center justify-center w-[100%]">
          {/* <CustomSkeleton height={500} /> */}

          <NoDataFound />
        </div>
      </div>
    );
  }
  if (isLoading && !yearEndWhatsAppAdmin) {
    // Render a fallback UI or an error message when data is undefined
    return (
      <div className="flex flex-col bg-transparent  drop-shadow-lg backdrop-blur-md items-center  justify-center">
        <div className="flex items-center text-white dark:text-black justify-center p-2 font-semibold">
          <p>Year End Report </p>
        </div>

        <div className="w-[100%] bg-transparent ">
          <CustomSkeleton isTransparent={true} title={'Year End Report'} animated={true} height={500} />
        </div>
      </div>
    );
  }
  const { statistics, sourceData, roomData, monthWiseBreakDown, reviews } =
    data;

  const propertyName = window.localStorage.getItem("propertyName");
  return (
    <>
      <div className="flex  rounded-t-lg sticky  top-0 items-center justify-center p-2 font-semibold">
        <p className="sticky text-white dark:text-black">
          Year End Report - {propertyName}
          {` (  ${new Date(dateRange[0]).getFullYear() || formatDate(data.asOn?.startDate)} )`}
        </p>
      </div>
      <div className="w-full rounded-b-lg mb-2 sticky top-[40px] flex justify-end  ">
        <div className="m-3 w-auto flex justify-end items-center">
          <div className="w-fit">
            <div className=" flex items-center  text-white gap-2  dark:text-black justify-center pr-2">
              <div className="w-fit text-sm">Select date range</div>
              <div className="border dark:border-black p-2 flex items-center rounded-md justify-center">
                {`  ${new Date(dateRange[0]).getFullYear() || formatDate(data.asOn?.startDate)} `}
                <CustomCalander
                  calendarType={"single"}
                  onlyYear={true}
                  onlyMonth={false}
                  activeDateArray={"selected"}
                  selectedDateArray={dateRange}
                  setSelectedDateArray={setDateRange}
                  restrictionStart={fromDate}
                  restrictionEnd={endDate}
                />
              </div>
            </div>
          </div>
          <h1
            onClick={() => {
              YearEndReportdownload(data);
            }}
            className="flex m-auto  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            Download
          </h1>
          {/* <div
            onClick={setIsWhatsApp}
            className="flex m-auto w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            <AiOutlineWhatsApp size={18} /> Send to WhatsApp
          </div> */}
        <div ref={dropdownRef} className="relative">
      {/* Main Button */}
      <div
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="flex m-auto w-[180px] gap-2 justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-xs text-white  font-medium text-[17px]"
      >
        <AiOutlineWhatsApp size={18} /> Send via WhatsApp
      </div>

     
      {isDropdownOpen && (
        <div className="absolute mt-2 w-[180px] text-xs px-4 rounded-lg  shadow-lg dark:bg-[#1D3893] bg-[#3ec329]">
          <div
            onClick={() => {
              setIsDropdownOpen(false);
              setIsWhatsApp(); // Handle Send to Self
            }}
            className="flex gap-2 justify-start items-center p-2 cursor-pointer"
          >
            <AiOutlineWhatsApp size={18} /> Send to self
          </div>
          <div
            onClick={() => {
              setIsDropdownOpen(false);
              sendToWhatsappAdmin();
              // Handle Send to Personal Number
            }}
            className="flex gap-2 justify-start items-center p-2  cursor-pointer"
          >
            <AiOutlineWhatsApp size={18} /> Send to secondary 
          </div>
        </div>
      )}
    </div>
          {/* <div
            onClick={sendToWhatsappAdmin}
            className="flex  w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            <img src={whatsappBusiness} height={20} width={20} alt="" /> Send to
            Group
          </div> */}
        </div>
      </div>

      <div className="overflow-y-scroll mt-2 h-[90%] ">
        <div className="flex flex-col gap-4">
          <YearReportTable
            data={sourceData}
            reservation={true}
            arrival={true}
            label={"Source-wise Breakdown"}
            isExpanded={expandedIndex === 0}
            onExpand={() => handleExpand(0)}
          />
          <YearReportTable
            data={roomData}
            reservation={true}
            arrival={true}
            label={"Room-wise Breakdown"}
            room={true}
            isExpanded={expandedIndex === 1}
            onExpand={() => handleExpand(1)}
          />
          <YearReportTable
            data={monthWiseBreakDown}
            reservation={true}
            arrival={true}
            label={"Month-wise Breakdown"}
            // room={true}
            monthWise={true}
            isExpanded={expandedIndex === 2}
            onExpand={() => handleExpand(2)}
          />
        </div>
        <div className="flex my-4 justify-between gap-4">
          <TableCards
            title={"Revenue"}
            mainData={formatCurrency(statistics[0]?.totalRevenue)}
          />
          {/* <TableCards title={"Reservations"} mainData={statistics[0]?.Reservations} /> */}
          <TableCards
            title={"ADR"}
            mainData={formatCurrency(statistics[0]?.ADR)}
          />
          <TableCards
            title={"Cancellations"}
            mainData={statistics[0]?.cancellations}
          />
          <TableCards
            title={"Occupancy"}
            mainData={statistics[0]?.occupancy?.toFixed(2)}
          />
        </div>
        <ReviewTable
          data={reviews}
          arrival={true}
          room={true}
          isExpanded={expandedIndex === 3}
          onExpand={() => handleExpand(3)}
        />
      </div>
    </>
  );
};

export default YearEndReport;

const TableCards = ({ mainData, title, height, width }) => {
  return (
    <div
      className={`px-4 py-2 w-[100%] rounded-lg border text-white dark:bg-lightGradient bg-newGradient s`}
      style={{ height: `${height}px` }}
    >
      <div className=" font-semibold ">{title}</div>
      <div className=" mt-1 flex items-center gap-2 font-medium ">
        <div>
          {mainData}
          {title == "Occupancy" && "%"}
        </div>
      </div>
    </div>
  );
};
