// external/getCMRoomDetail
import { emptySplitApi } from "../../../injectEndpoints";

export const ChannelManagerRuleMapping = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCMRoomDetail: builder.query({
      query: (data) => {
        return {
          url: `/external/getCMRoomDetail`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getCMRoomDetail"],
    }),
    getCMRateChargeRule: builder.query({
      query: (data) => {
        return {
          url: `/external/getCMRateChargeRule`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getCMRateChargeRule"],
    }),
    // addCMRateChargeRule: builder.mutation({
    //   query: (data) => {
    //     return {
    //       url: `/userDashboardSetting/addCMRateChargeRule`,
    //       method: "POST",
    //       body: data
    //     };
    //   },
    //   // Replace with your actual endpoint
    //   providesTags: ["addCMRateChargeRule"],
    // }),
    editCMRateChargeRule: builder.mutation({
      query: (data) => {
        return {
          url: `/external/editCMRateChargeRule`,
          method: "PATCH",
          body: data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["editCMRateChargeRule"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetCMRoomDetailQuery,useGetCMRateChargeRuleQuery, useEditCMRateChargeRuleMutation } = ChannelManagerRuleMapping;
