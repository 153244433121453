import { emptySplitApi } from "../../../injectEndpoints";

export const ChannelManageSeasonTypes = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getSeasonType: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getPropertySeasons`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getRoomTypes"],
    }),
    addNewSeason: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/addNewSeason`,
          method: "POST",
          body: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["addNewSeason"],
    }),
    // editRoomTypes: builder.mutation({
    //   query: (data) => {
    //     return {
    //       url: `/userDashboardSetting/editRoomDetail`,
    //       method: "PATCH",
    //       body:data?.roomDetail,
    //       params:{
    //         hId:data?.hId,
    //         roomTypeId:data?.roomTypeId
    //       }
    //     };
    //   },
    //   // Replace with your actual endpoint
    //   providesTags: ["editRoomTypes"],
    // }),
    deleteSeason: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/deletePropertySeasons`,
          method: "PATCH",
          params: {
            hId: data?.hId,
            seasonTypeId: data?.seasonTypeId,
          },
        };
      },
      // Replace with your actual endpoint
      providesTags: ["deleteSeason"],
    }),
    updateSeason: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/editPropertySeason`,
          method: "PATCH",
          params: {
            hId: data?.hId,
            seasonTypeId: data?.seasonTypeId,
          },
        };
      },
      // Replace with your actual endpoint
      providesTags: ["deleteSeason"],
    }),
    updateSeasonOccupancyRule: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/editAllSeasonRateRule`,
          method: "PATCH",
          body:data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["updateSeasonOccupancyRule"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetSeasonTypeQuery, useAddNewSeasonMutation, useDeleteSeasonMutation, useUpdateSeasonMutation, useUpdateSeasonOccupancyRuleMutation } =
  ChannelManageSeasonTypes;
