import React, { useEffect, useRef, useState } from "react";
import { useGetRateChangeReportQuery } from "../../../redux/slices/reports";
import RateChangeReportTable from "../../newTable/rateChangeReportTable";
import { CurrentProperty, UserId } from "../../../utils/impVars";
import { formatDate } from "../DownloadExcel/formatDate";
import { format } from "date-fns";
import { Calendar, DateRangePicker } from "react-date-range";
import { AiFillCalendar, AiOutlineWhatsApp } from "react-icons/ai";
import CustomSkeleton from "../../../components/Skeleton";
import useIntelligenceDateRange from "../../../utils/dateRange";
import { DownloadCancellationReport } from "../DownloadExcel/CancellationReport";
import NewRateChangeReportTable from "../../newTable/newRateRangeRepotrTable";
import NewOtaOptions from "../../../utils/newOTAOptions";
import { useIsIpad } from "../../../utils/isIpad";
import CheckBox from "../../../components/checkbox/CheckBox";
import Dropdown from "../../../components/dropdown";
import { MultiSelectNestedDropdown } from "../../../components/dropdown/subComponent/multiSelectNestedDropdown";


const RateChangeReport = ({
  metricData,
  isMetricDataLoading,
  metricSourceSelector,
  metricDateRangeSelector,
  setMetricSourceSelector,
  setMetricDateRangeSelector,
  paceSourceSelector,
  setPaceSourceSelector,
  paceDateRangeSelector,
  setPaceDateRangeSelector,
  fluctuationDateRangeType,
  setFluctuationDateRangeType,
  paceData,
  isPaceDataLoading,
  rateData,
  isRateDataLoading,
  fluctuationData,
  isFluctuationDataLoading,
  reputationData,
  isReputationDataLoading,
  dateRange,
  setDateRange,
  bifercationType,
  setIsWhatsApp,sendToWhatsappAdmin
}) => {
  // const [showDateRange, setShowDateRange] = useState(false);
  const dateLimit = useIntelligenceDateRange();
  const [selectedDate, setSelectedDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
    }
  ]);
  const [showDateRange, setShowDateRange] = useState(false);
  const newDate = new Date();
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [isToggled, setIsToggled] = useState(false);
  const toggleButton = () => {
    if (metricDateRangeSelector === "day") {
      setMetricDateRangeSelector("week");
    } else {
      setMetricDateRangeSelector("day");
    }
    setIsToggled(!isToggled);
  };
  const fromDate = format(new Date(dateLimit?.fromDate), "MM/dd/yyyy");
  const endDate = format(new Date(dateLimit?.toDate), "MM/dd/yyyy");
  const ref1 = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref1?.current && !ref1?.current.contains(event.target)) {
        setShowDateRange(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref1]);
  const handleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  const options = NewOtaOptions();
  const [selectedItem, setSelectedItem] = useState([]);
  useEffect(() => {
    let list = [];
    selectedItem.map((item, id) => {
      list.push(item?.value);
    });
    let sourcesSelection = list.join(",");
    setMetricSourceSelector(sourcesSelection);
  }, [selectedItem]);
  const [isDropdownOpen,setIsDropdownOpen]=useState(false)
  const dropdownRef = useRef(null);
  
  useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsDropdownOpen(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
  
      // Cleanup the event listener on component unmount
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [dropdownRef]);
  const isIpad = useIsIpad();
  
  const [isPaceDateToggled, setIsPaceDateToggled] = useState(false);
  const paceToggleButton = () => {
    if (paceDateRangeSelector === "day") {
      setPaceDateRangeSelector("week");
    } else {
      setPaceDateRangeSelector("day");
    }
    setIsPaceDateToggled(!isPaceDateToggled);
  };

  const [selectedPaceItem, setSelectedPaceItem] = useState([]);
  useEffect(() => {
    let list = [];
    selectedPaceItem.map((item, id) => {
      list.push(item?.value);
    });
    let sourcesSelection = list.join(",");
    setPaceSourceSelector(sourcesSelection);
  }, [selectedPaceItem]);

  return (
    <div className="h-[80vh]">
      <div className="h-[10%] my-4 flex items-center text-white dark:text-black justify-between">
        <h1 className="text-[24px] font-bold"> Rate Change Report</h1>
        <div className="flex ">
          <div className=" flex items-center gap-2 justify-center pr-2">
            <div className="w-fit font-semibold text-[17px]">Select date</div>
            {
              <div
                className="relative mb-2 w-fit justify-start text-xs cursor-pointer select-none flex gap-4 items-center mx-1 px-2 py-3 text-white dark:text-black rounded-md border-solid border border-[#A0A4B0] z-20"
                ref={ref1}
              >
                <div
                  className="flex  text-white dark:text-black  gap-2 items-center"
                  onClick={() => setShowDateRange(!showDateRange)}
                >
                  {" "}
                  <AiFillCalendar size={14} />{" "}
                  {format(new Date(dateRange), "dd MMM yyy") || " "}{" "}
                </div>
                {showDateRange && (
                  <div className="absolute top-[50px] -left-[250px] text-black">
                    <Calendar
                      date={dateRange}
                      onChange={(e) => {
                        setDateRange(e);
                        setShowDateRange(!showDateRange);
                      }}
                      minDate={new Date(fromDate)}
                      maxDate={new Date(endDate)}
                    />
                  </div>
                )}
              </div>
            }
          </div>
          <div ref={dropdownRef} className="relative">
      {/* Main Button */}
      <div
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="flex m-auto w-[180px] gap-2 justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-xs text-white  font-medium text-[17px]"
      >
        <AiOutlineWhatsApp size={18} /> Send via WhatsApp
      </div>

     
        {isDropdownOpen && (
        <div className="absolute mt-2 w-[180px] text-white text-xs px-4 rounded-lg  shadow-lg dark:bg-[#1D3893] bg-[#3ec329]">
          <div
            onClick={() => {
              setIsDropdownOpen(false);
              setIsWhatsApp(); // Handle Send to Self
            }}
            className="flex gap-2 justify-start items-center p-2 cursor-pointer"
          >
            <AiOutlineWhatsApp size={18} /> Send to self
          </div>
          <div
            onClick={() => {
              setIsDropdownOpen(false);
              sendToWhatsappAdmin();
              // Handle Send to Personal Number
            }}
            className="flex gap-2 justify-start items-center p-2  cursor-pointer"
          >
            <AiOutlineWhatsApp size={18} /> Send to secondary 
          </div>
        </div>
      )}
    </div>
          {/* <h1
            onClick={() => {
              DownloadCancellationReport(
                data?.rateChangeReport?.cancellationData
              );
            }}
            className="flex m-auto   justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            Download
          </h1>{" "} */}
          {/* <div
            onClick={setIsWhatsApp}
            className="flex m-auto w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            <AiOutlineWhatsApp size={18} /> Send to WhatsApp
          </div> */}
        </div>
      </div>{" "}
      {/* -------------------------------------------------------------------------- */
      /*                                 Metric Data                                */
      /* -------------------------------------------------------------------------- */}
      <div className="flex flex-col gap-2 mt-2 overflow-y-scroll h-[90%]">
        {!isMetricDataLoading ? (
          <div className="flex flex-col ">
            <div className="flex flex-row justify-between w-[100%]">
              <h1 className="text-white dark:text-black text-[24px] font-[700]">
                {" "}
                Internal Data
              </h1>
              <div className="flex flex-row items-end justify-end w-[40%] gap-2">
                {/* <div className="flex items-center bg-white/30 p-2 dark:bg-customSubDarkBg rounded-lg gap-2">
                  <h1 className="text-sm text-white">Day</h1>
                  <CheckBox
                    checked={isToggled}
                    onChange={toggleButton}
                    name={"toggle"}
                  />
                  <h1 className="text-sm text-white">Week</h1>
                </div>
                <div className=" flex items-end justify-end">
                  <Dropdown
                    placeHolder={"Select Source"}
                    options={options}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                  />
                </div> */}
              </div>
            </div>

            {/* Today's comparison */}
            <NewRateChangeReportTable
              data={metricData?.result?.internalData?.todaysComparison}
              handleExpand={handleExpand}
              label={"Today's Comparison"}
              isExpanded={expandedIndex === 0}
              onExpand={() => handleExpand(0)}
            />

            <div className="flex flex-row justify-between w-[100%]">
              <h1 className="text-white dark:text-black text-[24px] font-[700]">
                {" "}
              </h1>
              <div className="flex flex-row items-end justify-end w-[40%] gap-2">
                <div className="flex items-center bg-white/30 p-2 dark:bg-customSubDarkBg rounded-lg gap-2">
                  <h1 className="text-sm text-white">Day</h1>
                  <CheckBox
                    checked={isToggled}
                    onChange={toggleButton}
                    name={"toggle"}
                  />
                  <h1 className="text-sm text-white">Week</h1>
                </div>
                <div className=" flex items-end justify-end">
                  <MultiSelectNestedDropdown
                    placeHolder={"Select Source"}
                    options={options}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                  />
                </div>
              </div>
            </div>
            {/* This Month Comparison */}
            <NewRateChangeReportTable
              data={metricData?.result?.internalData?.ComparisonData}
              isToggled={isToggled}
              handleExpand={handleExpand}
              label={"This Month Comparison"}
              isExpanded={expandedIndex === 1}
              onExpand={() => handleExpand(1)}
            />
          </div>
        ) : (
          <div className="w-[100%] mt-[50px]">
            <CustomSkeleton height={400} />
          </div>
        )}

        {/* -------------------------------------------------------------------------- */
        /*                                  PaceData                                  */
        /* -------------------------------------------------------------------------- */}
        {!isPaceDataLoading ? (
          <div className="flex flex-col ">
            {/* Pace Report */}
            <div className="flex flex-row justify-between w-[100%]">
              <h1 className="text-white dark:text-black text-[24px] font-[700]">
                {" "}
                Pace Report
              </h1>
              <div className="flex flex-row items-end justify-end w-[40%] gap-2">
                <div className="flex items-center bg-white/30 p-2 dark:bg-customSubDarkBg rounded-lg gap-2">
                  <h1 className="text-sm text-white">Day</h1>
                  <CheckBox
                    checked={isPaceDateToggled}
                    onChange={paceToggleButton}
                    name={"paceToggle"}
                  />
                  <h1 className="text-sm text-white">Week</h1>
                </div>
                <div className=" flex items-end justify-end">
                  <MultiSelectNestedDropdown
                    placeHolder={"Select Source"}
                    options={options}
                    selectedItem={selectedPaceItem}
                    setSelectedItem={setSelectedPaceItem}
                  />
                </div>
              </div>
            </div>

            {/* Arrival This Month */}
            <NewRateChangeReportTable
              handleExpand={handleExpand}
              data={paceData?.result?.paceData?.arrivalPace}
              label={"Arrival This Month"}
              isExpanded={expandedIndex === 2}
              onExpand={() => handleExpand(2)}
            />

            {/* Reservation Pace */}
            {/* <NewRateChangeReportTable
              handleExpand={handleExpand}
              data={paceData?.result?.paceData?.reservationPace}
              label={"Reservation Pace"}
              isExpanded={expandedIndex === 3}
              onExpand={() => handleExpand(3)}
            /> */}

            {/* Pickup */}
            <NewRateChangeReportTable
              handleExpand={handleExpand}
              data={paceData?.result?.paceData?.pickupPace}
              label={"Pickup Pace"}
              isExpanded={expandedIndex === 4}
              onExpand={() => handleExpand(4)}
            />
          </div>
        ) : (
          <div className="w-[100%] mt-[50px]">
            <CustomSkeleton height={400} />
          </div>
        )}

        {/* -------------------------------------------------------------------------- */
        /*                                  RateData                                  */
        /* -------------------------------------------------------------------------- */}
        {!isRateDataLoading ? (
          <div className="flex flex-col ">
            {/* compset data */}

            <h1 className="text-white dark:text-black text-[24px] font-[700]">
              {" "}
              Compset Based Data
            </h1>

            {/* My Compset Comparison */}

            <NewRateChangeReportTable
              handleExpand={handleExpand}
              data={rateData?.result?.compsetData?.rateComparison}
              label={"Rate Comparison"}
              isExpanded={expandedIndex === 5}
              onExpand={() => handleExpand(5)}
            />
          </div>
        ) : (
          <div className="w-[100%] mt-[50px]">
            <CustomSkeleton height={400} />
          </div>
        )}

        {/* -------------------------------------------------------------------------- */
        /*                            Rate Fluctuation Data                           */
        /* -------------------------------------------------------------------------- */}
        {!isFluctuationDataLoading ? (
          <div className="flex flex-col ">
            {/* Average Monthly Change */}
            <div className="flex flex-row justify-between w-[100%]">
              <h1 className="text-white dark:text-black text-[24px] font-[700]">
                {" "}
              </h1>
              <div className="flex flex-row items-end justify-end w-[40%] gap-2">
                <div className=" flex items-end justify-end">
                  <Dropdown
                    options={[
                      { label: "Last 7 Days", value: "last7" },
                      { label: "Last 15 Days", value: "last15" },
                      { label: "Last 30 Days", value: "last30" },
                      { label: "Last 45 Days", value: "last45" },
                    ]}
                    setSelectedItems={(val) => {
                      setFluctuationDateRangeType(val);
                    }}
                    selectedItem={
                      fluctuationDateRangeType?.label ||
                      "Number Of Fluctuation Days"
                    }
                  />
                </div>
              </div>
            </div>
            <NewRateChangeReportTable
              handleExpand={handleExpand}
              data={fluctuationData?.result?.fluctuation}
              label={"Average Monthly Change"}
              isExpanded={expandedIndex === 6}
              onExpand={() => handleExpand(6)}
            />
          </div>
        ) : (
          <div className="w-[100%] mt-[50px]">
            <CustomSkeleton height={400} />
          </div>
        )}

        {/* -------------------------------------------------------------------------- */
        /*                      Reputation Ranking & Review Data                      */
        /* -------------------------------------------------------------------------- */}
        {!isReputationDataLoading ? (
          <div className="flex flex-col">
            {/* Visibility Comparison */}
            <NewRateChangeReportTable
              handleExpand={handleExpand}
              data={reputationData?.result?.compsetData?.visibility}
              label={"Visibility Comparison"}
              isExpanded={expandedIndex === 7}
              onExpand={() => handleExpand(7)}
            />

            {/* Reputation Comparison */}
            <NewRateChangeReportTable
              handleExpand={handleExpand}
              data={reputationData?.result?.compsetData?.reputation}
              label={"Reputation Comparison"}
              isExpanded={expandedIndex === 8}
              onExpand={() => handleExpand(8)}
            />
          </div>
        ) : (
          <div className="w-[100%] mt-[50px]">
            <CustomSkeleton height={400} />
          </div>
        )}
      </div>
    </div>
  );
};

export default RateChangeReport;
