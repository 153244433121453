import React, { useEffect, useState } from "react";
import Dropdown from "../../components/dropdown";
import Calendar from "../../components/calendar/index";
import { addDays, format } from "date-fns";
import downArrow from "../../assets/icons/downArrow.svg";
import formatCurrency from "../../utils//formatCurrency";
import { useGetCompsetRateRecordsQuery, useRateRecordForExcelQuery } from "../../redux/slices/ratesInventory";
import { CurrentProperty, UserId } from "../../utils/impVars";
import BackdropLoader from "../../components/newLoader";
import { useGetCompsetDetailsQuery } from "../../redux/slices/settings/myProperties";
import { useGetMealPlanQuery } from "../../redux/slices/settings/channelManager/mealPlan";
import { extractUniqueValues, filterData } from "../ratesAndInventory";
import { Dropdown as MultiSelectDropdown } from "../IntelligenceNewDashboard";
import { Tooltip } from "react-tooltip";
import { useIsIpad } from "../../utils/isIpad";
import NoDataFound from "../../components/noData";
import { useGetAllCompsetRatesQuery } from "../../redux/slices/dashboard";
import { ratesAndInventor } from "../dashboard/DownloadExcel/RateAndInventory";
import { LuDownload } from "react-icons/lu";

const CompsetOverview = () => {
  const [isShowCompsetView, setIsShowCompsetView] = useState(false);
  const [selectMealPlan, setSelectMealPlan] = useState([]);
  const [selectedOta, setSelectedOta] = useState([]);
  const { data: mealPlan } = useGetMealPlanQuery({
    hId: CurrentProperty(),
    userId: UserId(),
  });
  const { data: compsetData } = useGetCompsetDetailsQuery({
    hId: CurrentProperty(),
    userId: UserId(),
  });

  const propertyName = localStorage.getItem('propertyName');

  const [option, setOption] = useState();
  const [selectedCompset, setSelectedCompset] = useState(null);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
console.log(selectedCompset,"selectedCompset")
  const [selectRoomType, setSelectRoomType] = useState([]);
  const [selected2Date, setSelected2Date] = React.useState(new Date());
  const { data: compsetsRates } = useGetAllCompsetRatesQuery({
    hId: CurrentProperty(),
    today: format(new Date(selectedDate), "yyy-MM-dd"),
    asOn: format(new Date(selected2Date), "yyy-MM-dd"),
    // otaId:selectedOta?.join(", ")
  });
  const { data, isFetching, isLoading } = useGetCompsetRateRecordsQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      today: format(new Date(selectedDate), "yyy-MM-dd"),
      asOn: format(new Date(selected2Date), "yyy-MM-dd"),
      compHId: selectedCompset?.value,
      // otaId: selectedOta?.join(", "),
      // mealPlanId: selectMealPlan?.join(", "),
      // otaId:selectedOta && selectedOta?.value
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: rateExcelData } = useRateRecordForExcelQuery({
    userId: UserId(),
    hId: selectedCompset?.value,
    today: format(new Date(selectedDate), "yyy-MM-dd"),
    asOn: format(new Date(selected2Date), "yyy-MM-dd"),
  });
  function getNext9Days(selectedDate) {
    let result = [];
    let startDate = new Date(selectedDate);
    for (let i = 0; i < 9; i++) {
      let nextDay = addDays(startDate, i);
      let day = format(nextDay, "EEEE"); // 'EEEE' gives the full name of the day
      result.push({ date: format(nextDay, "yyyy-MM-dd"), day: day });
    }
    return result;
  }

  useEffect(() => {
    if (compsetData && compsetData?.data?.compSetDetails) {
      const opt =
        Array.isArray(compsetData?.data?.compSetDetails) &&
        compsetData?.data?.compSetDetails.map((item) => {
          return {
            label: item?.propertyName,
            value: item?.hId,
          };
        });
      setOption(opt);
      if (!selectedCompset) {
        setSelectedCompset(opt[0]);
      }
    }
  }, [compsetData?.data?.compSetDetails]);

  const mealPlansOption = data && [
    // { label: "All", value: "" },
    ...extractUniqueValues(data?.data?.rateData)?.mealPlans?.map((item) => {
      return {
        label: item,
        value: item,
      };
    }),
  ];

  const otaOptions = data && [
    // { label: "All", value: "" },
    ...extractUniqueValues(data?.data?.rateData)?.otas,
  ];

  const roomTypesOption = data && [
    // { label: "All", value: "" },
    ...extractUniqueValues(data?.data?.rateData)?.roomTypes?.map((item) => {
      return {
        label: item,
        value: item,
      };
    }),
  ];

  const isIpad = useIsIpad();

  useEffect(() => {
    if (selected2Date) {
      setSelectedDate(selected2Date);
    }
  }, [selected2Date]);

  return (
    <div className="w-[100%] overflow-y-scroll bg-transparent h-full">
      <BackdropLoader loading={isFetching || isLoading} />
      <div className="flex justify-between w-[100%]">
        <div className="text-[17px] text-white p-4 font-[500] dark:text-black flex items-center gap-2">
          {/* <BackButton mainDashboard={true} /> */}
          Compset Overview
        </div>
        
        <div className="p-4 justify-end items-center flex gap-2">
        <div
            className="cursor-pointer mr-4"
            onClick={() => ratesAndInventor(rateExcelData?.data,selectedCompset?.label)}
          >
            <LuDownload />
          </div>
          <div
            className="min-w-max text-sm py-2 px-4 bg-white/30 mr-4 text-white  dark:bg-subMainDark rounded-lg max-w-max cursor-pointer "
            onClick={() => setIsShowCompsetView(!isShowCompsetView)}
          >
            {isShowCompsetView ? "Show room-wise" : "Show all compsets"}
          </div>
          <div className="flex justify-center items-center">
            <div className="text-sm flex items-center w-[80px] dark:text-black -mr-6 text-white">
              As On
            </div>
            <Calendar
              selectedDate={selected2Date}
              setSelectedDate={setSelected2Date}
              //   backgroundNone={true}
              //   right={"-right-0"}
              //   showIconBackground={true}
              onlyPastDaysSelectable={true}
            />
          </div>
        </div>
      </div>
      {!isShowCompsetView && (
        <div className="p-4 justify-end flex gap-2 -mt-4">
          <div className=" flex gap-4">
            <Dropdown
              selectedItem={selectedCompset?.label || "Select Compset"}
              options={option}
              setSelectedItems={setSelectedCompset}
              height={40}
            />
          </div>
          {roomTypesOption && (
            <MultiSelectDropdown
              selectedItem={selectRoomType || "Room Type"}
              setSelectedItem={setSelectRoomType}
              dropDownoptions={roomTypesOption}
              placeHolder={"Select Room Types"}
              // height={40}
              width={"w-[240px]"}
            />
          )}
          {otaOptions && (
            <MultiSelectDropdown
              width={"w-[240px]"}
              selectedItem={selectedOta}
              dropDownoptions={otaOptions}
              setSelectedItem={setSelectedOta}
              placeHolder={"Select OTA"}
            />
          )}
          {mealPlansOption && (
            <MultiSelectDropdown
              width={"w-[180px]"}
              selectedItem={selectMealPlan}
              dropDownoptions={mealPlansOption}
              setSelectedItem={setSelectMealPlan}
              placeHolder={"Select Meal Plan"}
              left={"-118px"}
            />
          )}
        </div>
      )}
      <div className="mx-4 py-1 rounded-lg drop-shadow-lg backdrop-blur-md">
        <div className="cal-header mt-4 px-4">
          <div className="flex justify-between bg-transparent text-[#ffffff]  dark:text-black  py-2 rounded-lg items-center">
            <div className="w-[250px]">
              <Calendar
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                // backgroundNone={true}
                // width={250}
                // right={"-right-0"}
                // showIconBackground={true}
                // enableDaysFromNow={45}
                // disablePastDays={true}
                minStart={selected2Date}
              />
            </div>
            {isIpad
              ? getNext9Days(selectedDate)
                  ?.slice(0, 6)
                  .map((date, index) => {
                    return (
                      <div className="px-6" key={index}>
                        <div className="flex font-[500] justify-center text-[#ffffff]  dark:text-black text-[13px]">
                          <div>{date.day.slice(0, 3)},&nbsp;</div>
                          <div>{date.date.split("-")[2]}</div>
                        </div>
                        <div className="text-[#f2f3f5] dark:text-black text-[11px]">
                          {format(new Date(date.date), "MMM")},&nbsp;
                          {format(new Date(date.date), "yyyy")}
                        </div>
                      </div>
                    );
                  })
              : getNext9Days(selectedDate).map((date, index) => {
                  return (
                    <div className="px-6" key={index}>
                      <div className="flex font-[500] justify-centertext-[13px]">
                        <div>{date.day.slice(0, 3)},&nbsp;</div>
                        <div>{date.date.split("-")[2]}</div>
                      </div>
                      <div className="text-[11px]">
                        {format(new Date(date.date), "MMM")},&nbsp;
                        {format(new Date(date.date), "yyyy")}
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
        {!isShowCompsetView && (
          <div className="mt-4 scrollHide min-h-auto max-h-[500px] overflow-y-scroll">
            <Accordion
              data={
                data?.data?.rateData &&
                filterData(
                  data?.data?.rateData,
                  selectRoomType,
                  selectedOta,
                  selectMealPlan
                )
              }
            />
          </div>
        )}
        {isShowCompsetView &&
          compsetsRates?.data &&
          compsetsRates?.data?.map((item, index) => {
            return (
              <div
                className={`flex gap-4 px-4 py-2 w-[100%]   bg-newGradient text-white dark:bg-lightGradient m-4 rounded-lg`}
              >
                <div
                  className="min-w-[210px] max-w-[210px] ml-6 p-2 truncate text-sm"
                  data-tooltip-id={`${item.propertyName}${index}`}
                  data-tooltip-content={item.propertyName}
                >
                  {item.propertyName}{" "}
                  <Tooltip id={`${item.propertyName}${index}`} />
                </div>
                {item?.rates?.map((items) => {
                  return (
                    <div className="w-[100%]  text-center p-2 text-sm">
                      {formatCurrency(items)}
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default CompsetOverview;

const Accordion = ({ data }) => {
  return (
    <div className="px-4">
      <NestedAccordion data={data} />
    </div>
  );
};

const NestedAccordion = ({ data }) => {
  const [openItems, setOpenItems] = React.useState([0]); // Set the initial state to have the first parent accordion item open

  function getLowestRate(data) {
    const lowestRates = [];

    data.forEach((room) => {
      const roomTitle = room.title;
      const dates = room.subItems[0].subItems[0].date;
      const roomPrices = room.subItems[0].subItems.map((ota) => ota.prices);

      const minPrices = dates.map((date, index) => {
        const pricesOnDate = roomPrices.map((otaPrices) => otaPrices[index]);
        const minPrice = Math.min(...pricesOnDate);
        const minPriceOTAs = room.subItems[0].subItems
          .filter((ota) => ota.prices[index] === minPrice)
          .map((ota) => ({ otaId: ota.otaId, price: minPrice }));
        return { date, minPrice, minPriceOTAs };
      });
      lowestRates.push({ roomTitle, minPrices });
    });
    return lowestRates;
  }
  const toggleItem = (index) => {
    setOpenItems((prevOpenItems) =>
      prevOpenItems.includes(index)
        ? prevOpenItems.filter((item) => item !== index)
        : [...prevOpenItems, index]
    );
  };

  const isItemOpen = (index) => {
    return openItems.includes(index);
  };

  const isIpad = useIsIpad();

  return (
    <div>
      {data?.length > 0 ? (
        Array.isArray(data) &&
        data?.slice(isIpad ? 0 : 0, isIpad ? 6 : 9).map((item, index) => (
          <div
            key={index}
            className={`accordion-item cursor-pointer w-[100%]   flex items-start flex-col ${
              isItemOpen(index) ? "rounded-xl" : "rounded-xl"
            }    bg-newGradient text-white dark:bg-lightGradient  mb-4`}
          >
            <div
              className={`w-[100%]  black:text-black  ${
                isItemOpen(index) ? "border-b border-b-[#D3D8E1]" : ""
              }  flex justify-between  items-center`}
            >
              <div>
                <div
                  className="accordion-title font-medium  py-4 px-2 h-[50px]  text-[15px] w-[250px] flex justify-between items-center"
                  onClick={() => toggleItem(index)}
                >
                  <div
                    className="w-[200px] truncate"
                    data-tooltip-id={"index"}
                    data-tooltip-content={item?.title}
                  >
                    {item.title}
                  </div>
                  <Tooltip id={"index"} />
                  <div>
                    <img
                      src={downArrow}
                      className={`${isItemOpen(index) ? "rotate-180" : ""}`}
                    />
                  </div>
                </div>
              </div>
              <div className="text-xs px-4 black:text-black text-center ml-2 w-[10%]">
                {formatCurrency(
                  getLowestRate(data)[index]?.minPrices[0]?.minPrice
                ) === "₹ 0.00"
                  ? "--"
                  : formatCurrency(
                      getLowestRate(data)[index]?.minPrices[0]?.minPrice
                    )}
              </div>
              <div className="text-xs px-4 text-center ml-2 w-[10%]">
                {formatCurrency(
                  getLowestRate(data)[index]?.minPrices[1]?.minPrice
                ) === "₹ 0.00"
                  ? "--"
                  : formatCurrency(
                      getLowestRate(data)[index]?.minPrices[1]?.minPrice
                    )}
              </div>
              <div className="text-xs px-4 text-center ml-2 w-[10%]">
                {formatCurrency(
                  getLowestRate(data)[index]?.minPrices[2]?.minPrice
                ) === "₹ 0.00"
                  ? "--"
                  : formatCurrency(
                      getLowestRate(data)[index]?.minPrices[2]?.minPrice
                    )}
              </div>
              <div className="text-xs px-4 text-center ml-2 w-[10%]">
                {formatCurrency(
                  getLowestRate(data)[index]?.minPrices[3]?.minPrice
                ) === "₹ 0.00"
                  ? "--"
                  : formatCurrency(
                      getLowestRate(data)[index]?.minPrices[3]?.minPrice
                    )}
              </div>
              <div className="text-xs px-4 text-center ml-2 w-[10%]">
                {formatCurrency(
                  getLowestRate(data)[index]?.minPrices[4]?.minPrice
                ) === "₹ 0.00"
                  ? "--"
                  : formatCurrency(
                      getLowestRate(data)[index]?.minPrices[4]?.minPrice
                    )}
              </div>
              {
                <div className="text-xs px-4 text-center ml-2 w-[10%]">
                  {formatCurrency(
                    getLowestRate(data)[index]?.minPrices[5]?.minPrice
                  ) === "₹ 0.00"
                    ? "--"
                    : formatCurrency(
                        getLowestRate(data)[index]?.minPrices[5]?.minPrice
                      )}
                </div>
              }
              {!isIpad && (
                <div className="text-xs px-4 text-center ml-2 w-[10%]">
                  {formatCurrency(
                    getLowestRate(data)[index]?.minPrices[6]?.minPrice
                  ) === "₹ 0.00"
                    ? "--"
                    : formatCurrency(
                        getLowestRate(data)[index]?.minPrices[6]?.minPrice
                      )}
                </div>
              )}
              {!isIpad && (
                <div className="text-xs px-4 text-center ml-2 w-[10%]">
                  {formatCurrency(
                    getLowestRate(data)[index]?.minPrices[7]?.minPrice
                  ) === "₹ 0.00"
                    ? "--"
                    : formatCurrency(
                        getLowestRate(data)[index]?.minPrices[7]?.minPrice
                      )}
                </div>
              )}
              {!isIpad && (
                <div className="text-xs px-4 text-center ml-2 w-[10%]">
                  {formatCurrency(
                    getLowestRate(data)[index]?.minPrices[8]?.minPrice
                  ) === "₹ 0.00"
                    ? "--"
                    : formatCurrency(
                        getLowestRate(data)[index]?.minPrices[8]?.minPrice
                      )}
                </div>
              )}
            </div>
            {isItemOpen(index) && (
              <div className="accordion-content bg-[#F6F8FB] w-[100%]">
                {item.subItems.map((subItem, subIndex) => (
                  <AccordionSubItems
                    key={subIndex}
                    item={subItem}
                    toggleItem={() => toggleItem(`${index}-${subIndex}`)} // Concatenate parent index with sub-item index
                    index={`${index}-${subIndex}`} // Concatenate parent index with sub-item index
                    openItems={openItems}
                    defaultOpen={subIndex === 0 && index === 0} // Conditionally set default open for the first sub-item of the parent at index 0
                  />
                ))}
              </div>
            )}
          </div>
        ))
      ) : (
        <NoDataFound />
      )}
    </div>
  );
};

const AccordionSubItems = ({
  item,
  toggleItem,
  index,
  openItems,
  defaultOpen,
}) => {
  const [isOpen, setIsOpen] = React.useState(defaultOpen);

  React.useEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen]);

  const handleClick = () => {
    setIsOpen(!isOpen);
    toggleItem(index);
  };

  const isIpad = useIsIpad();

  return (
    <div className=" bg-newGradient text-white dark:bg-lightGradient ">
      {" "}
      <div className="w-[100%] border-b  border-b-[#D3D8E1]">
        <div
          className="accordion-title  font-medium pr-2 pl-6 py-4  h-[50px] text-[#131926] text-[14px] w-[250px] flex justify-between items-center"
          onClick={handleClick}
        >
          <div className="text-white">{item.title}</div>
          <div>
            <img src={downArrow} className={`${isOpen ? "rotate-180" : ""} `} />
          </div>
        </div>
      </div>
      {isOpen && (
        <div>
          {item.subItems.map((ota, otaIndex) => {
            return (
              <div
                className="border-b border-b-[#D3D8E1] h-[50px] px-1 flex justify-between"
                key={otaIndex}
              >
                <div className="font-medium pr-2 flex items-center justify-start gap-2 pl-9 py-4 w-[250px] text-[14px] dark:text-white text-[#131926]">
                  <div className="flex gap-2 items-center">
                    {" "}
                    <img
                      src={ota?.otaLogo}
                      width={"12%"}
                      className="rounded-lg"
                    />{" "}
                    {ota?.title}
                  </div>
                </div>
                {ota.prices
                  .slice(isIpad ? 0 : 0, isIpad ? 6 : 9)
                  .map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="w-[100px] group relative flex justify-center items-center  rounded-lg my-2 flex-wrap"
                      >
                        {" "}
                        <div className="text-[#0D1321] dark:text-white font-medium text-[13px] ">
                          {item === 0 ? "--" : formatCurrency(item)}
                        </div>{" "}
                        {/* <div className="bg-[#4267E533] invisible group-hover:visible opacity-0 backdrop-blur-2xl group-hover:opacity-100 duration-200 ease-in-out transition-all  absolute -top-[58px] border border-[#C1CEFF] p-[8px] rounded-xl">
                        <div className="text-[9px] text-[#0D1321]">
                          Last Updated
                        </div>
                        <div className="text-[12px] text-[#0D1321] font-medium ">
                          3 hrs ago
                        </div>
                      </div> */}
                      </div>
                    );
                  })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
