import * as ExcelJS from "exceljs";
import { logoUrl } from "./retvensLogo.js";
import { formatDate } from "./formatDate.js";

export const ratesAndInventor = async (json,propertyName) => {
  try {
    const workbook = new ExcelJS.Workbook();
  

    // const logoBase64 = logoUrl;
    // const logoId = workbook.addImage({
    //   base64: logoBase64,
    //   extension: "jpg",
    // });
    // worksheet.mergeCells("A1:I3");
    // worksheet.addImage(logoId, "A1:A3");

    // const columns = [
    //   "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "K", "M", "N", "O", "P",
    // ];
    // const columnWidths = Array(columns.length).fill(20);
    // columns.forEach((column, index) => {
    //   worksheet.getColumn(column).width = columnWidths[index];
    // });

    // const dateCell = worksheet.getCell("I1");
    // dateCell.value = new Date();
    // dateCell.numFmt = "dd mmmm yyyy";
    // dateCell.alignment = { vertical: "middle" };

    // const mainHeading = worksheet.getCell("A4");
    // mainHeading.value = "Rates and Inventory Report";
    // mainHeading.alignment = { horizontal: "center", vertical: "middle" };
    // mainHeading.fill = {
    //   type: "pattern",
    //   pattern: "solid",
    //   fgColor: { argb: "B7D166" },
    // };
    
    // Merge main heading
    // if (!worksheet.getCell("A4").isMerged) {
    //   worksheet.mergeCells("A4", "I4");
    // }
    // worksheet.getRow(4).height = 40;

    // Set up columns for data'
    // const worksheet = workbook.addWorksheet("Data");
    // const json = {
    //     otas: [
    //       {
    //         name: "OTA 1",
    //         columns: [
    //           { header: "Date", key: "date" },
    //           { header: "Deluxe with Balcony", subHeaders: ["EP", "CP", "MAP", "AP"] },
    //           { header: "Deluxe with Colony", subHeaders: ["EP", "CP", "MAP", "AP"] },
    //           { header: "Deluxe with Society", subHeaders: ["EP", "CP", "MAP", "AP"] },
    //           { header: "Deluxe with Bathroom", subHeaders: ["EP", "CP", "MAP", "AP"] },
    //         ],
    //         data: [
    //           {
    //             date: new Date("2024-11-16"), // Date
    //             prices: {
    //               "Deluxe with Balcony": [100, 120, 150, 200], // EP, CP, MAP, AP
    //               "Deluxe with Colony": [110, 130, 160, 210],
    //               "Deluxe with Society": [120, 140, 170, 220],
    //               "Deluxe with Bathroom": [130, 150, 180, 230],
    //             },
    //           },
    //           {
    //             date: new Date("2024-11-17"), // Another date
    //             prices: {
    //               "Deluxe with Balcony": [102, 122, 152, 202],
    //               "Deluxe with Colony": [112, 132, 162, 212],
    //               "Deluxe with Society": [122, 142, 172, 222],
    //               "Deluxe with Bathroom": [132, 152, 182, 232],
    //             },
    //           },
    //         ],
    //       },
    //       {
    //         name: "OTA 2",
    //         columns: [
    //           { header: "Date", key: "date" },
    //           { header: "Deluxe with Balcony", subHeaders: ["EP", "CP", "MAP", "AP"] },
    //           { header: "Deluxe with Colony", subHeaders: ["EP", "CP", "MAP", "AP"] },
    //           { header: "Deluxe with Society", subHeaders: ["EP", "CP", "MAP", "AP"] },
    //           { header: "Deluxe with Bathroom", subHeaders: ["EP", "CP", "MAP", "AP"] },
    //         ],
    //         data: [
    //           {
    //             date: new Date("2024-11-16"), // Date
    //             prices: {
    //               "Deluxe with Balcony": [105, 125, 155, 205],
    //               "Deluxe with Colony": [115, 135, 165, 215],
    //               "Deluxe with Society": [125, 145, 175, 225],
    //               "Deluxe with Bathroom": [135, 155, 185, 235],
    //             },
    //           },
    //           {
    //             date: new Date("2024-11-17"), // Another date
    //             prices: {
    //               "Deluxe with Balcony": [107, 127, 157, 207],
    //               "Deluxe with Colony": [117, 137, 167, 217],
    //               "Deluxe with Society": [127, 147, 177, 227],
    //               "Deluxe with Bathroom": [137, 157, 187, 237],
    //             },
    //           },
    //         ],
    //       },
    //     ],
    //   };
      json.rateData.forEach(ota => {
        const worksheet = workbook.addWorksheet(ota.name); // Create a new worksheet with the OTA name
      
        // Add "Hotel Shiva" in the first row and style it
        worksheet.addRow([propertyName]);
        worksheet.mergeCells(1, 1, 1, ((ota.columns.length -1) *ota.columns?.[1]?.subHeaders.length )+1); // Merge across all columns
        worksheet.getCell('A1').font = { bold: true, size: 16 };
        worksheet.getCell('A1').alignment = { horizontal: 'center' };
       
        // Insert a placeholder row for the headers
        worksheet.addRow([]);
      
        // Set up columns
        worksheet.columns = ota.columns.map(col => ({
          key: col.key,
          width: 20,
        }));
      
        // Style and merge cells for headers
        let currentCol = 2; // Start from column 2 for subheaders
        ota.columns.forEach((col, index) => {
          if (index === 0) {
            // Special styling for "Date" header spanning two rows
            worksheet.mergeCells(2, 1, 3, 1); // Merge first column header across two rows
            worksheet.getCell(2, 1).value = col.header;
            worksheet.getCell(2, 1).alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell(2, 1).font = { bold: true };
          } else {
            const colRange = { start: currentCol, end: currentCol + col.subHeaders.length - 1 };
            worksheet.mergeCells(2, colRange.start, 2, colRange.end); // Merge header row
            worksheet.getCell(2, colRange.start).value = col.header;
            worksheet.getCell(2, colRange.start).alignment = { horizontal: 'center' };
            worksheet.getCell(2, colRange.start).font = { bold: true };
            worksheet.getCell(2, colRange.start).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
              };
              worksheet.getCell(2,1).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
              };
            col.subHeaders.forEach((subHeader, subIndex) => {
              worksheet.getCell(3, colRange.start + subIndex).value = subHeader;
              worksheet.getCell(3, colRange.start + subIndex).alignment = { horizontal: 'center' };
              worksheet.getCell(3, colRange.start + subIndex).font = { bold: true };
              worksheet.getCell(3, colRange.start + subIndex).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
              };
            });
            currentCol = colRange.end + 1;
          }
        });
      
        // Add and style data rows starting from the fourth row
        ota.data.forEach(item => {
          const row = [new Date(item.date).toLocaleDateString('en-US', {
            weekday: 'short', // "Mon", "Tue", etc.
            year: 'numeric', // "2024"
            month: 'short', // "Nov"
            day: 'numeric', // "6"
          })];
          Object.keys(item.prices).forEach(roomType => {
            const prices = item.prices[roomType];
            row.push(...prices);
          });
         worksheet.addRow(row);
      
          // Apply borders to all cells in the row
        
        });
      });
      
      

    // Continue processing data...
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the blob and trigger download
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Rates and Inventory Report.xlsx`; // Set filename
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
  } catch (e) {
    console.error("Error generating the report:", e);
  }
};
