import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import Dropdown from "../../../../../components/dropdown";
import Table from "../../../../../components/table";
import { AiFillDelete } from "react-icons/ai";
import { toast } from "react-toastify";
import { MdDeleteOutline } from "react-icons/md";
import { Tooltip } from "react-tooltip";
import { ChannelManagerDetail } from "../../../../../utils/impVars";
import parseJSON from "date-fns/parseJSON";

const CMRoomPlanMapping = ({
  setIsRoomSelected,
  isRoomSelected,
  setSelectedPlanRoom,
  selectedPlanRoom,
  selectedRoomPrice,
  setSelectedRoomPrice,
  handleAddData,
  ruleMappingData,
  setRuleMappingData,
  roomNameMap,
  roomIdMap,
  selectedPlanRoomId,
  setSelectedOccupancyPlanId,
  setSelectedOccupancyPlan,
  setIsRoomPlanSelected,
  applyRule,
  setRoomsClone,
}) => {
  const [currentRoom, setCurrentRoom] = useState();

  const [selectedRoomPlanOption, setSelectedRoomPlanOption] = useState();

  useEffect(() => {
    let selectedRoom = roomIdMap.get(selectedPlanRoom);
    setCurrentRoom(selectedRoom);
  }, [roomIdMap, roomNameMap, selectedPlanRoom]);

  const [roomPlanOptions, setRoomPlanOptions] = useState([]);
  const [roomPlanOptionsMap, setRoomPlanOptionsMap] = useState([]);

  useEffect(() => {
    let roomPlanOptionsT = currentRoom?.roomPlanInfo.map((itm) => ({
      label: itm?.roomPlanName,
      value: itm?.roomPlanId,
    }));
    const channelMap = new Map(
      roomPlanOptionsT?.map((entry, id) => [entry.label, entry.value])
    );
    setRoomPlanOptionsMap(channelMap);
    setRoomPlanOptions(roomPlanOptionsT);
  }, [currentRoom]);
  const handleChangeValue = (index, type, key, value) => {
    // Deep clone the ruleMappingData object to avoid direct mutation
    let updatedData = JSON.parse(JSON.stringify(ruleMappingData));

    if (type === "single") {
      updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan =
        updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan.map(
          (roomPlan, idx) =>
            idx === index ? { ...roomPlan, [key]: value } : roomPlan
        );
    } else if (type === "nested") {
      updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan =
        updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan.map(
          (room, idx) => (idx === index ? { ...room, [key]: value } : room)
        );
    } else if (type === "adjust") {
      let adjust = {
        adjustmentType:
          key === "adjustmentType"
            ? value
            : updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan?.[
                index
              ].adjust.adjustmentType,
        amountChangeType:
          key === "amountChangeType"
            ? value
            : updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan?.[
                index
              ].adjust.amountChangeType,
        adjustmentValue:
          key === "adjustmentValue"
            ? value
            : updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan?.[
                index
              ].adjust.adjustmentValue,
      };
      updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan =
        updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan.map(
          (room, idx) => (idx === index ? { ...room, adjust: adjust } : room)
        );
    }

    setRuleMappingData(updatedData);
  };

  const handleRemoveRoomData = (index) => {
    let updatedData = { ...ruleMappingData };
    updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan =
      updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan.filter(
        (_, idx) => idx !== index
      );

    setRuleMappingData(updatedData);
  };

  let channelManagerDetail = ChannelManagerDetail();
  channelManagerDetail = JSON.parse(channelManagerDetail);
  console.log(channelManagerDetail?.["id"], "aman channelManager");
  const roomPlanMapColumns = [
    {
      name: "Derived Room Plan",
      selector: (row) => row["derivedId"],
      cell: (row, index) => {
        return (
          <div>
            {row["derivedId"] && row["derivedId"] !== "" ? (
              <div className="flex flex-row">
                <p className="text-[12px] text-left">
                  {row["derivedId"]?.roomPlanName}
                </p>
                {
                  index !== 0 && 
                <button
                  onClick={() => {
                    handleChangeValue(index, "single", "derivedId", "");
                  }}
                >
                  <MdDeleteOutline className="text-white text-[20px]" />
                </button>
                }
              </div>
            ) : (
              <select
                onChange={(e) => {
                  let roomIdd = roomPlanOptionsMap.get(e.target.value);
                  let roomObj = {
                    roomPlanId: roomIdd,
                    roomPlanName: e.target.value,
                  };
                  handleChangeValue(index, "single", "derivedId", roomObj);
                }}
                className="focus:outline-none text-black border-none outline-none py-0 px-2 w-[100%] bg-gray-200 my-2 rounded-md h-8  text-xs "
              >
                <option value="">Select room</option>
                {roomPlanOptions &&
                  roomPlanOptions
                    ?.filter((itm) => {
                      return itm.label !== roomPlanOptions?.label;
                    })
                    .map((item) => {
                      return <option key={item?.label}>{item?.label}</option>;
                    })}
              </select>
            )}
          </div>
        );
      },
    },
    {
      name: "Master Room Plan",
      selector: (row) => row["masterId"],
      cell: (row) => {
        return (
          <div
            data-tooltip-content={row["masterId"]?.label}
            data-tooltip-id={row["masterId"]?.label}
            className="text-left text-[12px]"
          >
            {row["masterId"]?.label}
            <Tooltip place="bottom" id={row["masterId"]?.label} />
          </div>
        );
      },
    },
    {
      name: "Adjust",
      selector: (row) => row["adjust.adjustmentType"],
      cell: (row, index) => {
        return (
          <div className="flex gap-2">
            <button
              className={`${row?.adjust.adjustmentType === "percent" ? "bg-blue-800 text-white" : " text-white"} cursor-pointer rounded-lg px-4 py-2`}
              onClick={() =>
                handleChangeValue(index, "adjust", "adjustmentType", "percent")
              }
              disabled={index===0 ? true : false}
            >
              %
            </button>
            <button
              className={`${row?.adjust.adjustmentType === "amount" ? "bg-blue-800 text-white" : "text-white"} cursor-pointer rounded-lg px-4 py-2`}
              onClick={() =>
                handleChangeValue(index, "adjust", "adjustmentType", "amount")
              }
              disabled={index===0 ? true : false}
            >
              ₹
            </button>
          </div>
        );
      },
    },
    {
      name: "Amount",
      selector: (row) => row["adjust.amountChangeType"],
      cell: (row, index) => {
        return (
          <div className="flex gap-2">
            <button
              className={`${row?.adjust.amountChangeType === "decrease" ? "bg-blue-800 text-white " : "text-white"} cursor-pointer rounded-lg px-4 py-2`}
              onClick={() =>
                handleChangeValue(
                  index,
                  "adjust",
                  "amountChangeType",
                  "decrease"
                )
              }
              disabled={index===0 ? true : false}
            >
              -
            </button>
            <button
              className={`${row?.adjust.amountChangeType === "increase" ? "bg-blue-800 text-white " : "text-white"} cursor-pointer rounded-lg px-4 py-2`}
              onClick={() =>
                handleChangeValue(
                  index,
                  "adjust",
                  "amountChangeType",
                  "increase"
                )
              }
              disabled={index===0 ? true : false}
            >
              +
            </button>
          </div>
        );
      },
    },
    {
      name: "Adjustment Value",
      selector: (row) => row["adjust.adjustmentValue"],
      cell: (row, index) => {
        return (
          <input
            className="py-2 rounded-lg px-1 text-sm focus:outline-none w-[70%] text-black"
            onChange={(e) =>
              handleChangeValue(
                index,
                "adjust",
                "adjustmentValue",
                e.target.value
              )
            }
            disabled={index===0 ? true : false}
            value={row.adjust.adjustmentValue}
          />
        );
      },
    },
    {
      name: "Total Value",
      selector: (row) => row["totalValue"],
      cell: (row, index) => {
        return (
          <input
            className="py-2 rounded-lg px-1 text-sm focus:outline-none w-[70%] text-black"
            disabled={true}
            onChange={
              (e) => {}
              // handleChangeValue(index, "adjustValue", e.target.value)
            }
            value={applyRule(
              Number(
                applyRule(
                  Number(ruleMappingData?.masterRoom?.price),
                  ruleMappingData.rooms[selectedPlanRoomId].adjust
                )
              ),
              row.adjust
            )}
          />
        );
      },
    },
    {
      name: "Actions",
      selector: (row) => row["derivedRoom"],
      cell: (row, index) => {
        return (
          <div className="flex flex-row gap-2 ">
            {channelManagerDetail?.id === 102 ? (
              <button
                className=" text-white font-semibold text-[12px] text-left"
                onClick={() => {
                  if (
                    row["derivedId"]?.roomPlanId &&
                    row["derivedId"]?.roomPlanId !== ""
                  ) {
                    setIsRoomPlanSelected(true);
                    setSelectedOccupancyPlan(row["derivedId"]?.roomPlanId);
                    setSelectedOccupancyPlanId(index);

                    // setSelectedOccupancyPlanId(index)
                  } else {
                    toast.error("Please select room first", {
                      position: "bottom-right",
                    });
                  }
                }}
              >{`Map Occupancy`}</button>
            ) : (
              <></>
            )}
            <button
              className=""
              onClick={() => {
                handleRemoveRoomData(index);
              }}
            >
              <AiFillDelete className="text-red-700" />{" "}
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="mt-8">
        <div className="flex flex-row justify-start items-center gap-2">
          <button
            className="bg-blue-700 h-[35px] w-[35px] flex justify-center items-center rounded-md my-[10px]"
            onClick={() => {
              setIsRoomSelected(!isRoomSelected);
              setSelectedPlanRoom("");
            }}
          >
            <FaArrowLeft className="text-white text-[18px]" />
          </button>
          <div className="text-md mb-2 text-lightFontColor ">
            Select Master Room Plan & its adjustments for{" "}
            {currentRoom?.roomName}:
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            <div className="bg-black/40 rounded-lg w-fit">
              <Dropdown
                options={roomPlanOptions && roomPlanOptions}
                right={-100}
                selectedItem={
                  ruleMappingData.rooms[selectedPlanRoomId].masterRatePlan
                    .masterId.label
                }
                setSelectedItems={(e) => {
                  let test = { ...ruleMappingData };
                  test.rooms[selectedPlanRoomId].masterRatePlan = {
                    ...test.rooms[selectedPlanRoomId].masterRatePlan,
                    masterId: e,
                  };
                  setSelectedRoomPlanOption(e);
                  console.log(e, "aman eeeeee");
                  if (e && e.label !== "") {
                    if (
                      test.rooms[selectedPlanRoomId].masterRatePlan.ratePlan
                        .length === 0
                    ) {
                      test.rooms[selectedPlanRoomId].masterRatePlan.ratePlan = [
                        ...test.rooms[selectedPlanRoomId].masterRatePlan
                          .ratePlan,
                        {
                          masterId: e,
                          derivedId: {
                            "roomPlanId": e?.value,
                            "roomPlanName": e?.label
                        },
                          adjust: {
                            adjustmentType: "percent",
                            amountChangeType: "increase",
                            adjustmentValue: 0,
                          },
                          masterOccupancy: "",
                          occupancyPlan: [],
                        },
                      ];
                      setRoomsClone(test); // Update roomsClone as well
                    } else {
                      test.rooms[
                        selectedPlanRoomId
                      ].masterRatePlan.ratePlan[0] = {
                        masterId: e,
                        derivedId: {
                          "roomPlanId": e?.value,
                          "roomPlanName": e?.label
                      },
                        adjust: {
                          adjustmentType: "percent",
                          amountChangeType: "increase",
                          adjustmentValue: 0,
                        },
                        masterOccupancy: "",
                        occupancyPlan: [],
                      };
                      setRoomsClone(test); // Update roomsClone as well
                    }
                    test.rooms[selectedPlanRoomId].masterRatePlan.ratePlan.map((ratePlan,ratePlanId)=>{
                      test.rooms[selectedPlanRoomId].masterRatePlan.ratePlan[ratePlanId].masterId = e
                    })
                  }
                  setRuleMappingData(test);
                }}
              />
            </div>
            <div className="bg-black/25 rounded-lg w-fit py-0.5">
              <input
                placeholder="Enter rate"
                type="number"
                value={Number(
                  applyRule(
                    Number(ruleMappingData?.masterRoom?.price),
                    ruleMappingData.rooms[selectedPlanRoomId].adjust
                  )
                )}
                min={0}
                disabled={true}
                onChange={(e) => setSelectedRoomPrice(e.target.value)}
                className="text-sm focus:outline-none  bg-transparent text-white placeholder:text-white px-2"
              />
            </div>
          </div>
          <div className="text-lightFontColor flex items-center gap-4">
            <div className="] cursor-pointer text-sm" onClick={handleAddData}>
              Add More
            </div>
            {/* <div className=" cursor-pointer text-sm">Adjust Rate Plan</div> */}
          </div>
        </div>
      </div>
      <div className="h-[400px] scroll-container">
        <Table
          columns={roomPlanMapColumns}
          data={
            ruleMappingData?.rooms?.[selectedPlanRoomId]?.masterRatePlan
              ?.ratePlan
          }
        />
        {/* <CustomCMMappingTable
          // data={ruleMappingData}
          /> */}
      </div>
    </>
  );
};

export default CMRoomPlanMapping;
