import React, { useEffect, useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../noData";

const SolutionTile = ({data, dataIndex}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
//   dataIndex = data.dataIndex;
//   data = data.data;
  const navigate = useNavigate();
  useEffect(() => {
    setCurrentIndex(0);
  }, [dataIndex]);

  return (
    <div
      className={
        "w-full md:w-[30%] min-h-[300px] max-h-max  mt-8 relative"
      }
    >
      <div>
        <div className="flex  justify-between p-4">
          <div className="text-[#ffffff]  text-sm">
            Solution for improved performance
          </div>
          <div className="flex gap-2 items-center">
            <AiOutlineDown
            color="white"
              className="rotate-90 cursor-pointer"
              onClick={
                currentIndex === 0
                  ? () => null
                  : () => setCurrentIndex(currentIndex - 1)
              }
            />
            <div className="text-[#ffffff]">
              {data?.data?.[dataIndex]?.solution.length === 0
                ? 0
                : currentIndex + 1}
              /
              {Array?.isArray(data?.data) &&
                data?.data?.[dataIndex]?.solution &&
                data?.data?.[dataIndex]?.solution.length}
            </div>
            <AiOutlineDown
              className="-rotate-90 cursor-pointer"
              color="white"
              onClick={
                currentIndex + 1 === data?.data?.[dataIndex]?.solution.length
                  ? () => null
                  : () => setCurrentIndex(currentIndex + 1)
              }
            />
          </div>
        </div>
        <div className=" w-[90%] mx-auto mb-4"></div>
        {data?.data[dataIndex]?.solution?.length > 1 && (
          <div
            className={`${data?.data[dataIndex]?.sentiment === "negative" ? "bg-[#ff4b33] rotate-90" : "bg-[#6cec30] "} w-8 h-8 ml-4  rounded-full flex justify-center items-center`}
          >
            <svg
              width="22"
              height="15"
              viewBox="0 0 22 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 0.56665V2.06665H18.9394L12.5 8.50607L9.28025 5.2864C9.21064 5.21673 9.12798 5.16146 9.037 5.12374C8.94601 5.08603 8.84849 5.06662 8.75 5.06662C8.65151 5.06662 8.55399 5.08603 8.463 5.12374C8.37202 5.16146 8.28936 5.21673 8.21975 5.2864L0.5 13.0061L1.56058 14.0667L8.75 6.87723L11.9697 10.0969C12.0394 10.1666 12.122 10.2218 12.213 10.2596C12.304 10.2973 12.4015 10.3167 12.5 10.3167C12.5985 10.3167 12.696 10.2973 12.787 10.2596C12.878 10.2218 12.9606 10.1666 13.0303 10.0969L20 3.12723V8.06665H21.5V0.56665H14Z"
                fill="white"
              />
            </svg>
          </div>
        )}
        <div className="p-4 text-[16px] text-white">
          {data?.data[dataIndex]?.solution?.[currentIndex]?.solutionTitle}
          {data?.data[dataIndex]?.solution?.length < 1 && <NoDataFound />}
        </div>
        <div className="p-4 text-sm text-[#ffffff] -mt-3">
          {data?.data[dataIndex]?.solution?.[currentIndex]?.solutionDescription}
        </div>
        {data?.data[dataIndex]?.solution?.[currentIndex]?.actionButtonState && (
          <div className="m-4">
            <div
              onClick={() =>
                navigate(
                  dataIndex === 0
                    ? "/rates_&_inventory"
                    : dataIndex === 1
                      ? "/rates_&_inventory"
                      : dataIndex === 2
                        ? "/rate-pulse/visibility"
                        : dataIndex === 3 && "/rate-pulse/reputation"
                )
              }
              className="text-[#ffffff] w-[100%]  rounded-full text-center bg-[#383c4f] hover:bg-[#1D2D44] hover:text-white duration-150 transition-all ease-in-out py-2 cursor-pointer"
            >
              {data?.data[dataIndex]?.solution?.[currentIndex]?.buttonLabel}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SolutionTile;
