import { emptySplitApi } from "../../injectEndpoints";

export const RatesAndInventory = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getRatesAndInventory: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/rateRecord`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getRatesAndInventory"],
    }),
    getInventory: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/getLiveInventory`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getInventory"],
    }),
    getCompsetRateRecords: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/getCompsetRateRecords`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getCompsetRateRecords"],
    }),
    rateRecordForExcel:builder.query({
        query: (data) => {
            return {
              url: `/dashboard/rateRecordForExcel`,
              method: "GET",
              params: data,
            };
          },
          // Replace with your actual endpoint
          providesTags: ["rateRecordForExcel"],
    })
  }),
  overrideExisting: false,
});

export const {
  useGetRatesAndInventoryQuery,
  useGetCompsetRateRecordsQuery,
  useGetInventoryQuery,
  useRateRecordForExcelQuery
} = RatesAndInventory;
