import * as React from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import { AiOutlineDown } from "react-icons/ai";
import { useEffect } from "react";
import { useState } from "react";
import Img from "../../assets/img/empty.png";

const isIndeterminate = (indeterminate) => indeterminate;
const selectableRowsComponentProps = { indeterminate: isIndeterminate };

const Table = ({
  tableTitle,
  columns,
  data,
  titles,
  contextActions,
  handleRowSelected,
  toggleCleared,
  selectableRows,
  Checkbox,
  pending,
  onSelectedRowsChange,
  pagination,
  tableSearchClassName,
  setSidebarOpen,
  filterHeadKey,
  builder,
  highlightOnHover = true,
}) => {
  // const customStyles = {
  //   rows: {
  //     // style: {
  //     //     minHeight: '72px', // override the row height
  //     // },
  //   },
  //   headCells: {
  //     style: {
  //       color: "black",
  //     },
  //   },
  //   cells: {
  //     style: {
  //       // paddingLeft: '8px', // override the cell padding for data cells
  //       // paddingRight: '8px',
  //     },
  //   },
  // };

  const [filteredData, setFilteredData] = React.useState([]);
  const [asc, setAsc] = React.useState(null);
  const [filterInput, setFilterInput] = useState(null);

  const handleSearch = (e) => {
    setFilterInput(e.target.value);
  };

  const [showNoDataMessage, setShowNoDataMessage] = useState(false);

  useEffect(() => {
    if (filterInput === null || filterInput === "") {
      setFilteredData(null);
    } else {
      const filtered = data.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value
              .toString()
              .toLowerCase()
              .includes(filterInput && filterInput.toString().toLowerCase())
        )
      );
      setFilteredData(filtered);
      setShowNoDataMessage(filtered.length === 0);
    }
  }, [filterInput, data]);

  function dynamicSort(arr, isAscending) {
    return arr && arr.slice().sort((a, b) => {
      // Compare the two objects dynamically
      const keysA = Object.keys(a);
      const keysB = Object.keys(b);

      if (isAscending) {
        // Sort in ascending order
        for (let i = 0; i < keysA.length; i++) {
          const key = keysA[i];
          if (a[key] < b[key]) return -1;
          if (a[key] > b[key]) return 1;
        }
      } else {
        // Sort in descending order
        for (let i = keysA.length - 1; i >= 0; i--) {
          const key = keysA[i];
          if (a[key] < b[key]) return 1;
          if (a[key] > b[key]) return -1;
        }
      }

      // If all keys are equal, return 0
      return 0;
    });
  }
  React.useEffect(() => {
    // Sort in ascending order
    const sortedDataAsc = dynamicSort(filteredData, true);

    // Sort in descending order
    const sortedDataDesc = dynamicSort(filteredData, false);

    if (asc) {
      setFilteredData(sortedDataAsc);
    }
    if (!asc) {
      setFilteredData(sortedDataDesc);
    }
  }, [asc,data]);

  const conditionalRowStyles = [
    {
      when: () => true,
      style: {
        backgroundColor: "transparent",
        color: "white",
        fontSize: "14px",
      },
    },
  ];

  const customStyles = {
    table: {
      style: {
        backgroundColor: "transparent", // Transparent table for the gradient background to be applied
      },
    },
    headRow: {
      style: {
        background: "linear-gradient(to right, #4f93e4, #6fb1fc)", // Gradient background for header
        color: "white", // White text for header
      },
    },
    headCells: {
      style: {
        color: "white", // Ensure the text in all header cells is white
      },
    },
    rows: {
      style: {
        backgroundColor: "transparent", // Background for rows
        color: "white", // Ensure the text in all rows is white
      },
      highlightOnHoverStyle: {
        backgroundColor: "#4f93e4", // Optional: background color when hovering over a row
        color: "white", // White text on hover as well
      },
    },
    cells: {
      style: {
        color: "white", // White text for all cells
      },
    },
    pagination: {
      style: {
        backgroundColor: "transparent", // Transparent background for pagination
        color: "white", // White text for pagination
      },
      pageButtonsStyle: {
        backgroundColor: "transparent", // Transparent background for buttons
        color: "white", // White icons in pagination
        fill: "white", // Ensures the SVG icons (arrows) are white
        "&:hover": {
          backgroundColor: "#4f93e4", // Optional: Hover background color for buttons
          color: "white",
        },
      },
    },
  };

  return (
    <>
      <div
        className="App react-data-table relative  rounded-lg bg-newGradient text-white dark:bg-lightGradient"
        style={{ marginTop: "10px" }}
      >
        {showNoDataMessage && (
          <div className="grid justify-center mt-10 text-gray-500">
            <div> No search data found.</div>
            <div>
              <img className="w-24 m-6" src={Img} alt="" />
            </div>
          </div>
        )}

        {!showNoDataMessage && (
          <div className="bg-newGradient rounded-lg overflow-hidden text-lightThemeFont dark:bg-lightGradient">
            <DataTable
              customStyles={customStyles}
              title={titles}
              columns={columns}
              data={
                (filteredData && filteredData.length === 0) ||
                filteredData === null
                  ? data
                  : filteredData
              }
              defaultSortField="title"
              sortIcon={
                <div className="ml-2">
                  <AiOutlineDown />
                </div>
              }
              selectableRows={selectableRows}
              selectableRowsComponent={Checkbox}
              selectableRowsComponentProps={
                selectableRowsComponentProps && selectableRowsComponentProps
              }
              contextActions={contextActions && contextActions}
              clearSelectedRows={toggleCleared && toggleCleared}
              progressPending={pending}
              onSelectedRowsChange={onSelectedRowsChange}
              conditionalRowStyles={conditionalRowStyles}
              highlightOnHover={highlightOnHover}
              striped
              pagination={true}
              responsive
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Table;
