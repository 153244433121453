import React, { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineRight } from "react-icons/ai";
import { IoIosArrowDropdown, IoMdCheckmark, IoMdSearch } from "react-icons/io";
import {
  useAddNewPropertyChannelManagerMutation,
  useAddNewPropertyCompsetMutation,
  useAddNewPropertyOtaMutation,
  useAddPropertyMutation,
  useGetMyPropertyListQuery,
  useGetPropertyTypesQuery,
} from "../../../redux/slices/settings/myProperties";
import { toast } from "react-toastify";
import { FormProvider, useForm } from "react-hook-form";
import InputField from "../../../components/inputFeild";
import ReactStars from "react-stars";
import MapComponent from "../../../components/mapPicker";
import TitleComponent from "../../../components/cardComponent";
import "../../../pages/onboarding/Global.css";
import {
  useAddExtranetCredMutation,
  useChannelManagerListQuery,
  useExtranetCredListQuery,
  useGetOtaLinksMutation,
  useVerifyExtranetCredsMutation,
} from "../../../redux/slices/onBoarding";
import { CurrentProperty, UserId } from "../../../utils/impVars";
import CustomSkeleton from "../../../components/Skeleton";
import PropertyCard from "../../../components/propertyCard";
import Select from "react-select";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import BackdropLoader from "../../../components/newLoader";
import { useLocation } from "react-router-dom";
import { hi } from "date-fns/locale";
import { useUpdateMyPropertyMutation } from "../../../redux/slices/settings/userInterface";
import Business from "../../../assets/icons/onboarding/Business.svg";
import swal from "sweetalert";
import Backdrop from "../../../components/backdrop";

function convertToTitleCase(string) {
  return string.toLowerCase().replace(/(^|\s)\S/g, function (char) {
    return char.toUpperCase();
  });
}
export function removeDuplicateObjects(arr) {
  return arr.filter(
    (item, index, self) =>
      index ===
      self.findIndex(
        (t) => t?.propertyName === item?.propertyName // Customize this condition according to your object structure
      )
  );
}
export function matchOtaIdAndOtaPId(dataFromApi, abc) {
  const result = [];
  dataFromApi.forEach((item) => {
    const otaName = item.otaName;
    if (abc?.hasOwnProperty(otaName)) {
      result.push({
        otaPId: item.otaPId,
        otaId: item.otaId,
      });
    }
  });

  return result;
}

const PropertyManagement = () => {
  const [selectedProp, setSelectedProp] = useState(null);
  const [viewMore, setViewMore] = useState(false);
  const [showAddPropModal, setShowAddPropModal] = useState(false);
  const [propDetails, setPropDetails] = useState(false);
  const [isProgressView, setIsProgressView] = useState(false);
  const [isCompletedView, setIsCompletedView] = useState(false);
  const [isView, setIsView] = useState(false);
  const [propData, setPropData] = useState(null);
  const [statusOfProp, setStatusOfProp] = useState();
  const hId = CurrentProperty();

  const { data } = useGetMyPropertyListQuery({
    userId: UserId(),
    hId: hId,
  });
  const propertyList = data && data?.data?.responseData;
  const CompletedPropertyList =
    data &&
    data?.data?.responseData.filter((item) => {
      return item?.propertyStage?.completionStatus === "completed";
    });
  const inProgressPropertyList =
    data &&
    data?.data?.responseData.filter((item) => {
      return item?.propertyStage?.completionStatus !== "completed";
    });

  return (
    <div className="">
      {!showAddPropModal && !propDetails && !isView && (
        <div className="w-[100%] flex flex-col h-[80vh]">
          <div className="flex w-[100%] justify-between items-center">
            <div className="text-[17px] text-[#ffffff] dark:text-black my-2">
              My Properties
            </div>
            <div className="flex justify-between items-center">
              {/* <div className="bg-[#f6f8fb]  rounded-xl border px-4 flex justify-start items-center w-[30%] h-10">
              <div>
                <IoMdSearch size={28} />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Search by name"
                  className=" h-10 px-2  text text-gray-500 bg-[#f6f8fb] rounded-xl border-r-0 border-l-0 border w-[243px] focus:outline-none"
                />
              </div>
            </div> */}
              {/* <div className="w-[30%] h-10"></div> */}
              <div className="h-10 flex items-center">
                <div
                  className="flex items-center gap-2 cursor-pointer dark:bg-blue-500 text-sm bg-white/30 hover:bg-blue-700 duration-150 transition-all ease-in-out text-white font-medium px-4 py-2 rounded-lg  "
                  onClick={() => setShowAddPropModal(true)}
                >
                  <div>+</div>
                  <div>Create New</div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col overflow-y-auto">
            {/* Start of in progress table */}
            <div
              className={`w-[100%] ${isProgressView ? "h-auto" : "h-[400px]"}  pb-10 relative my-4 rounded-lg bg-newGradient text-white dark:bg-lightGradient`}
            >
              <div className="text-[18px] p-4">
                In Progress (
                {inProgressPropertyList && inProgressPropertyList.length})
              </div>
              <div className="mx-4">
                <div className="w-[100%] text-[14px] flex border-b py-2">
                  <div className="w-[30%]">LISTINGS</div>
                  <div className="w-[50%]">STATUS</div>
                  <div className="w-[20%]"></div>
                </div>

                {propertyList && propertyList?.length > 0 ? (
                  inProgressPropertyList
                    .slice(
                      0,
                      isProgressView ? inProgressPropertyList?.length : 3
                    )
                    .map((prop, index) => {
                      return (
                        <div className="w-[100%] group flex border-b hover:bg-slate-200 transition-all ease-in-out duration-200  cursor-pointer py-4 rounded-md">
                          <div className="w-[30%] flex">
                            <div>
                              <img
                                loading="lazy"
                                className="rounded-md"
                                src={
                                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRn_RuZhZ0dbSV58xTFdWnhX-3xB1INFSAcYg&s"
                                }
                                height={80}
                                width={80}
                              />
                            </div>
                            <div className="mx-2">
                              <div className="text-[14px] font-medium">
                                {prop?.propertyName}
                              </div>
                              <div className="text-[12px]">
                                {prop?.address?.city},{prop?.address?.county}
                              </div>
                            </div>
                          </div>
                          <div className="w-[50%] items-center flex gap-2">
                            <div className="w-[60%] text-sm">
                              {prop?.isActive
                                ? "Your property is successfully listed!"
                                : `Your property needs some revision before it's listed!`}
                            </div>
                            <div className="w-[40%]">
                              <div
                                className={`${prop?.isActive ? "bg-orange-50 text-orange-500 border border-orange-500" : "bg-red-50 text-red-500 border border-red-500"} w-fit h-7 px-2 text-[12px] flex justify-center items-center rounded-md  `}
                              >
                                {prop?.propertyStage?.completionStatus
                                  ? convertToTitleCase(
                                      prop?.propertyStage?.completionStatus
                                        .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
                                        .toUpperCase()
                                    )
                                  : "N/A"}
                              </div>
                            </div>
                          </div>
                          <div className="w-[20%] justify-evenly cursor-pointer flex items-center">
                            <div
                              className="text-[#081439] hover:text-blue-800 transition-all ease-in-out duration-150 text-[16px]"
                              onClick={() => {
                                setStatusOfProp(
                                  prop?.propertyStage?.completionStatus
                                );
                                setPropData(inProgressPropertyList[index]);
                                setPropDetails(true);
                              }}
                            >
                              {prop?.isActive ? "View Property" : "Resolve Now"}
                            </div>
                            <div>
                              <AiOutlineRight color="#081439" />
                            </div>
                          </div>
                        </div>
                      );
                    })
                ) : (
                  <CustomSkeleton width={"100%"} height={300} />
                )}
              </div>
              {propertyList && propertyList.length > 3 && (
                <div
                  className="w-[100%] cursor-pointer bottom-0  absolute py-2 text-center text-[#081439]  font-semibold"
                  onClick={() => setIsProgressView(!isProgressView)}
                >
                  {isProgressView ? "View Less" : "View More"}
                </div>
              )}
            </div>
            {/* End of in progress table */}
            <div
              className={`w-[100%] ${isCompletedView ? "h-auto" : "h-[400px]"} bg-newGradient text-white dark:bg-lightGradient pb-10 relative my-4 rounded-lg`}
            >
              <div className="text-[18px] p-4">
                Completed (
                {CompletedPropertyList && CompletedPropertyList.length})
              </div>
              <div className="mx-4">
                <div className="w-[100%] text-[14px] flex border-b py-2">
                  <div className="w-[30%]">LISTINGS</div>
                  <div className="w-[50%]">STATUS</div>
                  <div className="w-[20%]"></div>
                </div>

                {propertyList && propertyList?.length > 0 ? (
                  CompletedPropertyList.slice(
                    0,
                    isCompletedView ? CompletedPropertyList?.length : 3
                  ).map((prop, index) => {
                    return (
                      <div className="w-[100%] group flex border-b hover:bg-slate-200 transition-all ease-in-out duration-200  cursor-pointer py-4 rounded-md">
                        <div className="w-[30%] flex">
                          <div>
                            <img
                              loading="lazy"
                              className="rounded-md"
                              src={
                                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRn_RuZhZ0dbSV58xTFdWnhX-3xB1INFSAcYg&s"
                              }
                              height={80}
                              width={80}
                            />
                          </div>
                          <div className="mx-2">
                            <div className="text-[14px] font-medium">
                              {prop?.propertyName}
                            </div>
                            <div className="text-[12px]">
                              {prop?.address?.city},{prop?.address?.county}
                            </div>
                          </div>
                        </div>
                        <div className="w-[50%] items-center flex gap-2">
                          <div className="w-[60%] text-sm">
                            {prop?.isActive
                              ? "Your property is successfully listed!"
                              : `Your property needs some revision before it's listed!`}
                          </div>
                          <div className="w-[40%]">
                            <div
                              className={`${prop?.isActive ? "bg-green-50 text-green-500 border border-green-500" : "bg-red-50 text-red-500 border border-red-500"} w-[40%] h-7 px-2 text-[12px] flex justify-center items-center rounded-md  `}
                            >
                              {prop?.isActive ? "Confirm" : "Rejected"}
                            </div>
                          </div>
                        </div>
                        <div className="w-[20%] justify-evenly cursor-pointer flex items-center">
                          <div
                            className="text-[#081439] hover:text-blue-800 transition-all ease-in-out duration-150 text-[16px]"
                            onClick={() => {
                              setPropData(CompletedPropertyList[index]);
                              setIsView(true);
                            }}
                          >
                            {prop?.isActive ? "View Property" : "Resolve Now"}
                          </div>
                          <div>
                            <AiOutlineRight color="#081439" />
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <CustomSkeleton width={"100%"} height={300} />
                )}
              </div>
              {propertyList && propertyList.length > 3 && (
                <div
                  className="w-[100%] cursor-pointer bottom-0  absolute py-2 text-center text-[#081439]  font-semibold"
                  onClick={() => setIsCompletedView(!isCompletedView)}
                >
                  {isCompletedView ? "View Less" : "View More"}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {isView && (
        <PropDetails
          isView={isView}
          propertyData={propData}
          closeView={() => setIsView(false)}
        />
      )}
      {showAddPropModal && !propDetails && (
        <AddProperty
          hideModal={() => setShowAddPropModal(false)}
          showPropDetail={() => setPropDetails(true)}
          status={statusOfProp}
          selectedProp={selectedProp}
          setSelectedProp={setSelectedProp}
        />
      )}
      {propDetails && (
        <PropDetails
          status={statusOfProp}
          setPropDetails={setPropDetails}
          propertyData={propData}
          selectedProp={selectedProp}
        />
      )}
    </div>
  );
};

export default PropertyManagement;

const AddProperty = ({
  hideModal,
  showPropDetail,
  setSelectedProp,
  selectedProp,
}) => {
  const [isHotel, setIsHotel] = useState(true);
  const { data: getPropTypes } = useGetPropertyTypesQuery();
  const getPropData = getPropTypes && getPropTypes?.data;

  const [hotelData, setHotelData] = useState(null);
  const [otherData, setOtherData] = useState(null);
  useEffect(() => {
    if (isHotel && getPropTypes) {
      setOtherData(null);
      setHotelData(getPropData?.[0]?.documents);
    }
    if (!isHotel && getPropTypes) {
      setHotelData(null);
      setOtherData(getPropData?.[1]?.documents);
    }
  }, [isHotel, getPropData]);

  return (
    <div>
      <div className="text-[22px] flex justify-between text-lightFontColor items-center font-medium">
        <div className="text-white dark:text-black">
          {" "}
          Which property type would you like to list?
        </div>
        <div className="flex gap-4 items-center">
          <div
            onClick={
              selectedProp
                ? () => {
                    showPropDetail();
                    hideModal();
                  }
                : () =>
                    toast.error("Select Type of Hotel First!", {
                      position: "bottom-right",
                    })
            }
            className={`text-[16px] px-2 py-1 ${!selectedProp ? "cursor-not-allowed text-blue-500" : "cursor-pointer bg-blue-600 text-white"} border text-sm border-blue-500  hover:bg-blue-600 hover:text-white transition-all ease-in-out duration-150 rounded-lg`}
          >
            List Property
          </div>
          <div
            onClick={hideModal}
            className="text-[16px] px-2 py-1 cursor-pointer bg-red-100 border text-sm border-red-500 text-red-500 hover:bg-red-600 hover:text-red-200 transition-all ease-in-out duration-150 rounded-lg"
          >
            Cancel
          </div>
        </div>
      </div>
      <div className="text-[14px] mt-2 text-gray-400 dark:text-gray-800">
        Please select your property type from below options
      </div>
      <div className="flex gap-4 mt-4">
        <div
          className={`h-[120px] flex flex-col justify-between relative p-4 border border-gray-300 rounded-md ${isHotel ? "bg-newGradient text-white dark:bg-lightGradient" : "bg-white/40"} cursor-pointer shadow-md`}
          onClick={() => setIsHotel(true)}
        >
          <div className="text-[17px] font-medium">Hotel</div>
          <div className=" text-[14px] font-light">
            Accommodations with facilities like dining venues, meeting rooms &
            more
          </div>
          <div
            className={`h-[30px] flex justify-center items-center absolute right-8 top-4  w-[30px] rounded-full ${isHotel ? "bg-newGradient text-white dark:bg-lightGradient" : "bg-white/40"} border `}
          >
            {isHotel && <IoMdCheckmark size={28} color="white" />}
          </div>
          {isHotel && (
            <div
              className="w-0 h-0 absolute -bottom-[16px]
  border-l-[10px] border-l-transparent
  border-t-[15px] border-t-gray-300
  border-r-[10px] border-r-transparent"
            ></div>
          )}
        </div>
        <div
          className={`h-[120px] flex flex-col justify-between relative p-4 border border-gray-300 rounded-md  ${isHotel ? "bg-white/40" : "bg-newGradient text-white dark:bg-lightGradient"} cursor-pointer shadow-md`}
          onClick={() => setIsHotel(false)}
        >
          <div className="text-[17px] font-medium">Homestays & Villas</div>
          <div className="text-[14px] font-light">
            Large independent homes / bungalows for guests that can be rented
            entirely or by room
          </div>
          <div
            className={`h-[30px] flex justify-center items-center absolute right-8 top-4  w-[30px] rounded-full ${!isHotel ? "bg-blue-700 border-blue-700" : "bg-gray-100 border-gray-400"} border `}
          >
            {!isHotel && <IoMdCheckmark size={28} color="white" />}
          </div>
          {!isHotel && (
            <div
              className="w-0 h-0 absolute -bottom-[16px]
  border-l-[10px] border-l-transparent
  border-t-[15px] border-t-gray-300
  border-r-[10px] border-r-transparent"
            ></div>
          )}
        </div>
      </div>
      <div className="mt-5 w-[100%] bg-newGradient text-white dark:bg-lightGradient p-4 rounded-lg">
        <div className="text-[18px] my-4 font-medium">
          Type of {isHotel ? "Hotel" : "Homestays & Villas"}
        </div>
        <div className="h-[45vh]">
          <PropertyData
            data={getPropData}
            isHotel={isHotel}
            selectedProp={selectedProp}
            setSelectedProp={setSelectedProp}
            hotelData={hotelData}
            otherData={otherData}
          />
        </div>
      </div>
    </div>
  );
};

const PropertyData = ({
  data,
  isHotel,
  setSelectedProp,
  selectedProp,
  hotelData,
  otherData,
}) => {
  return (
    <div className="h-[45vh] overflow-hidden">
      <div
        className="grid grid-cols-4 gap-4 w-[100%] scroll-container"
        style={{ overflow: "auto", height: "45vh" }}
      >
        {isHotel
          ? hotelData &&
            data &&
            hotelData.map((item, index) => {
              return (
                <div
                  key={item?.propertyTypeID}
                  className={`p-2 border relative cursor-pointer rounded-md ${item?.propertyTypeID === selectedProp ? "bg-blue-100" : "bg-white"} `}
                  style={{ width: "100%" }}
                  onClick={() => setSelectedProp(item?.propertyTypeID)}
                >
                  <div className="flex justify-between items-center">
                    <div className="text-[16px] text-black dark:text-black">
                      {item?.propertyType}
                    </div>
                    <div>
                      <div
                        className={`h-[20px] flex justify-center items-center absolute right-2 top-2 mt-0.5  w-[20px] rounded-full ${item?.propertyTypeID === selectedProp ? "bg-blue-700 border-blue-700" : "bg-gray-100 border-gray-400"} border `}
                      >
                        {item?.propertyTypeID === selectedProp && (
                          <IoMdCheckmark size={28} color="white" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <img
                      loading="lazy"
                      src={item?.propertyTypeImage}
                      className="rounded-md"
                    />
                  </div>
                  <div className="mt-4 text-[13px] text-gray-600">
                    {item?.propertyTypeDescription}
                  </div>
                </div>
              );
            })
          : data &&
            otherData &&
            otherData.map((item, index) => {
              return (
                <div
                  key={item?.propertyTypeID}
                  className={`p-2 border relative cursor-pointer rounded-md ${item?.propertyTypeID === selectedProp ? "bg-blue-50" : "bg-white"} `}
                  style={{ width: "100%" }}
                  onClick={() => setSelectedProp(item?.propertyTypeID)}
                >
                  <div className="flex justify-between items-center">
                    <div className="text-[16px]">{item?.propertyType}</div>
                    <div>
                      <div
                        className={`h-[20px] flex justify-center items-center absolute right-2 top-2 mt-0.5  w-[20px] rounded-full ${item?.propertyTypeID === selectedProp ? "bg-blue-700 border-blue-700" : "bg-gray-100 border-gray-400"} border `}
                      >
                        {item?.propertyTypeID === selectedProp && (
                          <IoMdCheckmark size={28} color="white" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <img
                      loading="lazy"
                      src={item?.propertyTypeImage}
                      className="rounded-md"
                    />
                  </div>
                  <div className="mt-4 text-[13px] text-gray-600">
                    {item?.propertyTypeDescription}
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export const PropDetails = ({
  isEdit,
  isView,
  propertyData,
  closeEdit,
  closeView,
  status,
  setPropDetails,
  selectedProp,
}) => {
  const [updateMyProperty] = useUpdateMyPropertyMutation();

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Create an empty array to store years
  const yearsArray = [];

  // Loop from 1800 to the current year and push each year to the array
  for (let year = 1800; year <= currentYear; year++) {
    yearsArray.push(year);
  }

  const steps = [
    "Basic Information",
    "Location",
    "OTA's",
    "Compset",
    "Channel Manager",
  ];
  const userId = UserId();
  const propertyId = selectedProp;
  const [addProperty] = useAddPropertyMutation();
  const [addNewPropertyOta] = useAddNewPropertyOtaMutation();
  const [addNewPropertyCompset] = useAddNewPropertyCompsetMutation();
  const [addNewPropertyChannelManager] =
    useAddNewPropertyChannelManagerMutation();
  const [selectedChannelManager, setSelectedChannelManager] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showYear, setShowYear] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [rating, setRating] = useState(null);
  const [lat, setLat] = useState(null);
  const [log, setLog] = useState(null);
  const [cityCode, setCityCode] = useState();
  const [city, setCity] = useState(null);
  const [locState, setLocState] = useState(null);
  const [country, setCountry] = useState(null);
  const [address, setAddress] = useState(null);
  const [compAddress, setCompAddress] = useState();
  const [compCity, setCompCity] = useState();
  const [otaLinkData, setOtaLinkData] = useState();
  const [formData, setFormData] = useState(null);
  const [otaApiData, setOtaApiData] = useState(null);
  const [compsetData, setCompsetData] = useState([]);
  const [compset, setCompset] = useState([]);
  const [CompsetCityCode, setCompsetCityCode] = useState();
  const [state, setState] = useState();
  // const [hId, sethId] = useState(null);
  const hId = CurrentProperty();
  const [newHId, setNewHId] = useState(null);
  const [propertyStage, setPropertyStage] = useState(null);
  const [selectedOTAs, setSelectedOTAs] = useState([]);
  const [selectedCompsetOTAs, setSelectedCompsetOTAs] = useState([]);
  const [hotelName, setHotelName] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const ratingChanged = (newRating) => {
    setRating(newRating);
  };
  const [addExtranetCred] = useAddExtranetCredMutation();
  const { data: extranetCredList } = useExtranetCredListQuery();
  const extranetList = extranetCredList && extranetCredList?.data;

  const [updateBodyData, setUpdateBodyData] = useState();
  const [updateBodyDataState, setUpdateBodyDataState] = useState(false);

  useEffect(() => {
    if (updateBodyData && updateBodyDataState) {
      updateMyProperty(updateBodyData)
        .unwrap()
        .then((res) => {
          setUpdateBodyDataState(false);
          setIsContactDetailEditing(!isContactDetailEditing);
          toast.success("Updated Successfully!", { position: "bottom-right" });
        })
        .catch((err) => {
          setIsLoading(false);
          setUpdateBodyDataState(false);

          toast.error(err?.message, { position: "bottom-right" });
        });
    }
  }, [updateBodyDataState]);

  const methods = useForm({});
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = methods;

  useEffect(() => {
    if (compsetData) {
      const comp = compsetData.map((com) => {
        return {
          propertyName: com?.compsetName,
          activeOta: selectedCompsetOTAs,
          cityCode: cityCode,
        };
      });
      let anyCompset =
        compset && Array.isArray(compset) && compset.length > 0
          ? comp.length === 1
            ? comp
            : compset
          : [];
      anyCompset.push(comp[comp.length - 1]);
      setCompset(anyCompset);

      setSelectedCompsetOTAs([]);
    }
  }, [compsetData]);

  useEffect(() => {
    if (address) {
      setHotelName(address?.split(",")[0]);
    }
  }, [address]);

  useEffect(() => {
    console.log(propertyData, "propData aman 1");
    if (propertyData) {
      console.log(propertyData, "propData aman 2");
      setValue("propertyName", propertyData?.propertyName);
      setValue("totalRooms", propertyData?.totalRooms);
      setValue("email", propertyData?.propertyEmail);
      setValue("mobileNumber", propertyData?.propertyNumber);
      setValue("secondaryMobileNumber", propertyData?.propertySecondaryNumber);
      setRating(propertyData?.hotelStarRating);
      setSelectedYear(propertyData?.propertyBuiltYear);
    }
  }, []);

  const [isOn, setIsOn] = useState(false);
  const toggleSwitch = () => {
    setIsOn(!isOn);
  };

  const onSubmit = async (data) => {
    const otas = otaApiData && matchOtaIdAndOtaPId(otaApiData, data?.ourOta);
    setFormData({
      ...data,
      city: city,
      builtYear: selectedYear,
      rating: rating,
      // ourOta: otas,
    });

    const body = {
      userId: userId,
      propertyTypeID: propertyId,
      propertyName: data?.propertyName,
      totalRooms: data?.totalRooms,
      hotelStarRating: rating,
      propertyBuiltYear: selectedYear,
      propertyEmail: data?.email,
      propertyNumber: data?.mobileNumber,
      secondaryPropertyNumber: data?.secondaryMobileNumber,
    };
    const otaBody = {
      userId: userId,
      hId: hId,
      newhId: newHId,
      cityCode: cityCode,
      address: {
        latitude: lat,
        longitude: log,
        city: city,
        state: state,
        fullAddress: address,
        country: country,
      },
      otaDetails: selectedOTAs,
    };
    const compBody = {
      hId: hId,
      newhId: status ? propertyData?.hId : newHId,
      compsets: removeDuplicateObjects(compset),
    };

    const channelManagerBody = {
      hId: hId,
      newhId: status ? propertyData?.hId : newHId,
      cmId: selectedChannelManager?.value,
      userId: userId,
      userCredential:
        selectedChannelManager?.value === 101
          ? {
              username: data?.userName,
              password: data?.password,
              propertyCode: data?.propertyCode,
            }
          : selectedChannelManager?.value === 108
            ? {
                email: data?.email,
                password: data?.password,
                propertyId: data?.propertyId,
              }
            : selectedChannelManager?.value === 104 ||
                selectedChannelManager?.value === 106 ||
                selectedChannelManager?.value === 107 ||
                selectedChannelManager?.value === 110 ||
                selectedChannelManager?.value === 111 ||
                selectedChannelManager?.value === 108
              ? {
                  email: data?.email,
                  password: data?.password,
                }
              : {
                  username: data?.userName,
                  password: data?.password,
                },
    };
    
    let values,formattedData;
    if(!isOn){
      values = methods.getValues();
      console.log(values,'aman','value')
      formattedData = extranetList.map((extra, index) => ({
        extranetId: extra.extranetId,
        cred: {
          userName: values[`userName_${extra.extranetId}`] || "",
          password: values[`password_${extra.extranetId}`] || "",
        },
        propertyCode: values[`propertyCode_${extra.extranetId}`] || ""
      }));
    }
    const addExtranetCredData = {
      oldHId: hId,
      hId: status ? propertyData?.hId : newHId,
      userId: userId,
      extranetCred: formattedData,
    };
    if (activeIndex === 0) {
      setIsLoading(true);
      addProperty(body)
        .unwrap()
        .then((res) => {
          setIsLoading(false);
          setPropertyStage(res?.details?.propertyStage);
          setNewHId(res?.details?.hId);
          // sethId(res?.details?.hId);
          setActiveIndex(1);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else if (activeIndex === 1) {
      setActiveIndex(2);
    } else if (activeIndex === 2) {
      setIsLoading(true);
      addNewPropertyOta(otaBody)
        .unwrap()
        .then((res) => {
          setIsLoading(false);
          setPropertyStage(res?.details?.propertyStage);
          // sethId(res?.details?.hId);

          setActiveIndex(3);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else if (activeIndex === 3) {
      setIsLoading(true);
      addNewPropertyCompset(compBody)
        .unwrap()
        .then((res) => {
          setPropertyStage(res?.details?.propertyStage);
          setIsLoading(false);
          // sethId(res?.details?.hId);
          setActiveIndex(4);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else if (activeIndex === 4) {
      setIsLoading(true);
      
    if (!isOn) {
      addExtranetCred(addExtranetCredData)
        .unwrap()
        .then((res) => {
          setIsLoading(false);
          toast.success("Property Added Successfully", {
            position: "bottom-right",
          });
          setPropDetails(false);
        })
        .catch((err) => {
          toast.error(err?.data?.message, { position: "bottom-right" });
          setIsLoading(false);
        });
    } else {
      addNewPropertyChannelManager(channelManagerBody)
        .unwrap()
        .then((res) => {
          setIsLoading(false);
          toast.success("Property Added Successfully", {
            position: "bottom-right",
          });
          setPropDetails(false);
        })
        .catch((err) => {
          toast.error(
            err?.data?.message ? err?.data?.message : "Something Went Wrong",
            { position: "bottom-right" }
          );

          setIsLoading(false);
        });
      }
    }
  };
  const handleBack = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };
  

  useEffect(() => {
    if (status && status === "compsetAddition") {
      setActiveIndex(3);
    } else if (status && status === "propertyDetails") {
      setActiveIndex(0);
    } else if (
      (status && status === "compsetAddition") ||
      (status && status === "compsetUpdated")
    ) {
      setActiveIndex(4);
    }
  }, [status]);

  const location = useLocation();
  console.log(hotelName, "hotel Name in prop detail");

  const [isContactDetailEditing, setIsContactDetailEditing] = useState(false);

  function savePropertyDetails(data) {
    console.log(data, "aman data");
    let bodyData = {
      hId: hId,
      mobileNumber: data?.mob,
      secondaryMobileNumber: data?.secMob,
      email: data?.email,
    };
    setUpdateBodyData(bodyData);
    setUpdateBodyDataState(true);
  }

  return (
    <div className={`${isView && "-mt-8"}`}>
      <div className="w-[100%] flex justify-end mb-6">
        {isView && (
          <div
            className=" bg-red-50 outline outline-red-400 text-red-600 w-fit px-4 rounded-md hover:bg-red-200 hover:text-red-700 transition-all ease-in-out duration-150 cursor-pointer"
            onClick={closeView}
          >
            Close
          </div>
        )}
      </div>
      {!isView && (
        <div className="w-[100%] flex justify-end">
          <div
            className={`flex gap-2 relative ${location.pathname.includes("settings") ? "top-0" : "-top-12"} `}
          >
            {activeIndex > 0 && (
              <div
                onClick={status ? () => setPropDetails(false) : handleBack}
                className="text-[14px] font-[500] hover:bg-gray-500 hover:text-white transition-all ease-in-out duration-150 bg-gray-100 border  border-gray-500 text-gray-500 py-1 px-2 cursor-pointer rounded-lg"
              >
                Back
              </div>
            )}
            <button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              className={`text-[14px] font-[500] hover:bg-blue-500 hover:text-white transition-all ease-in-out duration-150 bg-blue-100 border  border-blue-500 text-blue-500 py-1 px-2 cursor-pointer rounded-lg`}
            >
              Save & Continue
            </button>
          </div>
        </div>
      )}

      {!isView && (
        <div className="flex justify-between items-center border-b">
          <div className="flex">
            {steps.map((item, index) => {
              return (
                <div
                  className={`px-4 cursor-pointer flex gap-4 items-center  border py-2 text-[14px] font-[500] 
                ${activeIndex === index ? " border-t-2 border-t-blue-600 bg-[#F6F8FB]" : ""}`}
                  // onClick={() => setActiveIndex(index)}
                >
                  <div
                    className={`border border-black text-[12px] ${activeIndex === index ? "" : "bg-gray-200 border-none"}
                   h-5 w-5 justify-center rounded-full flex items-center`}
                  >
                    {index + 1}
                  </div>
                  <div
                    className={`${activeIndex === index ? "" : " text-white dark:text-black"}`}
                  >
                    {" "}
                    {item}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {activeIndex === 0 && (
        <div className="">
          <form
            className=""
            // onSubmit={handleSubmit(onSubmit)}
          >
            <div
              className="scroll-container text-white"
              style={{ overflow: "auto", height: "65vh" }}
            >
              <div
                className={`bg-newGradient dark:bg-lightGradient rounded-b-md p-4`}
              >
                <div className="text-[24px] mt-4">Basic Information</div>
                {!isView && (
                  <div className="text-[12px] text-gray-400 mt-1">
                    Please fill in details of your property.
                  </div>
                )}

                <div className={`mt-8 w-[100%] p-4 rounded-md  `}>
                  <div className="w-[40%] flex flex-col gap-4">
                    <div className="flex gap-2 flex-col">
                      <div className="text-sm">Property Name</div>
                      <input
                        className={`h-10 rounded-md px-2 outline outline-gray-400 focus:outline focus:outline-blue-500 text-sm ${isView ? "text-white" : "text-gray-500"} `}
                        placeholder="Enter Property Name"
                        {...register("propertyName", { required: true })}
                        disabled={isView}
                      />
                      {errors.propertyName && (
                        <span className="text-red-400 text-xs">
                          This field is required
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2 flex-col">
                      <div className="text-sm ">Property Rating</div>
                      <ReactStars
                        count={5}
                        onChange={ratingChanged}
                        size={20}
                        value={rating}
                        color2={"#f39c12"}
                      />
                    </div>
                    <div className="flex gap-2 flex-col">
                      <div className="text-sm">
                        When was this property built
                      </div>
                      <div
                        onClick={!isView ? () => setShowYear(!showYear) : null}
                        className="h-10 outline  justify-between flex items-center select-none outline-gray-400 relative rounded-md px-2 focus:outline focus:outline-blue-500 bg-white text-sm text-gray-500"
                        placeholder="Enter Property Name"
                      >
                        <div>{selectedYear}</div>{" "}
                        <IoIosArrowDropdown
                          className={`${showYear && "rotate-180"}`}
                          size={20}
                        />
                        {showYear && (
                          <div className="absolute h-[200px] left-0 -bottom-[210px] overflow-scroll w-[100%] rounded-lg">
                            {yearsArray &&
                              yearsArray.reverse().map((item) => {
                                return (
                                  <div
                                    onClick={() => {
                                      setSelectedYear(item);
                                      setShowYear(false);
                                    }}
                                    className={`p-3  hover:bg-gray-200 ${selectedYear === item ? "bg-gray-300" : "bg-gray-50"} text- cursor-pointer border-b`}
                                  >
                                    {item}
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex gap-2 flex-col">
                      <div className="text-sm">Total Rooms</div>
                      <input
                        className={`h-10 rounded-md px-2 outline outline-gray-400 focus:outline focus:outline-blue-500 text-sm ${isView ? "text-white" : "text-gray-500"}`}
                        placeholder="Enter Total Rooms"
                        {...register("totalRooms", { required: true })}
                        disabled={isView}
                      />
                      {errors.totalRooms && (
                        <span className="text-red-400 text-xs">
                          This field is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-newGradient dark:bg-lightGradient rounded-md mt-4 p-4">
                <div className="flex flex-row justify-between">
                  <div className="text-[18px] mb-4">Contact Details</div>
                  {isView ? (
                    isContactDetailEditing ? (
                      <div
                        className="text-white bg-blue-400 px-[2%] rounded-lg flex justify-center items-center"
                        onClick={() => {
                          savePropertyDetails({
                            mob: watch("mobileNumber"),
                            secMob: watch("secondaryMobileNumber"),
                            email: watch("email"),
                          });
                        }}
                      >
                        Save
                      </div>
                    ) : (
                      <div
                        className="text-white bg-blue-400 px-[2%] rounded-lg flex justify-center items-center"
                        onClick={() => {
                          setIsContactDetailEditing(!isContactDetailEditing);
                        }}
                      >
                        Edit
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                </div>
                <div className="w-[100%]  rounded-md p-4">
                  <div className=" w-[40%] flex flex-col gap-4">
                    <div className="flex gap-2 flex-col">
                      <div className="text-sm">Primary Mobile Number</div>
                      <input
                        className={`h-10 rounded-md px-2 outline outline-gray-400 focus:outline focus:outline-blue-500 text-sm ${
                          isView
                            ? !isContactDetailEditing
                              ? "text-white"
                              : "text-gray-500"
                            : "text-gray-500"
                        } `}
                        placeholder="Enter Contact Number"
                        {...register("mobileNumber", { required: true })}
                        disabled={
                          isView
                            ? !isContactDetailEditing
                              ? true
                              : false
                            : false
                        }
                      />
                      {errors.mobileNumber && (
                        <span className="text-red-400 text-xs">
                          This field is required
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2 flex-col">
                      <div className="text-sm">Secondary Mobile Number</div>
                      <input
                        className={`h-10 rounded-md px-2 outline outline-gray-400 focus:outline focus:outline-blue-500 text-sm ${
                          isView
                            ? !isContactDetailEditing
                              ? "text-white"
                              : "text-gray-500"
                            : "text-gray-500"
                        }`}
                        placeholder="Enter Contact Number"
                        {...register("secondaryMobileNumber")}
                        disabled={
                          isView
                            ? !isContactDetailEditing
                              ? true
                              : false
                            : false
                        }
                      />
                    </div>
                    <div className="flex gap-2 flex-col">
                      <div className="text-sm">Email</div>
                      <input
                        className={`h-10 rounded-md px-2 outline outline-gray-400 focus:outline focus:outline-blue-500 text-sm ${
                          isView
                            ? !isContactDetailEditing
                              ? "text-white"
                              : "text-gray-500"
                            : "text-gray-500"
                        }`}
                        placeholder="Enter Email"
                        {...register("email", { required: true })}
                        disabled={
                          isView
                            ? !isContactDetailEditing
                              ? true
                              : false
                            : false
                        }
                      />
                      {errors.email && (
                        <span className="text-red-400 text-xs">
                          This field is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
      {activeIndex === 1 && (
        <>
          <PropertyLocation
            formData={formData}
            lat={lat}
            log={log}
            setLat={setLat}
            setLog={setLog}
            address={address}
            setAddress={setAddress}
            setCity={setCity}
            setLocState={setLocState}
            setCountry={setCountry}
            onSubmit={onSubmit}
            city={city}
            hotelName={hotelName}
            setHotelName={setHotelName}
            country={country}
            setState={setState}
            state={state}
          />
        </>
      )}
      {activeIndex === 2 && (
        <div className="mt-10">
          <AddOtaLink
            city={city}
            address={address}
            formData={formData}
            setData={setOtaLinkData}
            onSubmit={onSubmit}
            showButton={false}
            register={register}
            setValue={setValue}
            setOtaApiData={setOtaApiData}
            watch={watch}
            selectedOTAs={selectedOTAs}
            setSelectedOTAs={setSelectedOTAs}
            setCityCode={setCityCode}
            cityCode={cityCode}
            hotelName={hotelName}
            setHotelName={setHotelName}
          />
        </div>
      )}
      {activeIndex === 3 && (
        <div className="mt-10">
          {" "}
          <CompsetLoaction
            onSubmit={onSubmit}
            register={register}
            formData={formData}
            setCompAddress={setCompAddress}
            compAddress={compAddress}
            setCompCity={setCompCity}
            compCity={compCity}
            setLocState={setLocState}
            setCountry={setCountry}
            watch={watch}
            compsetData={compsetData}
            setCompsetData={setCompsetData}
            selectedOTAs={selectedCompsetOTAs}
            setSelectedOTAs={setSelectedCompsetOTAs}
            setCompsetCityCode={setCompsetCityCode}
            CompsetCityCode={CompsetCityCode}
            hotelName={hotelName}
            setHotelName={setHotelName}
            country={country}
            setState={setState}
            state={state}
          />{" "}
        </div>
      )}
      {activeIndex === 4 && (
        <PropertyChannelManager
          register={register}
          onSubmit={onSubmit}
          watch={watch}
          selectedChannelManager={selectedChannelManager}
          setSelectedChannelManager={setSelectedChannelManager}
          control={control}
          isChannelManager={isOn}
          setIsChannelManager={setIsOn}
          toggleSwitch={toggleSwitch}
          extranetList={extranetList}
          methods={methods}
        />
      )}
      {/* {steps.step3 && <AddOtaLink setData={setManuallyData} setSteps={setSteps} steps={steps} data={data} />}
                            {steps.step4 && <ConfirmProperty setSteps={setSteps} data={manuallydata} steps={steps} selectedOTAs={manuallyselectedOTAs} onSelectedOTAsChange={handleManuallySelectedOTAsChange} onSubmit={onSubmit} />} */}
      <BackdropLoader loading={isLoading} />
    </div>
  );
};

const PropertyLocation = ({
  lat,
  log,
  setLat,
  setLog,
  address,
  setAddress,
  setCity,
  onSubmit,
  formData,
  setLocState,
  setCountry,
  title,
  city,
  compset = false,
  setHotelName,
  hotelName,
  country,
  state,
  setState,
}) => {
  const methods = useForm();

  // useEffect(()=>{
  //   if(city){
  //     methods.setValue('property_address')
  //   }
  // })
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex items-center text-lightFontColor dark:text-darkFontColor justify-center flex-col gap-1 scroll-container">
          <h1 className="  ">
            {title
              ? `Let’s Find Your ${title} Property`
              : "Let’s Find Your Property`"}
          </h1>
          <p className="">
            {title
              ? `Pin your ${title} property in the map`
              : `Pin your property in the map`}
          </p>
        </div>
        <div className="flex flex-col w-full gap-2 p-5 items-center">
          <div className="w-full flex flex-col h-[350px] overflow-x-auto justify-center gap-1">
            <div className="w-full">
              <MapComponent
                apiKey="AIzaSyBRAnfSXzM-fQXpa751GkbMQDEuavUSDP0"
                version="weekly"
                libraries={["places"]}
                lat={lat}
                log={log}
              />
            </div>
            <div className="flex flex-col w-full items-center justify-center gap-0 rounded-lg drop-shadow-3xl bg-white ">
              <div className="w-full rounded-lg h-auto overflow-hidden">
                <InputField
                  type={"Address"}
                  address={address}
                  setAddress={setAddress}
                  id={"Property address"}
                  label={"Property address"}
                  className={"w-full text-[15px]"}
                  control={methods.control}
                  name={"property_address"}
                  placeholder={"Property Address"}
                  rules={{
                    required: "Property Address is required",
                  }}
                  setValue={methods.setValue}
                  // startIcon={Location}
                  setLat={setLat}
                  setLog={setLog}
                  setCity={setCity}
                  country={country}
                  setCountry={setCountry}
                  state={state}
                  setState={setState}
                />
              </div>
            </div>
          </div>
          {address && (
            <div className="  flex flex-col items-center  w-full gap-2 ">
              {/* <div className=" flex items-center text-black text-sm   w-full">
                  <label className="w-[28%]" htmlFor="hotelName">Hotel Name : </label>
                  <input
                    type="text"
                    id="hotelName"
                    value={address}
                    className="ml-1 w-[72%] h-10 border rounded-md pl-3 border-gray-400"
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div> */}
              <div className=" flex items-center text-black text-sm w-full">
                <label
                  htmlFor="hotelName "
                  className="w-[28%] text-white dark:text-black"
                >
                  {" "}
                  Hotel Name :{" "}
                </label>
                <input
                  type="text"
                  id="hotelName"
                  className="ml-2 w-[50%] h-10 border rounded-md pl-3 border-gray-400"
                  value={hotelName}
                  onChange={(e) => setHotelName(e.target.value)}
                />
              </div>
              <div className=" flex items-center text-black text-sm w-full">
                <label
                  htmlFor="city "
                  className="w-[28%] text-white dark:text-black"
                >
                  {" "}
                  City Name :{" "}
                </label>
                <input
                  type="text"
                  id="city"
                  className="ml-2 w-[50%] h-10 border rounded-md pl-3 border-gray-400"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
            </div>
          )}
          {!address && (
            <p className="text-red-500">First find your property address</p>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

const AddOtaLink = ({
  setSteps,
  steps,
  setData,
  showButton = true,
  onSubmit,
  register,
  formData,
  setOtaApiData,
  watch,
  city,
  hotelName,
  setHotelName,
  selectedOTAs,
  setSelectedOTAs,
  address,
  setCityCode,
  cityCode,
}) => {
  useEffect(() => {
    setSelectedOTAs([]);
  }, []);
  const [getOtaLinks] = useGetOtaLinksMutation();
  const [otaLinks, setOtaLinks] = useState();
  const [verifyLink, setVerifyLink] = useState([]);
  const [isVerify, setIsVerify] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm();
  // const onSubmit = (data) => {
  //     setLoading(true);
  //     // const atLeastOneLinkFilled = ['makeMyTrip', 'goibibo', 'expedia', 'agoda'].some((fieldName) => !!data[fieldName]);

  //     // if (!atLeastOneLinkFilled) {
  //     //     setError('makeMyTrip', {
  //     //         type: 'manual',
  //     //         message: 'Minimum 1 OTA is required',
  //     //     });
  //     // } else {
  //     //     alert('Form submitted successfully');
  //     // }

  //     const addNewCompsetData = {

  //         otaDetails :[
  //         { otaId: 1, url: data.makeMyTrip },
  //         { otaId: 2, url: data.goibibo },
  //         { otaId: 3, url: data.booking },
  //         { otaId: 4, url: data.agoda },
  //         { otaId: 5, url: data.clearTrip },
  //         { otaId: 6, url: data.easeMyTrip },
  //         { otaId: 7, url: data.happyEasyGo }
  //         ]
  //     }

  //     gethotelId(addNewCompsetData)
  //         .unwrap()
  //         .then((res) => {
  //             setData(res.data)
  //             setSteps({
  //                 step1: false, step2: false, step3: false, step4: true,
  //             })
  //             toast.success(res.message, { position: "bottom-right" });
  //             setLoading(false);
  //         })
  //         .catch((err) => {
  //             toast.error(err.message, { position: "bottom-right" });
  //             setLoading(false);
  //         });
  // };
  // const navigate = useNavigate();
  const options = [
    { label: "Channel Manager 1", value: "Channel Manager 1" },
    { label: "Channel Manager 2", value: "Channel Manager 2" },
  ];
  useEffect(() => {
    if (formData) {
      setIsLoading(true);
      getOtaLinks({
        hotelName: hotelName,
        hotelCity: formData?.city,
      })
        .unwrap()
        .then((res) => {
          setIsLoading(false);
          setOtaApiData(res?.data);
          setOtaLinks(res?.data);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (otaLinks) {
      const mmtCityCode = otaLinks?.filter((item) => {
        return item?.otaName === "MakeMyTrip";
      });
      if (mmtCityCode) {
        setCityCode(mmtCityCode[0]?.cityCode);
      }
    }
  }, [otaLinks]);

  const checkIsOta = (array, otaId) => {
    return array &&
      array.filter((item) => {
        return item === otaId;
      }).length
      ? true
      : false;
  };
  const checkIsVerify = (array, otaId) => {
    return (
      array &&
      array.filter((item) => {
        return item === otaId;
      })
    );
  };
  const handleRejectOtaVerification = (array, otaId) => {
    const newArray =
      array &&
      array?.filter((item) => {
        return item != otaId;
      });
    setIsVerify(newArray);
  };
  const [finalData, setFinalData] = useState();
  const [removedOptions, setRemovedOptions] = useState([]);
  const [optionss, setOptions] = useState();
  useEffect(() => {
    setOptions(removedOptions.map((item) => ({ value: item, label: item })));
  }, [removedOptions]);
  function filterDataWithLink(data) {
    setFinalData(data?.filter((item) => item.link !== null && !item.hotelCity));
  }
  useEffect(() => {
    filterDataWithLink(otaLinks);
  }, [otaLinks]);

  const [checkedCount, setCheckedCount] = useState(0);

  const handlePropertyCardCheck = (ota) => {
    const updatedSelectedOTAs = selectedOTAs && [...selectedOTAs, ota];

    onSelectedOTAsChange(updatedSelectedOTAs);
    setCheckedCount((prevCount) => prevCount + 1);
  };

  const handlePropertyCardCheck2 = (ota) => {
    onSelectedOTAsChange((selectedOTAs) =>
      selectedOTAs.filter((selectedOta) => selectedOta !== ota)
    ); // Update selected OTAs in parent component
    setCheckedCount((prevCount) => prevCount - 1);
  };
  const onSelectedOTAsChange = (updatedSelectedOTAs) => {
    setSelectedOTAs(updatedSelectedOTAs);
  };

  const handleChange = (val) => {
    const filteredData = otaLinks.filter((item) => item.otaName === val.value);
    setFinalData([...finalData, ...filteredData]);
    setRemovedOptions((prev) => prev.filter((item) => item != val.value));
  };
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="verify-email-content2">
            <div className="flex flex-col">
              <TitleComponent
                title={"Set Your Online Travel Agents dsff"}
                subtitle={"Set your OTA’s for easy monitoring"}
              />
              {optionss?.length > 0 && (
                <Select
                  options={optionss}
                  className={`  border rounded-md focus:outline-none focus:border-blue-500`}
                  onChange={handleChange}
                />
              )}
              {/* <p className='text-[#0D1321]'>Your Property’s OTA Links</p> */}
            </div>
            <div className="flex flex-col w-full gap-4 bg-[#F6F8FB] p-5 rounded-lg">
              <div className="w-full flex flex-row justify-center">
                <div className="flex flex-col w-[90%] px-5 py-4 items-center justify-center gap-0 rounded-lg  bg-white ">
                  {otaLinks &&
                    finalData?.map((ota) => {
                      return (
                        <div key={ota.otaId}>
                          <PropertyCard
                            setFinalData={setFinalData}
                            onCheck2={() => handlePropertyCardCheck2(ota)}
                            onCheck={() => handlePropertyCardCheck(ota)}
                            removedOptions={removedOptions}
                            id={ota.otaId}
                            setRemovedOptions={setRemovedOptions}
                            finalData={finalData}
                            link={ota.link}
                            name={ota.otaName}
                            address={ota?.hotelCity}
                            img={ota.otaImage}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <BackdropLoader loading={isLoading} />
    </>
  );
};

export const AddCompOtaLink = ({
  setSteps,
  steps,
  setData,
  showButton = true,
  onSubmit,
  register,
  formData,
  setCompCity,
  hotelName,
  setHotelName,
  compCity,
  address,
  setOta,
  watch,
  setOtaApiData,
  setSelectedOTAs,
  selectedOTAs,
  setCityCode,
}) => {
  const [getOtaLinks] = useGetOtaLinksMutation();
  const [otaLinks, setOtaLinks] = useState();
  const [verifyLink, setVerifyLink] = useState([]);
  const [isVerify, setIsVerify] = useState([]);
  const [showOta, setShowOta] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm();

  const options = [
    { label: "Channel Manager 1", value: "Channel Manager 1" },
    { label: "Channel Manager 2", value: "Channel Manager 2" },
  ];

  useEffect(() => {
    // if (formData) {
    setIsLoading(true);
    getOtaLinks({
      hotelName: hotelName,
      hotelCity: compCity,
    })
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        setOtaLinks(res?.data);
        setOtaApiData(res?.data);
      })
      .catch((err) => setIsLoading(false));
    // }
  }, [address, compCity]);

  useEffect(() => {
    if (otaLinks) {
      const mmtCityCode = otaLinks?.filter((item) => {
        return item?.otaName === "MakeMyTrip";
      });
      if (mmtCityCode) {
        setCityCode(mmtCityCode[0]?.cityCode);
      }
    }
  }, [otaLinks]);

  const checkIsOta = (array, otaId) => {
    return array &&
      array.filter((item) => {
        return item === otaId;
      }).length
      ? true
      : false;
  };
  const checkIsVerify = (array, otaId) => {
    return (
      array &&
      array.filter((item) => {
        return item === otaId;
      })
    );
  };
  const handleRejectOtaVerification = (array, otaId) => {
    const newArray =
      array &&
      array?.filter((item) => {
        return item != otaId;
      });
    setIsVerify(newArray);
  };
  useEffect(() => {
    if (methods.watch("compOta")) {
      setOta(methods.watch("compOta"));
    }
  });
  const [finalData, setFinalData] = useState();
  const [removedOptions, setRemovedOptions] = useState([]);
  const [optionss, setOptions] = useState();
  useEffect(() => {
    setOptions(removedOptions.map((item) => ({ value: item, label: item })));
  }, [removedOptions]);
  function filterDataWithLink(data) {
    setFinalData(data?.filter((item) => item.link !== null && !item.hotelCity));
  }

  useEffect(() => {
    filterDataWithLink(otaLinks);
  }, [otaLinks]);

  const [checkedCount, setCheckedCount] = useState(0);

  const handlePropertyCardCheck = (ota) => {
    const updatedSelectedOTAs = [...selectedOTAs, ota];

    onSelectedOTAsChange(updatedSelectedOTAs);
    setCheckedCount((prevCount) => prevCount + 1);
  };

  const handlePropertyCardCheck2 = (ota) => {
    onSelectedOTAsChange((selectedOTAs) =>
      selectedOTAs.filter((selectedOta) => selectedOta !== ota)
    ); // Update selected OTAs in parent component
    setCheckedCount((prevCount) => prevCount - 1);
  };
  const onSelectedOTAsChange = (updatedSelectedOTAs) => {
    setSelectedOTAs(updatedSelectedOTAs);
  };
  const handleChange = (val) => {
    const filteredData = otaLinks.filter((item) => item.otaName === val.value);
    setFinalData([...finalData, ...filteredData]);
    setRemovedOptions((prev) => prev.filter((item) => item != val.value));
  };
  return (
    <>
      {
        <FormProvider {...methods}>
          <form onSubmit={() => methods.handleSubmit(onSubmit)}>
            <div className="verify-email-content2">
              <div className="flex flex-col">
                <TitleComponent
                  title={"Set Competitor Online Travel Agents"}
                  subtitle={"Set your competitor OTA’s for easy monitoring"}
                />
                {optionss?.length > 0 && (
                  <Select
                    options={optionss}
                    className={`  border rounded-md focus:outline-none focus:border-blue-500`}
                    onChange={handleChange}
                  />
                )}

                {/* <p className='text-[#0D1321]'>Your Property’s OTA Links</p> */}
              </div>
              <div className="flex flex-col w-full gap-4 bg-[#F6F8FB] p-5 rounded-lg">
                <div className="w-full flex flex-row justify-center">
                  <div className="flex flex-col w-[90%] px-5 py-4 items-center justify-center gap-0 rounded-lg  bg-white ">
                    {otaLinks &&
                      finalData?.map((ota) => {
                        return (
                          <div key={ota.otaId}>
                            <PropertyCard
                              setFinalData={setFinalData}
                              onCheck2={() => handlePropertyCardCheck2(ota)}
                              onCheck={() => handlePropertyCardCheck(ota)}
                              removedOptions={removedOptions}
                              id={ota.otaId}
                              setRemovedOptions={setRemovedOptions}
                              finalData={finalData}
                              link={ota.link}
                              name={ota.otaName}
                              address={ota?.hotelCity}
                              img={ota.otaImage}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      }
      <BackdropLoader loading={isLoading} />
    </>
  );
};

export const CompsetLoaction = ({
  formData,
  onSubmit,
  register,
  setCompAddress,
  setCompCity,
  compCity,
  setLocState,
  setCountry,
  compAddress,
  watch,
  compsetData,
  setCompsetData,
  setOtaApiData,
  selectedOTAs,
  setSelectedOTAs,
  setCompsetCityCode,
  CompsetCityCode,
  country,
  setState,
  state,
}) => {
  const [lat, setLat] = useState();
  const [log, setLog] = useState();
  const [city, setCity] = useState();
  const [addAnother, setAddAnother] = useState();
  const [hotelName, setHotelName] = useState();
  const [showLocation, setShowLocation] = useState(false);
  const methods = useForm();
  const [showOta, setShowOta] = useState(false);
  const [ota, setOta] = useState();
  const handleDeleteCompset = (compIndex) => {
    const newCompsetArr =
      compsetData &&
      compsetData?.filter((item, index) => {
        return index != compIndex;
      });
    setCompsetData(newCompsetArr);
  };
  useEffect(() => {
    if (compAddress) {
      setHotelName(compAddress?.split(",")[0]);
    }
  }, [compAddress]);
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="w-[100%] flex text-white dark:text-black justify-between items-center text-start text-lg">
            <div className="font-medium ">Add Your Compset</div>

            {showOta && (
              <div
                onClick={() => {
                  setCompsetData((prev) => [
                    ...prev,
                    {
                      compsetName: hotelName,
                      compsetCity: compCity,
                      compsetOta: selectedOTAs,
                    },
                  ]);
                  setHotelName();
                  setCompCity();
                  setShowLocation(false);
                  setShowOta(false);
                }}
                className="bg-blue-100 text-blue-600 outline hover:bg-blue-600 hover:text-white outline-blue-600 transition-all ease-in-out duration-150 px-4 text-sm rounded-md py-1 cursor-pointer"
              >
                Finish
              </div>
            )}
            {showLocation && (
              <div
                onClick={() => {
                  setShowLocation(false);
                  setShowOta(true);
                }}
                className="bg-blue-100 text-blue-600 -mt-4 outline hover:bg-blue-600 hover:text-white outline-blue-600 transition-all ease-in-out duration-150 px-4 text-sm rounded-md py-1 cursor-pointer"
              >
                Next
              </div>
            )}
          </div>
          <div className="text-white dark:text-black text-sm my-4 ">
            The hospitality industry's compset includes a diverse array of
            businesses such as hotels, resorts, bed and breakfasts, hostels,
            vacation rentals, restaurants, bars, cafes, event venues, theme
            parks, cruise lines, and tourism companies. These entities compete
            within the market to attract guests and patrons by offering unique
            experiences, exceptional service, and amenities tailored to various
            preferences and budgets.
          </div>
          {!showLocation && !showOta && compsetData?.length > 0
            ? compsetData.map((compset, index) => {
                return (
                  <div className="w-[100%] flex justify-center items-center flex-col">
                    <div className="bg-gray-50 w-[50%] border border-gray-100 hover:bg-blue-50 text-[13px] shadow-sm flex justify-between items-center mt-2 rounded-lg p-4 h-[50px]">
                      <div className="font-medium text-gray-800">
                        {compset?.compsetName}
                      </div>
                      <div
                        onClick={() => handleDeleteCompset(index)}
                        className="cursor-pointer"
                      >
                        <AiOutlineClose size={18} />
                      </div>
                    </div>
                  </div>
                );
              })
            : !showLocation &&
              !showOta && (
                <div className="h-[40vh] flex justify-center flex-col gap-2 items-center">
                  <div className="text-white dark:text-black">
                    Add atleast one compset!
                  </div>
                  <div
                    onClick={() => setShowLocation(true)}
                    className="bg-blue-100 text-blue-600 cursor-pointer transition-all duration-150 ease-in-out text-sm w-fit px-4 py-2 rounded-md hover:bg-blue-600 hover:text-white"
                  >
                    + Add Now
                  </div>
                </div>
              )}
          <div className="w-[100%] flex justify-between flex-col">
            {compsetData &&
              !showOta &&
              !showLocation &&
              compsetData.length > 0 && (
                <div
                  onClick={() => setShowLocation(true)}
                  className="bg-blue-100 mt-4 text-blue-600 cursor-pointer transition-all duration-150 ease-in-out text-sm w-fit px-4 py-2 rounded-md hover:bg-blue-600 hover:text-white"
                >
                  + Add Another compset
                </div>
              )}
          </div>

          <div className="">
            {!showLocation && showOta && (
              <AddCompOtaLink
                address={compAddress}
                onSubmit={onSubmit}
                register={register}
                formData={formData}
                compCity={compCity}
                setAddAnother={setAddAnother}
                setOta={setOta}
                watch={watch}
                hotelName={hotelName}
                setHotelName={setHotelName}
                setOtaApiData={setOtaApiData}
                selectedOTAs={selectedOTAs}
                setSelectedOTAs={setSelectedOTAs}
                setCityCode={setCompsetCityCode}
                CompsetCityCode={CompsetCityCode}
                country={country}
                setCountry={setCountry}
                setState={setState}
                state={state}
              />
            )}
          </div>
          {showLocation && !showOta && (
            <>
              <PropertyLocation
                formData={formData}
                lat={lat}
                log={log}
                setLat={setLat}
                setLog={setLog}
                address={compAddress}
                setAddress={setCompAddress}
                setCity={setCompCity}
                city={compCity}
                setLocState={setLocState}
                setCountry={setCountry}
                title={"Competitor"}
                onSubmit={onSubmit}
                compset={true}
                hotelName={hotelName}
                setHotelName={setHotelName}
                country={country}
                setState={setState}
                state={state}
              />
            </>
          )}
        </form>
      </FormProvider>

      {/* <div className="flex flex-col w-full items-center justify-center gap-0 rounded-lg drop-shadow-3xl bg-white ">
        <div className="w-full rounded-lg overflow-hidden">
          <InputField
            type={"Address"}
            address={address}
            setAddress={setAddress}
            id={"property_address"}
            label={"Property Address"}
            className={"w-full text-[15px]"}
            control={methods.control}
            name={"property_address"}
            placeholder={"Property Address"}
            rules={{
              required: "Property Address is required",
            }}
            setValue={methods.setValue}
            // startIcon={Location}
            setLat={setLat}
            setLog={setLog}
            setCity={setCity}
          />
        </div>
      </div> */}
    </>
  );
};

export const PropertyChannelManager = ({
  register,
  onSubmit,
  watch,
  control,
  selectedChannelManager,
  setSelectedChannelManager,
  isChannelManager,
  setIsChannelManager,
  toggleSwitch,
  extranetList,
  methods
}) => {
  const { data: channelManagerApiData } = useChannelManagerListQuery();
  const [isView, setIsView] = useState(false);
  const [showOption, setShowOptions] = useState(false);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);


  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };
  const channelManagers =
    channelManagerApiData &&
    channelManagerApiData?.data?.map((item) => {
      return {
        label: item?.cmname,
        value: item?.cmid,
      };
    });


  const [verifyExtranetCreds, { isLoading: verifyExtranetCredsIsLoading }] =
    useVerifyExtranetCredsMutation();

    const [selectedExtranetId, setSelectedExtranetId] = useState();
    const [propertyListData, setPropertyListData] = useState();
    const [propertyModal, setPropertyModal] = useState(false);

  const [selectedExtranetPropId, setSelectedExtranetPropId] = useState();

  const verifyCM_Creds = (
    index,
    mappedPassword,
    mappedUsername,
    mappedChannelCode
  ) => {
    const data = methods.getValues();
    const userName = data[mappedUsername];
    const password = data[mappedPassword];
    const channelCode = mappedChannelCode;
    const obj = {
      channelCode: channelCode,
      userName: userName,
      password: password,
    };
    setSelectedExtranetId(mappedChannelCode);
    verifyExtranetCreds(obj)
      .unwrap()
      .then((res) => {
        console.log(res);
        if (res?.code === 404) {
          swal("OOPS!", res?.message, "error");
        }
        if (res?.code === 200) {
          setPropertyListData(res.data);
          setPropertyModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(()=>{
    console.log(propertyListData,'aman propertyListData')
    console.log(methods.getValues(),'aman')
  },[propertyListData])

  return (
    <div >
    
    {propertyModal && (
        <Backdrop color="white">
          <div className="w-[45%] bg-white p-4 rounded-lg">
            <div className="flex justify-between items-center">
              <div>Select Property</div>
              <div
                onClick={() => setPropertyModal(false)}
                className="text-xs text-red-500 bg-red-100 hover:bg-red-500 hover:text-white duration-150 ease-in-out transition-all px-4 py-1 w-fit rounded-lg cursor-pointer"
              >
                Close
              </div>
            </div>

            <div className="mt-6 max-h-[400px] overflow-y-scroll">
              {Array.isArray(propertyListData) &&
                propertyListData?.map((prop) => {
                  return (
                    <div className="text-black flex my-2 items-center justify-between">
                      <div className="text-sm">{prop?.propertyName}</div>
                      <div
                        onClick={() => {
                          methods.setValue(
                            `propertyCode_${selectedExtranetId}`,
                            prop?.propertyCode
                          );
                          setSelectedExtranetPropId(prop?.propertyCode);
                          setPropertyModal(false);
                        }}
                        className="text-xs w-fit bg-blue-50 text-gray-700 px-4 py-1 hover:bg-blue-500 rounded-lg cursor-pointer hover:text-white duration-150 ease-in-out transition-all"
                      >
                        Select
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </Backdrop>
      )}
      
    <div className="mt-8 px-2">
      <div className="text-start">
        <div className="text-xl text-white dark:text-black">
          Seamlessly import your hotel reservation data
        </div>
        <div className="text-xs my-1 text-gray-300 dark:text-gray-800">
          Connect your channel manager for seamless integration.
        </div>
        <div className="text-xs my-1 text-gray-300 dark:text-gray-800">
          Don’t worry your data is safe with us. Read our{" "}
          <b>Terms and Conditions</b>
        </div>
      </div>
      <div className="w-[50%] bg-white rounded-md px-[15px] py-[10px] h-full">
        <div className="w-[100%]">
          <div className="flex items-center justify-between mb-4">
            <div className=""> Are you using a channel manager?</div>
            <div>
              <div className="flex items-center justify-center">
                <button
                  type="button"
                  onClick={toggleSwitch}
                  className={`relative w-14 h-7 ${
                    isChannelManager ? "bg-green-500" : "bg-gray-300"
                  } rounded-full transition-colors duration-300`}
                >
                  <span
                    className={`absolute w-5 h-5 bg-white rounded-full top-1 transition-transform duration-300 transform ${
                      isChannelManager ? "translate-x-1" : "-translate-x-6"
                    }`}
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>

          {isChannelManager ? (
            <FormProvider {...methods}>
              <form
                className="w-[50%] mt-8"
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <div
                  onClick={!isView ? () => setShowOptions(!showOption) : null}
                  className="h-10 outline   justify-between flex items-center select-none outline-gray-400 relative rounded-md px-2 focus:outline focus:outline-blue-500 bg-white text-sm text-gray-500"
                >
                  <div>
                    {selectedChannelManager?.label || "Select Channel Manager"}
                  </div>
                  <IoIosArrowDropdown
                    className={`${showOption && "rotate-180"}`}
                    size={20}
                  />
                  {showOption && (
                    <div className=" h-[200px] absolute -bottom-[210px] z-50 -ml-2 overflow-scroll w-[100%] rounded-lg">
                      {channelManagers &&
                        channelManagers.map((item) => {
                          return (
                            <div
                              onClick={() => {
                                setSelectedChannelManager(item);
                                setShowOptions(false);
                              }}
                              className={`p-3  hover:bg-gray-200 ${selectedChannelManager === item ? "bg-gray-300" : "bg-gray-50"} text- cursor-pointer border-b`}
                            >
                              {item?.label}
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
                {selectedChannelManager?.value === 101 ||
                  (selectedChannelManager?.value === 108 && (
                    <div className="w-[100%] my-4">
                      <input
                        {...register(
                          `${selectedChannelManager?.value === 101 ? "propertyCode" : selectedChannelManager?.value === 108 ? "propertyId" : "propertyCode"}`
                        )}
                        name={`${selectedChannelManager?.value === 101 ? "propertyCode" : selectedChannelManager?.value === 108 ? "propertyId" : "propertyCode"}`}
                        placeholder={`${selectedChannelManager?.value === 101 ? "Enter Property Code" : selectedChannelManager?.value === 108 ? "Enter Property Id" : "Enter Property Code"}`}
                        type="text"
                        className="h-10 outline w-[100%] justify-between flex items-center select-none outline-gray-400 relative rounded-md px-2 focus:outline focus:outline-blue-500 bg-white text-sm text-gray-500"
                      />
                    </div>
                  ))}
                <div className="w-[100%] my-4">
                  <input
                    placeholder={`${selectedChannelManager?.value === 104 || selectedChannelManager?.value === 106 || selectedChannelManager?.value === 107 || selectedChannelManager?.value === 110 || selectedChannelManager?.value === 111 || selectedChannelManager?.value === 108 ? "Enter Email" : "Enter Username"}`}
                    {...register(
                      `${selectedChannelManager?.value === 104 || selectedChannelManager?.value === 106 || selectedChannelManager?.value === 107 || selectedChannelManager?.value === 110 || selectedChannelManager?.value === 111 || selectedChannelManager?.value === 108 ? "email" : "userName"}`
                    )}
                    name={`${selectedChannelManager?.value === 104 || selectedChannelManager?.value === 106 || selectedChannelManager?.value === 107 || selectedChannelManager?.value === 110 || selectedChannelManager?.value === 111 || selectedChannelManager?.value === 108 ? "email" : "userName"}`}
                    className="h-10 outline w-[100%] justify-between flex items-center select-none outline-gray-400 relative rounded-md px-2 focus:outline focus:outline-blue-500 bg-white text-sm text-gray-500"
                  />
                </div>
                <div className="w-[100%]  flex my-4 h-10 outline  justify-between  items-center select-none outline-gray-400 relative rounded-md px-2 focus:outline focus:outline-blue-500 bg-white text-sm text-gray-500">
                  <input
                    placeholder="Enter Password"
                    type={type}
                    {...register("password")}
                    name="password"
                    className="h-10  w-[100%] justify-between flex items-center select-none  relative rounded-md px-2 text-sm focus:outline-none text-gray-500"
                  />
                  <span class="flex justify-end " onClick={handleToggle}>
                    <Icon class="" icon={icon} size={25} />
                  </span>
                </div>
                <div className="w-[20%] my-4"></div>
              </form>
            </FormProvider>
          ) : (
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="max-h-[200px] overflow-y-scroll">
                  {Array.isArray(extranetList) &&
                    extranetList.map((extra, extraIndex) => (
                      <div key={extra.extranetId} className="my-4">
                        <div>{extra?.extranetName}</div>
                        <div className="w-full">
                          <InputField
                            placeholder="Username"
                            className="w-full rounded-t-lg"
                            label="Username"
                            name={`userName_${extra.extranetId}`}
                            control={control}
                            startIcon={Business}
                            type="text"
                          />
                        </div>
                        <div className="w-full mt-2">
                          <InputField
                            placeholder="Password"
                            className="w-full rounded-t-lg"
                            label="Password"
                            name={`password_${extra.extranetId}`}
                            control={control}
                            startIcon={Business}
                            type="password"
                          />
                        </div>
                        <div className="w-[100%] flex justify-end">
                          <div
                            onClick={() => {
                              methods.watch(`propertyCode_${extra.extranetId}`)
                                ? console.log("")
                                : verifyCM_Creds(
                                    extraIndex,
                                    `password_${extra.extranetId}`,
                                    `userName_${extra.extranetId}`,
                                    extra?.extranetId
                                  );
                            }}
                            className={` ${methods.watch(`propertyCode_${extra.extranetId}`) ? "bg-green-100 text-green-500 hover:bg-green-500 hover:text-white" : "bg-blue-100 text-blue-500 hover:bg-blue-500 hover:text-white"}  cursor-pointer  duration-150 transition-all ease-linear w-fit px-4 py-2 rounded-lg text-xs my-2`}
                          >
                            {methods.watch(`propertyCode_${extra.extranetId}`)
                              ? "Verified"
                              : "Verify Now"}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </form>
            </FormProvider>
          )}
        </div>
      </div>
    </div>
  );
};
