import React, { useEffect, useState } from "react";
import "../Global.css";
import SimpleButton from "../../../components/button/SimpleButton";
import Business from "../../../assets/icons/onboarding/Business.svg";
import { FormProvider, useForm } from "react-hook-form";
import InputField from "../../../components/inputFeild";
import { useNavigate } from "react-router-dom";
import TitleComponent from "../../../components/cardComponent";
import {
  useAddCmCredMutation,
  useAddExtranetCredMutation,
  useChannelManagerListQuery,
  useExtranetCredListQuery,
  useVerifyExtranetCredsMutation,
} from "../../../redux/slices/onBoarding";
import { toast } from "react-toastify";
import Select from "react-select";

import Password from "../../../assets/icons/onboarding/Password.svg";
import Email from "../../../assets/icons/onboarding/Email.svg";
import Property from "../../../assets/icons/onboarding/Property.svg";
import Backdrop from "../../../components/backdrop";
import { SyncLoader } from "react-spinners";
import { CurrentProperty, UserId } from "../../../utils/impVars";
import swal from "sweetalert";

const ChannelManagerSetUp = ({ text, setSteps }) => {
  const [formData, setFormData] = useState({
    hId: CurrentProperty(),
    extranetCred: [],
  });
  const [addCmCred] = useAddCmCredMutation();
  const [addExtranetCred] = useAddExtranetCredMutation();
  const { data: channelManagerList } = useChannelManagerListQuery();
  const { data: extranetCredList } = useExtranetCredListQuery();
  const [selectedCmId, setSelectedCmId] = useState(null);
  const [propertyListData, setPropertyListData] = useState();
  const [propertyModal, setPropertyModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(null);
  const methods = useForm({});
  const navigate = useNavigate();
  const userId = UserId();
  const extranetList = extranetCredList && extranetCredList?.data;
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isSubmitting, isValidating },
    setError,
    clearErrors,
  } = methods;
  const [location, onLocationSelected] = useState(null);
  const [options, setOptions] = useState([]);
  const [extraOptions, setExtraOptions] = useState([]);
  const [verifyExtranetCreds, { isLoading: verifyExtranetCredsIsLoading }] =
    useVerifyExtranetCredsMutation();
  const [selectedExtranetPropId, setSelectedExtranetPropId] = useState();
  const [selectedExtranetId, setSelectedExtranetId] = useState();
  const [isOn, setIsOn] = useState(false);
  const toggleSwitch = () => {
    setIsOn(!isOn);
  };

  useEffect(() => {
    if (channelManagerList && channelManagerList.data) {
      const mappedOptions = channelManagerList.data.map((item) => ({
        value: item.cmid,
        label: item.cmname,
      }));

      setOptions(mappedOptions);
    }
  }, [channelManagerList]);

  useEffect(() => {
    if (extranetCredList && extranetCredList.data) {
      const mappedOptions = extranetCredList.data.map((item) => ({
        value: item.extranetName,
        label: item.extranetName,
      }));
      setExtraOptions(mappedOptions);
    }
  }, [extranetCredList]);

  const handleSelectChange = (selectedOption) => {
    setSelectedCmId(selectedOption.value);
    setSelectedChannelManager(selectedOption); // Set the selected cmid
  };
  const handleExtranetSelectChange = (selectedOption) => {
    // setSelectedCmId(selectedOption.value);
    setSelectedExtranet(selectedOption); // Set the selected cmid
  };
  const [selectedChannelManager, setSelectedChannelManager] = useState(null);
  const [selectedExtranet, setSelectedExtranet] = useState(null);
  const compareCmName = (selectedCmName) => {
    return channelManagerList.data.some(
      (channelManager) => channelManager.cmname === selectedCmName
    );
  };
  const channelManagerType = methods.watch("channelManager_type");
  const extranetType = methods.watch("extranet_type");

  useEffect(() => {
    setSelectedChannelManager(channelManagerType?.value);
  }, [channelManagerType]);
  useEffect(() => {
    setSelectedExtranet(extranetType?.value);
  }, [extranetType]);

  const onSubmit = async (data) => {
    const values = methods.getValues();
    const formattedData = extranetList.map((extra, index) => ({
      extranetId: extra.extranetId,
      cred: {
        userName: values[`userName_${extra.extranetId}`] || "",
        password: values[`password_${extra.extranetId}`] || "",
      },
      propertyCode: values[`propertyCode_${extra.extranetId}`] || ""
    }));

    setFormData((prev) => ({
      ...prev,
      extranetCred: formattedData,
    }));

    console.log(formattedData);
    const updatedHId = localStorage.getItem("hId");

    setLoading(true);
    const addCmCredData = {
      hId: updatedHId,
      cmId: selectedCmId,
      userId: userId,
      userCredential: {
        username: data.username,
        email: data.email,
        address: data.address,
        password: data.password,
        propertyCode: data.propertyCode,
        accountType: data.accountType,
      },
    };
    console.log(userId);
    const addExtranetCredData = {
      hId: updatedHId,
      userId: userId,
      extranetCred: formattedData,
    };
    if (!isOn) {
      addExtranetCred(addExtranetCredData)
        .unwrap()
        .then((res) => {
          toast.success("Extranet Added Successfully!", {
            position: "bottom-right",
          });
          setSteps({
            step1: false,
            step2: true,
            step3: false,
            step4: false,
            step5: false,
          });
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err?.data?.message, { position: "bottom-right" });
          setLoading(false);
        });
    } else {
      addCmCred(addCmCredData)
        .unwrap()
        .then((res) => {
          toast.success(res?.message, { position: "bottom-right" });
          setSteps({
            step1: false,
            step2: true,
            step3: false,
            step4: false,
            step5: false,
          });
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err?.data?.message, { position: "bottom-right" });
          setLoading(false);
        });
    }
  };

  const focusedFieldName = watch("focusFieldName");

  useEffect(() => {
    if (focusedFieldName) {
      const fieldError = errors[focusedFieldName];
      if (fieldError) {
        setError(focusedFieldName, {
          type: "manual",
          message: fieldError.message,
        });
      } else {
        clearErrors(focusedFieldName);
      }
    }
  }, [focusedFieldName, errors, setError, clearErrors]);

  useEffect(() => {
    let body = document.body;
    body.style.overflow = "";
  });

  const verifyCM_Creds = (
    index,
    mappedPassword,
    mappedUsername,
    mappedChannelCode
  ) => {
    const data = methods.getValues();
    const userName = data[mappedUsername];
    const password = data[mappedPassword];
    const channelCode = mappedChannelCode;
    const obj = {
      channelCode: channelCode,
      userName: userName,
      password: password,
    };
    setSelectedExtranetId(mappedChannelCode);
    verifyExtranetCreds(obj)
      .unwrap()
      .then((res) => {
        console.log(res);
        if (res?.code === 404) {
          swal("OOPS!", res?.message, "error");
        }
        if (res?.code === 200) {
          setPropertyListData(res.data);
          setPropertyModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(methods.getValues());

  return (
    <>
      {loading && (
        <Backdrop>
          <SyncLoader color="white" />
        </Backdrop>
      )}
      {propertyModal && (
        <Backdrop color="white">
          <div className="w-[45%] bg-white p-4 rounded-lg">
            <div className="flex justify-between items-center">
              <div>Select Property</div>
              <div
                onClick={() => setPropertyModal(false)}
                className="text-xs text-red-500 bg-red-100 hover:bg-red-500 hover:text-white duration-150 ease-in-out transition-all px-4 py-1 w-fit rounded-lg cursor-pointer"
              >
                Close
              </div>
            </div>

            <div className="mt-6 max-h-[400px] overflow-y-scroll">
              {Array.isArray(propertyListData) &&
                propertyListData?.map((prop) => {
                  return (
                    <div className="text-black flex my-2 items-center justify-between">
                      <div className="text-sm">{prop?.propertyName}</div>
                      <div
                        onClick={() => {
                          methods.setValue(
                            `propertyCode_${selectedExtranetId}`,
                            prop?.propertyCode
                          );
                          setSelectedExtranetPropId(prop?.propertyCode);
                          setPropertyModal(false);
                        }}
                        className="text-xs w-fit bg-blue-50 text-gray-700 px-4 py-1 hover:bg-blue-500 rounded-lg cursor-pointer hover:text-white duration-150 ease-in-out transition-all"
                      >
                        Select
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </Backdrop>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="verify-email-content2">
            <div className="verify-email-content2">
              <TitleComponent
                title={"Seamlessly import your reservation data"}
                subtitle={
                  "Connect your channel manager for seamless integration."
                }
                subtitle2={"Don’t worry your data is safe with us. Read our "}
                terms={"Terms and Conditions"}
              />
              <div className="flex flex-col w-full gap-4">
                <div className="w-full flex flex-col justify-center gap-2">
                  <div className="w-full">
                    <div className="flex items-center justify-between mb-4">
                      <div> Are you using a channel manager?</div>
                      <div>
                        <div className="flex items-center justify-center">
                          <button
                            type="button"
                            onClick={toggleSwitch}
                            className={`relative w-14 h-7 ${
                              isOn ? "bg-green-500" : "bg-gray-300"
                            } rounded-full transition-colors duration-300`}
                          >
                            <span
                              className={`absolute w-5 h-5 bg-white rounded-full top-1 transition-transform duration-300 transform ${
                                isOn ? "translate-x-1" : "-translate-x-6"
                              }`}
                            ></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <>
                    {isOn ? (
                      <>
                        <div className="w-full">
                          <Select
                            placeholder={"Select Channel Manager"}
                            label={"channelManagerList"}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                height: "48px",
                                border: "none",
                                borderBottom: state.isFocused
                                  ? "1px solid rgba(51, 97, 255, 1)"
                                  : "none",
                                boxShadow: "0 3px 5px rgb(189 180 180 / 32%)",
                              }),
                            }}
                            id={"channelManagerList"}
                            type={"select"}
                            control={control}
                            name={"channelManagerList"}
                            options={options}
                            value={selectedChannelManager}
                            onChange={handleSelectChange}
                            rules={{ required: "Channel Manager is required" }}
                          />
                        </div>
                        <div className="flex flex-col w-full items-center justify-center gap-0 rounded-lg drop-shadow-3xl bg-white ">
                          {selectedChannelManager?.label === "StayFlexi" ||
                          selectedChannelManager?.label === "RateGain" ||
                          selectedChannelManager?.label === "AxisRooms" ||
                          selectedChannelManager?.label === "Staah" ? (
                            ""
                          ) : (
                            <div className="w-full">
                              <InputField
                                placeholder={"username"}
                                className={"w-full  rounded-t-lg "}
                                label={"Username"}
                                id={"username"}
                                control={methods.control}
                                name={"username"}
                                type={"text"}
                                startIcon={Email}
                              />
                            </div>
                          )}

                          {selectedChannelManager?.label === "eZee" ? (
                            <div className="w-full">
                              <InputField
                                placeholder={"Property Code"}
                                className={"w-full  rounded-t-lg "}
                                label={"Property Code"}
                                id={"propertyCode"}
                                control={methods.control}
                                startIcon={Property}
                                name={"propertyCode"}
                                type={"text"}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {(selectedChannelManager?.label === "StayFlexi" ||
                            selectedChannelManager?.label === "RateGain" ||
                            selectedChannelManager?.label === "AxisRooms" ||
                            selectedChannelManager?.label === "Staah" || selectedChannelManager?.label ==='BookingJini' || selectedChannelManager?.label ==='Maximojo') && (
                            <div className="w-full">
                              <InputField
                                placeholder={"Email"}
                                className={"w-full rounded-t-lg "}
                                label={"Email"}
                                id={"email"}
                                control={methods.control}
                                name={"email"}
                                startIcon={Email}
                                type={"email"}
                                rules={{
                                  required: "Email is required",
                                  pattern: {
                                    value: /^\S+@\S+\.\S+$/,
                                    message:
                                      "Entered value does not match email format",
                                  },
                                }}
                              />
                            </div>
                          )}

                          {selectedChannelManager?.label === "AsiaTech" && (
                            <div className="w-full">
                              <InputField
                                placeholder={"Ac/type"}
                                className={"w-full rounded-t-lg "}
                                label={"AccountType"}
                                id={"accountType"}
                                control={methods.control}
                                startIcon={Business}
                                name={"accountType"}
                                type={"text"}
                                rules={{ required: "AccountType is required" }}
                              />
                            </div>
                          )}
                          {selectedChannelManager?.label === "Staah" && (
                            <div className="w-full">
                              <InputField
                                placeholder={"PropertyId"}
                                className={"w-full rounded-t-lg "}
                                label={"PropertyId"}
                                id={"propertyId"}
                                control={methods.control}
                                startIcon={Business}
                                name={"propertyId"}
                                type={"text"}
                                rules={{ required: "AccountType is required" }}
                              />
                            </div>
                          )}

                          <div className="w-full rounded-b-lg overflow-hidden">
                            <InputField
                              placeholder={"Password"}
                              className={"w-full "}
                              label={"Password"}
                              id={"password"}
                              control={methods.control}
                              name={"password"}
                              type={"password"}
                              startIcon={Password}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* <div className="w-full">
                          <Select
                            placeholder={"Select Extranet"}
                            label={"extranetList"}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                height: "48px",
                                border: "none",
                                borderBottom: state.isFocused
                                  ? "1px solid rgba(51, 97, 255, 1)"
                                  : "none",
                                boxShadow: "0 3px 5px rgb(189 180 180 / 32%)",
                              }),
                            }}
                            id={"extranetList"}
                            type={"select"}
                            control={control}
                            name={"extranetList"}
                            options={extraOptions}
                            value={selectedExtranet}
                            onChange={handleExtranetSelectChange}
                            rules={{ required: "Channel Manager is required" }}
                          />
                        </div> */}

                        {/* filter((items)=>{
                            return items?.extranetName === selectedExtranet?.label
                          }) */}
                        <div className="max-h-[200px] overflow-y-scroll">
                          {Array.isArray(extranetList) &&
                            extranetList.map((extra, extraIndex) => (
                              <div key={extra.extranetId} className="my-4">
                                <div>{extra?.extranetName}</div>
                                <div className="w-full">
                                  <InputField
                                    placeholder="Username"
                                    className="w-full rounded-t-lg"
                                    label="Username"
                                    name={`userName_${extra.extranetId}`}
                                    control={control}
                                    startIcon={Business}
                                    type="text"
                                  />
                                </div>
                                <div className="w-full mt-2">
                                  <InputField
                                    placeholder="Password"
                                    className="w-full rounded-t-lg"
                                    label="Password"
                                    name={`password_${extra.extranetId}`}
                                    control={control}
                                    startIcon={Business}
                                    type="password"
                                  />
                                </div>
                                <div className="w-[100%] flex justify-end">
                                  <div
                                    onClick={() => {
                                      methods.watch(
                                        `propertyCode_${extra.extranetId}`
                                      )
                                        ? console.log('')
                                        : verifyCM_Creds(
                                            extraIndex,
                                            `password_${extra.extranetId}`,
                                            `userName_${extra.extranetId}`,
                                            extra?.extranetId
                                          );
                                    }}
                                    className={` ${methods.watch(`propertyCode_${extra.extranetId}`) ? "bg-green-100 text-green-500 hover:bg-green-500 hover:text-white" : "bg-blue-100 text-blue-500 hover:bg-blue-500 hover:text-white"}  cursor-pointer  duration-150 transition-all ease-linear w-fit px-4 py-2 rounded-lg text-xs my-2`}
                                  >
                                    {methods.watch(
                                      `propertyCode_${extra.extranetId}`
                                    )
                                      ? "Verified"
                                      : "Verify Now"}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </>
                    )}
                  </>
                </div>
                {focusedFieldName && errors[focusedFieldName] && (
                  <span className="error-message">
                    {errors[focusedFieldName].message}
                  </span>
                )}
                <SimpleButton text="Next" type="submit" />
                <div className="flex flex-col w-full gap-4 items-center">
                  <div>
                    <p className="text-sm font-[400] text-[#0D1321]">
                      Your Channel Manager Not Listed?
                      <span className="text-sm font-[400] text-[#3F70ED] pl-2 cursor-pointer">
                        Let Us Know
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default ChannelManagerSetUp;
