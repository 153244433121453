import React from "react";
import SettingButton from "../../../components/settingButton/SettingButton";
import property from "../../../assets/img/property.svg";
import goibibo from "../../../assets/icons/goibibo.svg";
import { FormProvider, useForm } from "react-hook-form";
import InputField from "../../../components/inputFeild";
import remove from "../../../assets/icons/Remove.svg";
import { useGetRoomDetailsQuery } from "../../../redux/slices/rooms";

const EditUserProfile = ({ propertyData, onClose, propertyId }) => {
  const { data: roomDetails,isLoading,isFetching } = useGetRoomDetailsQuery(
    {
      hId: propertyId,
    },
    { refetchOnMountOrArgChange: true }
  );
  const roomOptions =
    roomDetails && roomDetails?.data &&
    roomDetails?.data[0]?.roomDetail.map((room) => {
      return {
        label: room?.roomTypeName,
        value: room?.roomTypeId,
      };
    });
  const myPropertyData =
    Array.isArray(propertyData) &&
    propertyData?.filter((property) => {
      return property?.hId === propertyId;
    });
  return (
    <>
      <div className="flex flex-col gap-4 w-[100%] h-[85vh]">
        <SettingButton
          Title={"Compset Name"}
          text={"Save"}
          onClose={onClose}
          handleBackButtonClick={onClose}
        />

        <div className="h-[80px] flex items-center bg-[#f6f8fb] p-4 rounded-2xl justify-between">
          <div className="flex gap-2 items-center p-1">
            <div>
              <img src={property} alt={""} />
            </div>
            <div className="flex flex-col items-start">
              <p className="font-[600] text-[14px] text-[#131926]">
                {myPropertyData && myPropertyData[0]?.propertyName}
              </p>
              <p className="text-[#0D1321] font-[400] text-[12px]">
                {myPropertyData && myPropertyData[0]?.address?.city}
              </p>
            </div>
          </div>
          <div className="bg-[#eef1f6] rounded-lg h-[28px] flex items-center cursor-pointer text-[#0D1321] text-[12px] font-[500] px-2">
            Remove Compset
          </div>
        </div>
        <div className="flex flex-col gap-4">{/* <OtaLink /> */}</div>
        <div className="flex flex-col gap-4">
          {!isLoading && !isFetching ? <CompsetsRoom roomOptions={roomOptions} /> :"Rooms for this property is loading..."}
        </div>
      </div>
    </>
  );
};

export default EditUserProfile;

// const OtaLink = () => {
//   const methods = useForm({});
//   const onSubmit = () => {};
//   return (
//     <>
//       <FormProvider {...methods}>
//         <form onSubmit={methods.handleSubmit(onSubmit)}>
//           <div className="w-[100%] bg-[#F6F8FB] p-4 flex gap-4 flex-col rounded-2xl">
//             <p className="text-[#8794AD] text-[14px] font-[500]">OTA Links</p>
//             <div className=" flex flex-col gap-4">
//               <div className="flex gap-4 w-[100%]">
//                 <div className="rounded-lg overflow-hidden w-[50%]">
//                   <InputField
//                     type={"text"}
//                     className={"text-[#0D1321] w-full bg-[#FBFCFD] h-9"}
//                     startIcon={goibibo}
//                     label={false}
//                     placeholder={"Hotelemail@email.com"}
//                     id={"goibibo"}
//                     name={"goibibo"}
//                     controls={methods.control}
//                   />
//                 </div>
//                 <div className="cursor-pointer">
//                   <img src={remove} alt="" />
//                 </div>
//               </div>
//               <div className="flex gap-4 w-[100%]">
//                 <div className="rounded-lg overflow-hidden w-[50%]">
//                   <InputField
//                     type={"text"}
//                     className={"text-[#0D1321] w-full bg-[#FBFCFD] h-9"}
//                     startIcon={goibibo}
//                     label={false}
//                     placeholder={"Hotelemail@email.com"}
//                     id={"goibibo"}
//                     name={"goibibo"}
//                     controls={methods.control}
//                   />
//                 </div>
//                 <div className="cursor-pointer">
//                   <img src={remove} alt="" />
//                 </div>
//               </div>
//               <div className="flex gap-4 w-[100%]">
//                 <div className="rounded-lg overflow-hidden w-[50%]">
//                   <InputField
//                     type={"text"}
//                     className={"text-[#0D1321] w-full bg-[#FBFCFD] h-9"}
//                     startIcon={goibibo}
//                     label={false}
//                     placeholder={"Hotelemail@email.com"}
//                     id={"goibibo"}
//                     name={"goibibo"}
//                     controls={methods.control}
//                   />
//                 </div>
//                 <div className="cursor-pointer">
//                   <img src={remove} alt="" />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </form>
//       </FormProvider>
//     </>
//   );
// };

const CompsetsRoom = ({ roomOptions }) => {
  const methods = useForm({});
  const onSubmit = () => {};
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="w-[100%] bg-[#F6F8FB] p-4 flex gap-4 rounded-2xl">
            <div className="flex flex-col gap-4 w-[100%]">
              <p className="text-[#8794AD] text-[14px] font-[500]">
                Compsets Rooms
              </p>
              <div className=" flex flex-col gap-4">
                <div className="rounded-lg overflow-hidden">
                  <InputField
                    type={"text"}
                    className={"text-[#0D1321] w-full bg-[#FBFCFD] h-9"}
                    startIcon={goibibo}
                    label={false}
                    placeholder={"Hotelemail@email.com"}
                    id={"goibibo"}
                    name={"goibibo"}
                    controls={methods.control}
                  />
                </div>
                <div className="rounded-lg overflow-hidden">
                  <InputField
                    type={"text"}
                    className={"text-[#0D1321] w-full bg-[#FBFCFD] h-9"}
                    startIcon={goibibo}
                    label={false}
                    placeholder={"Hotelemail@email.com"}
                    id={"goibibo"}
                    name={"goibibo"}
                    controls={methods.control}
                  />
                </div>
                <div className="rounded-lg overflow-hidden">
                  <InputField
                    type={"text"}
                    className={"text-[#0D1321] w-full bg-[#FBFCFD] h-9"}
                    startIcon={goibibo}
                    label={false}
                    placeholder={"Hotelemail@email.com"}
                    id={"goibibo"}
                    name={"goibibo"}
                    controls={methods.control}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4 w-[100%]">
              <p className="text-[#8794AD] text-[14px] font-[500]">
                Mapped To My Rooms
              </p>
              <div className=" flex flex-col gap-4">
                <div className="rounded-lg overflow-hidden">
                  <InputField
                    type={"select"}
                    className={"text-[#0D1321] w-full bg-[#FBFCFD] h-9"}
                    startIcon={goibibo}
                    options={roomOptions}
                    label={false}
                    placeholder={"Hotelemail@email.com"}
                    id={"goibibo"}
                    name={"goibibo"}
                    controls={methods.control}
                  />
                </div>
                <div className="rounded-lg overflow-hidden">
                  <InputField
                    type={"select"}
                    className={"text-[#0D1321] w-full bg-[#FBFCFD] h-9"}
                    startIcon={goibibo}
                    label={false}
                    placeholder={"Hotelemail@email.com"}
                    id={"goibibo"}
                    name={"goibibo"}
                    controls={methods.control}
                  />
                </div>
                <div className="rounded-lg overflow-hidden">
                  <InputField
                    type={"select"}
                    className={"text-[#0D1321] w-full bg-[#FBFCFD] h-9"}
                    startIcon={goibibo}
                    label={false}
                    placeholder={"Hotelemail@email.com"}
                    id={"goibibo"}
                    name={"goibibo"}
                    controls={methods.control}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
