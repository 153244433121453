import ExcelJS from "exceljs";
import { logoUrl } from "./retvensLogo.js";
import { formatDate } from "./formatDate.js";

export const DownloadDynamicRateChangeReport = async (temp) => {
  try{
    

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Data");

  const logoBase64 = logoUrl;
  // Add image at B1
  const logoId = workbook.addImage({
    base64: logoBase64,
    extension: "jpg",
  });
  worksheet.mergeCells("A1:F3");
  worksheet.addImage(logoId, "A1:A3");
  const columns = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "K",
    "M",
    "N",
    "O",
    "P",
  ];
  const columnWidths = [
    20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20,
  ];
  columns.forEach((column, index) => {
    worksheet.getColumn(column).width = columnWidths[index];
  });
  const propertyName = window.localStorage.getItem("propertyName");

  // Display today's date at J1
  const dateCell = worksheet.getCell("F1");
  dateCell.value = new Date();
  dateCell.numFmt = "dd mmmm yyyy";
  dateCell.alignment = { vertical: "middle" };
  // Move main heading to A2 to G2 and increase row height
  const mainHeading = worksheet.getCell("A4");
  mainHeading.value = `Month End Report - ${propertyName} (${formatDate(temp.asOn?.startDate)} - ${formatDate(temp.asOn?.endDate)})`;
  mainHeading.alignment = { horizontal: "center", vertical: "middle" };
  mainHeading.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "B7D166" }, // Yellow color
  };
  worksheet.mergeCells("A4", "F4");
  worksheet.getRow(4).height = 40; // Increase row height
  worksheet.mergeCells("A5", "F5");
  

  worksheet.addRow(temp?.excel?.header).eachCell((cell) => {
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "B7D166" },
    };
    cell.border = {
      top: { style: "thin", color: { argb: "ffffff" } },
    };
    cell.alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };})

    temp?.excel?.body.forEach((source, index) => {
        const row = worksheet.addRow(source);
        const fourthColumnCell = row.getCell(5); // Access the fourth column cell
    
        // Check if the fourth column value is a valid URL (optional)
        fourthColumnCell.value = {
          text: source[4] === 0 || source[4] === "0" || !source[4] ? "N/A" : source[4], // Display the original URL text
          hyperlink: "https://ratex.retvenslabs.com/signup", // Set the hyperlink to the URL itself
        };
    
        // Apply formatting (optional)
        if (source[0] === "Sold out") {
          fourthColumnCell.font = { color: { argb: "0000FF" } }; // Blue font for "Sold out"
        }
    
        if (index % 2 !== 0) {
          row.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ECEFCE" },
          }; // Grey background for odd rows
        }
    
        worksheet.eachRow((cell, rowNumber, columnNumber) => {
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          };
        });
      });
      worksheet.addRow([]);


  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  
  // Create a URL for the blob
  const url = window.URL.createObjectURL(blob);
  
  // Create an anchor element and trigger a download
  const a = document.createElement("a");
  a.href = url;
  a.download = `Dynamic Rate Change Report ${temp?.hotelName } .xlsx`; // Set the desired filename here
  a.click();
  
  // Clean up
  window.URL.revokeObjectURL(url);
}
catch(err){
  alert("No enough data to download")
}
};
