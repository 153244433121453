import React, { useEffect, useRef, useState } from "react";
import { CurrentProperty, UserId } from "../../../utils/impVars";
import { useGetInventoryQuery } from "../../../redux/slices/ratesInventory";
import { addDays, format } from "date-fns";
import { Tooltip } from "react-tooltip";
import { AiOutlineWhatsApp } from "react-icons/ai";
import NoDataFound from "../../../components/noData";
import Calendar from "../../../components/calendar";
import CustomSkeleton from "../../../components/Skeleton";
import whatsappBusiness from "../../../assets/img/whatsapp-business-icon.svg";
import { DownloadOccupencyReport } from "../DownloadExcel/OccupencyReport";

const OccupencyReport = ({
  getInventory,
  selectedDate,
  setSelectedDate,
  isLoading,
  setIsWhatsApp,
  sendToWhatsappAdmin,
}) => {
  //   const [selectedDate, setSelectedDate] = useState(new Date());

  const roomsData = {};
  const totals = [];
  const occupancies = [];
  function getNext9Days(selectedDate) {
    let result = [];
    let startDate = new Date(selectedDate);
    for (let i = 0; i < 9; i++) {
      let nextDay = addDays(startDate, i);
      let day = format(nextDay, "EEEE"); // 'EEEE' gives the full name of the day
      result.push({ date: format(nextDay, "yyyy-MM-dd"), day: day });
    }

    return result;
  }

  // Organize data by roomName and gather totals and occupancies
  getInventory?.data?.forEach((item) => {
    item.rooms.forEach((room) => {
      if (!roomsData[room.roomName]) {
        roomsData[room.roomName] = [];
      }
      roomsData[room.roomName].push({
        date: item.date,
        availableRooms: room.availableRooms,
      });
    });
    totals.push({ date: item.date, total: item.totalAvailableRooms });
    occupancies.push({ date: item.date, occupancy: item.occupancy });
  });
  const [isDropdownOpen,setIsDropdownOpen]=useState(false)
  const dropdownRef = useRef(null);
  
  useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsDropdownOpen(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
  
      // Cleanup the event listener on component unmount
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [dropdownRef]);
  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center justify-center p-2 font-semibold">
          <p>Occupancy Report </p>
        </div>
        <div className="m-3 w-full flex justify-end items-end">
          <div className="w-fit"></div>
          <h1
            onClick={() => {}}
            className="flex  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            Download
          </h1>
          <div
            onClick={""}
            className="flex  w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            <AiOutlineWhatsApp size={18} /> Send to WhatsApp
          </div>
        </div>
        <div className="w-[100%]">
          <CustomSkeleton
          isTransparent={true}
            animated={true}
            height={500}
            title={"Occupancy Report"}
          />
        </div>
      </div>
    );
  }
  const propertyName = window.localStorage.getItem("propertyName");
  return (
    <div className="px-4 overflow-y-auto h-auto">
      <div className="flex flex-col items-center justify-center">
        <div className="flex text-white dark:text-black  items-center justify-center p-2 font-semibold">
          <p>Occupancy Report - {propertyName} </p>
        </div>
        <div className="m-3 w-full flex justify-end items-end">
          <div className="w-fit"></div>
          <h1
            onClick={() => {
              DownloadOccupencyReport(getInventory, selectedDate);
            }}
            className="flex  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            Download
          </h1>
          <div ref={dropdownRef} className="relative">
      {/* Main Button */}
      <div
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="flex m-auto w-[180px] gap-2 justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-xs text-white  font-medium text-[17px]"
      >
        <AiOutlineWhatsApp size={18} /> Send via WhatsApp
      </div>

     
        {isDropdownOpen && (
        <div className="absolute mt-2 w-[180px] text-white text-xs px-4 rounded-lg  shadow-lg dark:bg-[#1D3893] bg-[#3ec329]">
          <div
            onClick={() => {
              setIsDropdownOpen(false);
              setIsWhatsApp(); // Handle Send to Self
            }}
            className="flex gap-2 justify-start items-center p-2 cursor-pointer"
          >
            <AiOutlineWhatsApp size={18} /> Send to self
          </div>
          <div
            onClick={() => {
              setIsDropdownOpen(false);
              sendToWhatsappAdmin();
              // Handle Send to Personal Number
            }}
            className="flex gap-2 justify-start items-center p-2  cursor-pointer"
          >
            <AiOutlineWhatsApp size={18} /> Send to secondary 
          </div>
        </div>
      )}
    </div>
          {/* <div
            onClick={sendToWhatsappAdmin}
            className="flex  w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            <img src={whatsappBusiness} height={20} width={20} alt="" /> Send to
            Group
          </div> */}
        </div>
        <div className="h-full flex flex-col items-center  rounded-lg justify-center bg-newGradient text-white dark:bg-lightGradient w-[100%]">
          <div className="flex w-full rounded-lg bg-blue-800  py-2  top-0 justify-between items-center">
            <div className="w-[250px] px-2 ">
              <Calendar
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                // backgroundNone={true}
                // width={250}
                // right={"right-2"}
                // showIconBackground={true}
                enableDaysFromNow={45}
              />
            </div>
          

          
            {getNext9Days(selectedDate).map((date, index) => {
              return (
                <div className="px-6  " key={index}>
                  <div className="flex font-[500] justify-center text-[13px]">
                    <div>{date.day.slice(0, 3)},&nbsp;</div>
                    <div>{date.date.split("-")[2]}</div>
                  </div>
                  <div className=" text-[11px]">
                    {format(new Date(date.date), "MMM")},&nbsp;
                    {format(new Date(date.date), "yyyy")}
                  </div>
                </div>
              );
            })}
           
          </div>

          <div className="flex w-full flex-col gap-2">
            {Object.entries(roomsData).map(
              ([roomName, availability], index) => (
                <div className="flex  flex-col gap-2" key={index}>
                  <div className=" rounded-xl m-2">
                    <div className="accordion-item cursor-pointer w-[100%] flex items-start flex-col rounded-xl ">
                      <div className="w-[100%] flex justify-between items-center">
                        <div>
                          <div className="accordion-title font-medium h-[50px]  text-[15px] w-[250px] flex justify-between items-center">
                            <div
                              className="w-[200px] truncate px-6"
                              data-tooltip-id={`tooltip-${index}`}
                              data-tooltip-content={roomName}
                            >
                              {roomName}
                            </div>
                            <Tooltip id={`tooltip-${index}`} />
                          </div>
                        </div>

                        {availability.slice(0, 9).map((availabilityItem, i) => (
                          <div
                            key={i}
                            className="text-xs flex items-center justify-center px-6 text-center ml-2 w-[6%]"
                          >
                            {availabilityItem.availableRooms}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}

            {/* Totals Row */}
            <div className="flex flex-col gap-2">
              <div className=" rounded-xl m-2">
                <div className="accordion-item cursor-pointer w-[100%] flex items-start flex-col rounded-xl ">
                  <div className="w-[100%] flex justify-between items-center">
                    <div className="accordion-title font-medium h-[50px] text-[15px] w-[250px] flex justify-between items-center">
                      <div className="w-[200px] truncate font-semibold px-6">Total</div>
                    </div>
                    {totals.slice(0, 9).map((totalItem, i) => (
                      <div
                        key={i}
                        className="text-xs font-semibold flex items-center justify-center px-6 text-center ml-2 w-[6%]"
                      >
                        {totalItem.total}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Occupancy Row */}
            <div className="flex flex-col gap-2">
              <div className=" rounded-xl m-2">
                <div className="accordion-item cursor-pointer w-[100%] flex items-start flex-col rounded-xl ">
                  <div className="w-[100%] flex justify-between items-center">
                    <div className="accordion-title font-medium h-[50px]  text-[15px] w-[250px] flex justify-between items-center">
                      <div className="w-[200px] font-semibold truncate px-6">Occupancy</div>
                    </div>
                    {occupancies?.slice(0, 9).map((occupancyItem, i) => (
                      <div
                        key={i}
                        className="text-xs flex items-center font-semibold justify-center px-6 text-center ml-2 w-[6%]"
                      >
                        {occupancyItem.occupancy}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OccupencyReport;
