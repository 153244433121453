import { emptySplitApi } from "../../injectEndpoints";

export const Reports = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomReport: builder.query({
      query: (data) => ({
        url: `/reports/getCustomReport`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getCustomReport"],
    }),
    getAllCustomReport: builder.query({
      query: (data) => ({
        url: `/reports/getAllCustomReport`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getAllCustomReport"],
    }),
    addCustomReport: builder.mutation({
      query: (data) => ({
        url: `/reports/addCustomReport`,
        method: "POST",
        body: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["addCustomReport"],
    }),
    editCustomReport: builder.mutation({
      query: (data) => ({
        url: `/reports/editCustomReport`,
        method: "PATCH",
        body: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["editCustomReport"],
    }),
    deleteCustomReport: builder.mutation({
      query: (data) => ({
        url: `/reports/deleteCustomReport`,
        method: "PATCH",
        body: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["deleteCustomReport"],
    }),
    getTemplateReport: builder.query({
      query: (data) => ({
        url: `/reports/getTemplateReports`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getTemplateReport"],
    }),
    getRevenueReport: builder.query({
      query: (data) => ({
        url: `/reports/getRevenueReport`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getRevenueReport"],
    }),
    hotelProductivityReport: builder.query({
      query: (data) => ({
        url: `/reports/hotelProductivityReport`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["hotelProductivityReport"],
    }),
    getCompsetReport: builder.query({
      query: (data) => ({
        url: `/reports/getCompsetReport`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getCompsetReport"],
    }),
    getMonthEndReport: builder.query({
      query: (data) => ({
        url: `/reports/getMonthEndReport`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getMonthEndReport"],
    }),
    dynamicRateChangeReport: builder.query({
      query: (data) => ({
        url: `/reports/dynamicRateChangeReport`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["dynamicRateChangeReport"],
    }),
    getPaceReport: builder.query({
      query: (data) => ({
        url: `/reports/getPaceReport`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getPaceReport"],
    }),
    getActualRateChangeReport: builder.query({
      query: (data) => ({
        url: `/reports/getActualRateChangeReport`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getActualRateChangeReport"],
    }),
    arcrIdMetricsData: builder.query({
      query: (data) => ({
        url: `/reports/arcrIdMetricsData`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["arcrIdMetricsData"],
    }),
    arcrEdReputationRankReviewData: builder.query({
      query: (data) => ({
        url: `/reports/arcrEdReputationRankReviewData`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["arcrEdReputationRankReviewData"],
    }),
    arcrIdPaceData: builder.query({
      query: (data) => ({
        url: `/reports/arcrIdPaceData`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["arcrIdPaceData"],
    }),
    arcrEdRateData: builder.query({
      query: (data) => ({
        url: `/reports/arcrEdRateData`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["arcrEdRateData"],
    }),
    arcrEdRateFluctuationData: builder.query({
      query: (data) => ({
        url: `/reports/arcrEdRateFluctuationData`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["arcrEdRateFluctuationData"],
    }),
    getQuarterlyReport: builder.query({
      query: (data) => ({
        url: `/reports/getQuarterlyReport`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getQuarterlyReport"],
    }),
    getParityReport: builder.query({
      query: (data) => ({
        url: `/reports/getParityReport`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getParityReport"],
    }),
    getSourceReport: builder.query({
      query: (data) => ({
        url: `/reports/getSourceReport`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getSourceReport"],
    }),
    getSegmentReport: builder.query({
      query: (data) => ({
        url: `/reports/getSegmentReport`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getSegmentReport"],
    }),
    getPickUpReport: builder.query({
      query: (data) => ({
        url: `/reports/getPickupReport`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getPickUpReport"],
    }),
    getYearEndReport: builder.query({
      query: (data) => ({
        url: `/reports/getYearEndReport`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getYearEndReport"],
    }),
    getRatesReport: builder.query({
      query: (data) => ({
        url: `/reports/getRatesReport`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getRatesReport"],
    }),
    getRateChangeReport: builder.query({
      query: (data) => ({
        url: `/reports/getRateChangeReport`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getRateChangeReport"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCustomReportQuery,
  useGetAllCustomReportQuery,
  useAddCustomReportMutation,
  useEditCustomReportMutation,
  useGetTemplateReportQuery,
  useDeleteCustomReportMutation,
  useGetRevenueReportQuery,
  useGetMonthEndReportQuery,
  useGetPaceReportQuery,
  useGetParityReportQuery,
  useGetQuarterlyReportQuery,
  useGetSegmentReportQuery,
  useGetSourceReportQuery,
  useGetPickUpReportQuery,
  useGetYearEndReportQuery,
  useGetRatesReportQuery,
  useGetRateChangeReportQuery,
  useGetActualRateChangeReportQuery,
  useArcrIdMetricsDataQuery,
  useArcrEdReputationRankReviewDataQuery,
  useArcrIdPaceDataQuery,
  useArcrEdRateDataQuery,
  useArcrEdRateFluctuationDataQuery,
  useDynamicRateChangeReportQuery,
  useGetCompsetReportQuery,
  useHotelProductivityReportQuery
} = Reports;
