import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const PropertyName = () => {
    const [propertyName, setPropertyName] = useState('');
    const reduxPropName = useSelector((state)=>state?.loginData?.currentProperty?.propertyName)
    useEffect(() => {
        const propList = window.localStorage.getItem('propertiesList');
        if (propList) {
            const parsedPropList = JSON.parse(propList);
            if (parsedPropList.length > 0) {
                // setPropertyName(reduxPropName ? reduxPropName : parsedPropList[0].propertyName);
                setPropertyName(parsedPropList[0].propertyName || reduxPropName);
            }
        }
    }, [reduxPropName]);

    return propertyName || ''
}

export default PropertyName;
