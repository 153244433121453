import { useEffect, useState } from "react";
import { CurrentProperty } from "./impVars";
import { BASE_URL } from "../redux/injectEndpoints";

const OtaOptions = () => {
  const [ota, setOta] = useState([]);
  const gId = CurrentProperty();
  useEffect(() => {
    const fetchData = async () => {
      try {
          const response = await fetch(
            `${BASE_URL}utils/getSourcesSelectors?hId=${gId}`
          );
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          const otaData = await response.json();
          if (otaData && Array.isArray(otaData.data.sources)) {
            const mappedOta = otaData.data.sources.map((item) => ({
              value: item,
              label: item,
            }));
            setOta(mappedOta);
          }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return ota;
};

export default OtaOptions;

export const ActiveOtaOptions = () => {
  const [ota, setOta] = useState([]);

  useEffect(() => {
    const localOtas = JSON.parse(localStorage.getItem("activeOta"));
    if (localOtas) {
      const arr = localOtas.map((ota) => ({
        label: ota.otaName,
        value: ota.otaId,
      }));
      // Add the "All" option to the beginning of the array
      setOta([{ label: "All", value: "All" }, ...arr]);
    }
  }, []);

  return ota;
};
