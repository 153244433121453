import React, { useEffect, useState } from "react";
import DynamicTable from "../dynamicTable";
import Calender from "./calender";
import Dropdown from "../dropdown";
import "../../pages/onboarding/Global.css";
import { CurrentProperty } from "../../utils/impVars";
import { format } from "date-fns";
import NewParityCalenderTable from "./NewParityCalenderTable";
import formatCurrency from "../../utils/formatCurrency";
import {
  AiFillCalendar,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { BsCalendar2Date } from "react-icons/bs";
import CheckBox from "../checkbox/CheckBox";
import { useIsIpad } from "../../utils/isIpad";

function convertJsonToFormatOTA(data) {
  try {
    if (!Array.isArray(data)) {
      throw new Error("Input data should be an array.");
    }

    const result = {};

    data.forEach((item) => {
      const { roomName, otaName, price } = item;

      if (!roomName || !otaName || !price) {
        throw new Error("Invalid data structure.");
      }

      if (!result[roomName]) {
        result[roomName] = {};
      }

      result[roomName][otaName] = price.toString();
    });

    const formattedResult = Object.entries(result).map(([name, prices]) => {
      const item = { name };
      Object.entries(prices).forEach(([otaName, price]) => {
        item[otaName] = price;
      });
      return item;
    });

    return formattedResult;
  } catch (error) {
    console.error("Error converting JSON data:", error.message);
    return null;
  }
}

function convertJsonToFormatCompset(data) {
  try {
    if (!Array.isArray(data) || data.length === 0) {
      throw new Error("Input data should be a non-empty array.");
    }

    const result = {};

    data.forEach((item) => {
      const { propertyName, roomName, price } = item;

      if (!result[propertyName]) {
        result[propertyName] = {};
      }

      result[propertyName][roomName] = price.toString();
    });

    // Convert result object to array of objects
    const formattedResult = Object.entries(result).map(([name, prices]) => {
      const item = { name };
      Object.entries(prices).forEach(([roomName, price]) => {
        item[roomName] = price;
      });
      return item;
    });

    return formattedResult;
  } catch (error) {
    console.error("Error converting JSON data:", error.message);
    return null;
  }
}

const ParityCalender = ({
  data,
  setSelectedDropdown,
  selectedDropdown,
  selectedDate,
  setSelectedDate,
  selectedMonth,
  setSelectedMonth,
  maxHeight,
  asPer,
  otaOptions,
  selectedPartityOtaDropdown,
  setSelectedPartityOtaDropdown,
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const newFormattedData =
    Array.isArray(data?.parity) &&
    data?.parity.map((item) => ({
      ...item,
      price: item.price,
    }));

  useEffect(() => {
    if (otaOptions) {
      setSelectedPartityOtaDropdown(otaOptions[0]);
    }
  }, [otaOptions]);

  const [selectedOTA, setSelectedOTA] = useState("MakeMyTrip");
  const hId = CurrentProperty();
  const formattedData = convertJsonToFormatOTA(data?.parity);
  const formattedSecondData = convertJsonToFormatCompset(data?.parity);
  const [expandedIndex, setExpandedIndex] = useState(0);
  const handleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const columns2 = React.useMemo(() => {
    if (!formattedSecondData || formattedSecondData.length === 0) {
      return [];
    }

    // Extract all unique room names from the formatted data
    const allRoomNames = Array.from(
      new Set(
        formattedSecondData.flatMap((item) =>
          Object.keys(item).filter((key) => key !== "name")
        )
      )
    );

    const dynamicColumns = allRoomNames.map((roomName) => ({
      Header: roomName,
      accessor: roomName,
      Cell: ({ value, row }) => (
        <div
          className={`hover:bg-[#CCD6E5] hover:text-neutral-800 text-sm font-medium w-[90px] h-8 rounded-lg flex items-center justify-center bg-[#EEF1F6]`}
        >
          {value}
        </div>
      ),
    }));

    return [
      {
        Header: "Hotel Name",
        accessor: "name",
        Cell: ({ value }) => (
          <div className="lg:w-60 md:w-40 w-48">{value}</div>
        ),
      },
      ...dynamicColumns,
    ];
  }, [formattedSecondData]);

  const columns = React.useMemo(() => {
    if (!formattedData || formattedData.length === 0) {
      return [];
    }

    // Extract all unique OTA names
    const allOtaNames = [
      ...new Set(
        formattedData.flatMap((item) =>
          Object.keys(item).filter((key) => key !== "name")
        )
      ),
    ];

    const dynamicColumns = allOtaNames.map((header) => ({
      Header: header,
      accessor: header,
      width: "100%",
      Cell: ({ value, row }) => {
        return (
          <div
            className={`hover:bg-[#CCD6E5] hover:text-neutral-800 text-sm font-medium w-[90px] h-8 rounded-lg flex items-center justify-center bg-[#EEF1F6] ${
              row.original[header.toLowerCase()] !== row.original.agoda
                ? "text-red-500"
                : ""
            }`}
          >
            {value}
          </div>
        );
      },
    }));

    return [
      {
        Header: "Name",
        accessor: "name",
        width: "100%",
        Cell: ({ value }) => <div className="">{value}</div>,
      },
      ...dynamicColumns,
    ];
  }, [formattedData]);

  function transformData(apiData) {
    const result = [];

    // Create a map to store roomName and associated otaWiseRoomRate
    const roomMap = new Map();

    // Iterate through the API data
    apiData.forEach((hotel) => {
      const roomName = hotel.hName; // Assuming 'hName' represents the room name
      const otaId = hotel.hId;
      const rates = hotel.rates;

      // Check if the roomName is already in the map
      if (!roomMap.has(roomName)) {
        roomMap.set(roomName, []);
      }

      // Add otaWiseRoomRate to the map
      roomMap.get(roomName).push({
        otaId: otaId,
        rates: rates,
        otaData: {
          _id: "", // Add the appropriate ID
          otaId: otaId,
          otaName: hotel.hName,

          // You might need to adjust the image URL based on your requirements
        },
      });
    });

    // Iterate through the map and format the result array
    roomMap.forEach((otaWiseRoomRate, roomName) => {
      result.push({
        roomName: roomName,
        otaWiseRoomRate: otaWiseRoomRate,
      });
    });

    return result;
  }

  function formatDateToMonthYear(date) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const year = date?.getFullYear();
    const month = months[date?.getMonth()];

    return `${month}, ${year}`;
  }
  function getFirstDayOfPreviousMonth(date) {
    // Copy the input date to avoid modifying the original
    const resultDate = new Date(date);

    // Set the date to the first day of the current month
    resultDate.setDate(1);

    // Subtract one day to get to the last day of the previous month
    resultDate.setDate(0);

    // Set the date to the first day of the previous month
    resultDate.setDate(1);

    return resultDate;
  }
  function getFirstDayOfNextMonth(date) {
    // Copy the input date to avoid modifying the original
    const resultDate = new Date(date);

    // Set the date to the first day of the current month
    resultDate.setDate(1);

    // Move to the next month
    resultDate.setMonth(resultDate.getMonth() + 1);

    // Set the date to the first day of the next month
    resultDate.setDate(1);

    return resultDate;
  }
  function getDateAfterThreeMonths(inputDate) {
    // Copy the input date to avoid modifying the original
    const resultDate = new Date(inputDate);

    // Add three months to the current month
    resultDate.setMonth(resultDate.getMonth() + 3);

    return resultDate;
  }
  function formatDateDMY(inputDate) {
    // Extract day, month, and year components from the input date
    const day = inputDate.getDate();
    const month = inputDate.getMonth() + 1; // Months are zero-based, so we add 1
    const year = inputDate.getFullYear();

    // Use template literals to format the date
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }

  function dropDownSelect(e, index) {
    setSelectedOTA(e["otaName"]);
  }

  const [isExpand, setIsExpand] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const toggleButton = () => {
    setIsToggled(!isToggled);
  };
  
  useEffect(() => {
    if (isToggled) {
      setSelectedDropdown({ label: "Among Compsets", value: "amongCompset" });
    }else{
      setSelectedDropdown({ label: "Among OTA", value: "amongOta" });
    }
  }, [isToggled]);

  const isIpad = useIsIpad()

  return (
    <>
      <div>
        <div className="flex flex-col lg:flex-row md:flex-none  bg-white  w-full h-full rounded-xl px-5 mb-5">
          <div
            className={`flex flex-col justify-start w-full pt-5 pb-2 lg:pt-0 lg:py-5 ${isExpand ? `lg:w-[${isIpad ? '60%':'90%'}]` :  "lg:w-[98%] "}  overflow-hidden`}
          >
            <div className="flex flex-row  w-full justify-between items-center ">
              <div className="flex w-full lg:justify-between md:flex-none relative">
                <div className="mt-4">
                  {" "}
                  <h1 className=" text-neutral-dark-25  py-2 mb-2 -mt-2">
                    Parity Calendar{" "}
                    {asPer && (
                      <span className="text-[#8794AD] text-[12px] ml-1 mt-1">
                        as per {asPer && format(new Date(asPer), "dd MMM','yy")}
                      </span>
                    )}
                  </h1>
                </div>

                <div className="lg:p-4 relative z-30 md:p-2 p-2 flex items-center gap-4">
               
                  <div className="flex gap-2 items-center">
                    <div className="text-sm">Among OTA</div>
                    <CheckBox
                      checked={isToggled}
                      onChange={toggleButton}
                      name={"toggle"}
                    />
                    <div className="text-sm">Among Compsets</div>
                  </div>
                 
                </div>
                <div className="md:block lg:hidden   block absolute right-0">
                  <div>
                    <div className="flex flex-row w-full  items-center mb-2">
                      <div className=" rounded-md flex flex-row w-full px-2 py-1 items-center justify-between h-fit">
                        <button
                          className="h-4 "
                          onClick={() => {
                            setSelectedMonth(
                              getFirstDayOfPreviousMonth(selectedMonth)
                            );
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="15"
                            viewBox="0 0 8 15"
                            fill="none"
                          >
                            <path
                              d="M7.70694 2.20755C8.09694 1.81755 8.09694 1.18456 7.70694 0.79306C7.31694 0.401563 6.68395 0.403063 6.29245 0.79306L7.70694 2.20755ZM0.998992 7.50101L0.292497 6.79451C-0.0974992 7.18451 -0.0974992 7.81751 0.292497 8.209L0.998992 7.50101ZM6.29245 14.2075C6.68245 14.5975 7.31544 14.5975 7.70694 14.2075C8.09844 13.8175 8.09694 13.1845 7.70694 12.793L6.29245 14.2075ZM6.29245 0.79306L0.292497 6.79451L1.70699 8.2075L7.70694 2.20755L6.29245 0.79306ZM0.292497 8.2075L6.29245 14.2075L7.70694 12.793L1.70699 6.79301L0.292497 8.2075Z"
                              fill="#6F767E"
                            />
                          </svg>
                        </button>
                        <div
                          className=" flex flex-row gap-2 px-3 items-center"
                          onClick={() =>
                            setIsCalendarOpen((prevState) => !prevState)
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M5.80135 1.66797C6.14928 1.66797 6.43134 1.92627 6.43134 2.24489V3.40866C6.99015 3.39874 7.61653 3.39874 8.3213 3.39874H8.32132H11.6813H11.6813C12.3861 3.39874 13.0125 3.39874 13.5713 3.40866V2.24489C13.5713 1.92627 13.8533 1.66797 14.2013 1.66797C14.5492 1.66797 14.8313 1.92627 14.8313 2.24489V3.45971C16.0403 3.54836 16.834 3.76595 17.4171 4.29995C18.0002 4.83395 18.2378 5.56079 18.3346 6.66797V7.5013H1.66797V6.66797C1.76478 5.56079 2.00238 4.83395 2.5855 4.29995C3.16862 3.76595 3.96233 3.54836 5.17136 3.45971V2.24489C5.17136 1.92627 5.45341 1.66797 5.80135 1.66797Z"
                              fill="#5E687F"
                            ></path>
                            <path
                              opacity="0.5"
                              d="M18.3346 11.6667V10C18.3346 9.3008 18.3241 8.05439 18.3133 7.5H1.67285C1.6621 8.05439 1.67267 9.3008 1.67267 10V11.6667C1.67267 14.8094 1.67267 16.3807 2.6487 17.357C3.62474 18.3333 5.19565 18.3333 8.33746 18.3333H11.6699C14.8117 18.3333 16.3826 18.3333 17.3586 17.357C18.3346 16.3807 18.3346 14.8094 18.3346 11.6667Z"
                              fill="#5E687F"
                            ></path>
                            <path
                              d="M15 13.75C15 14.4404 14.4404 15 13.75 15C13.0596 15 12.5 14.4404 12.5 13.75C12.5 13.0596 13.0596 12.5 13.75 12.5C14.4404 12.5 15 13.0596 15 13.75Z"
                              fill="#5E687F"
                            ></path>
                          </svg>
                          <p>{formatDateToMonthYear(selectedMonth)}</p>
                        </div>
                        <button
                          className="h-4 "
                          onClick={() => {
                            setSelectedMonth(
                              getFirstDayOfNextMonth(selectedMonth)
                            );
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="15"
                            viewBox="0 0 8 15"
                            fill="none"
                          >
                            <path
                              d="M0.29306 12.7925C-0.0969365 13.1824 -0.0969365 13.8154 0.29306 14.2069C0.683057 14.5984 1.31605 14.5969 1.70755 14.2069L0.29306 12.7925ZM7.00101 7.49899L7.7075 8.20549C8.0975 7.81549 8.0975 7.18249 7.7075 6.791L7.00101 7.49899ZM1.70755 0.792546C1.31755 0.40255 0.684557 0.40255 0.29306 0.792546C-0.0984371 1.18254 -0.096937 1.81554 0.29306 2.20704L1.70755 0.792546ZM1.70755 14.2069L7.7075 8.20549L6.29301 6.7925L0.29306 12.7925L1.70755 14.2069ZM7.7075 6.7925L1.70755 0.792546L0.29306 2.20704L6.29301 8.20699L7.7075 6.7925Z"
                              fill="#6F767E"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full justify-center ">
                    {isCalendarOpen && (
                      <div>
                    
                        <Calender
                          calendarDateTimeFrame={selectedMonth}
                          setCalendarDateTimeFrame={setSelectedMonth}
                          selectedDate={selectedDate}
                          setSelectedDate={setSelectedDate}
                          restrictionStart={new Date().setDate(
                            new Date().getDate() - 1
                          )}
                          restrictionEnd={getDateAfterThreeMonths(new Date())}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <NewParityCalenderTable
              data={newFormattedData}
              arrival={true}
              isExpanded={expandedIndex === 0}
              onExpand={() => handleExpand(0)}
              columns={columns}
            />
          </div>
          <div
            className={`md:hidden lg:block ${isExpand ? (isIpad? "w-[60%]" :"w-[40%]") : "w-fit"} transition-all ease-in-out duration-200 lg:py-5 -mt-1 hidden relative`}
          >
            <div
              className={`absolute ${isExpand ? "rounded-r-lg" : "rounded-r-lg"} z-20 top-[35%]  text-white flex justify-center items-center bg-[#1D2D44] px-2 h-[100px] `}
            >
              {isExpand ? (
                <AiOutlineArrowRight
                  className="cursor-pointer"
                  size={24}
                  onClick={() => setIsExpand(!isExpand)}
                />
              ) : (
                <BsCalendar2Date
                  className="cursor-pointer"
                  size={24}
                  onClick={() => setIsExpand(!isExpand)}
                />
              )}
            </div>
            {
              <div
                className={`${isExpand ? "opacity-100" : "opacity-0"} absolute flex flex-col h-full w-full justify-center items-center transition-all ease-in-out duration-200`}
              >
                <div>
                  <div className="flex flex-row w-full  items-center mb-2">
                    <div className=" rounded-md flex flex-row w-full px-2 py-1 items-center justify-between h-fit">
                      <button
                        className="h-4 "
                        onClick={() => {
                          setSelectedMonth(
                            getFirstDayOfPreviousMonth(selectedMonth)
                          );
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="15"
                          viewBox="0 0 8 15"
                          fill="none"
                        >
                          <path
                            d="M7.70694 2.20755C8.09694 1.81755 8.09694 1.18456 7.70694 0.79306C7.31694 0.401563 6.68395 0.403063 6.29245 0.79306L7.70694 2.20755ZM0.998992 7.50101L0.292497 6.79451C-0.0974992 7.18451 -0.0974992 7.81751 0.292497 8.209L0.998992 7.50101ZM6.29245 14.2075C6.68245 14.5975 7.31544 14.5975 7.70694 14.2075C8.09844 13.8175 8.09694 13.1845 7.70694 12.793L6.29245 14.2075ZM6.29245 0.79306L0.292497 6.79451L1.70699 8.2075L7.70694 2.20755L6.29245 0.79306ZM0.292497 8.2075L6.29245 14.2075L7.70694 12.793L1.70699 6.79301L0.292497 8.2075Z"
                            fill="#6F767E"
                          />
                        </svg>
                      </button>
                      <div className=" flex flex-row gap-2 px-3 items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M5.80135 1.66797C6.14928 1.66797 6.43134 1.92627 6.43134 2.24489V3.40866C6.99015 3.39874 7.61653 3.39874 8.3213 3.39874H8.32132H11.6813H11.6813C12.3861 3.39874 13.0125 3.39874 13.5713 3.40866V2.24489C13.5713 1.92627 13.8533 1.66797 14.2013 1.66797C14.5492 1.66797 14.8313 1.92627 14.8313 2.24489V3.45971C16.0403 3.54836 16.834 3.76595 17.4171 4.29995C18.0002 4.83395 18.2378 5.56079 18.3346 6.66797V7.5013H1.66797V6.66797C1.76478 5.56079 2.00238 4.83395 2.5855 4.29995C3.16862 3.76595 3.96233 3.54836 5.17136 3.45971V2.24489C5.17136 1.92627 5.45341 1.66797 5.80135 1.66797Z"
                            fill="#5E687F"
                          ></path>
                          <path
                            opacity="0.5"
                            d="M18.3346 11.6667V10C18.3346 9.3008 18.3241 8.05439 18.3133 7.5H1.67285C1.6621 8.05439 1.67267 9.3008 1.67267 10V11.6667C1.67267 14.8094 1.67267 16.3807 2.6487 17.357C3.62474 18.3333 5.19565 18.3333 8.33746 18.3333H11.6699C14.8117 18.3333 16.3826 18.3333 17.3586 17.357C18.3346 16.3807 18.3346 14.8094 18.3346 11.6667Z"
                            fill="#5E687F"
                          ></path>
                          <path
                            d="M15 13.75C15 14.4404 14.4404 15 13.75 15C13.0596 15 12.5 14.4404 12.5 13.75C12.5 13.0596 13.0596 12.5 13.75 12.5C14.4404 12.5 15 13.0596 15 13.75Z"
                            fill="#5E687F"
                          ></path>
                        </svg>
                        <p>{formatDateToMonthYear(selectedMonth)}</p>
                      </div>
                      <button
                        className="h-4 "
                        onClick={() => {
                          setSelectedMonth(
                            getFirstDayOfNextMonth(selectedMonth)
                          );
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="15"
                          viewBox="0 0 8 15"
                          fill="none"
                        >
                          <path
                            d="M0.29306 12.7925C-0.0969365 13.1824 -0.0969365 13.8154 0.29306 14.2069C0.683057 14.5984 1.31605 14.5969 1.70755 14.2069L0.29306 12.7925ZM7.00101 7.49899L7.7075 8.20549C8.0975 7.81549 8.0975 7.18249 7.7075 6.791L7.00101 7.49899ZM1.70755 0.792546C1.31755 0.40255 0.684557 0.40255 0.29306 0.792546C-0.0984371 1.18254 -0.096937 1.81554 0.29306 2.20704L1.70755 0.792546ZM1.70755 14.2069L7.7075 8.20549L6.29301 6.7925L0.29306 12.7925L1.70755 14.2069ZM7.7075 6.7925L1.70755 0.792546L0.29306 2.20704L6.29301 8.20699L7.7075 6.7925Z"
                            fill="#6F767E"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex w-auto justify-center ">
                  <Calender
                    calendarDateTimeFrame={selectedMonth}
                    setCalendarDateTimeFrame={setSelectedMonth}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    restrictionStart={new Date().setDate(
                      new Date().getDate() - 1
                    )}
                    restrictionEnd={getDateAfterThreeMonths(new Date())}
                  />
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default ParityCalender;
