import React, { useEffect, useState } from "react";
import Table from "../../../../components/table";
import SettingButton from "../../../../components/settingButton/SettingButton";
import Dropdown from "../../../../components/dropdown";
import Tableactiontype from "../../../../components/table/TableActionTYpe";
import adult from "../../../../assets/icons/adult.svg";
import child from "../../../../assets/icons/child.svg";
import TableButton from "../../../../components/settingButton/TableButton";
import DynamicCounter from "../../../../components/dynamicCounter/DynamicCounter";
import { FormProvider, useForm } from "react-hook-form";
import BackdropLoader from "../../../../components/newLoader";
import InputField from "../../../../components/inputFeild";
import {
  useAddRoomTypesMutation,
  useDeleteRoomTypesMutation,
  useEditRoomTypesMutation,
  useGetRoomTypesQuery,
} from "../../../../redux/slices/settings/channelManager/roomTypes";
import formatCurrency from "../../../../utils/formatCurrency";
import CustomSkeleton from "../../../../components/Skeleton";
import { toast } from "react-toastify";
import { CurrentProperty, UserId } from "../../../../utils/impVars";
import GenerateShortCode from "../../../../utils/shortCode";
import { useGetMasterRoomQuery } from "../../../../redux/slices/rooms";
import { Toggle } from "../../userManagement";

const RoomType = ({ handleBackButtonClick }) => {
  const [isAddRoom, setIsAddRoom] = useState(false);
  const [roomTypeData, setRoomTypeData] = useState(null);
  const [isEdit, setIsEdit] = useState();
  const [isView, setIsView] = useState(false);
  const hId = CurrentProperty();

  const {
    data: roomTypes,
    isLoading,
    isFetching,
    refetch,
  } = useGetRoomTypesQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [addRoomTypes] = useAddRoomTypesMutation();
  const [editRoomTypes] = useEditRoomTypesMutation();
  const [deleteRoomTypes] = useDeleteRoomTypesMutation();
  const handleAddRoomClick = () => {
    setIsAddRoom(true);
  };
  const handleDelete = (roomTypeId) => {
    deleteRoomTypes({ hId: hId, roomTypeId: roomTypeId })
      .unwrap()
      .then((res) => {
        toast.success("Room type deleted successfully!", {
          position: "bottom-right",
        });
        refetch();
      })
      .catch((err) => {
        toast.error("Something went wrong!", { position: "bottom-right" });
        refetch();
      });
  };
  const column = [
    {
      name: "Room Type Name",
      selector: (row) => row["roomTypeName"],
    },
    {
      name: "Total Inventory",
      selector: (row) => row["inventory"],
    },
    {
      name: "Rate & Threshold",
      selector: (row) => row["rateThreshold"],
      width: "25%",
      cell: (row) => {
        return (
          <div className="flex gap-2">
            {formatCurrency(row?.perNightCharge?.baseRate)}{" "}
            {` ( ${formatCurrency(row?.perNightCharge?.minimumRate)} - ${formatCurrency(row?.perNightCharge?.maximumRate)} )`}
          </div>
        );
      },
    },
    {
      name: "Adult & Child",
      selector: (row) => row["adultChild"],
      cell: (row) => {
        return (
          <div className="flex gap-2">
            <div className="flex gap-2 items-center">
              <img src={adult} alt="" />
              <p className="font-[700]">{`1-${row?.occupancyconfiguration?.maxAdults}`}</p>
              <img src={child} alt="" />
              <p className="font-[700]">{`0-${row?.occupancyconfiguration?.maxChild}`}</p>
            </div>
          </div>
        );
      },
    },
    {
      name: "Occupancy",
      selector: (row) => row["occupancyconfiguration"]["baseOccupancy"],
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <Tableactiontype
            onEditClick={() => {
              setRoomTypeData(row);
              setIsEdit(true);
            }}
            onViewClick={() => {
              setRoomTypeData(row);
              setIsView(true);
            }}
            showView={true}
            showDelete={true}
            handleDelete={() => handleDelete(row?.roomTypeId)}
          />
        );
      },
    },
  ];

  const data = [
    {
      roomTypeName: "Delux Room",
      totalInventory: "40",
      rateThreshold: "₹ 5,000 (3000-8000)",
      adultChild: "",
      occupancy: "2-4",
    },
    {
      roomTypeName: "Delux Room",
      totalInventory: "40",
      rateThreshold: "₹ 5,000 (3000-8000)",
      adultChild: "",
      occupancy: "2-4",
    },
    {
      roomTypeName: "Delux Room",
      totalInventory: "40",
      rateThreshold: "₹ 5,000 (3000-8000)",
      adultChild: "",
      occupancy: "2-4",
    },
  ];

  return (
    <>
      {isAddRoom || isEdit || isView ? (
        <AddRoomType
          onClose={() => {
            setIsView(false);
            setIsEdit(false);
            setIsAddRoom(false);
          }}
          addRoomTypes={addRoomTypes}
          refetch={refetch}
          data={roomTypeData}
          isEdit={isEdit}
          isView={isView}
          editRoomTypes={editRoomTypes}
          deleteRoomTypes={deleteRoomTypes}
        />
      ) : (
        <div className="flex flex-col gap-4 w-[100%]">
          <SettingButton
            Title={"Room Types"}
            table={true}
            handleBackButtonClick={handleBackButtonClick}
          />
          <div className="flex gap-2 w-[100%]">
            <div className="relative w-[100%] bg-[#F6F8FB] flex flex-col rounded-xl p-4">
              <div className="flex items-center justify-between">
                <p className="text-[14px] font-[500]">Room Types</p>
                <div className="flex items-center gap-2">
                  <Dropdown selectedItem={"Room"} />
                  <TableButton title={"Add New"} onClick={handleAddRoomClick} />
                </div>
              </div>
              {
              !isLoading &&
              !isFetching ? (
                <Table
                  columns={column}
                  pagination={true}
                  data={roomTypes?.count === 0 ? [] : roomTypes?.data}
                />
              ) : (
                <CustomSkeleton width={"100%"} height={400} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RoomType;

const AddRoomType = ({
  onClose,
  addRoomTypes,
  refetch,
  data: roomTypeData,
  isEdit,
  isView,
  editRoomTypes,
}) => {
  function changeValue(currentValue, changeAmount, isPercentage, isIncrease) {
    if (isPercentage) {
      if (isIncrease) {
        return (parseInt(currentValue) * (1 + parseInt(changeAmount) / 100)).toFixed(2);
      } else {
        return (parseInt(currentValue) * (1 - parseInt(changeAmount) / 100)).toFixed(2);
      }
    } else {
      if (isIncrease) {
        return parseInt(currentValue) + parseInt(changeAmount);
      } else {
        return parseInt(currentValue) - parseInt(changeAmount);
      }
    }
  }

  const methods = useForm({});
  const hId = CurrentProperty();
  const [selectedRoom, setSelectedRoom] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [numberOfRoom, setNumberOfRoom] = useState(0);
  const [selectedItems, setSelectedItems] = useState({
    label: "Master",
    value: "master",
  });
  const [inventory, setInventory] = useState(0);
  const [baseOccupancy, setbaseOccupancy] = useState(0);
  const [maxOccupancy, setMaxOccupancy] = useState(0);
  const [maxAdult, setMaxAdult] = useState(0);
  const [maxChild, setMaxChild] = useState(0);
  const [minRate, setMinRate] = useState(0);
  const [maxRate, setMaxRate] = useState(0);
  const [baseRate, setBaseRate] = useState(0);
  const [additionlAdult, setAdditionlAdult] = useState(0);
  const [additionlChild, setAdditionlChild] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isPercentageNightCharge, setIsPercentageNightCharge] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [nightCharge, setNightCharge] = useState();
  const [isToggle, setIsToggle] = useState(false);
  const [isMater, setIsMaster] = useState(true);
  const [additionalAdultInput, setAdditionalAdultInput] = useState();
  const [additionalChildInput, setAdditionalChildInput] = useState();
  const [
    isAdditionalPercentageNightCharge,
    setIsAdditionalPercentageNightCharge,
  ] = useState(false);
  const [isAdditionalAdd, setIsAdditionalAdd] = useState(true);
  const [
    isAdditionalChildPercentageNightCharge,
    setIsAdditionalChildPercentageNightCharge,
  ] = useState(false);
  const [isAdditionalChildAdd, setIsAdditionalChildAdd] = useState(true);
  const { data: getRooms } = useGetMasterRoomQuery(
    {
      userId: UserId(),
      hId: hId,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );
  // const getRoomOptions =
  //   Array.isArray(getRooms?.data) &&
  //   getRooms?.data?.map((item) => {
  //     return {
  //       label: item?.roomTypeName,
  //       value: item.roomTypeId,
  //     };
  //   });

  useEffect(() => {
    if (getRooms?.data && selectedRoom) {
      const arrayData = getRooms?.data?.filter((item) => {
        return item?.label === selectedRoom?.label;
      });
      if (arrayData) {
        setMinRate(arrayData[0]?.baseRate?.minimumRate);
        setBaseRate(arrayData[0]?.baseRate?.baseRate);
        setMaxRate(arrayData[0]?.baseRate?.maximumRate);
        setAdditionlAdult(arrayData[0]?.extraOccupancy?.AdditionalAdultCharges);
        setAdditionlChild(arrayData[0]?.extraOccupancy?.AdditionalChildCharges);
        // setbaseOccupancy(arrayData[0]?.occupancy?.baseOccupancy);
        // setMaxOccupancy(arrayData[0]?.occupancy?.maxOccupancy);
        // setMaxChild(arrayData[0]?.occupancy?.maxChild);
        // setMaxAdult(arrayData[0]?.occupancy?.maxAdults);
      }
    }
  }, [selectedRoom]);
  const onSubmit = async (data) => {
    const isDerived = {
      roomType: isMater ? "master" : "derived",
      roomTypeName: data?.roomTypeName,
      roomShortCode: data?.shortCode,
      inventory: inventory,
      occupancyconfiguration: {
        baseOccupancy: baseOccupancy,
        maxOccupancy: maxOccupancy,
        maxAdults: maxAdult,
        maxChild: maxChild,
      },

      derived: {
        derivedRoomTypeId: selectedRoom?.value,
        derivedBaseRateChange: {
          adjustmentType: isPercentageNightCharge ? "Percent" : "Amount",
          amountChangeType: isAdd ? "add" : "sub",
          adjustmentValue: nightCharge,
          overwritten: isToggle,
        },
        derivedExtraAdultChange: {
          adjustmentType: isAdditionalPercentageNightCharge
            ? "Percent"
            : "Amount",
          amountChangeType: isAdditionalAdd ? "add" : "sub",
          adjustmentValue: additionalAdultInput,
          overwritten: false,
        },
        derivedExtraChildChange: {
          adjustmentType: isAdditionalChildPercentageNightCharge
            ? "Percent"
            : "Amount",
          amountChangeType: isAdditionalChildAdd ? "add" : "sub",
          adjustmentValue: additionalChildInput,
          overwritten: true,
        },
      },

      perNightCharge: {
        minimumRate: minRate,
        baseRate: baseRate,
        maximumRate: maxRate,
      },
      extraOccupancyCharges: {
        AdditionalAdultCharges: additionlAdult,
        AdditionalChildCharges: additionlChild,
      },
    };
    setIsLoading(true);
    const obj = {
      hId: hId,
      roomDetail: isMater
        ? {
            roomType: isMater ? "master" : "derived",
            roomTypeName: data?.roomTypeName,
            roomShortCode: data?.shortCode,
            inventory: inventory,
            occupancyconfiguration: {
              baseOccupancy: baseOccupancy,
              maxOccupancy: maxOccupancy,
              maxAdults: maxAdult,
              maxChild: maxChild,
            },

            perNightCharge: {
              minimumRate: minRate,
              baseRate: baseRate,
              maximumRate: maxRate,
            },
            extraOccupancyCharges: {
              AdditionalAdultCharges: additionlAdult,
              AdditionalChildCharges: additionlChild,
            },
          }
        : isDerived,
    };
    const editObj = {
      hId: hId,
      roomTypeId: roomTypeData?.roomTypeId,
      roomDetail: {
        roomTypeName: data?.roomTypeName,
        roomShortCode: data?.shortCode,
        inventory: inventory,
        occupancyconfiguration: {
          baseOccupancy: baseOccupancy,
          maxOccupancy: maxOccupancy,
          maxAdults: maxAdult,
          maxChild: maxChild,
        },
        perNightCharge: {
          minimumRate: minRate,
          baseRate: baseRate,
          maximumRate: maxRate,
        },
        extraOccupancyCharges: {
          AdditionalAdultCharges: additionlAdult,
          AdditionalChildCharges: additionlChild,
        },
      },
    };
    if (isEdit) {
      editRoomTypes(editObj)
        .unwrap()
        .then((res) => {
          toast.success("Room type updated successfully!", {
            position: "bottom-right",
          });
          refetch();
          onClose();
        })
        .catch((err) => {
          toast.error("Something went wrong!", { position: "bottom-right" });
          refetch();
          onClose();
        });
    } else if (obj) {
      addRoomTypes(obj)
        .unwrap()
        .then((res) => {
          toast.success("Room type added successfully!", {
            position: "bottom-right",
          });
          refetch();
          setIsLoading(false);
          onClose();
        })
        .catch((err) => {
          toast.error("Something went wrong!", { position: "bottom-right" });
          refetch();
          setIsLoading(false);
          onClose();
        });
    }
  };
  useEffect(() => {
    if (isView || isEdit) {
      methods.setValue("roomTypeName", roomTypeData?.roomTypeName);
      methods.setValue("shortCode", roomTypeData?.roomShortCode);
      setBaseRate(roomTypeData?.perNightCharge?.baseRate);
      setMaxRate(roomTypeData?.perNightCharge?.maximumRate);
      setMinRate(roomTypeData?.perNightCharge?.minimumRate);
      setMaxOccupancy(roomTypeData?.occupancyconfiguration?.maxOccupancy);
      setbaseOccupancy(roomTypeData?.occupancyconfiguration?.baseOccupancy);
      setMaxAdult(roomTypeData?.occupancyconfiguration?.maxAdults);
      setMaxChild(roomTypeData?.occupancyconfiguration?.maxChild);
      setAdditionlAdult(
        roomTypeData?.extraOccupancyCharges?.AdditionalAdultCharges
      );
      setAdditionlChild(
        roomTypeData?.extraOccupancyCharges?.AdditionalChildCharges
      );
      setInventory(roomTypeData?.inventory);
    }
  }, [isEdit, isView]);

  useEffect(() => {
    if (methods.watch("roomTypeName")) {
      methods.setValue(
        "shortCode",
        GenerateShortCode(methods.watch("roomTypeName"))
      );
    }
  }, [methods.watch("roomTypeName")]);

  useEffect(() => {
    if (selectedItems?.value === "master" || selectedItems?.value === "independent" ) {
      setIsMaster(true);
    } else {
      setIsMaster(false);
    }
  }, [selectedItems]);

  useEffect(() => {
    if (nightCharge) {
      setMinRate(
        changeValue(minRate, nightCharge, isPercentageNightCharge, isAdd)
      );
      setBaseRate(
        changeValue(baseRate, nightCharge, isPercentageNightCharge, isAdd)
      );
      setMaxRate(
        changeValue(maxRate, nightCharge, isPercentageNightCharge, isAdd)
      );
      setAdditionlAdult(
        changeValue(additionlAdult, nightCharge, isPercentageNightCharge, isAdd)
      );
      setAdditionlChild(
        changeValue(additionlChild, nightCharge, isPercentageNightCharge, isAdd)
      );
      setbaseOccupancy(
        changeValue(baseOccupancy, nightCharge, isPercentageNightCharge, isAdd)
      );
      setMaxOccupancy(
        changeValue(maxOccupancy, nightCharge, isPercentageNightCharge, isAdd)
      );
      setMaxChild(
        changeValue(maxChild, nightCharge, isPercentageNightCharge, isAdd)
      );
      setMaxAdult(
        changeValue(maxAdult, nightCharge, isPercentageNightCharge, isAdd)
      );
    }
  }, [nightCharge]);
  useEffect(() => {
    if (additionalChildInput) {
      setAdditionlChild(
        changeValue(
          additionlChild,
          additionalChildInput,
          isAdditionalChildPercentageNightCharge,
          isAdditionalChildAdd
        )
      );
    }
    if (additionalAdultInput) {
      setAdditionlAdult(
        changeValue(
          additionlAdult,
          additionalAdultInput,
          isAdditionalPercentageNightCharge,
          isAdditionalAdd
        )
      );
    }
  }, [additionalChildInput, additionalAdultInput]);
  return (
    <>
      <BackdropLoader loading={isLoading} />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4 w-[100%] h-[85vh]">
            <SettingButton
              text={isEdit ? "Update" : "Save"}
              Title={
                isView
                  ? "Room Type"
                  : isEdit
                    ? "Edit Room Type"
                    : "Add Room Type"
              }
              onClose={onClose}
              handleBackButtonClick={onClose}
              table={true}
              isView={isView}
              type={"submit"}
            />
          <div className="flex gap-4 rounded-lg w-[100%] bg-newGradient text-lightFontColor dark:bg-lightGradient  p-2 flex-col">
              <div className="flex flex-col gap-4 w-[100%]">
                <p className="text-white  text-[13px]">Room Details</p>
                <div className="flex flex-col justify-center gap-2">
                  <p className="text-white text-[12px]">Room Type Name</p>
                  <div className="w-1/2">
                    <InputField
                      type={"text"}
                      className={
                        "text-gray-800 w-full bg-[#F6F8FB] h-9 rounded-lg"
                      }
                      label={false}
                      placeholder={"Delux Room"}
                      id={"roomTypeName"}
                      name={"roomTypeName"}
                      controls={methods.control}
                      rules={{ required: "Room Type Name is required" }}
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-center gap-2">
                  <p className="text-white text-[12px]">Short Code</p>
                  <div className="w-1/2 ">
                    <InputField
                      type={"text"}
                      className={
                        "text-gray-600 w-full bg-[#F6F8FB] h-9 rounded-lg"
                      }
                      label={false}
                      placeholder={"Short Code"}
                      id={"shortCode"}
                      name={"shortCode"}
                      controls={methods.control}
                      rules={{ required: "Room Type Short Code is required" }}
                    />
                  </div>
                </div>
                <div className=" w-1/4 flex flex-col justify-center gap-2">
                  <p className="text-white text-[12px]">Inventory</p>
                  <div>
                    <DynamicCounter
                      initialValue={8}
                      count={inventory}
                      setCount={setInventory}
                      isChecked={"false"}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4 w-[100%]">
                <p className="text-white   text-[13px]">
                  Occupancy Configuration
                </p>
                <div className="flex gap-4 w-1/2">
                  <div className="w-1/2 flex flex-col justify-center gap-2">
                    <p className="text-white   text-[12px]">Base Occupancy</p>
                    <DynamicCounter
                      initialValue={8}
                      count={baseOccupancy}
                      setCount={setbaseOccupancy}
                      isChecked={"false"}
                    />
                  </div>
                  <div className="w-1/2 flex flex-col justify-center gap-2">
                    <p className="text-white text-[12px]">Max Occupancy</p>
                    <DynamicCounter
                      initialValue={8}
                      count={maxOccupancy}
                      setCount={setMaxOccupancy}
                      isChecked={"false"}
                    />
                  </div>
                </div>
                <div className="flex gap-4 w-1/2">
                  <div className="w-1/2 flex flex-col justify-center gap-2">
                    <p className="text-white text-[12px]">Max Adults</p>
                    <DynamicCounter
                      initialValue={8}
                      count={maxAdult}
                      setCount={setMaxAdult}
                      isChecked={"false"}

                    />
                  </div>
                  <div className="w-1/2 flex flex-col justify-center gap-2">
                    <p className="text-white text-[12px]">Max Child</p>
                    <DynamicCounter
                      initialValue={8}
                      count={maxChild}
                      setCount={setMaxChild}
                      isChecked={"false"}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="w-[100%] mt-4 flex items-center gap-2">
                  <div className="text-sm text-[#0e0f10]   dark:text-white ">Create Room Type as: </div>
                  <Dropdown
                    right={"-50px"}
                    height={32}
                    options={[
                      { label: "Master", value: "master" },
                      { label: "Derived", value: "derived" },
                      { label: "Independent", value: "independent" },
                    ]}
                    width={150}
                    selectedItem={selectedItems?.label}
                    setSelectedItems={setSelectedItems}
                    backgroundColor={"#EEF1F6"}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2 w-[100%]">
                {!isMater && (
                  <div className="w-[100%] flex gap-10 mb-2">
                    <div className="">
                      <Dropdown
                        right={"-50px"}
                        height={32}
                        options={getRooms?.data}
                        width={150}
                        selectedItem={selectedRoom?.label}
                        setSelectedItems={setSelectedRoom}
                        backgroundColor={"#EEF1F6"}
                      />
                    </div>
                    <div className="flex items-center gap-2">
                      <div
                        className={`${isPercentageNightCharge ? "text-[#2A85FF]" : "bg-[#2A85FF] text-white"} cursor-pointer w-[30px] h-[30px] flex justify-center items-center rounded-md`}
                        onClick={() => setIsPercentageNightCharge(false)}
                      >
                        ₹
                      </div>
                      <div
                        className={`w-[30px] h-[30px] cursor-pointer flex justify-center items-center ${!isPercentageNightCharge ? "text-[#2A85FF]" : "bg-[#2A85FF] text-white"} rounded-md`}
                        onClick={() => setIsPercentageNightCharge(true)}
                      >
                        %
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div
                        className={`${!isAdd ? "text-[#2A85FF]" : "bg-[#2A85FF] text-white"} w-[30px] h-[30px] cursor-pointer flex justify-center items-center  rounded-md`}
                        onClick={() => setIsAdd(true)}
                      >
                        +
                      </div>
                      <div
                        className={`${isAdd ? "text-[#2A85FF]" : "bg-[#2A85FF] text-white"} w-[30px] h-[30px] cursor-pointer flex justify-center items-center text-blue-500 rounded-md`}
                        onClick={() => setIsAdd(false)}
                      >
                        -
                      </div>
                    </div>
                    <input
                      type="number"
                      className="bg-[#EEF1F6] focus:outline-none px-2 rounded-md"
                      value={nightCharge}
                      onChange={(e) => setNightCharge(e.target.value)}
                      placeholder=""
                    />
                  </div>
                )}
                <div>
                <p className="text-white text-[13px]">Per Night Charge</p>

                </div>
                {!isMater && (
                  <div className="flex items-center justify-start gap-4 mb-4">
                    <div className="text-sm flex items-center text-[#0e0f10]   dark:text-white ">
                      Edit Default Room Occupancy
                    </div>
                    <Toggle
                      isToggled={isToggle}
                      onClick={() => setIsToggle(!isToggle)}
                    />
                  </div>
                )}
                <div className="flex gap-4 w-3/4">
                  <div className="w-3/4 flex flex-col justify-center gap-2">
                    <p className="text-white text-[12px]">Minimum Rate</p>
                    <DynamicCounter
                      initialValue={"₹ 3,000.00"}
                      count={minRate}
                      setCount={setMinRate}
                      isChecked={"false"}
                    />
                  </div>
                  <div className="w-3/4 flex flex-col justify-center gap-2">
                    <p className="text-white text-[12px]">Base Rate</p>
                    <DynamicCounter
                      initialValue={"₹ 5,000.00"}
                      count={baseRate}
                      setCount={setBaseRate}
                      isChecked={"false"}
                    />
                  </div>
                  <div className="w-3/4 flex flex-col justify-center gap-2">
                    <p className="text-white text-[12px]">Maximum Rate</p>
                    <DynamicCounter
                      initialValue={"₹ 10,000.00"}
                      count={maxRate}
                      setCount={setMaxRate}
                      isChecked={"false"}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2 w-[100%]">
                <p className="text-white text-[13px]">
                  Extra Occupancy Charges
                </p>

                <div className=" w-[40%] flex flex-col justify-center gap-2">
                  <p className="text-white text-[12px]">Additional Adult</p>
                  {!isMater ? (
                    <div className="w-[100%] flex gap-10 mb-2">
                      <div className="flex items-center gap-2">
                        <div
                          className={`${isAdditionalPercentageNightCharge ? "text-[#2A85FF]" : "bg-[#2A85FF] text-white"} cursor-pointer w-[30px] h-[30px] flex justify-center items-center rounded-md`}
                          onClick={() =>
                            setIsAdditionalPercentageNightCharge(false)
                          }
                        >
                          ₹
                        </div>
                        <div
                          className={`w-[30px] h-[30px] cursor-pointer flex justify-center items-center ${!isAdditionalPercentageNightCharge ? "text-[#2A85FF]" : "bg-[#2A85FF] text-white"} rounded-md`}
                          onClick={() =>
                            setIsAdditionalPercentageNightCharge(true)
                          }
                        >
                          %
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <div
                          className={`${!isAdditionalAdd ? "text-[#2A85FF]" : "bg-[#2A85FF] text-white"} w-[30px] h-[30px] cursor-pointer flex justify-center items-center  rounded-md`}
                          onClick={() => setIsAdditionalAdd(true)}
                        >
                          +
                        </div>
                        <div
                          className={`${isAdditionalAdd ? "text-[#2A85FF]" : "bg-[#2A85FF] text-white"} w-[30px] h-[30px] cursor-pointer flex justify-center items-center text-blue-500 rounded-md`}
                          onClick={() => setIsAdditionalAdd(false)}
                        >
                          -
                        </div>
                      </div>
                      <input
                        type="number"
                        className="bg-[#EEF1F6] focus:outline-none px-2 rounded-md"
                        value={additionalAdultInput}
                        onChange={(e) => {
                          setAdditionalAdultInput(e.target.value);
                        }}
                      />
                      <DynamicCounter
                        initialValue={"₹ 10,000.00"}
                        count={additionlAdult}
                        setCount={setAdditionlAdult}
                        isChecked={"false"}
                      />
                    </div>
                  ) : (
                    <DynamicCounter
                      initialValue={"₹ 10,000.00"}
                      count={additionlAdult}
                      setCount={setAdditionlAdult}
                      isChecked={"false"}
                    />
                  )}
                </div>
              </div>
              <div className="w-[40%] flex flex-col justify-center gap-2">
                <p className="text-white text-[12px]">Additional Child</p>
                {!isMater ? (
                  <div className="w-[100%] flex gap-10 mb-2">
                    <div className="flex items-center gap-2">
                      <div
                        className={`${isAdditionalChildPercentageNightCharge ? "text-[#2A85FF]" : "bg-[#2A85FF] text-white"} cursor-pointer w-[30px] h-[30px] flex justify-center items-center rounded-md`}
                        onClick={() =>
                          setIsAdditionalChildPercentageNightCharge(false)
                        }
                      >
                        ₹
                      </div>
                      <div
                        className={`w-[30px] h-[30px] cursor-pointer flex justify-center items-center ${!isAdditionalChildPercentageNightCharge ? "text-[#2A85FF]" : "bg-[#2A85FF] text-white"} rounded-md`}
                        onClick={() =>
                          setIsAdditionalChildPercentageNightCharge(true)
                        }
                      >
                        %
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div
                        className={`${!isAdditionalChildAdd ? "text-[#2A85FF]" : "bg-[#2A85FF] text-white"} w-[30px] h-[30px] cursor-pointer flex justify-center items-center  rounded-md`}
                        onClick={() => setIsAdditionalChildAdd(true)}
                      >
                        +
                      </div>
                      <div
                        className={`${isAdditionalChildAdd ? "text-[#2A85FF]" : "bg-[#2A85FF] text-white"} w-[30px] h-[30px] cursor-pointer flex justify-center items-center text-blue-500 rounded-md`}
                        onClick={() => setIsAdditionalChildAdd(false)}
                      >
                        -
                      </div>
                    </div>
                    <input
                      type="number"
                      className="bg-[#EEF1F6] focus:outline-none px-2 rounded-md"
                      value={additionalChildInput}
                      onChange={(e) => setAdditionalChildInput(e.target.value)}
                    />
                    <DynamicCounter
                      initialValue={"₹ 10,000.00"}
                      count={additionlChild}
                      setCount={setAdditionlChild}
                      isChecked={"false"}
                    />
                  </div>
                ) : (
                  <DynamicCounter
                    initialValue={"₹ 10,000.00"}
                    count={additionlChild}
                    setCount={setAdditionlChild}
                    isChecked={"false"}
                  />
                )}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
