import { emptySplitApi } from "../../injectEndpoints";

export const Utils = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getOtaListForHI: builder.query({
      query: (data) => {
        return {
          url: `/utils/getSourcesSelectors`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getUserListUserManagement"],
    }),
    getSourceData: builder.query({
      query: (data) => {
        return {
          url: `/utils/getSourcesSegregatedData`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getSourcesSegregatedData"],
    }),
    getReportBuilderUtils: builder.query({
      query: (data) => {
        return {
          url: `/utils/getReportBuilderUtils`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getReportBuilderUtils"],
    }),
    getCompsetLinks: builder.query({
      query: (data) => {
        return {
          url: `/utils/getCompsetLinks`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getCompsetLinks"],
    }),
    getNotifications: builder.query({
      query: (data) => {
        return {
          url: `/notifications/getNotifications`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getNotifications"],
    }),
    earlyAccess: builder.mutation({
      query: (data) => {
        return {
          url: `/earlyAccess/earlyAccess`,
          method: "POST",
          body:data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["earlyAccess"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetOtaListForHIQuery,useGetReportBuilderUtilsQuery,useGetNotificationsQuery,useEarlyAccessMutation ,useGetSourceDataQuery, useGetCompsetLinksQuery } = Utils;
