import { emptySplitApi } from "../../injectEndpoints";

export const RateSuggestion = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDynamicRateRule: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getDynamicRateRule`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getDynamicRateRule"],
    }),
    addDynamicRateRule: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/postDynamicRateRule`,
          method: "POST",
          body: data,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["addDynamicRateRule"],
    }),
    updateDynamicRateRule: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/updateDynamicRateRule`,
          method: "PATCH",
          body: data,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["updateDynamicRateRule"],
    }),
    getCompsetRateRule: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getCompsetRateRule`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getCompsetRateRule"],
    }),
    addCompsetRateRule: builder.mutation({
      query: (data) => {
      
        return {
          url: `/userDashboardSetting/postCompsetRateRule`,
          method: "POST",
          body: data,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["addCompsetRateRule"],
    }),
    updateCompsetRateRule: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/updateCompsetRateRule`,
          method: "PATCH",
          body: data,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["updateCompsetRateRule"],
    }),

    getRateSuggestionCalendar: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/rateSuggestionCalendar`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["getRateSuggestionCalendar"],
    }),
    getRateSuggestionCalendarByDate: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/rateSuggestionOnDay`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["getRateSuggestionCalendarByDate"],
    }),
    getPropertySeason: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getPropertySeasons`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["getPropertySeason"],
    }),

    pushRate: builder.mutation({
      query: (data) => {
      
        return {
          url: `external/pushRate`,
          method: "POST",
          body: data,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["pushRate"],
    }),

    overrideRate: builder.mutation({
      query: (data) => {
      
        return {
          url: `/dashboard/overRideRates`,
          method: "POST",
          body: data,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["overrideRate"],
    }),

    getActualAndSuggestedRates: builder.query({
      query: (data) => {
      
        return {
          url: `/dashboard/getActualAndSuggestedRates`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["getActualAndSuggestedRates"],
    }),
    getSuggestedRateVsCompetition: builder.query({
      query: (data) => {
      
        return {
          url: `/dashboard/suggestedRateVsCompetition`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["getSuggestedRateVsCompetition"],
    }),
    createDynamicBreakdown: builder.mutation({
      query: (data) => {
      
        return {
          url: `/automationRoute/createDynamicBreakdown`,
          method: "POST",
          body: data,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["createDynamicBreakdown"],
    }),
    pushRateBifercation: builder.mutation({
        query:(data)=>{
            return {
                url: `external/pushRateBifercation`,
                method: "POST",
                body: data,
            }
        },
        invalidatesTags: ["pushRateBifercation"],
    })
  }),
  overrideExisting: false,
});

export const {
  useGetDynamicRateRuleQuery,
  useAddDynamicRateRuleMutation,
  useUpdateDynamicRateRuleMutation,
  useGetCompsetRateRuleQuery,
  useAddCompsetRateRuleMutation,
  useUpdateCompsetRateRuleMutation,
useGetRateSuggestionCalendarQuery,
useGetRateSuggestionCalendarByDateQuery,
useGetPropertySeasonQuery,
useOverrideRateMutation,
usePushRateMutation,
useGetActualAndSuggestedRatesQuery,
useGetSuggestedRateVsCompetitionQuery,
useCreateDynamicBreakdownMutation,
usePushRateBifercationMutation,
} = RateSuggestion;
