import React, { useState } from "react";
import "./Global.css";
import Img from "../../assets/img/rateXBlack.svg";
import Img2 from "../../assets/img/Frame 1321314698.svg";
import Fb from "../../assets/icons/onboarding/FB.svg";
import Google from "../../assets/icons/onboarding/Google.svg";
import SimpleButton from "../../components/button/SimpleButton";
import MyUniversalComponent from "../../components/sideComponent/leftSection";
import Password from "../../assets/icons/onboarding/Password.svg";
import Phone from "../../assets/icons/onboarding/Phone.svg";
import Email from "../../assets/icons/onboarding/Email.svg";
import Business from "../../assets/icons/onboarding/Business.svg";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useUserSignupMutation } from "../../redux/slices/onBoarding";
import { toast } from "react-toastify";
import InputField from "../../components/inputFeild";
import Backdrop from "../../components/backdrop";
import { SyncLoader } from "react-spinners";

const SignUp = ({ text }) => {
  const [userSignup] = useUserSignupMutation();
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const methods = useForm({});

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };


  const onSubmit = async (data) => {
    if (!isChecked) {
      toast.error("Please agree to the Terms & Conditions", {
        position: "bottom-right",
      });
      return;
    }
    setLoading(true);
    const signUpData = {
      name: data.yourName,
      email: data.email,
      designation: data.designation,
      password: data.password,
      bussinessName: data.bussinessName,
      phoneNumber: data.phoneNumber,
    };
    userSignup(signUpData)
      .unwrap()
      .then((res) => {
        toast.success(res?.message, { position: "bottom-right" });
        localStorage.setItem("userId", res.userId);
        localStorage.setItem("email", JSON.stringify(data.email));
        // localStorage.setItem('token', JSON.stringify(res.token.token));

        navigate("/verify_number", { state: { email: data.email } });
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.data?.message, { position: "bottom-right" });
        setLoading(false);
      });
  };
 
 
  return (
    <>
      {loading && (
        <Backdrop>
          <SyncLoader color="white" />
        </Backdrop>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="login-container">
            <MyUniversalComponent
             title="Made For True Hoteliers"
             subtitle="Step into a world where innovation meets intuition, tailored specifically for the discerning hotelier. Unlock the gateway to a new era of hospitality excellence with RMS - where every detail is designed with you in mind."
              imageSrc={Img2}
            />
            <div className="login-main">
              <div className="login-main-content ">
                <div className="flex w-full justify-center">
                  <img className="img-logo" loading="lazy" src={Img} alt="" />
                </div>
                <div className="flex items-center justify-center flex-col gap-2">
                  <h1 className="text-[1.2rem] text-[#0D1321] text-center font-sans font-semibold ">
                    Your operations, your control just few steps away
                  </h1>
                  <p className="text-[0.8rem] text-center text-[#485266]">
                    Sign up to access powerful tools that elevate your business.
                  </p>
                </div>
                <div className="flex flex-col w-full items-center justify-center gap-0  rounded-lg drop-shadow-3xl bg-white ">
                  <div className="w-full">
                    <InputField
                      type={"text"}
                      className={"w-full text-gray-600"}
                      label={"Your Name"}
                      placeholder={" Enter your Name"}
                      id={"yourName"}
                      name={"yourName"}
                      controls={methods.control}
                      startIcon={Business}
                      rules={{ required: "Your Name is required" }}
                    />
                  </div>
                  <div className="w-full">
                    <InputField
                      type={"text"}
                      className={"w-full text-gray-600"}
                      label={"Business Name"}
                      placeholder={" Enter business Name"}
                      id={"bussinessName"}
                      name={"bussinessName"}
                      controls={methods.control}
                      startIcon={Business}
                      rules={{ required: "Buisness Name is required" }}
                    />
                  </div>
                  <div className="w-full">
                    <InputField
                      type={"text"}
                      className={"w-full text-gray-600"}
                      label={"Your Designation"}
                      placeholder={" Enter your designation"}
                      id={"designation"}
                      name={"designation"}
                      controls={methods.control}
                      startIcon={Business}
                      rules={{ required: "Your Name is required" }}
                    />
                  </div>
                  <div className="w-full">
                    <InputField
                      placeholder={"Enter email"}
                      className={"w-full text-gray-600"}
                      label={"Email"}
                      id={"email"}
                      control={methods.control}
                      name={"email"}
                      type={"email"}
                      startIcon={Email}
                      rules={{
                        required: "Email is required",
                        pattern: {
                          value: /^\S+@\S+\.\S+$/,
                          message: "Invalid email format",
                        },
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <InputField
                      type={"tel"}
                      label={"Phone Number"}
                      className={"w-full text-gray-600"}
                      placeholder={" Enter phone number"}
                      controls={methods.control}
                      id={"phoneNumber"}
                      name={"phoneNumber"}
                      startIcon={Phone}
                      rules={{
                        required: "Phone Number is required",
                        pattern: {
                          value: /^\+91\d{10}$/, // Updated pattern to include "+91" country code followed by 10 digits
                          message:
                            'Phone Number must start with "+91" followed by 10 digits',
                        },
                      }}
                    />
                  </div>

                  <div className="w-full rounded-b-lg ">
                    <InputField
                      type={"password"}
                      label={"Password"}
                      placeholder={"Enter password"}
                      className={"w-full"}
                      id={"password"}
                      name={"password"}
                      control={methods.control}
                      startIcon={Password}
              
                      rules={{
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message:
                            "Password must be at least 8 characters long",
                        },
                        maxLength: {
                          value: 20,
                          message: "Password must not exceed 20 characters",
                        },
                        validate: {
                          uppercase: (v) =>
                            /[A-Z]/.test(v) ||
                            "Password must contain at least one uppercase letter",
                          lowercase: (v) =>
                            /[a-z]/.test(v) ||
                            "Password must contain at least one lowercase letter",
                          digit: (v) =>
                            /[0-9]/.test(v) ||
                            "Password must contain at least one digit",
                          specialCharacter: (v) =>
                            /[^A-Za-z0-9]/.test(v) ||
                            "Password must contain at least one special character",
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full gap-4">
                  <div className="w-full flex flex-row justify-between">
                    <div class="flex items-center">
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        checked={isChecked}
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="default-checkbox"
                        className={`ms-2 text-sm font-[400] text-gray-600`}
                      >
                        I agree to the{" "}
                        <span
                          className={`${isChecked ? " text-gray-600" : " text-gray-600"} font-[500]`}
                        >
                          Terms & conditions
                        </span>
                      </label>
                    </div>
                  </div>
                  <SimpleButton text="Sign up" type="submit" />
                  <div className="flex flex-col w-full gap-4 items-center">
                    {/* <div className="flex items-center w-full">
                      <div className="flex-grow border-t border-gray-300"></div>
                      <span className="px-4 text-[#0D1321]">or</span>
                      <div className="flex-grow border-t border-gray-300"></div>
                    </div> */}
                    {/* <div className="flex items-center gap-4">
                      <img className="img-size" src={Fb} alt="" />
                      <img className="img-size" src={Google} alt="" />
                    </div> */}
                    <div>
                      <p className="text-sm font-[400] text-[#0D1321]">
                        Already have an account?
                        <span
                          onClick={handleLoginClick}
                          className="text-sm font-[400] text-[#3F70ED] pl-2 cursor-pointer"
                        >
                          Log in
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default SignUp;
