import React, { useEffect, useState } from "react";
import "../Global.css";
import Img from "../../../assets/img/Branding.svg";
import SimpleButton from "../../../components/button/SimpleButton";
import Location from "../../../assets/icons/onboarding/location.svg";
import { FormProvider, useForm } from "react-hook-form";
import InputField from "../../../components/inputFeild";
import { useLocation, useNavigate } from "react-router-dom";
import MapComponent from "../../../components/mapPicker";
import Backdrop from "../../../components/backdrop";
import { SyncLoader } from "react-spinners";
import UniversalProgressBar from "../../../components/progessBar";
import SelectProperty from "../SelectProperty";
import {
  useGetOtaLinksMutation,
  usePropertyDetailsMutation,
} from "../../../redux/slices/onBoarding";
import { toast } from "react-toastify";
import AddOtaLink from "../signupStep3/AddOtaLink";
import ConfirmProperty from "../ConfirmProperty";
import { UserId } from "../../../utils/impVars";
const SinglePropertyStep2 = ({ compsetId }) => {
  const [lat, setLat] = useState();
  const [log, setLog] = useState();
  const [locState, setLocState] = useState(null);
  const [country, setCountry] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [manuallydata, setManuallyData] = useState(null);
  const [propertyData, setPropertyData] = useState();
  const navigate = useNavigate();
  const [propertyDetails] = usePropertyDetailsMutation();
  const [steps, setSteps] = useState({
    step1: true,
    step2: false,
    step3: false,
    step4: false,
  });
  const [selectedOTAs, setSelectedOTAs] = useState([]);
  const [manuallyselectedOTAs, setManuallySelectedOTAs] = useState([]);
  const handleSelectedOTAsChange = (updatedSelectedOTAs) => {
    setSelectedOTAs(updatedSelectedOTAs);
  };
  const handleManuallySelectedOTAsChange = (updatedSelectedOTAs) => {
    setManuallySelectedOTAs(updatedSelectedOTAs);
  };
  const locations = useLocation();
  // Assuming this code is inside a function or a block of code
  let activeOta = Array.isArray(data)
    ? data.map((item, index) => {
      return {
        otaId: item.otaId,
        otaLink: item?.link,
        otaName: item?.otaName
      };
    })
    : [];
  let otaArray = [];
  if (Array.isArray(selectedOTAs) && selectedOTAs.length > 0) {
    otaArray = selectedOTAs;
  } else if (
    Array.isArray(manuallyselectedOTAs) &&
    manuallyselectedOTAs.length > 0
  ) {
    otaArray = manuallyselectedOTAs;
  }
  // Access propsData from the location state
  const propertyName = locations.state && locations.state.propertyName;
  const userId = UserId()

  const selectedoption = locations.state && locations.state.selectedoption;
  const chainSize = locations.state && locations.state.propertySize;
  const onSubmit = (data) => {
    const userIdString = window.localStorage.getItem("userId");
    const hotelCity = localStorage.getItem('hotelCity').trim();
    const hotelName = localStorage.getItem('hotelName').trim();
    setLoading(false);
    let propertyDetailsData = {
      userId: userId,
      propertyType: selectedoption.value,
      propertyName: hotelName,
      cityCode: "", // Initialize cityCode to an empty string
      activeOta: [],
      otaDetails:[],
      address: {
        latitude: lat,
        longitude: log,
        city: hotelCity,
        state: locState,
        country: country,
        fullAddress: ""
      },
      propertyChainSize: chainSize,
    };
    otaArray.forEach((item, index) => {
      // Check if the otaName is MakeMyTrip and it has a cityCode
      if (item.otaName === 'MakeMyTrip' && item.cityCode) {
        // If found, assign MakeMyTrip's cityCode to propertyDetailsData.cityCode
        propertyDetailsData.cityCode = item.cityCode;
      }
      // Push modified ota object to activeOta
      propertyDetailsData.otaDetails.push({
        otaId: item.otaId,
        // otaPId: item.otaPId,
        otaName:item.otaName,
        otaLink:item?.link,
        // cityCode: item.cityCode,
        // competitor: [
        //   {
        //     competitorName: item.otaName,
        //     competitorLink: item.link,
        //   },
        // ],

        // otaDetails: activeOta
      });
      propertyDetailsData.activeOta.push({
        otaPId:item?.otaPId,
        otaId:item.otaId
      })
    });


    propertyDetails(propertyDetailsData)
      .unwrap()
      .then((res) => {
        setData(res.data);
        const newHId = res.details.hId;
        if (newHId) {
          window.localStorage.setItem("hId", newHId);
        }
        toast.success(res.message, { position: "bottom-right h-5 w-5" });
        setLoading(false);
      })

      .catch((err) => {
        toast.error(err.message, { position: "bottom-right" });
        setLoading(false);
      });
  };

  useEffect(()=>{
    let body = document.body
    body.style.overflow = ""
  })
  
  return (
    // <>   <FormProvider {...methods}>
    // <form onSubmit={methods.handleSubmit(onSubmit)}>
    <>
      {loading && (
        <Backdrop>
          <SyncLoader color="white" />
        </Backdrop>
      )}
      <div className="verify-email-container  bg-transparent">
        <div className="verify-email-main">
          <div className="flex w-full justify-center">
            <img className="img-logo" loading="lazy" src={Img} alt="" />
          </div>
          <div className="verify-email-content">
            <UniversalProgressBar
              setSteps={setSteps}
              steps={steps}
              totalSteps={Object.keys(steps).length}
              title={"Property Information"}
              stepCount={
                steps.step1
                  ? 1
                  : steps.step2
                    ? 2
                    : steps.step3
                      ? 3
                      : steps.step4 && 4
              }
            />
            <div>
              {steps.step1 && (
                <AddPropertyStep
                  lat={lat}
                  setLat={setLat}
                  setLog={setLog}
                  setCountry={setCountry}
                  setLocState={setLocState}
                  log={log}
                  setData={setData}
                  setSteps={setSteps}
                  steps={steps}
                />
              )}
              {steps.step2 && (
                <SelectProperty
                  data={data}
                  setSteps={setSteps}
                  steps={steps}
                  selectedOTAs={selectedOTAs}
                  onSelectedOTAsChange={handleSelectedOTAsChange}
                  onSubmit={onSubmit}
                />
              )}
              {steps.step3 && (
                <AddOtaLink
                  setData={setManuallyData}
                  setSteps={setSteps}
                  steps={steps}
                  data={data}
                />
              )}
              {steps.step4 && (
                <ConfirmProperty
                  setSteps={setSteps}
                  data={manuallydata}
                  steps={steps}
                  selectedOTAs={manuallyselectedOTAs}
                  onSelectedOTAsChange={handleManuallySelectedOTAsChange}
                  onSubmit={onSubmit}
                />
              )}
            </div>
          </div>
          {/* <SimpleButton text='Next' type='submit' /> */}
        </div>
      </div>
    </>

    //     </form>
    // </FormProvider>
    // </>
  );
};

export default SinglePropertyStep2;

export const AddPropertyStep = ({
  setSteps,
  steps,
  setData,
  setLat,
  setLog,
  setCountry,
  setLocState,
  lat,
  log,
}) => {
  const [getOtaLinks] = useGetOtaLinksMutation();
  const [address, setAddress] = useState(null);

  const [loading, setLoading] = useState(false);
  const hotelName = (address && address.split(",")[0]) || (address && address.split("|")[0]);

  const methods = useForm({});
  const [location, onLocationSelected] = useState(null);
  const [city, setCity] = useState(null);
  const [fullAddress, setFullAddress] = useState(null);

  const navigate = useNavigate();
  let globalApiResponse;

  const onSubmit = (data) => {
    if (!address) {
      toast.error("Please fill in the required fields.", {
        position: "bottom-right",
      });
      return;
    }
    setLoading(true);
    const getOtaLinksData = {
      hotelName: hotel,
      hotelCity: cityName,
    };

    getOtaLinks(getOtaLinksData)
      .unwrap()
      .then((res) => {
        setData(res.data);

        setSteps({
          step1: false,
          step2: true,
          step3: false,
          step4: false,
        });
        toast.success(res.message, { position: "bottom-right h-5 w-5" });
        localStorage.setItem('hotelCity', res.data[0].hotelCity);
        localStorage.setItem('hotelName', res.data[0].hotelName);


        // localStorage.setItem('globalApiResponse', JSON.stringify(globalApiResponse));
        // localStorage.setItem('otaImage', JSON.stringify(otaImage));
        //  localStorage.setItem('otaName', JSON.stringify(otaName));

        // navigate('/select_property')
        setLoading(false);
      })

      .catch((err) => {
        toast.error(err.message ? err.message : "Unable to fetch location", {
          position: "bottom-right",
        });
        setLoading(false);
      });
  };
  const [hotel, setHotel] = useState(hotelName || '');
  const [cityName, setCityName] = useState(city || '');
  useEffect(() => {
    // Set hotel and cityName state after city data is fetched
    if (city) {
      setHotel(hotelName || '');
      setCityName(city);
    }
  }, [city]);

  return (
    <>
      {loading && (
        <Backdrop>
          <SyncLoader color="white" />
        </Backdrop>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex items-center justify-center flex-col gap-2">
            <h1 className="title">Let’s Find Your Property</h1>
            <p className="subtitile">Pin your property in the map</p>
          </div>

          <div className="flex w-full justify-center"></div>

          <div className="flex flex-col w-full gap-4 p-5">
            <div className="w-full flex flex-col h-[400px] overflow-x-auto justify-center gap-4">
              <div className="w-full overflow-hidden">
                <MapComponent

                  apiKey="AIzaSyBRAnfSXzM-fQXpa751GkbMQDEuavUSDP0"
                  version="weekly"
                  libraries={["places"]}
                  lat={lat}
                  log={log}
                />
              </div>
              <div className="flex flex-col w-full items-center justify-center gap-0 rounded-lg drop-shadow-3xl bg-white ">
                <div className="w-[90%] rounded-lg overflow-hidden">
                  <InputField
                    type={"Address"}
                    address={address}
                    setAddress={setAddress}
                    id={"property_address"}
                    label={"Property Address"}
                    className={"w-full text-[15px]"}
                    control={methods.control}
                    name={"property_address"}
                    placeholder={"Property Address"}
                    rules={{
                      required: "Property Address is required",
                    }}
                    setValue={methods.setValue}
                    startIcon={Location}
                    setLat={setLat}
                    setLog={setLog}
                    setCity={setCity}
                    setLocState={setLocState}
                    setCountry={setCountry}
                  />
                </div>
              </div>
            </div>
            {!address && (
              <p className="text-red-500"> First find your property address</p>
            )}
            {address && (
              <div className="  flex flex-col items-center  w-full gap-2 ">

                <div className=" flex items-center text-black text-sm   w-full">
                  <label className="w-[28%]" htmlFor="hotelName">Hotel Name : </label>
                  <input
                    type="text"
                    id="hotelName"
                    value={hotel}
                    className="ml-1 w-[72%] h-10 border rounded-md pl-3 border-gray-400"
                    onChange={(e) => setHotel(e.target.value)}
                  />
                </div>
                <div className=" flex items-center text-black text-sm w-full">
                  <label htmlFor="city " className="w-[28%]"> City Name : </label>
                  <input
                    type="text"
                    id="city"
                    className="ml-2 w-[72%] h-10 border rounded-md pl-3 border-gray-400"
                    value={cityName}
                    onChange={(e) => setCityName(e.target.value)}
                  />
                </div>
              </div>
            )}
            <SimpleButton text="Next" type="submit" disabled={!address} />

          </div>
        </form>
      </FormProvider>
    </>
  );
};
