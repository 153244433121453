import React, { useEffect, useState } from 'react';
import '../Global.css';
import Img from '../../../assets/img/Branding.svg';
import { useNavigate } from 'react-router-dom';
import SimpleButton from '../../../components/button/SimpleButton';
import Business from '../../../assets/icons/onboarding/Business.svg';
import { FormProvider, useForm } from 'react-hook-form';
import InputField from '../../../components/inputFeild';
import MapComponent from '../../../components/mapPicker';
import Property from '../../../assets/icons/onboarding/Property.svg';
import { toast } from "react-toastify";
import { useAddNewCompsetMutation, useGetOtaLinksMutation } from '../../../redux/slices/onBoarding';
import UniversalProgressBar from '../../../components/progessBar';
import ChannelManagerSetUp from '../signupStep3/ChannelMangerSetUp';
import SelectCompetition from './SelectCompetetion';
import AddCompetitionOtaLink from './AddCompetitionOtaLink';
import ConfirmCompetition from './ConfirmCompetition';
import { UserId } from '../../../utils/impVars';

const CompetitionSetup = () => {
    const navigate = useNavigate();
    const [addNewCompset] = useAddNewCompsetMutation({

    })
    const userID = UserId()
    const [steps, setSteps] = useState({
        step1: true,
        step2: false,
        step3: false,
        step4: false,
        step5: false,
    })
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null)
    const [lat, setLat] = useState();
    const [log, setLog] = useState();
    const [locState, setLocState] = useState(null);
    const [country, setCountry] = useState(null);
    const [manuallydata, setManuallyData] = useState(null)
    const [selectedOTAs, setSelectedOTAs] = useState([]);
    const [manuallyselectedOTAs, setManuallySelectedOTAs] = useState([]);
    const handleSelectedOTAsChange = (updatedSelectedOTAs) => {
        setSelectedOTAs(updatedSelectedOTAs);
    };
    const handleManuallySelectedOTAsChange = (updatedSelectedOTAs) => {
        setManuallySelectedOTAs(updatedSelectedOTAs);
    };
    let otaArray = [];
    if (Array.isArray(selectedOTAs) && selectedOTAs.length > 0) {
        otaArray = selectedOTAs;
    } else if (Array.isArray(manuallyselectedOTAs) && manuallyselectedOTAs.length > 0) {
        otaArray = manuallyselectedOTAs;
    }

    const onSubmit = (data) => {

        setLoading(true)
        const updatedHId = localStorage.getItem('hId');
        const hotelName = localStorage.getItem('hotelName').trim();
        const hotelCity = localStorage.getItem('hotelCity').trim();
        const addNewCompsetData = {
            hId: updatedHId,
            userId:userID,
            address: {
                latitude: lat,
                longitude: log,
                city: hotelCity,
                state: locState,
                country: country
            },
            compsets: [
                {
                    propertyName: hotelName,
                    activeOta: [],
                    hotelCity: hotelCity,
                    cityCode: "",
                }
            ]
        };

        otaArray.forEach((item, index) => {
            // Check if the otaName is MakeMyTrip and it has a cityCode
            if (item.otaName === 'MakeMyTrip' && item.cityCode) {
                // If found, assign MakeMyTrip's cityCode to cityCode
                addNewCompsetData.compsets[0].cityCode = item.cityCode;
            }
            // Push modified ota object to activeOta
            addNewCompsetData.compsets[0].activeOta.push({
                otaName: item.otaName,
                otaId: item.otaId,
                otaPId: item.otaPId,
                cityCode: item.cityCode,
                link:item?.link
            });
        });

        addNewCompset(addNewCompsetData)
            .unwrap()
            .then((res) => {
                toast.success(res.message, { position: "bottom-right" });
                setLoading(false);
                if (res.code == 200) {
                    navigate('/confirmation_done');
                }
            })
            .catch((err) => {
                toast.error(err.message, { position: "bottom-right" });
                setLoading(false);
            });
    };
    useEffect(()=>{
        let body = document.body
        body.style.overflow = ""
      })
    return (

        <div className='verify-email-container'>
            <div className='verify-email-main'>
                <div className='flex w-full justify-center'>
                    <img className='img-logo' src={Img} alt='' />
                </div>
                <div className='verify-email-content'>
                    <UniversalProgressBar setSteps={setSteps} steps={steps} totalSteps={Object.keys(steps).length} title={"competition Information"} stepCount={steps.step1 ? 1 : steps.step2 ? 2 : steps.step3 ? 3 : steps.step4 ? 4 : steps.step5 && 5


                    }
                    />
                    {
                        steps.step1 && <ChannelManagerSetUp setSteps={setSteps} steps={steps} />
                    }
                    {
                        steps.step2 && <AddCompetiton lat={lat}
                            setLat={setLat}
                            setLog={setLog}
                            setLocState={setLocState}
                            setCountry={setCountry}
                            log={log} setSteps={setSteps} setData={setData} steps={steps} />
                    }
                    {
                        steps.step3 && <SelectCompetition selectedOTAs={selectedOTAs} onSelectedOTAsChange={handleSelectedOTAsChange} data={data} setSteps={setSteps} steps={steps} onSubmit={onSubmit} />
                    }
                    {
                        steps.step4 && <AddCompetitionOtaLink setData={setManuallyData} setSteps={setSteps} steps={steps} />
                    }
                    {
                        steps.step5 && <ConfirmCompetition data={manuallydata} setSteps={setSteps} steps={steps} selectedOTAs={manuallyselectedOTAs} onSelectedOTAsChange={handleManuallySelectedOTAsChange} onSubmit={onSubmit} />
                    }
                </div>
            </div>
        </div>

    )
}

export default CompetitionSetup;

const AddCompetiton = ({ setSteps, setData, steps,
    setLat,
    setLog,
    setLocState,
    setCountry,
    lat,
    log,
}) => {
    const [getOtaLinks] = useGetOtaLinksMutation()
    const [address, setAddress] = useState(null);

    const [loading, setLoading] = useState(false);
    const hotelName = address && address.split(",")[0] || address && address.split("|")[0]
    const methods = useForm({});
    const [city, setCity] = useState(null)
    const { control, handleSubmit, watch } = methods;
    const [location, onLocationSelected] = useState(null);
    const navigate = useNavigate();
    let globalApiResponse;
    const onSubmit = (data) => {
        if (!address) {
            toast.error("Please fill in the required fields.", { position: "bottom-right" });
            return;
        }
        setLoading(true);

        const getOtaLinksData = {
            hotelName: hotel,
            hotelCity: cityName,

        };
        setLoading(true)
        getOtaLinks(getOtaLinksData)
            .unwrap()
            .then((res) => {
                setData(res.data)
                localStorage.setItem('hotelCity', res.data[0].hotelCity);
                localStorage.setItem('hotelName', res.data[0].hotelName);
                setSteps({
                    step1: false, step2: false, step3: true, step4: false, step5: false
                })
                toast.success(res.message, { position: "bottom-right h-5 w-5" });


                // localStorage.setItem('globalApiResponse', JSON.stringify(globalApiResponse));
                // localStorage.setItem('otaImage', JSON.stringify(otaImage));
                //  localStorage.setItem('otaName', JSON.stringify(otaName));

                // navigate('/select_property')
                setLoading(false);
            })

            .catch((err) => {
                toast.error(err.message, { position: "bottom-right" });
                setLoading(false);
            });


    };
    const [hotel, setHotel] = useState(hotelName || '');
    const [cityName, setCityName] = useState(city || '');
    useEffect(() => {
        // Set hotel and cityName state after city data is fetched
        if (city) {
            setHotel(hotelName || '');
            setCityName(city);
        }
    }, [city]);

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className='flex items-center justify-center flex-col gap-2'>
                        <h1 className='title'>Pick Your Competition</h1>
                        <p className='subtitile'>Find and add your competition on OTAs same as yours</p>
                    </div>


                    <div className='flex flex-col w-full gap-4 p-5'>
                        <div className='w-full flex flex-row justify-center'>
                            <div className='flex flex-col w-full items-center justify-center gap-0 rounded-lg drop-shadow-3xl bg-white '>
                                <div className='w-[100%]'>
                                    <MapComponent apiKey="AIzaSyBRAnfSXzM-fQXpa751GkbMQDEuavUSDP0"
                                        version="weekly"
                                        libraries={['places']}
                                        lat={lat}
                                        log={log} />
                                </div>
                                <div className="w-full rounded-lg overflow-hidden">

                                    <InputField
                                        type={"Address"}
                                        address={address}
                                        setAddress={setAddress}
                                        id={"property_address"}
                                        label={"Property Address"}
                                        className={"w-full text-[15px]"}
                                        control={methods.control}
                                        name={"property_address"}
                                        placeholder={"Property Address"}
                                        rules={{
                                            required: "Property Address is required",
                                        }}
                                        setValue={methods.setValue}
                                        setLat={setLat}
                                        setLog={setLog}
                                        setCity={setCity}
                                        setLocState={setLocState}
                                        setCountry={setCountry}
                                    />
                                </div>
                            </div>
                        </div>
                        {!address && <p className="text-red-500"> First find your competition address  on OTAs</p>}
                        {address && (
                            <div className="  flex flex-col items-center  w-full gap-2 ">

                                <div className=" flex items-center text-black text-sm   w-full">
                                    <label className="w-[28%]" htmlFor="hotelName">Hotel Name : </label>
                                    <input
                                        type="text"
                                        id="hotelName"
                                        value={hotel}
                                        className="ml-1 w-[70%] h-10 border rounded-md pl-3 border-gray-400"
                                        onChange={(e) => setHotel(e.target.value)}
                                    />
                                </div>
                                <div className=" flex items-center text-black text-sm w-full">
                                    <label htmlFor="city " className="w-[28%]"> City Name : </label>
                                    <input
                                        type="text"
                                        id="city"
                                        className="ml-2 w-[72%] h-10 border rounded-md pl-3 border-gray-400"
                                        value={cityName}
                                        onChange={(e) => setCityName(e.target.value)}
                                    />
                                </div>
                            </div>
                        )}
                        <SimpleButton text='Next' type='submit' disabled={!address} />
                        {/* <div className='flex flex-col w-full gap-4 items-center'>
                            <div className='text-sm font-[600] text-[#3F70ED]'>Resend Code</div>
                            <div>
                                <p className='text-sm font-[400] text-[#0D1321]'>Entered Wrong Number?<span className='text-sm font-[400] text-[#3F70ED] pl-2'>Edit Mobile</span></p>
                            </div>
                        </div> */}
                    </div>
                </form>

            </FormProvider>
        </>

    )
}