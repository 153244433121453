import React, { useEffect, useState } from "react";


export const DateFormat = ({date}) => {
  const [foramt, setFormat] = useState('dd-MMM-yy');

  useEffect(() => {
      const localDateFormat = JSON.parse(localStorage.getItem('dateFormat'));
      if (localDateFormat) {
        setFormat(localDateFormat)
      } 
  }, []);

  return foramt;
};


export function isDate(dateString) {
  // Attempt to parse the date string using the ISO 8601 format
  const parsedDate = new Date(dateString);

  // Check if the parsed date is a valid date object
  return !isNaN(parsedDate.getTime());
}
export function formatDateWWeek(date) {

  date = new Date(date)
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
  const dayOfMonthSuffixes = ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th", "th", "th", "th", "th", "th", "th", "th", "th", "th", "th", "th", "th", "th", "th", "th", "th", "th", "th", "th", "th"]; // Add more elements for days 14-31

  const dayOfWeekIndex = date.getDay();
  const dayOfMonth = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct",   
 "Nov", "Dec"];   


  const formattedDayOfMonth = dayOfMonth + dayOfMonthSuffixes[dayOfMonth - 1]; // Use index 0 for day 1

  return `${formattedDayOfMonth} ${monthNames[monthIndex]} ${year}, ${daysOfWeek[dayOfWeekIndex]}`;
}