import React, { useEffect, useRef, useState } from "react";
import CustomSkeleton from "../../../components/Skeleton";
import NewRateChangeReportTable from "../../newTable/newRateRangeRepotrTable";
import Calendar from "../../../components/calendar";
import { AiFillCalendar, AiOutlineWhatsApp } from "react-icons/ai";
import { format } from "date-fns";
import useIntelligenceDateRange from "../../../utils/dateRange";
import { DateRangePicker } from "react-date-range";
import {
  useGetActualAndSuggestedRatesQuery,
  useOverrideRateMutation,
  usePushRateMutation,
} from "../../../redux/slices/rateSuggestions";
import { CurrentProperty, UserId } from "../../../utils/impVars";
import formatCurrency from "../../../utils/formatCurrency";
import { ProgressBar } from "../../newRatePulseDashboard";
import { increaseByPercentage } from "../../rateSuggestion/rateAnalysis";
import { RxCross2 } from "react-icons/rx";
import {
  useAcceptRateMutation,
  useVerifyRatesMutation,
} from "../../../redux/slices/newDashboard";
import { useIsIpad } from "../../../utils/isIpad";
import { useLocation, useNavigate } from "react-router-dom";
import Backdrop from "../../../components/backdrop";
import { toast } from "react-toastify";
import { useGetCompsetRatesQuery } from "../../../redux/slices/rateMonitor";

const DynamicRateChangeReport = ({
  data,
  dateRange,
  setDateRange,
  isLoading,
  setIsWhatsApp,
  sendToWhatsappAdmin,
  monthEndWhatsAppAdmin,
  handleDownload,
}) => {
  const dateLimit = useIntelligenceDateRange();
  const [showDateRange, setShowDateRange] = useState(false);

  const fromDate = format(new Date(dateLimit?.fromDate), "MM/dd/yyyy");
  const endDate = format(new Date(dateLimit?.toDate), "MM/dd/yyyy");
  const ref1 = useRef();

  const [selectedDateForDetail, setSelectedDateForDetail] = useState(
    new Date()
  );
  const [dynamicRateModal, setDynamicRateModal] = useState(false);
  const [compsetRateModal, setCompsetRateModal] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref1?.current && !ref1?.current.contains(event.target)) {
        setShowDateRange(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref1]);
  const [isDropdownOpen,setIsDropdownOpen]=useState(false)
const dropdownRef = useRef(null);

useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);
  function getDate30DaysAfter() {
    const today = new Date();
    const thirtyDaysLater = new Date(today);
    thirtyDaysLater.setDate(today.getDate() + 365);
    return thirtyDaysLater;
  }
  return (
    <>
      <div className="h-[80vh]">
        {dynamicRateModal && (
          <Backdrop>
            <div
              className="w-[80%] mx-auto  h-auto rounded-lg relative p-5"
              style={{
                background: "linear-gradient(45deg, #ffffffc4, #ffffff9c)",
              }}
            >
              <DynamicRateDetailModal
                selectedDate={selectedDateForDetail}
                setPushRateModal={setDynamicRateModal}
                pushRateModal={dynamicRateModal}
                toastAction={(res, val) => {
                  if (res) {
                    toast.success("Rates Push Request Successfully", {
                      position: "bottom-right",
                    });
                  } else {
                    toast.error(val, {
                      position: "bottom-right",
                    });
                  }
                }}
              />
            </div>
          </Backdrop>
        )}
        {compsetRateModal && (
          <Backdrop>
            <div
              className="w-[80%] mx-auto  h-auto rounded-lg relative p-5"
              style={{
                background: "linear-gradient(45deg, #ffffffc4, #ffffff9c)",
              }}
            >
              <CompsetMedianDetailModal
                selectedDate={selectedDateForDetail}
                setPushRateModal={setCompsetRateModal}
                pushRateModal={compsetRateModal}
              />
            </div>
          </Backdrop>
        )}
        <div className="h-[10%] my-4 flex items-center text-white dark:text-black justify-between">
          <h1 className="text-[24px] font-bold"> Dynamic Rate Change Report</h1>
          <div className="flex flex-row items-center">
            <div className=" flex items-center gap-2 justify-center pr-2">
              <div className="w-fit font-semibold text-[17px]">Select date</div>
              {
                <div
                  className="relative mb-2 w-fit justify-start text-xs cursor-pointer select-none flex gap-4 items-center mx-1 px-2 py-3 text-white dark:text-black rounded-md border-solid border border-[#A0A4B0] z-20"
                  ref={ref1}
                >
                  <div
                    className="flex  text-white dark:text-black  gap-2 items-center"
                    onClick={() => setShowDateRange(!showDateRange)}
                  >
                    {" "}
                    <AiFillCalendar size={14} />{" "}
                    {format(new Date(dateRange[0].startDate), "dd MMM yyy")} -{" "}
                    {format(new Date(dateRange[0].endDate), "dd MMM yyy")}
                  </div>
                  {showDateRange && (
                    <div className="absolute z-50 top-[50px] right-[0px]">
                      <DateRangePicker
                        ranges={dateRange}
                        onChange={(range) => {
                          setDateRange([
                            {
                              startDate: range?.range1?.startDate,
                              endDate: range?.range1?.endDate,
                              range: "range 1",
                            },
                          ]);
                        }}
                        minDate={new Date()}
                        maxDate={getDate30DaysAfter()}
                      />
                    </div>
                  )}
                </div>
              }
            </div>
            <div ref={dropdownRef} className="relative">
      {/* Main Button */}
      <div
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="flex m-auto w-[180px] gap-2 justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-xs text-white  font-medium text-[17px]"
      >
        <AiOutlineWhatsApp size={18} /> Send via WhatsApp
      </div>

     
        {isDropdownOpen && (
        <div className="absolute mt-2 w-[180px] text-white text-xs px-4 rounded-lg  shadow-lg dark:bg-[#1D3893] bg-[#3ec329]">
          <div
            onClick={() => {
              setIsDropdownOpen(false);
              setIsWhatsApp(); // Handle Send to Self
            }}
            className="flex gap-2 justify-start items-center p-2 cursor-pointer"
          >
            <AiOutlineWhatsApp size={18} /> Send to self
          </div>
          <div
            onClick={() => {
              setIsDropdownOpen(false);
              sendToWhatsappAdmin();
              // Handle Send to Personal Number
            }}
            className="flex gap-2 justify-start items-center p-2  cursor-pointer"
          >
            <AiOutlineWhatsApp size={18} /> Send to secondary 
          </div>
        </div>
      )}
    </div>
            <h1
              onClick={handleDownload}
              className="flex  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
            >
              Download
            </h1>

            {/* <div
              onClick={setIsWhatsApp}
              className="flex  w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
            >
              <AiOutlineWhatsApp size={18} /> Send to WhatsApp
            </div> */}
          </div>
        </div>{" "}
        {/* -------------------------------------------------------------------------- */
        /*                                 Metric Data                                */
        /* -------------------------------------------------------------------------- */}
        <div className="flex flex-col gap-2 mt-2 overflow-y-scroll h-[90%]">
          {!isLoading ? (
            <div className="flex flex-col ">
              <div className="flex flex-row justify-between w-[100%]"></div>

              {/* Dynamic RateChange Report */}
              <NewRateChangeReportTable
                data={data}
                label={"Dynamic Rates"}
                isExpanded={true}
                function1={(i, j, k) => {
                  console.log(i, j, k, "func2", data.body[i][0].value);
                  setDynamicRateModal(true);
                  setSelectedDateForDetail(data.body[i][0].value);
                }}
                function2={(i, j, k) => {
                  console.log(i, j, k, "func1", data.body[i][0].value);
                  setCompsetRateModal(true);
                  setSelectedDateForDetail(data.body[i][0].value);
                }}
                fullHeight={true}
              />
            </div>
          ) : (
            <div className="w-[100%] mt-[50px]">
              <CustomSkeleton height={400} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export const DynamicRateDetailModal = ({
  selectedDate,
  setPushRateModal,
  pushRateModal,
  toastAction,
}) => {
  const [overrideRate, { isLoading: overideRateLoading }] =
    useOverrideRateMutation();
  const [pushRate, { isLoading: pushRateLoading }] = usePushRateMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isTransition, setIsTransition] = useState(false);
  const isIpad = useIsIpad();
  const [isActive, setIsActive] = useState(false);
  const hId = CurrentProperty();
  const userId = UserId();
  const [inputValue, setInputValue] = useState("");
  const [acceptRate, { isLoading }] = useAcceptRateMutation();
  const [verifyRates, { isLoading: verifyRatesIsLoading }] =
    useVerifyRatesMutation();
  const [selectedDropdown, setSelectedDropdown] = useState();
  const [is7DayPrice, setIs7DayPrice] = useState(false);

  const [date, setDate] = useState({
    startDate: new Date(selectedDate),
    endDate: new Date(selectedDate),
  });

  const {
    data: getActualAndSuggestedRates,
    isLoading: getActualAndSuggestedRatesIsLoading,
    isFetching: getActualAndSuggestedRatesIsFetching,
  } = useGetActualAndSuggestedRatesQuery({
    userId: UserId(),
    startDate: format(new Date(date.startDate), "yyyy-MM-dd"),
    endDate: format(new Date(date.endDate), "yyyy-MM-dd"),
    hId: CurrentProperty(),
    type: "dynamic",
  },{
    refetchOnMountOrArgChange:true
  });

  const handlePushRateAction = () => {
    let rateDetails = [];
    getActualAndSuggestedRates?.data.map((rateData, index) => {
      rateDetails.push({
        rate: increaseByPercentage(
          parseInt(rateData?.suggestedRate),
          rateData?.discount
        ),
        date: format(new Date(rateData.date), "yyyy-MM-dd"),
      });
    });
    const verifyRateObj = {
      hId: hId,
    };
    const obj = {
      hId: hId,
      rateDetail: rateDetails,
    };
    pushRate(obj)
      .unwrap()
      .then((res) => {
        setPushRateModal(false);
        toastAction(true, "success");
        // refetch();
      })
      .catch((err) => {
        return toastAction(false, err?.data?.data?.message);
      });
  };
  return (
    <div className="w-[100%]">
      <div className="flex  flex-row w-[100%] h-[50px] space-between item-center">
        <button
          className=" left-0  text-left"
          onClick={() => {
            setPushRateModal(false);
          }}
        >
          <RxCross2 className="text-white text-[24px]" />
        </button>
        <h1 className="w-[100%]  text-[24px] text-black text-center font-bold">
          Dynamic Rate Detail
        </h1>
      </div>

      <div className="w-[100%] relative h-auto pb-4 overflow-y-auto rounded-md bg-transparent my-[10px]">
        <div className="flex w-[100%] border-b border-[#E1E1E1] px-4 py-6 bg-[#383c4f] text-white h-[45px] sticky top-0  gap-4  items-center">
          <div className="text-xs w-[100%] ">Dates</div>
          <div className="text-xs w-[100%] ">Occupancy</div>
          <div className="text-xs w-[100%] ">Current Rate</div>
          <div className="text-xs w-[100%] ">CM Rates</div>
          <div className="text-xs w-[100%] ">Discount</div>
          <div className="text-xs w-[100%] ">Dynamic Rate</div>

          <div className="text-xs w-[100%] ">Dynamic Rates in CM</div>
        </div>
        {getActualAndSuggestedRates?.data &&
        !getActualAndSuggestedRatesIsLoading &&
        !getActualAndSuggestedRatesIsFetching ? (
          getActualAndSuggestedRates?.data.map((item, index) => {
            return (
              <div
                key={index}
                className={`flex w-[100%] py-[10px] ${index % 2 === 0 ? "bg-newGradient dark:bg-lightGradient" : "bg-newGradient dark:bg-lightGradient"} text-white border-[#E1E1E1] group px-4 gap-4 items-center`}
              >
                <div className="w-[100%] text-sm">
                  {format(new Date(item?.date), "EEE, dd MMM yy")}
                </div>
                <div className="w-[100%] text-sm">
                  <p className="text-[12px]">{item?.occupancy}%</p>
                  <ProgressBar
                    width={120}
                    maxValue={100}
                    value={item?.occupancy}
                  />
                </div>
                <div className="w-[100%] text-sm">
                  {formatCurrency(item?.rate)}
                </div>
                <div className="w-[100%] text-sm">
                  {formatCurrency(item?.CMRate)}
                </div>
                <div className="w-[100%] text-sm">
                  <p className="text-[12px]">{item?.discount}%</p>
                  <ProgressBar
                    width={120}
                    maxValue={100}
                    value={item?.discount}
                  />
                </div>
                <div className="w-[100%] text-sm">
                  {formatCurrency(item?.dynamicRate)}
                </div>

                <div className="w-[100%] text-sm">
                  {formatCurrency(
                    increaseByPercentage(
                      parseInt(item?.dynamicRate),
                      item?.discount
                    )
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div className="w-[100%]">
            <CustomSkeleton height={100} />
          </div>
        )}
      </div>
    </div>
  );
};


export const CompsetMedianDetailModal = ({
  selectedDate,
  setPushRateModal,
  pushRateModal,
  toastAction,
}) => {
  const hId = CurrentProperty();

  const [date, setDate] = useState({
    startDate: new Date(selectedDate),
    endDate: new Date(selectedDate),
  });

  const {
    data: getCompsetRate,
    isLoading: getCompsetRateIsLoading,
    isFetching: getCompsetRateIsFetching,
  } = useGetCompsetRatesQuery({
    startDate: format(new Date(date.startDate), "yyyy-MM-dd"),
    endDate: format(new Date(date.endDate), "yyyy-MM-dd"),
    hId: CurrentProperty()
  });

  return (
    <div className="w-[100%]">
      <div className="flex  flex-row w-[100%] h-[50px] space-between item-center">
        <button
          className=" left-0 text-left"
          onClick={() => {
            setPushRateModal(false);
          }}
        >
          <RxCross2 className="text-white text-[24px]" />
        </button>
        <h1 className="w-[100%]  text-[24px] text-black text-center font-bold">
          Compset Rate Detail
        </h1>
      </div>

      <div className="w-[100%] relative h-auto pb-4 overflow-y-auto rounded-md bg-transparent my-[10px]">
        <div className="flex w-[100%] border-b border-[#E1E1E1] px-4 py-6 bg-[#383c4f] text-white h-[45px] sticky top-0  gap-4  items-center">
          <div className="text-xs w-[100%] ">Dates</div>
          <div className="text-xs w-[100%] ">Property Name</div>
          <div className="text-xs w-[100%] ">Average Rate</div>
        </div>
        {getCompsetRate?.data &&
        !getCompsetRateIsLoading &&
        !getCompsetRateIsFetching ? (
          getCompsetRate?.data.map((item, index) => {
            return (
              <div
                key={index}
                className={`flex w-[100%] py-[10px] ${index % 2 === 0 ? "bg-newGradient dark:bg-lightGradient" : "bg-newGradient dark:bg-lightGradient"} text-white border-[#E1E1E1] group px-4 gap-4 items-center`}
              >
              <div className="w-[100%] text-sm">
                {item?.ratesDate?.[0]?.date}
              </div>
                <div className="w-[100%] text-sm">
                  {item?.hotelName}
                </div>
                <div className="w-[100%] text-sm">
                  {item?.ratesDate?.[0]?.averageRate}
                </div>
              </div>
            );
          })
        ) : (
          <div className="w-[100%]">
            <CustomSkeleton height={100} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DynamicRateChangeReport;
