import React, { useRef, useState } from "react";
import RevenueTable from "../../newTable/RevenueTable";
import Calendar from "../../../components/calendar";
import { DownloadRevenueReport } from "../DownloadExcel/RevenueReport";
import CustomSkeleton from "../../../components/Skeleton";
import { AiFillCalendar, AiOutlineWhatsApp } from "react-icons/ai";
import { formatDate } from "../DownloadExcel/formatDate";
import { useEffect } from "react";
import { format } from "date-fns";
import { DateRangePicker } from "react-date-range";
import NoDataFound from "../../../components/noData";
import useIntelligenceDateRange from "../../../utils/dateRange";
import whatsappBusiness from '../../../assets/img/whatsapp-business-icon.svg'

const RevenueReportTable = ({
  data,
 setDateRange,
 dateRange,
  isLoading,
  setIsWhatsApp,
  paceWhatsAppAdmin,
  sendToWhatsappAdmin
}) => {
  const dateLimit=useIntelligenceDateRange();

  const [expandedIndex, setExpandedIndex] = useState(0);
  const [showDateRange, setShowDateRange] = useState(false);
  const ref1 = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref1?.current && !ref1?.current.contains(event.target)) {
        setShowDateRange(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref1]);
  const [isDropdownOpen,setIsDropdownOpen]=useState(false)
const dropdownRef = useRef(null);

useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  if (!data && !isLoading) {
    // Render a fallback UI or an error message when data is undefined
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center justify-center text-white dark:text-black p-2 font-semibold">
          <p>Revenue Report - </p>
        </div>
        <div className="m-3 w-full flex justify-end items-end">
          <div className="w-fit"></div>
          <h1
            onClick={() => {}}
            className="flex  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            Download
          </h1>
          <div
            onClick={setIsWhatsApp}
            className="flex  w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            <AiOutlineWhatsApp size={18} /> Send to WhatsApp
          </div>
        </div>
        <div className="h-full flex items-center justify-center w-[100%]">
          {/* <CustomSkeleton height={500} /> */}
          

        <NoDataFound/>
          
        </div>
      </div>
    );
  }
  if (isLoading) {
    // Render a fallback UI or an error message when data is undefined
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center text-white dark:text-black justify-center p-2 font-semibold">
          <p>Revenue Report  </p>
        </div>
        <div className="w-[100%]">
          <CustomSkeleton isTransparent={true} height={500} animated={true} title={'Revenue Report'} />
          {/* No data found */}
        </div>
      </div>
    );
  }

  const { monthlySourceData, MonthlyRoomData, yearSourceData, yearlyRoomData } =
    data;  
    const fromDate = format( new Date(dateLimit?.fromDate), 'MM/dd/yyyy');
    const endDate = format( new Date(dateLimit?.toDate), 'MM/dd/yyyy');
  const propertyName = window.localStorage.getItem("propertyName");
  
  return (
    <>
    
      <div className="flex top-0 sticky items-center justify-center p-2 font-semibold">
        <p className="sticky text-white dark:text-black">Revenue Report - {propertyName} {`(  ${format(new Date(dateRange[0].startDate), "dd MMM yyy") || formatDate(data.asOn?.startDate)} - ${ format(new Date(dateRange[0].endDate), "dd MMM yyy") ||formatDate(data.asOn?.endDate)})`}</p>
      </div>
      <div className="m-3 flex sticky top-[40px] items-center justify-end w-[100%] ">
        <div className="w-fit">
        <div className=" flex items-center gap-2 justify-center text-white dark:text-black pr-2">
              <div className="w-fit text-sm ">Select date range</div>
              {
                <div
                  className="relative mb-2 w-fit justify-start text-xs cursor-pointer select-none flex gap-4 items-center mx-1 px-2 py-3 rounded-md border-solid border border-[#A0A4B0] z-20"
                  ref={ref1}
                >
                  <div
                    className="flex gap-2 items-center"
                    onClick={() => setShowDateRange(!showDateRange)}
                  >
                    {" "}
                    <AiFillCalendar size={14} />{" "}
                    {format(new Date(dateRange[0]?.startDate), "dd MMM yyy")} -{" "}
                    {format(new Date(dateRange[0]?.endDate), "dd MMM yyy")}
                  </div>
                  {showDateRange && (
                    <div className="absolute top-[50px] -left-[200px]">
                      <DateRangePicker
                        ranges={dateRange}
                        onChange={(range) => {
                          setDateRange(
                           [ {
                              startDate: range?.range1?.startDate,
                              endDate: range?.range1?.endDate,
                              range: "range 1",
                            },]
                          );
                        }}
                        minDate={new Date(fromDate?fromDate:'')} // Example: Restrict to dates starting from today
                        maxDate={new Date(endDate?endDate:'')} 
                      />
                    </div>
                  )}
                </div>
              }
             
            </div>
        </div>
        <h1
          onClick={() => {
            DownloadRevenueReport(data);
          }}
          className=" flex  justify-center items-center  rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
        >
          Download
        </h1>{" "}
        <div ref={dropdownRef} className="relative">
      {/* Main Button */}
      <div
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="flex m-auto w-[180px] gap-2 justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-xs text-white  font-medium text-[17px]"
      >
        <AiOutlineWhatsApp size={18} /> Send via WhatsApp
      </div>

     
        {isDropdownOpen && (
        <div className="absolute mt-2 w-[180px] text-white text-xs px-4 rounded-lg  shadow-lg dark:bg-[#1D3893] bg-[#3ec329]">
          <div
            onClick={() => {
              setIsDropdownOpen(false);
              setIsWhatsApp(); // Handle Send to Self
            }}
            className="flex gap-2 justify-start items-center p-2 cursor-pointer"
          >
            <AiOutlineWhatsApp size={18} /> Send to self
          </div>
          <div
            onClick={() => {
              setIsDropdownOpen(false);
              sendToWhatsappAdmin();
              // Handle Send to Personal Number
            }}
            className="flex gap-2 justify-start items-center p-2  cursor-pointer"
          >
            <AiOutlineWhatsApp size={18} /> Send to secondary 
          </div>
        </div>
      )}
    </div>
        {/* <div
            onClick={() => {
              sendToWhatsappAdmin();
          
            }}
            className="flex w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-4 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
            >
          <img src={whatsappBusiness} height={20} width={20} alt="" /> Send to Group
          </div> */}
      </div>

        <div className="flex flex-col gap-2 overflow-y-auto h-[80%] ">
          <RevenueTable
            data={monthlySourceData}
            sourceMonth={true}
            isExpanded={expandedIndex === 0}
            onExpand={() => handleExpand(0)}
          />
          <RevenueTable
            data={MonthlyRoomData}
            roomMonth={true}
            room={true}
            isExpanded={expandedIndex === 1}
            onExpand={() => handleExpand(1)}
          />
          <RevenueTable
            data={yearSourceData}
            sourceYear={true}
            isExpanded={expandedIndex === 2}
            onExpand={() => handleExpand(2)}
          />
          <RevenueTable
            data={yearlyRoomData}
            roomYear={true}
            room={true}
            isExpanded={expandedIndex === 3}
            onExpand={() => handleExpand(3)}
          />
        </div>
    </>
  );
};

export default RevenueReportTable;
