import React, { useEffect, useState } from "react";
import arrowDown from "../../assets/icons/Group 356.svg";
import arrowUp from "../../assets/icons/uparrow.svg";
import { format } from "date-fns";
import NoDataFound from "../../components/noData";


const RateChangeReportTable = ({ data, room, filename, defaultSortColumn, label, isExpanded,source,
    onExpand, }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [currentPage, setCurrentPage] = useState(1);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const rowsPerPage = 5; // Change this value as per your requirement
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    let currentRows = [];

    if (Array.isArray(data)) {
        const indexOfLastRow = currentPage * rowsPerPage;
        const indexOfFirstRow = indexOfLastRow - rowsPerPage;
        currentRows = data
        // .slice(indexOfFirstRow, indexOfLastRow);
    } else {
    }

    useEffect(() => {
        if (defaultSortColumn) {
            setSortColumn(defaultSortColumn);
        }
    }, [defaultSortColumn]);

    const sortedData = currentRows.slice().sort((a, b) => {
        if (sortColumn) {
            const valueA = a[sortColumn];
            const valueB = b[sortColumn];
            if (valueA < valueB) return sortDirection === "asc" ? 1 : -1;
            if (valueA > valueB) return sortDirection === "asc" ? -1 : 1;
        }
        return 0;
    });

    return (
        <div onClick={onExpand} className={`w-auto flex flex-col bg-newGradient dark:bg-lightGradient border rounded-lg  cursor-pointer`}>
            <div className="w-[100%] flex p-2 justify-center items-center">
                <div className="w-[50%] flex flex-col  text-white  justify-around">
                    <div className="pl-4">
                        <p className=" text-[16px]">
                          {label}
                        </p>
                        <p className=" text-sm">
                            {data ? data?.length : 0} results found
                        </p>
                    </div>
                </div>
                <div className="w-[50%] flex justify-end pr-6">
                    <img src={isExpanded ? arrowUp : arrowDown} alt="" />
                </div>
            </div>
            {isExpanded && (
                <>
                {data ?  <div className={`text-white`}>
                    <div className="w-[100%] border-b-4 flex p-2 justify-center items-center">
                        <div className="w-[20%] text-center text-base font-medium flex items-center">
                            <p className="w-[55%]">{room ? "Rooms" : source ? 'Source' : "Date"}</p>
                            {sortColumn === (room ? "Rooms" : source ? 'Source' : "Date") && (
                                <img src={sortDirection === "asc" ? arrowUp : arrowDown} alt="Sort Indicator" className="w-4 h-4" />
                            )}
                        </div>
                        <div className="w-[20%] text-center text-base font-medium">
                            <p className="w-[70%]">Cancellation</p>
                        </div>
            
                    </div>
                    <div className="w-[100%] flex flex-col">
                        <div class="h-[250px] scroll-container">
                            {sortedData.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <DataRow
                                            key={index}
                                            item={item}
                                            index={indexOfFirstRow + index}
                                            selectedRows={selectedRows}
                                            room={room}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>:<NoDataFound />}
                </>
               
            )}
        </div>
    );
};

export default RateChangeReportTable;

const DataRow = ({
    data,
    room,
    item,
    index,
    selectedRows,
    setSelectedIndex,
    arrival=true,
    night,
    reservation
}) => {
    Array.prototype.random = function () {
        return this[Math.floor(Math.random() * this.length)];
    };
    const color = [
        "#1abc9c",
        "#f1c40f",
        "#2ecc71",
        "#2980b9",
        "#fad390",
        "#f8c291",
        "#60a3bc",
        "#4a69bd",
    ];

    const isSelected = selectedRows.includes(index);

    if (isSelected) {
    }

    return (
        <div className="flex flex-col py-2">
            <div
                className={`w-[100%] flex p-2 gap-2 justify-center items-center cursor-pointer`}

            >
             
                {arrival && <div className="w-[20%] text-center text-base font-medium flex items-center">
                    <p className="w-[55%]">{item?.date ? format(new Date(item.date),'dd-MMM-yyyy'): item?.source ? item?.source :item?.roomType }</p>
                </div>}
                {arrival && <div className="w-[20%] text-center text-base font-medium flex items-center">
                    <p className={`w-[55%]`}>
                        {item?.cancellations}
                    </p>
                </div>}
            
             
            </div>
        </div>
    );
};


