import React, { useEffect, useRef, useState } from "react";
import ParityReportTable from "../../newTable/ParityReportTable";
import formatCurrency from "../../../utils/formatCurrency";
import Calendar from "../../../components/calendar";
import { DownloadParityReport } from "../DownloadExcel/ParityReport";
import { AiFillCalendar, AiOutlineWhatsApp } from "react-icons/ai";
import CustomSkeleton from "../../../components/Skeleton";
import { formatDate } from "../DownloadExcel/formatDate";
import { format } from "date-fns";
import { DateRangePicker } from "react-date-range";
import NoDataFound from "../../../components/noData";
import useIntelligenceDateRange from "../../../utils/dateRange";
import whatsappBusiness from "../../../assets/img/whatsapp-business-icon.svg";
import RatesReportTable from "../../newTable/RatesReportTable";
import { ratesReportDownload } from "../DownloadExcel/RatesReport";

const RatesReport = ({
  data,
  dateRange,
  setDateRange,
  setIsWhatsApp,
  isLoading,
  sendToWhatsappAdmin,
  parityWhatsAppAdmin,
  asOn,
}) => {
  const [expandedIndex, setExpandedIndex] = useState(0);
  const dateLimit = useIntelligenceDateRange();
  const [showDateRange, setShowDateRange] = useState(false);
  const [transformedData, setTransformedData] = useState([]);
  const ref1 = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref1?.current && !ref1?.current.contains(event.target)) {
        setShowDateRange(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref1]);
  const [isDropdownOpen,setIsDropdownOpen]=useState(false)
  const dropdownRef = useRef(null);
  
  useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsDropdownOpen(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
  
      // Cleanup the event listener on component unmount
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [dropdownRef]);
  useEffect(() => {
    const fetchData = async () => {
      if (data) {
        const result = await Promise.all(
          data.map((item) => transformData(item, "₹"))
        );

        setTransformedData(result);
      }
    };
    fetchData();
  }, [data]);

  const handleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  if (!data && !isLoading) {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center text-white dark:text-black justify-center p-2 font-semibold">
          <p>Rates Report - </p>
        </div>
        <div className="m-3 w-full flex justify-end items-end">
          <div className="w-fit"></div>
          <h1
            onClick={() => {}}
            className="flex  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            Download
          </h1>
          <div
            onClick={setIsWhatsApp}
            className="flex  w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            <AiOutlineWhatsApp size={18} /> Send to WhatsApp
          </div>
        </div>
        <div className="h-full flex items-center justify-center w-[100%]">
          <NoDataFound />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center justify-center p-2 font-semibold">
          <p>Rates Report - </p>
        </div>
        <div className="m-3 w-full flex justify-end items-end">
          <div className="w-fit"></div>
          <h1
            onClick={() => {}}
            className="flex  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            Download
          </h1>
          <div
            onClick={setIsWhatsApp}
            className="flex  w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            <AiOutlineWhatsApp size={18} /> Send to WhatsApp
          </div>
        </div>
        <div className="w-[100%]">
          <CustomSkeleton isTransparent={true}  animated={true} height={500} title={'Rates Report'} />
        </div>
      </div>
    );
  }

  function ColoredRate({ parity, rateData, otaId }) {
    const rate = rateData.find((rate) => rate.otaId === otaId)?.rate;
    if (parity && rate) {
      return <span style={{ color: "green" }}>{formatCurrency(rate)}</span>;
    } else if (!parity && rate) {
      return <span style={{ color: "red" }}>{formatCurrency(rate)}</span>;
    } else {
      return <span style={{ color: "blue" }}>Sold out</span>;
    }
  }

  async function transformData(data, currencySymbol = "₹") {
    const OTAs = [
      "Booking.com",
      "Goibibo",
      "Agoda",
      "MakeMyTrip",
      "Trip.com",
      "EaseMyTrip",
      "HappyEasyGo",
      "ClearTrip",
      "HRS",
    ];

    const defaultRate = "Sold out";

    function getUniqueOtaNames(data) {
      const uniqueOtas = new Set();

      for (const item of data) {
        for (const rateData of item.rateData) {
          uniqueOtas.add(rateData.otaName);
        }
      }

      return Array.from(uniqueOtas);
    }

    let otasPresent = getUniqueOtaNames(data.ratesDate);

    let res = data.ratesDate.map((entry) => {
      let transformedEntry = {
        "Hotel Name": data.hotelName,
        Date: format(new Date(entry.date), "dd MMM yyyy"),
        Day: new Date(entry.date).toLocaleString("en-GB", { weekday: "short" }),
      };

      otasPresent.forEach((ota) => {
        transformedEntry[ota] = "Sold Out";
      });

      entry.rateData.forEach((rateEntry) => {
        transformedEntry[rateEntry.otaName] =
          `${currencySymbol}${rateEntry.rate}`;
      });

      return transformedEntry;
    });

    return res;
  }

  const fromDate = format(new Date(dateLimit?.fromDate), "dd MMM yyyy");
  const endDate = format(new Date(dateLimit?.toDate), "dd MMM yyyy");
  const propertyName = window.localStorage.getItem("propertyName");
  return (
    <>
      <div className=" flex sticky top-0 bg-black dark:bg-white items-center justify-center p-2 font-semibold">
        <p className="text-white dark:text-black" >
          Rates Report - {propertyName}{" "}
          {`(  ${format(new Date(dateRange[0].startDate), "dd MMM yyy") || formatDate(asOn?.startDate)} - ${format(new Date(dateRange[0].endDate), "dd MMM yyy") || formatDate(asOn?.endDate)})`}{" "}
        </p>
      </div>
      <div className="sticky top-[40px] bg-black dark:bg-white  w-full flex justify-end  items-center">
        <div className=" m-3 w-full text-white dark:text-black  flex justify-end gap-2 items-center">
          <div className="w-fit text-sm">Select date range</div>
          {
            <div
              className="relative  mb-2 w-fit justify-start text-xs cursor-pointer select-none flex gap-4 items-center mx-1 px-2 py-3  rounded-md border-solid border border-[#fffff]"
              ref={ref1}
            >
              <div
                className="flex gap-2 items-center"
                onClick={() => setShowDateRange(!showDateRange)}
              >
                {" "}
                <AiFillCalendar size={14} />{" "}
                {format(new Date(dateRange[0].startDate), "dd MMM yyy")} -{" "}
                {format(new Date(dateRange[0].endDate), "dd MMM yyy")}
              </div>
              {showDateRange && (
                <div className="absolute z-50 top-[50px] -left-[200px]">
                  <DateRangePicker
                    ranges={dateRange}
                    onChange={(range) => {
                      setDateRange([
                        {
                          startDate: range?.range1?.startDate,
                          endDate: range?.range1?.endDate,
                          range: "range 1",
                        },
                      ]);
                    }}
                    minDate={new Date(fromDate)}
                    maxDate={new Date(endDate)}
                  />
                </div>
              )}
            </div>
          }
          <h1
            onClick={() => {
              ratesReportDownload(data, data[0].hotelName, dateRange[0].startDate,dateRange[0].endDate);
            }}
            className="flex  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            Download
          </h1>
          <div ref={dropdownRef} className="relative">
      {/* Main Button */}
      <div
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="flex m-auto w-[180px] gap-2 justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-xs text-white  font-medium text-[17px]"
      >
        <AiOutlineWhatsApp size={18} /> Send via WhatsApp
      </div>

     
        {isDropdownOpen && (
        <div className="absolute mt-2 w-[180px] text-white text-xs px-4 rounded-lg  shadow-lg dark:bg-[#1D3893] bg-[#3ec329]">
          <div
            onClick={() => {
              setIsDropdownOpen(false);
              setIsWhatsApp(); // Handle Send to Self
            }}
            className="flex gap-2 justify-start items-center p-2 cursor-pointer"
          >
            <AiOutlineWhatsApp size={18} /> Send to self
          </div>
          <div
            onClick={() => {
              setIsDropdownOpen(false);
              sendToWhatsappAdmin();
              // Handle Send to Personal Number
            }}
            className="flex gap-2 justify-start items-center p-2  cursor-pointer"
          >
            <AiOutlineWhatsApp size={18} /> Send to secondary 
          </div>
        </div>
      )}
    </div>
          {/* <div
            onClick={sendToWhatsappAdmin}
            className="flex  w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            <img src={whatsappBusiness} height={20} width={20} alt="" /> Send to
            Group
          </div> */}
        </div>
      </div>

      <div
        className={`${transformedData.length === 0 && "h-screen"} flex flex-col gap-2  overflow-y-auto`}
      >
        {transformedData.map((data, index) => {
          return (
            <>
              {data.length > 0 && (
                <RatesReportTable
                  key={index}
                  arrival={true}
                  data={data}
                  isExpanded={expandedIndex === index}
                  onExpand={() => handleExpand(index)}
                />
              )}
            </>
          );
        })}
      </div>
    </>
  );
};
// ?.map(obj => {
//   const { ['Hotel Name']: _, ...rest } = obj; // Destructure and omit the key
//   return rest; // Return the object without the key
// })
export default RatesReport;
