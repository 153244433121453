import ExcelJS from "exceljs";
import { logoUrl } from "./retvensLogo.js";
import { formatDate } from "./formatDate.js";

export const DownloadHotelProductivityReport = async (
  data,
  hotelName,
  dayStart
) => {
  try {
    const workbook = new ExcelJS.Workbook();
    const overviewWorksheet = workbook.addWorksheet("Overview");
    const worksheet = workbook.addWorksheet("Data");
    const reputationWorksheet = workbook.addWorksheet("Reputation");

    const logoBase64 = logoUrl; // Add image at B1
    const logoId = workbook.addImage({
      base64: logoBase64,
      extension: "jpg",
    });

    worksheet.mergeCells("A1:Y3");
    worksheet.addImage(logoId, "A1:A3");
    reputationWorksheet.mergeCells("A1:P3");
    reputationWorksheet.addImage(logoId, "A1:A3");
    overviewWorksheet.mergeCells("A1:P3");
    overviewWorksheet.addImage(logoId, "A1:A3");
    const columns = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
    ];
    const columnWidths = [
      15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15,
      15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15,
    ];

    // Adjust widths as needed
    columns.forEach((column, index) => {
      worksheet.getColumn(column).width = columnWidths[index];
    });
    const reputationColumns = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
    ];
    const reputationColumnsWidths = [
      15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15,
    ];

    // Adjust widths as needed
    reputationColumns.forEach((column, index) => {
      reputationWorksheet.getColumn(column).width =
        reputationColumnsWidths[index];
    });
    const overviewColumnsWidths = [
      25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25,
      25, 25, 25, 25, 25, 25,
    ];

    // Adjust widths as needed
    reputationColumns.forEach((column, index) => {
      overviewWorksheet.getColumn(column).width = overviewColumnsWidths[index];
    });

    // Display today's date at J1
    const dateCell = worksheet.getCell("Y1");
    dateCell.value = new Date();
    dateCell.numFmt = "dd mmmm yyyy";
    const reputationDateCell = reputationWorksheet.getCell("P1");
    reputationDateCell.value = new Date();
    reputationDateCell.numFmt = "dd mmmm yyyy";
    const overviewDateCell = overviewWorksheet.getCell("P1");
    overviewDateCell.value = new Date();
    overviewDateCell.numFmt = "dd mmmm yyyy";

    // Move main heading to A2 to G2 and increase row height
    const mainHeading = worksheet.getCell("A4");
    mainHeading.value = `Hotel Productivity Report \nHotel ${hotelName} `;
    mainHeading.alignment = { horizontal: "center", vertical: "middle" };
    mainHeading.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "B7D166" }, // Yellow color
    };

    const reputationMainHeading = reputationWorksheet.getCell("P4");
    reputationMainHeading.value = `Hotel Productivity Report \nHotel ${hotelName} `;
    reputationMainHeading.alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    reputationMainHeading.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "B7D166" }, // Yellow color
    };

    const overviewMainHeading = overviewWorksheet.getCell("P4");
    overviewMainHeading.value = `Hotel Productivity Report \nHotel ${hotelName} `;
    overviewMainHeading.alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    overviewMainHeading.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "B7D166" }, // Yellow color
    };

    worksheet.mergeCells("A4", "Y4");
    worksheet.getRow(4).height = 40; // Increase row height
    worksheet.mergeCells("A5", "Y5");

    reputationWorksheet.mergeCells("A4", "P4");
    reputationWorksheet.getRow(4).height = 40; // Increase row height
    reputationWorksheet.mergeCells("A5", "P5");

    overviewWorksheet.mergeCells("A4", "P4");
    overviewWorksheet.getRow(4).height = 40; // Increase row height
    overviewWorksheet.mergeCells("A5", "P5");

    let monthWiseData = data.monthWiseBreakdown;
    let monthWiseLastYearBreakdown = data.monthWiseLastYearBreakdown;
    let lastWeekReputation = data.lastWeekReputation;
    let thisWeekReputation = data.thisWeekReputation;
    let growthComparison = data.growthComparison;
    let mOm = growthComparison.mOm;

    //momGrowth

    let momGrowthHeading = [
      "Revenue Growth",
      "",
      "",
      "",
      "",
      "ADR Growth",
      "",
      "",
      "",
      "",
      "Room Nights Growth",
    ];

    overviewWorksheet.addRow(momGrowthHeading).eachCell((cell) => {
      cell.font = {
        family: 4,
        size: 16,
        bold: true,
      };
    });

    let mainmOmHeader = [
      ...mOm?.revenue?.headerData,
      "",
      "",
      ...mOm?.adr?.headerData,
      "",
      "",
      ...mOm?.rns?.headerData,
    ];
    overviewWorksheet.addRow(mainmOmHeader).eachCell((cell) => {
      if (
        cell?._value?.model?.value != "" &&
        cell?._value?.model?.value != " " &&
        cell?._value?.model?.value != "RemoveSpace"
      ) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "B7D166" },
        };
      }
      cell.border = {
        top: { style: "thin", color: { argb: "ffffff" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });
    mOm?.revenue?.bodyData.map((DATA3, id) => {
      let finalOverviewMomBodyData = monthWiseLastYearBreakdown?.bodyData?.[id]
        ? [
            ...DATA3,
            "",
            "",
            ...mOm?.adr?.bodyData?.[id],
            "",
            "",
            ...mOm?.rns?.bodyData?.[id],
          ]
        : [...DATA3];
      overviewWorksheet.addRow(finalOverviewMomBodyData).eachCell((cell) => {
        if (DATA3.length !== 1) {
          if (
            cell?._value?.model?.value != "" &&
            cell?._value?.model?.value != " " &&
            cell?._value?.model?.value != "RemoveSpace"
          ) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "ECEFCE" },
            };
          }
        }
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });
    });

    worksheet.addRow([]);

    //monthwise breakdown

    let mainDatanHeading = ["Hotel Summary for This year"];
    monthWiseData.headerData.map((xyz) => {
      mainDatanHeading.push("");
    });
    mainDatanHeading.push("", "Hotel Summary for Last year");

    worksheet.addRow(mainDatanHeading).eachCell((cell) => {
      cell.font = {
        family: 4,
        size: 16,
        bold: true,
      };
    });

    let mainMonthwiseHeader = [
      ...monthWiseData.headerData,
      "",
      "",
      ...monthWiseLastYearBreakdown.headerData,
    ];
    worksheet.addRow(mainMonthwiseHeader).eachCell((cell) => {
      if (
        cell?._value?.model?.value != "" &&
        cell?._value?.model?.value != " " &&
        cell?._value?.model?.value != "RemoveSpace"
      ) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "B7D166" },
        };
      }
      cell.border = {
        top: { style: "thin", color: { argb: "ffffff" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });
    monthWiseData.bodyData.map((DATA3, id) => {
      let finalMonthwiseBodyData = monthWiseLastYearBreakdown?.bodyData[id]
        ? [...DATA3, "", "", ...monthWiseLastYearBreakdown.bodyData[id]]
        : [...DATA3];
      worksheet.addRow(finalMonthwiseBodyData).eachCell((cell) => {
        if (DATA3.length !== 1) {
          if (
            cell?._value?.model?.value != "" &&
            cell?._value?.model?.value != " " &&
            cell?._value?.model?.value != "RemoveSpace"
          ) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "ECEFCE" },
            };
          }
        }
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });
    });

    worksheet.addRow([]);

    //last week breakdown

    let mainReputationHeading = ["Hotel Reputation & Visibility Last Week"];
    lastWeekReputation.headerData.map((xyz) => {
      mainReputationHeading.push("");
    });
    mainReputationHeading.push(
      "",
      "",
      "Hotel Reputation & Visibility This Week"
    );

    reputationWorksheet.addRow(mainReputationHeading).eachCell((cell) => {
      cell.font = {
        family: 4,
        size: 16,
        bold: true,
      };
    });
    let newHeaderReputationData = [
      ...lastWeekReputation.headerData,
      "",
      "",
      ...thisWeekReputation.headerData,
    ];
    reputationWorksheet.addRow(newHeaderReputationData).eachCell((cell) => {
      if (
        cell?._value?.model?.value != "" &&
        cell?._value?.model?.value != " " &&
        cell?._value?.model?.value != "" &&
        cell?._value?.model?.value != "RemoveSpace"
      ) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "B7D166" },
        };
      }
      cell.border = {
        top: { style: "thin", color: { argb: "ffffff" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });
    lastWeekReputation.bodyData.map((DATA3, id) => {
      let newBodyReputationData = [
        ...DATA3,
        "",
        "",
        ...thisWeekReputation.bodyData[id],
      ];
      reputationWorksheet.addRow(newBodyReputationData).eachCell((cell) => {
        if (DATA3.length !== 1) {
          if (
            cell?._value?.model?.value !== "" &&
            cell?._value?.model?.value !== " " &&
            cell?._value?.model?.value !== "" &&
            cell?._value?.model?.value !== "RemoveSpace"
          ) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "ECEFCE" },
            };
          }
        }
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });
      if ((id + 1) % 3 === 0) {
        reputationWorksheet.addRow([]);
      }
    });

    reputationWorksheet.addRow([]);

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Compset Rate Report ${hotelName || ""}.xlsx`;
    a.click();

    window.URL.revokeObjectURL(url);
  } catch (e) {
    console.log(e);
  }
};
