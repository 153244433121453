import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import Dropdown from "../../../../../components/dropdown";
import Table from "../../../../../components/table";
import { Tooltip } from "react-tooltip";
import { AiFillDelete } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import { toast } from "react-toastify";
import { ChannelManagerDetail } from "../../../../../utils/impVars";

const CMOccupancyMapping = ({
  setIsRoomPlanSelected,
  isRoomPlanSelected,
  setSelectedOccupancyPlan,
  selectedOccupancyPlan,
  roomOptions,
  selectedRoomOption,
  setSelectedRoomOption,
  selectedRoomPrice,
  setSelectedRoomPrice,
  ruleMappingData,
  setSelectedOccupancyPlanId,
  selectedOccupancyPlanId,
  selectedPlanRoomId,
  setRuleMappingData,
  occupancyData,
  applyRule,
}) => {
  const [selectedMasterOccupancy, setSelectedMasterOccupancy] = useState();

  const handleChangeValue = (index, type, key, value) => {
    let updatedData = JSON.parse(JSON.stringify(ruleMappingData));

    if (type === "single") {
      updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan[
        selectedOccupancyPlanId
      ].occupancyPlan = updatedData.rooms[
        selectedPlanRoomId
      ].masterRatePlan.ratePlan[selectedOccupancyPlanId].occupancyPlan.map(
        (roomPlan, idx) =>
          idx === index ? { ...roomPlan, [key]: value } : roomPlan
      );
    } else if (type === "nested") {
      updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan[
        selectedOccupancyPlanId
      ].occupancyPlan = updatedData.rooms[
        selectedPlanRoomId
      ].masterRatePlan.ratePlan[selectedOccupancyPlanId].occupancyPlan.map(
        (room, idx) => (idx === index ? { ...room, [key]: value } : room)
      );
    } else if (type === "adjust") {
      let adjust = {
        adjustmentType:
          key === "adjustmentType"
            ? value
            : updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan[
                selectedOccupancyPlanId
              ].occupancyPlan?.[index].adjust.adjustmentType,
        amountChangeType:
          key === "amountChangeType"
            ? value
            : updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan[
                selectedOccupancyPlanId
              ].occupancyPlan?.[index].adjust.amountChangeType,
        adjustmentValue:
          key === "adjustmentValue"
            ? value
            : updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan[
                selectedOccupancyPlanId
              ].occupancyPlan?.[index].adjust.adjustmentValue,
      };
      updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan[
        selectedOccupancyPlanId
      ].occupancyPlan = updatedData.rooms[
        selectedPlanRoomId
      ].masterRatePlan.ratePlan[selectedOccupancyPlanId].occupancyPlan.map(
        (room, idx) => (idx === index ? { ...room, adjust: adjust } : room)
      );
    }

    setRuleMappingData(updatedData);
  };
  const handleRemoveRoomData = (index) => {
    let updatedData = { ...ruleMappingData };
    updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan[selectedOccupancyPlanId].occupancyPlan =
      updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan[selectedOccupancyPlanId].occupancyPlan.filter(
        (_, idx) => idx !== index
      );
      

    setRuleMappingData(updatedData);
  };

  const handleAddOccupancyData = () => {
    if (
      !ruleMappingData?.rooms?.[selectedPlanRoomId]?.masterRatePlan?.ratePlan[
        selectedOccupancyPlanId
      ].occupancyPlan ||
      ruleMappingData?.rooms?.[selectedPlanRoomId]?.masterRatePlan?.ratePlan[
        selectedOccupancyPlanId
      ].masterOccupancy === "" ||
      ruleMappingData?.rooms?.[selectedPlanRoomId]?.masterRatePlan?.ratePlan[
        selectedOccupancyPlanId
      ].masterOccupancy === 0
    ) {
      toast.error(`Select Master occupancy & enter its price first.`, {
        position: "bottom-right",
      });
    } else {
      // Create a copy of the current ruleMappingData
      let updatedData = { ...ruleMappingData };

      updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan[
        selectedOccupancyPlanId
      ].occupancyPlan = [
        ...updatedData.rooms?.[selectedPlanRoomId]?.masterRatePlan?.ratePlan[
          selectedOccupancyPlanId
        ]?.occupancyPlan,
        {
          masterId: selectedMasterOccupancy?.label,
          derivedId: "",
          adjust: {
            adjustmentType: "percent",
            amountChangeType: "increase",
            adjustmentValue: 0,
          },
        },
      ];
      setRuleMappingData(updatedData); // Set directly
    }
  };

  const [occupancyOptions, setOccupancyOptions] = useState();
  const [occupancyMap, setOccupancyMap] = useState();

  useEffect(() => {
    let roomPlanOptionsT = occupancyData.map((itm) => ({
      label: itm?.occupancyName,
      value: itm?.occupancyId,
    }));
    const occMap = new Map(
      occupancyData?.map((entry, id) => [
        entry.occupancyName,
        entry.occupancyId,
      ])
    );
    setOccupancyMap(occMap);
    setOccupancyOptions(roomPlanOptionsT);
  }, [occupancyData]);
  const occupancyMapColumns = [
    {
      name: "Derived Occupancy",
      selector: (row) => row["derivedId"],
      cell: (row, index) => {
        return (
          <div>
            {row["derivedId"] && row["derivedId"] !== "" ? (
              <div className="flex flex-row">
                <p className="text-[12px] text-left">
                  {row["derivedId"]?.occupancyPlan}
                </p>
                <button
                  onClick={() => {
                    handleChangeValue(index, "single", "derivedId", "");
                  }}
                >
                  {index !== 0 && (
                    <MdDeleteOutline className="text-white text-[20px]" />
                  )}
                </button>
              </div>
            ) : (
              <select
                onChange={(e) => {
                  let roomIdd = occupancyMap.get(e.target.value);
                  let roomObj = {
                    occupancyId: roomIdd,
                    occupancyPlan: e.target.value,
                  };
                  handleChangeValue(index, "single", "derivedId", roomObj);
                }}
                className="focus:outline-none text-black border-none outline-none py-0 px-2 w-[100%] bg-gray-200 my-2 rounded-md h-8  text-xs "
              >
                <option value="">Select Occupancy</option>
                {occupancyOptions &&
                  occupancyOptions
                    ?.filter((itm) => {
                      return itm.label !== occupancyOptions?.label;
                    })
                    .map((item) => {
                      return <option key={item?.label}>{item?.label}</option>;
                    })}
              </select>
            )}
          </div>
        );
      },
    },
    {
      name: "Master Occupancy",
      selector: (row) => row["masterId"],
      cell: (row) => {
        return (
          <div
            data-tooltip-content={row["masterId"]}
            data-tooltip-id={row["masterId"]}
            className="text-left text-[12px]"
          >
            {row["masterId"]}
            <Tooltip place="bottom" id={row["masterId"]} />
          </div>
        );
      },
    },
    {
      name: "Adjust",
      selector: (row) => row["adjust.adjustmentType"],
      cell: (row, index) => {
        return (
          <div className="flex gap-2">
            <button
              className={`${row?.adjust.adjustmentType === "percent" ? "bg-blue-800 text-white" : " text-white"} cursor-pointer rounded-lg px-4 py-2`}
              onClick={() =>
                handleChangeValue(index, "adjust", "adjustmentType", "percent")
              }
              disabled={index === 0 ? true : false}
            >
              %
            </button>
            <button
              className={`${row?.adjust.adjustmentType === "amount" ? "bg-blue-800 text-white" : "text-white"} cursor-pointer rounded-lg px-4 py-2`}
              onClick={() =>
                handleChangeValue(index, "adjust", "adjustmentType", "amount")
              }
              disabled={index === 0 ? true : false}
            >
              ₹
            </button>
          </div>
        );
      },
    },
    {
      name: "Amount",
      selector: (row) => row["adjust.amountChangeType"],
      cell: (row, index) => {
        return (
          <div className="flex gap-2">
            <button
              className={`${row?.adjust.amountChangeType === "decrease" ? "bg-blue-800 text-white " : "text-white"} cursor-pointer rounded-lg px-4 py-2`}
              onClick={() =>
                handleChangeValue(
                  index,
                  "adjust",
                  "amountChangeType",
                  "decrease"
                )
              }
              disabled={index === 0 ? true : false}
            >
              -
            </button>
            <button
              className={`${row?.adjust.amountChangeType === "increase" ? "bg-blue-800 text-white " : "text-white"} cursor-pointer rounded-lg px-4 py-2`}
              onClick={() =>
                handleChangeValue(
                  index,
                  "adjust",
                  "amountChangeType",
                  "increase"
                )
              }
              disabled={index === 0 ? true : false}
            >
              +
            </button>
          </div>
        );
      },
    },
    {
      name: "Adjustment Value",
      selector: (row) => row["adjust.adjustmentValue"],
      cell: (row, index) => {
        return (
          <input
            className="py-2 rounded-lg px-1 text-sm focus:outline-none w-[70%] text-black"
            onChange={(e) =>
              handleChangeValue(
                index,
                "adjust",
                "adjustmentValue",
                e.target.value
              )
            }
            disabled={index === 0 ? true : false}
            value={row.adjust.adjustmentValue}
          />
        );
      },
    },
    {
      name: "Total Value",
      selector: (row) => row["totalValue"],
      cell: (row, index) => {
        console.log(row, "aman row");
        let totalVal = applyRule(
          Number(
            applyRule(
              Number(
                applyRule(
                  Number(ruleMappingData?.masterRoom?.price),
                  ruleMappingData.rooms[selectedPlanRoomId].adjust
                )
              ),
              ruleMappingData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan[
                selectedOccupancyPlanId
              ].adjust
            )
          ),
          ruleMappingData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan[
            selectedOccupancyPlanId
          ].occupancyPlan[index].adjust
        );
        return (
          <input
            className="py-2 rounded-lg px-1 text-sm focus:outline-none w-[70%] text-black"
            disabled={true}
            onChange={
              (e) => {}
              // handleChangeValue(index, "adjustValue", e.target.value)
            }
            value={totalVal}
          />
        );
      },
    },
    {
      name: "Actions",
      selector: (row) => row["derivedRoom"],
      cell: (row, index) => {
        return (
          <div className="flex flex-row gap-2 ">
            {index !== 0 && (
              <button
                className=""
                onClick={() => {
                  handleRemoveRoomData(index);
                }}
              >
                <AiFillDelete className="text-red-700" />{" "}
              </button>
            )}
          </div>
        );
      },
    },
  ];

  let channelManagerDetail = ChannelManagerDetail();
  channelManagerDetail = JSON.parse(channelManagerDetail);
  let channelManagerId = channelManagerDetail?.["id"]

  return (
    <>
      <div className="mt-8">
        <div className="flex flex-row justify-start items-center gap-2">
          <button
            className="bg-blue-700 h-[35px] w-[35px] flex justify-center items-center rounded-md my-[10px]"
            onClick={() => {
              setIsRoomPlanSelected(!isRoomPlanSelected);
              setSelectedOccupancyPlan("");
              setSelectedOccupancyPlanId();
            }}
          >
            <FaArrowLeft className="text-white text-[18px]" />
          </button>
          <div className="text-md mb-2 text-lightFontColor ">
            Select Occupancy & its adjustments for {selectedOccupancyPlan}:
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            <div className="bg-black/40 rounded-lg w-fit">
              <Dropdown
                options={occupancyOptions && occupancyOptions}
                right={-100}
                selectedItem={
                  ruleMappingData.rooms[selectedPlanRoomId].masterRatePlan
                    .ratePlan[selectedOccupancyPlanId].masterOccupancy.label
                }
                setSelectedItems={(e) => {
                  let test = { ...ruleMappingData };
                  test.rooms[selectedPlanRoomId].masterRatePlan.ratePlan[
                    selectedOccupancyPlanId
                  ].masterOccupancy = { value: e.value, label: e.label };
                  setSelectedMasterOccupancy(e);

                  if (e && e?.label !== "") {
                    if (
                      test.rooms[selectedPlanRoomId].masterRatePlan.ratePlan[
                        selectedOccupancyPlanId
                      ].occupancyPlan.length === 0
                    ) {
                      test.rooms[selectedPlanRoomId].masterRatePlan.ratePlan[
                        selectedOccupancyPlanId
                      ].occupancyPlan = [
                        ...test.rooms?.[selectedPlanRoomId]?.masterRatePlan
                          ?.ratePlan[selectedOccupancyPlanId]?.occupancyPlan,
                        {
                          masterId: e?.label,
                          derivedId: {
                            occupancyId: e?.id,
                            occupancyPlan: e?.label,
                          },
                          adjust: {
                            adjustmentType: "percent",
                            amountChangeType: "increase",
                            adjustmentValue: 0,
                          },
                        },
                      ];
                    } else {
                      test.rooms[selectedPlanRoomId].masterRatePlan.ratePlan[
                        selectedOccupancyPlanId
                      ].occupancyPlan[0] = 
                        {
                          masterId: e?.label,
                          derivedId: {
                            occupancyId: e?.id,
                            occupancyPlan: e?.label,
                          },
                          adjust: {
                            adjustmentType: "percent",
                            amountChangeType: "increase",
                            adjustmentValue: 0,
                          },
                        }
                    }
                  }

                  setRuleMappingData(test);
                }}
              />
            </div>
            <div className="bg-black/25 rounded-lg w-fit py-0.5">
              <input
                placeholder="Enter rate"
                type="number"
                value={applyRule(
                  Number(
                    applyRule(
                      Number(ruleMappingData?.masterRoom?.price),
                      ruleMappingData.rooms[selectedPlanRoomId].adjust
                    )
                  ),
                  ruleMappingData.rooms[selectedPlanRoomId].masterRatePlan
                    .ratePlan[selectedOccupancyPlanId].adjust
                )}
                min={0}
                disabled={true}
                onChange={(e) => setSelectedRoomPrice(e.target.value)}
                className="text-sm focus:outline-none  bg-transparent text-white placeholder:text-white px-2"
              />
            </div>
          </div>
          <div className="text-lightFontColor flex items-center gap-4">
            <div
              className="] cursor-pointer text-sm"
              onClick={handleAddOccupancyData}
            >
              Add More
            </div>
            {/* <div className=" cursor-pointer text-sm">Adjust Rate Plan</div> */}
          </div>
        </div>
      </div>
      <div className="h-[400px] scroll-container">
        <Table
          columns={occupancyMapColumns}
          data={
            ruleMappingData?.rooms?.[selectedPlanRoomId]?.masterRatePlan
              ?.ratePlan[selectedOccupancyPlanId].occupancyPlan
          }
        />
        {/* <CustomCMMappingTable
          // data={ruleMappingData}
          /> */}
      </div>
    </>
  );
};

export default CMOccupancyMapping;
