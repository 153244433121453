import React from "react";
import img1 from "../../assets/icons/Okay.svg";
import img2 from "../../assets/icons/false.svg";
import formatCurrency from "../../utils/formatCurrency";
import { ProgressDoughnut } from "../../pages/rateSuggestion";
import { calculatePercentage } from "../../utils/calculatePercentage";
import increase from "../../assets/icons/increase.svg";
import down from "../../assets/icons/decrease.svg";
import { useIsIpad } from "../../utils/isIpad";

export function isDateInThePast(dateNumber) {
  // Get the current date
  const currentDate = new Date();

  // Get the day of the month from the current date
  const currentDay = currentDate.getDate();

  // Compare with the provided date number
  return dateNumber < currentDay;
}

const RateSuggestionCard = ({
  number,
  rate,
  isTrue,
  isSelected,
  suggestedRate,
  showSuggestedLabel = true,
  height,
  showOccupancy,
  occupancyPercentage,
  isPastDate,
  selectedType,
  overRideRate,
  isDynamic,
}) => {
  const containerClasses = `relative border border-black/10 flex flex-col drop-shadow-lg backdrop-blur-md overflow-hidden rounded-lg  dark:bg-subMainDark   w-[100%] p-2 ${
    isSelected ? "bg-newGradient dark:bg-lightGradient" : isPastDate ? "bg-white/20 dark:bg-[#3f485bab]" : "bg-[#485c9d8f] dark:bg-dark-gradient"
  }`;

  const numberClasses = `flex justify-end items-start text-[14px] text-[#666666]  dark:text-mainDark ${
    isSelected ? "text-white" : "text-[#80b3ff]"
  } font-semibold`;

  const rateClasses = `${isSelected ? "text-white" : "text-[#c5d2ff]"}`;
  const isIpad = useIsIpad();
  return (
    <div
      className={containerClasses}
      style={{
        height: `${height}px`,
        // background: isSelected && "linear-gradient(181deg, #081439, #403b7c)",
      }}
    >
      <div className={numberClasses}>{number}</div>

      {showOccupancy && (
        <div className="absolute left-2" title="Occupancy">
          <ProgressDoughnut
            fontSize={"12px"}
            percentage={occupancyPercentage || 0}
            height={20}
            width={20}
            textColor={isSelected ? "white" : "black"}
          />
        </div>
      )}
      <div className="h-[100%] w-[100%] flex flex-col justify-center items-between">
        <div className="w-[100%] flex justify-start md:justify-between md:flex-row flex-col md:items-center">
          {overRideRate != 0 && !isDynamic && !isPastDate ? (
            <div
              title={`${selectedType === "Suggestion" ? "Suggested Rate" : "Dynamic Rate"}`}
              className={`${rateClasses}  ${isPastDate && "-mt-6"} flex text-xs text-start whitespace-nowrap  w-full md:w-[60%]`}
              style={{ fontWeight: 500 }}
            >
              <strike>
                {" "}
                {isPastDate
                  ? formatCurrency(rate)
                  : formatCurrency(suggestedRate)}{" "}
              </strike>
            </div>
          ) : (
            <div
              title={`${selectedType === "Suggestion" ? "Suggested Rate" : "Dynamic Rate"}`}
              className={`${rateClasses}  ${isPastDate && "-mt-6"}  flex text-md text-start w-full md:w-[60%] whitespace-nowrap`}
              style={{ fontWeight: 550 }}
            >
              {isPastDate
                ? formatCurrency(rate)
                : formatCurrency(suggestedRate)}
            </div>
          )}
          {overRideRate != 0 && !isPastDate && (
            <div
              title="Overridden rate"
              className={`${rateClasses}  ${isPastDate && ""} text-sm text-end w-[100%]`}
            >
              {formatCurrency(overRideRate)}
            </div>
          )}
        </div>

        {!isPastDate && (
          <div className="flex justify-between items-center">
            <div
              title="Actual Rate"
              className={`text-xs md:text-[12px] text-start  mt-2 w-[100%]  ${isSelected ? "text-white" : "text-[#ffc374]"}`}
            >
              {showSuggestedLabel && (
                <span className="text-sm md:text-[12px] font-light "> </span>
              )}{" "}
              {formatCurrency(rate) === "₹0.00" ? "N/A" : formatCurrency(rate)}
            </div>
            <div>
              {!isPastDate &&
                calculatePercentage(rate, suggestedRate).toFixed(0) !==
                  "Infinity" && (
                  <div
                    className={`${isIpad ? "text-[8px] w-[52px] " : "text-xs w-[60px]"} md:text-[10px] mt-1   py-1 rounded-lg md:px-2 justify-start md:justify-between ${calculatePercentage(rate, suggestedRate).toFixed(0).toString().includes("-") ? "bg-[#ef8f8f] text-[#cc2929]" : "bg-[#3dd598] text-[#0f6b42]"}  flex gap-2 items-center`}
                  >
                    <img
                      src={
                        calculatePercentage(rate, suggestedRate)
                          .toFixed(0)
                          .toString()
                          .includes("-")
                          ? down
                          : increase
                      }
                      width={isIpad && 13}
                    />
                    {calculatePercentage(rate, suggestedRate).toFixed(0)}%
                  </div>
                )}
            </div>
          </div>
        )}
      </div>

      {/* <div className={`absolute -bottom-[4px] -right-[2px]`}>
                {isSelected ? null : (
                    <img className='h-10' src={isTrue ? img1 : img2} alt='' />
                )}
            </div> */}
    </div>
  );
};

export default RateSuggestionCard;
