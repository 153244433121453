import React from "react";
import Img from '../../assets/img/empty.png'

const NoDataFound = (isWhite) => {
  return (
    <div className="grid justify-center mt-10 text-gray-500">
      <div className={`${isWhite ? 'text-white':'dark:text-black text-black'} w-full text-center `}>Data not found.</div>
      <div>
        <img className="w-24 m-6" src={Img} alt="" />
      </div>
    </div>
  );
};

export default NoDataFound;
