import ExcelJS from "exceljs";
import { logoUrl } from "./retvensLogo.js";
import { formatDate } from "./formatDate.js";

export const DownloadCompsetReport = async (data, hotelName, dayStart) => {
  try {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data");
    

    const logoBase64 = logoUrl; // Add image at B1
    const logoId = workbook.addImage({
      base64: logoBase64,
      extension: "jpg",
    });
    worksheet.mergeCells("A1:G3");
    worksheet.addImage(logoId, "A1:A3");
    const columns = ["A", "B", "C", "D", "E", "F", "G"];
    const columnWidths = [15, 15, 15, 15, 15, 15, 15]; // Adjust widths as needed
    columns.forEach((column, index) => {
      worksheet.getColumn(column).width = columnWidths[index];
    });
  
    // Display today's date at J1
    const dateCell = worksheet.getCell("G1");
    dateCell.value = new Date();
    dateCell.numFmt = "dd mmmm yyyy";
  
    // Move main heading to A2 to G2 and increase row height
    const mainHeading = worksheet.getCell("A4");
    mainHeading.value = `Compset Rates Report \nHotel ${hotelName} (${formatDate(
      dayStart
    )})`;
    mainHeading.alignment = { horizontal: "center", vertical: "middle" };
  
    mainHeading.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ED7D31" }, // Yellow color
    };
    worksheet.mergeCells("A4", "G4");
    worksheet.getRow(4).height = 40; // Increase row height
    worksheet.mergeCells("A5", "G5");
  
    data.map((datesData) => {
      datesData.map((DATA3, id) => {
        if (id !== 0) {
          console.log(DATA3, "row", id);
          worksheet.addRow(DATA3).eachCell((cell) => {
            if (cell.value === "Sold out") {
              cell.font = { color: { argb: "0000FF" } }; // Set font color to blue (hex: 0000FF)
            }
            if (id % 2 != 0) {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "eccbb4" },
              };
            }
            cell.alignment = {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            };
          });
        } else {
          worksheet.addRow(DATA3).eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "ED7D31" },
            };
            cell.border = {
              top: { style: "thin", color: { argb: "ffffff" } },
            };
            cell.alignment = {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            };
          });
        }
      });
      worksheet.addRow([]);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Compset Rate Report ${hotelName || ''}.xlsx`;
    a.click();

    window.URL.revokeObjectURL(url);
  } catch (e) {
    console.log(e);
  }
};
