import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Sidebar from "../../components/sidebar";
import logo from "../../assets/img/rateX.svg";
import bellIcon from "../../assets/icons/SVG.svg";
import dotIcon from "../../assets/icons/__before.svg";
import profile from "../../assets/img/8.svg";
import DownloadButton from "../../components/downloadsButton/DownloadButton";
import RefreshButton from "../../components/refreshData/RefreshButton";
import Profile from "../../components/profile";
import Home from "../../assets/icons/home.svg";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoClose } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDistanceToNow, isToday, isYesterday, subDays } from "date-fns";
import { MdArrowDropUp, MdOutlineArrowDropDown } from "react-icons/md";

import {
  AiFillBell,
  AiFillCaretUp,
  AiFillCloseCircle,
  AiFillHome,
  AiOutlineDown,
  AiOutlineHome,
  AiOutlineLogout,
  AiOutlineReload,
  AiOutlineSetting,
  AiOutlineUp,
} from "react-icons/ai";
import { LiaHandPointer } from "react-icons/lia";
import {
  useOnDemandBookingsMutation,
  useOnDemandRanksMutation,
  useOnDemandRatesMutation,
  useOnDemandSuggestedRatesMutation,
} from "../../redux/slices/onDemand";
import io from "socket.io-client";
import { CurrentProperty, UserId } from "../../utils/impVars";
import { toast } from "react-toastify";
import { setIsAdmin } from "../../redux/states/admin";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "../../components/backdrop";
import { useGetNotificationsQuery } from "../../redux/slices/utils";
import AdminNavbar from "../../components/adminNavbar";
import { useIsIpad } from "../../utils/isIpad";

import lit from "../../assets/icons/lit.png";
import { BackButton } from "../../pages/rateSuggestion/rateAnalysis";
import { useGetLoginInfoQuery } from "../../redux/slices/settings/userManagement";
import ratexDark from "../../assets/rateXdarkBg.jpeg";
import ratexLight from "../../assets/rateXlightBg.png";
import sunpic from "../../assets/img/Sun.svg";
import sunbg from "../../assets/img/sunBg.svg";
import moon from "../../assets/img/Moon.svg";
import moonbg from "../../assets/img/moonBg.svg";
function breakCamelCaseAndCapitalize(str) {
  // Break camelCase and capitalize the first word
  return (
    str
      // Add a space before each capital letter
      .replace(/([A-Z])/g, " $1")
      // Capitalize the first letter of the entire string
      .replace(/^./, function (match) {
        return match.toUpperCase();
      })
  );
}

const formatRelativeTimeShort = (timeStamp) => {
  const distance = formatDistanceToNow(timeStamp, { addSuffix: true });

  return distance
    .replace(/seconds?/, "sec")
    .replace(/minutes?/, "min")
    .replace(/hours?/, "hrs")
    .replace(/days?/, "days")
    .replace(/weeks?/, "wks")
    .replace(/months?/, "mths")
    .replace(/years?/, "yrs")
    .replace(/about/, "About");
};

const groupNotificationsByTime = (notificationsData) => {
  const grouped = [
    { name: "Today", notifications: [] },
    { name: "Yesterday", notifications: [] },
    { name: "This Week", notifications: [] },
    { name: "Earlier", notifications: [] },
  ];

  const addNotificationToGroup = (group, notification) => {
    let categoryGroup = group.notifications.find(
      (catGroup) => catGroup.category === notification.category
    );
    if (!categoryGroup) {
      categoryGroup = { category: notification.category, viewNotification: [] };
      group.notifications.push(categoryGroup);
    }
    categoryGroup.viewNotification.push(notification);
  };

  notificationsData?.forEach((notification) => {
    notification?.notifications?.forEach((item) => {
      const timeStamp = new Date(item?.timeStamp);

      if (isNaN(timeStamp.getTime())) {
        console.error("Invalid time value:", item?.timeStamp);
        return; // Skip this notification if the timestamp is invalid
      }

      const notificationCopy = { ...item };
      notificationCopy.relativeTime = formatRelativeTimeShort(timeStamp);
      if (isToday(timeStamp)) {
        notificationCopy.key = "Today";
        addNotificationToGroup(
          grouped.find((group) => group.name === "Today"),
          notificationCopy
        );
      } else if (isYesterday(timeStamp)) {
        notificationCopy.key = "Yesterday";
        addNotificationToGroup(
          grouped.find((group) => group.name === "Yesterday"),
          notificationCopy
        );
      } else if (timeStamp >= subDays(new Date(), 7)) {
        notificationCopy.key = "This Week";
        addNotificationToGroup(
          grouped.find((group) => group.name === "This Week"),
          notificationCopy
        );
      } else {
        notificationCopy.key = "Earlier";
        addNotificationToGroup(
          grouped.find((group) => group.name === "Earlier"),
          notificationCopy
        );
      }
    });
  });

  return grouped;
};

function getTimeDifference(request, actual) {
  const requestTime = new Date();
  const actualTime = new Date(actual);

  const timeDifference = Math.abs(actualTime - requestTime);
  const secondsDifference = Math.floor(timeDifference / 1000);
  const minutesDifference = Math.floor(secondsDifference / 60);
  const hoursDifference = Math.floor(minutesDifference / 60);
  const daysDifference = Math.floor(hoursDifference / 24);

  if (daysDifference > 0) {
    return `${daysDifference} day${daysDifference > 1 ? "s" : ""} ago`;
  } else if (hoursDifference > 0) {
    return `${hoursDifference} hr${hoursDifference > 1 ? "s" : ""} ago`;
  } else if (minutesDifference > 0) {
    return `${minutesDifference} min${minutesDifference > 1 ? "s" : ""} ago`;
  } else {
    return `${secondsDifference} sec${secondsDifference > 1 ? "s" : ""} ago`;
  }
}

export function formatString(str) {
  // Remove leading '/' if exists
  str = str.replace(/^\//, "");

  // Split string by underscore or camel case
  let words = str.split(/_|(?=[A-Z])/);

  // Capitalize the first letter of each word
  words = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  // Join the words with space
  return words.join(" ");
}

const TopBar = ({ open, children }) => {
  const [showAdminBar, setShowAdminBar] = useState(false);
  const [showNotification, setShowNotification] = useState();
  const [refresh, setRefresh] = useState(false);
  const location = useLocation();
  const [toggle, setToggle] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState();
  const [selectedSubItem, setSelectedSubItem] = useState();
  const [width, setWidth] = useState("100%");
  const [profilePic, setProfilePic] = useState(null);
  const [name, setName] = useState(null);
  const [designation, setDesignation] = useState(null);
  const [selectRefresh, setSelectRefresh] = useState(null);
  const [onDemandIsLoading, setOnDemandIsLoading] = useState({
    bookings: false,
    ranks: false,
    rates: false,
    suggestedRate: false,
  });
  const [onDemandData, setOnDemandData] = useState();
  const [onDemandBookings] = useOnDemandBookingsMutation();
  const [onDemandRanks] = useOnDemandRanksMutation();
  const [onDemandRates] = useOnDemandRatesMutation();
  const [onDemandSuggestedRates] = useOnDemandSuggestedRatesMutation();
  const { data: getNotification, status } = useGetNotificationsQuery({
    hId: CurrentProperty(),
  });
  const [notificationDropdown, setNotificationDropDown] = useState({
    parentIndex: 0,
    childIndex: 0,
  });
  const [isSideTransition, setIsSideTransition] = useState(true);
  const { pathname } = useLocation();
  const isSettings = location.pathname === "/settings";
  const profileRef = useRef();
  const refreshRef = useRef();
  const { data: loginInfo } = useGetLoginInfoQuery({
    userId: UserId(),
    hId: CurrentProperty(),
  });
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    if (loginInfo) {
      window.localStorage.setItem(
        "channelManagerDetail",
        JSON.stringify(loginInfo?.data?.channelManagerDetail)
      );
    }
  }, [loginInfo]);
  
  useEffect(() => {
    const currentRouteName = window.location.pathname;
    const hasUppercase = /[A-Z]/.test(currentRouteName); // Check if pathname contains uppercase letters

    // Get the last word after the last "/"
    const lastPathSegment = currentRouteName.substring(
      currentRouteName.lastIndexOf("/") + 1
    );

    // Adjust the regex to replace underscores with spaces
    const formattedRouteName = lastPathSegment
      .replace(/_/g, "-") // Replace underscores with hyphens
      .trim() // Remove any leading/trailing whitespace
      .split(" ")
      .map(
        (word, index) =>
          // Capitalize the first letter of each word if pathname is all lowercase and doesn't contain uppercase letters
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join("");

    setSelectedMenuItem(formattedRouteName);

    //   const handleClickOutside = (event) => {
    //     if (profileSectionRef.current && !profileSectionRef.current.contains(event.target)) {
    //       setToggle(false);
    //     }
    //   };

    //   document.addEventListener('dblclick', handleClickOutside);

    //   return () => {
    //     document.removeEventListener('mouseenter', handleClickOutside);
    //   };
  }, []);
  const [isShow, setIsShow] = useState(false);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const roleAdmin = window.localStorage.getItem("role") === "SUPERADMIN";
  const isAdmin = useSelector((state) => state?.isAdmin);
  useEffect(() => {
    if (isAdmin?.value || roleAdmin) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  }, [isAdmin, roleAdmin]);

  useEffect(() => {
    const pic = window.localStorage.getItem("profilePic");
    const userName = window.localStorage.getItem("name");
    const designat = window.localStorage.getItem("designation");
    if (pic) {
      setProfilePic(pic);
    }
    if (userName) {
      setName(userName);
    }
    if (designat) {
      setDesignation(designat);
    }
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef?.current && !profileRef?.current.contains(event.target)) {
        setToggle(false);
      }
      if (refreshRef?.current && !refreshRef?.current.contains(event.target)) {
        setRefresh(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileRef, refreshRef]);

  const handleMenuItemClick = (menuItem, subitem) => {
    if (subitem) {
      setSelectedMenuItem(`Rate Pulse / ${subitem.name}`);
    } else {
      setSelectedMenuItem(menuItem.name || menuItem);
    }
    setSelectedSubItem(subitem ? subitem.name : null);
  };

  useEffect(() => {
    if (isSidebarOpen) {
      setWidth("calc(100% - 15%)");
    }
  }, [isSidebarOpen]);

  const dashboardMenuItems = [
    "Dashboard",
    "Rate Suggestions",
    "Hotel Intelligence",
    "Rates & Inventory",
    "Compset Overview",
    "Reservations",
    {
      name: "Reports",
      subItems: [
        // { name: "Demand Outlook", path: "/rate-pulse/demand_outlook" },
        // { name: "Custom Report", path: "/Report/Custom" },
        { name: "Template Report", path: "/Report/TemplateReport" },
      ],
    },
    {
      name: "Rate Pulse",
      subItems: [
        // { name: "Demand Outlook", path: "/rate-pulse/demand_outlook" },
        { name: "Dashboard", path: "/rate-pulse/dashboard" },
        { name: "Rate Monitor", path: "/rate-pulse/rate_monitor" },
        // { name: "Rate Fluctuations", path: "/rate-pulse/rate_fluctuation" },
        { name: "Reputation", path: "/rate-pulse/reputation" },
        { name: "Visibility Rank", path: "/rate-pulse/visibility" },
      ],
    },
  ];
  useEffect(() => {
    const obj = {
      userId: userId,
      hId: hId,
    };
    if (selectRefresh === "Bookings") {
      setRefresh(false);
      setOnDemandIsLoading((prevState) => ({
        ...prevState,
        bookings: true,
      }));
      onDemandBookings(obj)
        .unwrap()
        .then((res) => {
          toast.success(res?.message, { position: "bottom-right" });
          selectRefresh();
          setOnDemandIsLoading();
        })
        .catch((err) => {
          toast.error(err?.data?.message, { position: "bottom-right" });
        });
    }
    if (selectRefresh === "Rates") {
      setRefresh(false);
      setOnDemandIsLoading((prevState) => ({
        ...prevState,
        rates: true,
      }));
      onDemandRates(obj)
        .unwrap()
        .then((res) => {
          toast.success(res?.message, { position: "bottom-right" });
          setOnDemandIsLoading();
          selectRefresh();
        })
        .catch((err) => {
          toast.error(err?.data?.message, { position: "bottom-right" });
        });
    }
    if (selectRefresh === "Ranks") {
      setRefresh(false);
      setOnDemandIsLoading((prevState) => ({
        ...prevState,
        ranks: true,
      }));
      onDemandRanks(obj)
        .unwrap()
        .then((res) => {
          toast.success(res?.message, { position: "bottom-right" });
          setOnDemandIsLoading();
          selectRefresh();
        })
        .catch((err) => {
          toast.error(err?.data?.message, { position: "bottom-right" });
        });
    }
    if (selectRefresh === "Suggested Rates") {
      setRefresh(false);
      setOnDemandIsLoading((prevState) => ({
        ...prevState,
        suggestedRate: true,
      }));
      onDemandSuggestedRates(obj)
        .unwrap()
        .then((res) => {
          toast.success(res?.message, { position: "bottom-right" });
          setOnDemandIsLoading();
          selectRefresh();
        })
        .catch((err) => {
          toast.error(err?.data?.message, { position: "bottom-right" });
        });
    }
  }, [selectRefresh]);

  useEffect(() => {
    setSelectedMenuItem(`Dashboard`);
    if (pathname === "/") {
      setSelectedMenuItem("Dashboard");
      setHoveredIndex();
    }
    if (pathname === "/rates_&_inventory") {
      setSelectedMenuItem("Rates & Inventory");
      setHoveredIndex();
    }
    if (pathname === "/compset_overview") {
      setSelectedMenuItem("Compset Overview");
      setHoveredIndex();
    }
    if (pathname === "/reports") {
      setSelectedMenuItem("Reports");
      setHoveredIndex();
    }
    if (pathname === "/rate_suggestions") {
      setSelectedMenuItem("Rate Suggestions");
      setHoveredIndex();
    }
    if (pathname === "/reservations") {
      setSelectedMenuItem("Reservations");
      setHoveredIndex();
    }
    if (pathname === "/hotel_intelligence") {
      setSelectedMenuItem("Hotel Intelligence");
      setHoveredIndex();
    }
    if (pathname?.includes("Report")) {
      setSelectedMenuItem("Reports");
      setHoveredIndex();
    }
    if (pathname?.includes("rate-pulse")) {
      setSelectedMenuItem("Rate Pulse");
      setHoveredIndex();
    }
  }, [pathname]);
  const userId = UserId();
  const hId = CurrentProperty();
  useEffect(() => {
    const socket = io("https://rxserver.retvenslabs.com/socket"); // Establish socket connection

    // Function to fetch data from API
    const fetchData = () => {
      // Emit 'getLastModified' event with userId and hId data
      socket.emit("getLastModified", { userId: userId, hId: hId });
    };

    // Initial fetch
    fetchData();

    // Set up interval to fetch data every 3 seconds
    const intervalId = setInterval(fetchData, 3000);

    // Listen for 'getLastModified' event from the server
    socket.on("lastModifiedData", (data) => {
      setOnDemandData(data?.lastModified);
      if (data) {
        setOnDemandIsLoading({
          bookings: data?.lastModified?.bookings?.isRefreshing,
          ranks: data?.lastModified?.ranks?.isRefreshing,
          rates: data?.lastModified?.rates?.isRefreshing,
          suggestedRate: data?.lastModified?.rateSuggestion?.isRefreshing,
        });
      }
    });

    // Clean up the socket connection and interval when component unmounts
    return () => {
      socket.disconnect();
      clearInterval(intervalId);
    };
  }, [selectRefresh]); // Run effect only once on component mount

  const isIpad = useIsIpad();

  const [isTransition, setIstransition] = useState(false);

  useEffect(() => {
    if (showNotification) {
      setTimeout(() => {
        setIstransition(true);
      }, [50]);
    } else {
      setTimeout(() => {
        setIstransition(false);
      }, [50]);
    }
  }, [showNotification]);
  useEffect(() => {
    if (!isTransition) {
      setTimeout(() => {
        setShowNotification(false);
      }, [200]);
    }
  }, [isTransition]);

  // side bar transition

  useEffect(() => {
    if (!isSideTransition) {
      setTimeout(() => {
        setSidebarOpen(false);
      }, [200]);
    }
  }, [isSideTransition]);

  const handleParent = (parentIndex) => {
    if (notificationDropdown.parentIndex === parentIndex) {
      setNotificationDropDown({ parentIndex: -1, childIndex: 0 });
    } else {
      setNotificationDropDown({
        ...notificationDropdown,
        parentIndex: parentIndex,
      });
    }
  };
  const handleChild = (childIndex) => {
    if (notificationDropdown.childIndex === childIndex) {
      setNotificationDropDown({ ...notificationDropdown, childIndex: -1 });
    } else {
      setNotificationDropDown({
        ...notificationDropdown,
        childIndex: childIndex,
      });
    }
  };

  const streakMessages = [
    "Kudos! You're on fire with your streaks! 🔥 Keep the momentum going!",
    "High five! You've just added another day to your streak! ✋",
    "Streak-tastic! 🌟 You've kept it up for another day!",
    "Awesome! Your streak is growing stronger every day! 💪",
    "Way to go! Another day, another streak! 🌈 Keep shining!",
    "Boom! Streak count just went up by one! 🚀",
    "Streak alert! 🌟 You’re on a roll, keep it up!",
    "You're unstoppable! Another day, another streak added! 🏆",
    "Wow! Another day added to your impressive streak! 🎉",
    "You did it again! Your streak just got longer! 🌟 Keep up the great work!",
    "Amazing! Another day, another streak! 🌟",
    "You're a streak superstar! Keep it up! 🌟",
    "Fantastic! Your streak is going strong! 💪",
    "Hooray! Another streak day in the books! 📚",
    "Bravo! You’ve extended your streak once again! 👏",
    "Keep it going! Your streak is impressive! 🌟",
    "Awesome work! Another day added to your streak! 🎉",
    "Keep shining! Your streak is growing every day! 🌟",
    "Well done! Another day, another streak! 🎉",
    "You're on a roll! Keep up the great work! 🏆",
    "Impressive! Your streak just got longer! 🌟",
    "Streak-tacular! Keep it going! 🌟",
    "Another day, another streak! Way to go! 💪",
    "You're streaking ahead! Keep it up! 🚀",
    "Fantastic job! Your streak is unstoppable! 🌟",
    "You're a streak champion! Keep shining! 🏆",
    "Streak status: Strong! Keep it going! 🌟",
    "Amazing job! Your streak is growing! 🎉",
    "Well done! Another streak day added! 📚",
    "Keep the momentum! Your streak is on fire! 🔥",
    "Way to shine! Your streak is impressive! 🌟",
    "Streak success! Keep up the great work! 🎉",
    "Bravo! Your streak is unstoppable! 💪",
    "Awesome! Another day, another streak! 🌟",
    "You're on fire! Keep that streak going! 🔥",
    "Keep it up! Your streak is impressive! 💪",
    "Well done! Another day added to your streak! 🌟",
    "Hooray! Your streak is growing stronger! 🎉",
    "You're unstoppable! Keep that streak alive! 🏆",
    "Fantastic! Another streak day in the books! 📚",
    "You're streaking ahead! Keep it going! 🚀",
    "Amazing! Your streak just got longer! 🌟",
    "Streak alert! Keep up the great work! 🌟",
    "Impressive! Another day, another streak! 🎉",
    "Way to go! Your streak is shining! 🌟",
    "Bravo! Your streak is growing stronger! 💪",
    "Awesome job! Another streak day added! 🌟",
    "Keep it going! Your streak is on fire! 🔥",
    "You're a streak superstar! Keep it up! 🌟",
    "Fantastic! Your streak is growing every day! 💪",
  ];

  function getRandomStreakMessage() {
    const randomIndex = Math.floor(Math.random() * streakMessages.length);
    return streakMessages[randomIndex];
  }

  const [randomMessage, setRandomMessage] = useState("");

  const handleMouseEnter = () => {
    setRandomMessage(getRandomStreakMessage());
  };
  const [isHoverState, setIsHoverState] = useState(false);

  // const propertiesList = window.localStorage.getItem("propertiesList")
  //   ? JSON.parse(window.localStorage.getItem("propertiesList"))
  //   : [];
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ||
      (window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light")
  );
  const body = document.body;

  useEffect(() => {
    if (theme === "dark") {
      document.querySelector(".themeToggle").classList.add("dark");

      body.style.backgroundImage = `url(${ratexLight})`;
      localStorage.setItem("theme", "dark");
    } else {
      document.querySelector(".themeToggle").classList.remove("dark");
      body.style.backgroundImage = `url(${ratexDark})`;
      localStorage.setItem("theme", "light");
    }
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  return (
    <>
      {showAdminBar && isShow && <AdminNavbar />}
      <div className={"relative top-0 overflow-hidden"}>
        {/* <div  className="text-sm absolute left-[45.3%] top-3.5 z-50 flex text-white mt-6 items-center gap-2">
              <div>Powered by</div>
              <img
                src="https://pngimagesfree.com/wp-content/uploads/Google-Ai-Gemini.png"
                width={60}
              />
            </div> */}
        <div className="h-screen flex">
          {showNotification && (
            <Backdrop>
              <div
                className={`flex w-[100%] ${isIpad ? " h-full" : " h-full"} absolute duration-300 transition-all ease-in-out ${isTransition ? "right-0" : "-right-[600px]"} `}
              >
                <div
                  className="w-[60%]"
                  onClick={() => setIstransition(false)}
                ></div>
                <div className="w-[40%] bg-newGradient dark:bg-lightGradient h-[100vh] relative rounded-l-lg">
                  <div className="p-4 flex items-center justify-between">
                    <div className="text-xl text-white">
                      Notifications ({getNotification?.totalNotifications || 0})
                    </div>
                    <div>
                      <AiFillCloseCircle
                        className="cursor-pointer"
                        onClick={() => setIstransition(false)}
                        color="white"
                        size={20}
                      />
                    </div>
                  </div>
                  {status != "rejected" && (
                    <div className="h-[calc(100vh-57px)] bg-newGradient dark:bg-lightGradient flex flex-col justify-start items-start overflow-y-scroll px-4">
                      {getNotification?.data &&
                        groupNotificationsByTime(getNotification?.data)?.map(
                          (item, parentIndex) => (
                            <div key={parentIndex} className="w-full mb-4">
                              <div className="sticky top-0 z-50 py-2 bg-white rounded-md shadow-md">
                                <div
                                  onClick={() => handleParent(parentIndex)}
                                  className="flex cursor-pointer w-full justify-between items-center gap-2 px-2  text-sm font-semibold text-gray-900"
                                >
                                  {item?.name}
                                  {notificationDropdown.parentIndex ===
                                  parentIndex ? (
                                    <MdArrowDropUp size={20} />
                                  ) : (
                                    <MdOutlineArrowDropDown size={20} />
                                  )}
                                </div>
                              </div>
                              {notificationDropdown?.parentIndex ===
                                parentIndex && (
                                <div className="px-2 py-2 my-2 bg-gray-50 rounded-lg shadow-inner">
                                  {item?.notifications?.length > 0 ? (
                                    item?.notifications?.map(
                                      (itm, childIndex) => (
                                        <div
                                          key={childIndex}
                                          className="bg-white py-2 w-full rounded-lg shadow mb-2"
                                        >
                                          <div className="flex items-center justify-between">
                                            <div className="text-black text-sm w-full font-medium">
                                              <h1
                                                onClick={() =>
                                                  handleChild(childIndex)
                                                }
                                                className="px-2 cursor-pointer flex items-center gap-2 justify-between "
                                              >
                                                {itm?.category}
                                                {notificationDropdown.childIndex ===
                                                childIndex ? (
                                                  <MdArrowDropUp size={20} />
                                                ) : (
                                                  <MdOutlineArrowDropDown
                                                    size={20}
                                                  />
                                                )}
                                              </h1>
                                              {notificationDropdown.childIndex ===
                                                childIndex && (
                                                <div className="flex flex-col w-full justify-start items-start gap-2 mt-2">
                                                  {itm?.viewNotification?.map(
                                                    (
                                                      actualNotification,
                                                      notificationIndex
                                                    ) => (
                                                      <div
                                                        key={notificationIndex}
                                                        className="flex justify-between gap-2 items-center w-full p-2 bg-white rounded-md shadow-sm"
                                                      >
                                                        <div className="h-8 w-8 overflow-hidden rounded-full ">
                                                          <img
                                                            src={
                                                              actualNotification?.otaImage
                                                            }
                                                            className="w-full h-full object-cover"
                                                            alt=""
                                                          />
                                                        </div>
                                                        <div className="flex w-full justify-between ">
                                                          <h1 className="w-[80%] text-wrap text-gray-800">
                                                            {
                                                              actualNotification?.notificationMessage
                                                            }
                                                          </h1>
                                                          <h1 className="w-auto text-xs text-right text-gray-500">
                                                            {
                                                              actualNotification?.relativeTime
                                                            }
                                                          </h1>
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )
                                  ) : (
                                    <div className="text-sm mt-1 bg-white p-2 rounded-lg w-full text-gray-600">
                                      Notifications not found!
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )
                        )}
                    </div>
                  )}
                  {status === "rejected" && (
                    <div className="p-4 h-[calc(100vh-57px)] bg-white flex justify-center items-center">
                      {status === "rejected" && (
                        <div>No Notifications Found</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Backdrop>
          )}
          <div className={`bg-none z-40 w-min`}>
            <div
              className={`h-[96vh] rounded-xl bottom-4  ${isSidebarOpen ? "left-0" : "left-4"} backdrop-blur-sm duration-300 transition-all ease-in-out absolute z-40 ${isSidebarOpen || isHoverState ? "min-w-[16%]" : "w-min"} ${isSettings && "hidden"} `}
              style={{
                background:
                  "linear-gradient(327deg, rgb(37 117 174 / 50%), rgb(69 92 156 / 80%))",
              }}
              // style={{
              //   transition: isSidebarOpen ? "width 1s ease" : "",
              // }}
              onMouseOver={() => setIsHoverState(true)}
              // onMouseOut={() => setSidebarOpen(false)}
            >
              <Sidebar
                isSidebarOpen={isSidebarOpen}
                toggleSidebar={toggleSidebar}
                logo={logo}
                menuItems={dashboardMenuItems}
                selectedMenu={selectedMenuItem}
                footerText={"Go to RateShopper"}
                onMenuItemClick={handleMenuItemClick}
                name={name}
                designation={designation}
                setSidebarOpen={setSidebarOpen}
                isHoverState={isHoverState}
                setIsHoverState={setIsHoverState}
                hoveredIndex={hoveredIndex}
                setHoveredIndex={setHoveredIndex}
              />
            </div>
          </div>

          <div
            className="h-[60px]  mt-2 z-40 rounded-2xl drop-shadow-md backdrop-blur-lg  flex justify-between items-center p-4 absolute transition-all ease-in-out duration-200 right-[20%]"
            // className="h-[60px] bg-newGradient z-40 drop-shadow-md backdrop-blur-md  flex justify-between border-b items-center p-4 absolute transition-all ease-in-out duration-200 right-0"
            style={{
              background:
                "linear-gradient(45deg, rgba(59, 83, 143, 0.7), rgba(19, 66, 91, 0.7))",
              backdropFilter: "blur(4px)",
              width:
                isSidebarOpen && !isSettings
                  ? "60%"
                  : `${isSettings ? "60%" : isIpad ? "60%" : "60%"}`,
            }}
          >
            <div
              className={`flex items-center ${isSidebarOpen && isIpad && "relative left-[50px]"}`}
            >
              {!location.pathname.includes("settings") && (
                <div>
                  {isSidebarOpen ? (
                    <div
                      className="bg-[#0d1321] text-white p-2  rounded-lg cursor-pointer"
                      onClick={() => setSidebarOpen(false)}
                    >
                      {" "}
                      <IoClose size={20} />{" "}
                    </div>
                  ) : (
                    <div
                      className="bg-[#0d1321] text-white p-2  rounded-lg cursor-pointer"
                      onClick={() => setSidebarOpen(true)}
                    >
                      <RxHamburgerMenu
                        onClick={() => setSidebarOpen(true)}
                        size={20}
                      />
                    </div>
                  )}
                </div>
              )}

              {location?.pathname === "/" ||
                (location?.pathname === "/dashboard" ? (
                  <></>
                ) : (
                  <div className="ml-4">
                    <BackButton mainDashboard={true} />
                  </div>
                ))}

              <div>
                <div className="font-[500] ml-6 text-[#ffffff] text-[16px]">
                  {location?.pathname === "/"
                    ? "Dashboard"
                    : formatString(location.pathname)}
                </div>
                <div className="font-[400] ml-6 text-[#ffffff] text-[12px] ">
                  {location?.pathname === "/"
                    ? "/Dashboard"
                    : location.pathname}
                </div>{" "}
              </div>
            </div>

            {(isAdmin?.value || roleAdmin) && (
              <div
                onClick={() => setShowAdminBar(!showAdminBar)}
                style={{
                  background: showAdminBar
                    ? "bg-[#3e5c76]"
                    : "linear-gradient(45deg, black, #0000008c)",
                }}
                className={` hover:bg-[#2c2c2e] transition-all ease-in-out duration-150 w-fit px-2 py-1 text-sm text-white rounded-b-lg absolute top-0 left-[42%] cursor-pointer`}
              >
                {showAdminBar ? (
                  <span className="flex items-center gap-2">
                    <AiOutlineUp />
                    Hide Admin Bar
                  </span>
                ) : (
                  <span className="flex items-center gap-2">
                    <AiOutlineDown />
                    Show Admin Bar
                  </span>
                )}
              </div>
            )}
            <div
              className="flex justify-center items-center gap-2"
              ref={refreshRef}
            >
              {!isIpad && (
                <div
                  onMouseEnter={handleMouseEnter}
                  className="flex  relative group items-center text-white bg-black px-2 gap-1 rounded-lg text-sm"
                >
                  <img src={lit} width={35} alt="streak icon" />
                  {loginInfo?.data?.streak}
                  {loginInfo?.data?.streak != 0 && (
                    <div className="absolute z-30 -mr-2 w-[200px] hidden group-hover:block text-orange-100/70 top-10">
                      <AiFillCaretUp size={24} />
                    </div>
                  )}
                  {loginInfo?.data?.streak != 0 && (
                    <div className="absolute w-[200px] max-h-[100px] min-h-fit hidden group-hover:flex top-14 justify-center items-center text-sm text-gray-700 opacity-0 group-hover:opacity-100 transition-all ease-in-out rounded-lg -right-4 z-30 bg-orange-100 p-2">
                      {randomMessage}
                    </div>
                  )}
                </div>
              )}
              <div
                title="Switch theme"
                onClick={toggleTheme}
                className={`relative w-16 h-8  rounded-full cursor-pointer overflow-hidden transition-all duration-300 ${
                  theme === "light" ? "bg-transparent" : "bg-transparent"
                }`}
                style={{
                  backgroundImage: `url(${theme === "light" ? moonbg : sunbg})`,
                  backgroundSize: "cover",
                }}
              >
                {/* Sun/Moon Image */}
                <div
                  className={`absolute w-6 h-6 flex justify-center items-center rounded-full top-[5px] transition-transform duration-300 ${
                    theme === "light"
                      ? "translate-x-[0.1rem]"
                      : "translate-x-[2.5rem]"
                  }`}
                  style={{
                    backgroundImage: `url(${theme === "light" ? moon : sunpic})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
              </div>
              <div className=" relative">
                <div
                  title="Reload Data"
                  onClick={() => setRefresh(!refresh)}
                  className="group text-sm hover:bg-[#00000018] hover:text-black duration-200 transition-all ease-in-out text-black cursor-pointer flex gap-2 items-center p-2 rounded-full"
                >
                  {/* <AiOutlineReload className="" size={20} />  */}
                  <AiOutlineReload color="white" className="" size={20} />
                </div>
                {refresh && (
                  <div className="bg-white h-fit justify-between rounded-b-lg py-3 w-[400px] z-50 absolute top-[45px] -left-[290px]">
                    {["Bookings", "Ranks", "Rates", "Suggested Rates"].map(
                      (item) => {
                        return (
                          <div className=" hover:bg-gray-100 cursor-pointer flex gap-2 justify-between items-center transition-all ease-in-out duration-150">
                            <div className="flex items-center gap-2">
                              <div
                                onClick={() => {
                                  setSelectRefresh(item);
                                }}
                                className={`flex gap-2 items-center px-2 ml-2 bg-[#1D2D44] py-1 rounded-lg text-white text-[10px]`}
                              >
                                <AiOutlineReload
                                  className={`${
                                    onDemandIsLoading &&
                                    onDemandIsLoading[
                                      item === "Bookings"
                                        ? "bookings"
                                        : item === "Ranks"
                                          ? "ranks"
                                          : item === "Suggested Rates"
                                            ? "suggestedRate"
                                            : item === "Rates" && "rates"
                                    ]
                                      ? "rotate"
                                      : ""
                                  }`}
                                  size={12}
                                />
                              </div>
                              <div className="text-gray-800 w-fit flex text-sm items-center text-start py-2 ">
                                {item} &nbsp;{" "}
                                <span className="font-medium text-lg text-green-500">
                                  {
                                    onDemandData?.[
                                      item === "Bookings"
                                        ? "bookings"
                                        : item === "Ranks"
                                          ? "ranks"
                                          : item === "Suggested Rates"
                                            ? "rateSuggestion"
                                            : item === "Rates" && "rates"
                                    ]?.refreshCount
                                  }
                                </span>
                                /
                                <span>
                                  {
                                    onDemandData?.[
                                      item === "Bookings"
                                        ? "bookings"
                                        : item === "Ranks"
                                          ? "ranks"
                                          : item === "Suggested Rates"
                                            ? "rateSuggestion"
                                            : item === "Rates" && "rates"
                                    ]?.totalCount
                                  }
                                </span>
                              </div>
                            </div>

                            <div className="text-[10px] text-gray-400 px-2">
                              Last Updated{" "}
                              <span className="text-[12px] text-gray-600">
                                {getTimeDifference(
                                  onDemandData?.[
                                    item === "Bookings"
                                      ? "bookings"
                                      : item === "Ranks"
                                        ? "ranks"
                                        : item === "Suggested Rates"
                                          ? "rateSuggestion"
                                          : item === "Rates" && "rates"
                                  ]?.request,
                                  onDemandData?.[
                                    item === "Bookings"
                                      ? "bookings"
                                      : item === "Ranks"
                                        ? "ranks"
                                        : item === "Suggested Rates"
                                          ? "rateSuggestion"
                                          : item === "Rates" && "rates"
                                  ]?.actual
                                ) !== NaN
                                  ? getTimeDifference(
                                      onDemandData?.[
                                        item === "Bookings"
                                          ? "bookings"
                                          : item === "Ranks"
                                            ? "ranks"
                                            : item === "Suggested Rates"
                                              ? "rateSuggestion"
                                              : item === "Rates" && "rates"
                                      ]?.request,
                                      onDemandData?.[
                                        item === "Bookings"
                                          ? "bookings"
                                          : item === "Ranks"
                                            ? "ranks"
                                            : item === "Suggested Rates"
                                              ? "rateSuggestion"
                                              : item === "Rates" && "rates"
                                      ]?.actual
                                    )
                                  : "--"}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
              </div>
              <div
                title="Notifications"
                onClick={() => setShowNotification(true)}
                className="text-sm hover:bg-[#00000018] hover:text-black duration-200 transition-all ease-in-out text-black cursor-pointer flex gap-2 items-center p-2 rounded-full"
              >
                {getNotification?.totalCount > 0 && (
                  <div>
                    {getNotification?.totalCount > 0
                      ? getNotification?.totalCount
                      : ""}
                  </div>
                )}
                <AiFillBell color="white" size={20} />
              </div>
              <div
                className="flex relative items-center justify-center  pl-2"
                ref={profileRef}
              >
                <div
                  onClick={() => setToggle(!toggle)}
                  className="w-12 h-12 overflow-hidden rounded-full cursor-pointer"
                >
                  <img
                    src={profilePic ? profilePic : profile}
                    alt="Profile"
                    className="w-full h-full object-cover"
                  />
                </div>
                {toggle && (
                  <div className="absolute w-[200px] right-0 top-[60px] z-[50] drop-shadow-3xl rounded-lg h-fit overflow-hidden bg-white">
                    <ProfileSection
                      profilePic={profilePic ? profilePic : profile}
                      name={`Hi, ${name?.split(" ")[0]}`}
                      designation={designation}
                      setToggle={() => setToggle(false)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`top-[0px] pt-20 ${isSettings ? "w-[100%] " : isIpad ? "w-[92%]" : isSidebarOpen ? "w-[84%]" : "w-[94%] "} absolute right-0 transition-all   ease-in-out duration-300 scroll-container overflow-x-hidden md:overflow-x-auto h-[100vh] `}
          // style={{
          //   width:
          //     isSidebarOpen && !isSettings
          //       ? width
          //       : `${isSettings ? "100%" : "calc(100% - 5%)"}`,
          // }}
          
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default TopBar;

const ProfileSection = ({ setToggle, profilePic, name, designation }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  return (
    <div>
      <div
        className="px-4 relative z-50 hover:bg-gray-100 border-b cursor-pointer"
        onClick={() => {
          // navigate("/settings");
          // setToggle();
        }}
      >
        <Profile
          profilePic={profilePic}
          name={name}
          designation={designation}
        />
      </div>

      <div
        className="cursor-pointer flex gap-4 py-2 items-center pl-8  border-b hover:text-black hover:bg-gray-100  text-[#59647B] text-sm"
        onClick={() => {
          navigate(location.pathname === "/settings" ? "/" : "/settings");
          setToggle();
        }}
      >
        <div className="">
          {location.pathname === "/settings" ? (
            <AiOutlineHome size={20} />
          ) : (
            <AiOutlineSetting size={20} />
          )}
        </div>
        <p>{location.pathname === "/settings" ? "Dashboard" : "Settings"}</p>
      </div>
      <div
        className="cursor-pointer flex gap-4 py-2 items-center pl-8 hover:text-black hover:bg-gray-100  text-[#59647B] text-sm rounded-b-lg"
        // onClick={handleLogout}
        onClick={() => {
          window.localStorage.clear();
          navigate("/login");
          dispatch(setIsAdmin(false));
          setToggle("");
          window.location.reload();
        }}
      >
        <div className="">
          <AiOutlineLogout color="red" size={20} />
        </div>
        <p>Logout</p>
      </div>
    </div>
  );
};
