import { useEffect, useState } from "react";

export const useIsIpad = () => {
  const [isIpad, setIsIpad] = useState(false);

  useEffect(() => {
    const checkIfIpad = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isIpadUserAgent = /iPad/.test(userAgent);
      
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      
      const ipadDimensions = [
        { width: 768, height: 1024 }, // iPad Mini Portrait
        { width: 1024, height: 768 }, // iPad Mini Landscape
        { width: 820, height: 1180 }, // iPad Air Portrait
        { width: 1180, height: 820 }, // iPad Air Landscape
        { width: 834, height: 1194 }, // iPad Pro 11 Portrait
        { width: 1194, height: 834 }, // iPad Pro 11 Landscape
        { width: 1024, height: 1366 }, // iPad Pro 12.9 Portrait
        { width: 1366, height: 1024 }, // iPad Pro 12.9 Landscape
        { width: 707, height: 1080 }, // iPad 8th Gen Portrait
        { width: 1080, height: 707 }, // iPad 8th Gen Landscape
        { width: 695, height: 1080 }, // iPad 8th Gen Portrait Chrome
        { width: 1080, height: 695 } , // iPad 8th Gen Landscape Chrome
        { width: 1080, height: 740 } , // iPad 8th Gen Landscape Chrome
        { width: 1280, height: 658 }, // iPad 8th Gen Landscape Chrome
        { width: 727, height: 1194 },
        { width: 791, height: 1194 },  // iPad 8th Gen Landscape Chrome
        { width: 1194, height: 791 }  // iPad 8th Gen Landscape Chrome
      ];

      const isIpadDimension = ipadDimensions.some(
        dim =>
          (screenWidth === dim.width && screenHeight === dim.height) ||
          (screenWidth === dim.height && screenHeight === dim.width)
      );

      setIsIpad(isIpadUserAgent || isIpadDimension);
      
      // Log the dimensions for debugging
    };

    const handleResize = () => {
      setTimeout(checkIfIpad, 100); // Throttle resize events
    };

    checkIfIpad();
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  return isIpad;
};
