import React from "react";
import ProfileSideBar from "../../../components/profile/ProfileSideBar";

const SwitchProperty = () => {
  return <div className="px-4 text-black">
    
  </div>;
};

export default SwitchProperty;
