import { useSelector } from "react-redux";

 export const CurrentProperty = () => {
    const loginData = useSelector((state)=>state?.loginData)
   return window.localStorage.getItem('hId') || loginData?.currentProperty?.propertyId ;
 }

 export const UserId = () => {
    const loginData = useSelector((state)=>state?.loginData)
   return loginData?.userId || window.localStorage.getItem('userId')
 }

 export const ChannelManagerDetail = () => {
  const channelManagerDetail = useSelector((state)=>state?.channelManagerDetail)
 return channelManagerDetail || window.localStorage.getItem('channelManagerDetail')
}
 
 export const ExtractionDate = () => {
    const loginData = useSelector((state)=>state?.loginData)
   return loginData?.lastExraction
 }
 
 export const Permissions = () => {
    const loginData = useSelector((state)=>state?.loginData)
   return loginData?.permissions
 }

 export const Name = () => {
    const loginData = useSelector((state)=>state?.loginData)
   return loginData?.name  || window.localStorage.getItem('name')
 }

 export const PulseExtractionDate = () =>{
  const loginData = useSelector((state)=>state?.loginData)
  const localExt = JSON.parse(window.localStorage.getItem('extractionDate'))?.e
  return loginData?.lastExraction?.e || localExt
 }
 
 